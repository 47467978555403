/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
export type TicketRequestReturnProductCardModalFormFragment = { __typename?: 'TicketRequestReturnOrderProduct', notCheckeableWithNoWarrantyReason: string | null, notCheckeableWithWarrantyReason: string | null };

export type TicketRequestReturnProductCardModalFragment = { __typename?: 'TicketRequestReturnOrderProduct', notReturnableReason: string | null, notExchangeableReason: string | null, notCheckeableWithNoWarrantyReason: string | null, notCheckeableWithWarrantyReason: string | null, orderProduct: { __typename?: 'OrderProduct', id: string, name: string, imageUrl: string | null, options: Array<{ __typename?: 'OrderProductOption', name: string, value: string }>, price: { __typename?: 'Money', formattedAmount: string } } };

export const TicketRequestReturnProductCardModalFormFragmentDoc = gql`
    fragment TicketRequestReturnProductCardModalForm on TicketRequestReturnOrderProduct {
  notCheckeableWithNoWarrantyReason
  notCheckeableWithWarrantyReason
}
    `;
export const TicketRequestReturnProductCardModalFragmentDoc = gql`
    fragment TicketRequestReturnProductCardModal on TicketRequestReturnOrderProduct {
  ...TicketRequestReturnProductCardModalForm
  notReturnableReason
  notExchangeableReason
  orderProduct {
    id
    name
    imageUrl
    options {
      name
      value
    }
    price {
      formattedAmount
    }
  }
}
    ${TicketRequestReturnProductCardModalFormFragmentDoc}`;