import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";

export type ModalSize = "small" | "medium" | "large";

export const handleSize: Record<
  ModalSize,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  small: css`
    width: 380px;
  `,
  medium: css`
    width: 600px;
  `,
  large: css`
    width: 980px;
  `,
};
