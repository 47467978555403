import styled, { css } from "styled-components";
import { FadeIn, FadeOut } from "../../../animations";
import Row from "../../Row";
import { handlePlacement } from "./utils";
import type { BaseDrawerProps } from "./index";

export const animationTime = 200;

type DrawerShadowProps = { isLeaving: boolean };
export const DrawerShadow = styled.div<DrawerShadowProps>`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.shadow};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000066;
  animation-duration: ${animationTime / 1000}s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  ${({ isLeaving }) =>
    isLeaving
      ? css`
          animation-name: ${FadeOut};
        `
      : css`
          animation-name: ${FadeIn};
          opacity: 0;
        `}
`;

export const HeaderContainerDiv = styled.div`
  padding: 12px 16px;
  min-width: 240px;
  max-width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default.subdued};
  background-color: ${({ theme }) => theme.colors.background};
`;

export const FooterRow = styled(Row)`
  padding: 12px 16px;
  min-width: 240px;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${({ theme }) => theme.colors.border.default.subdued};
`;

export const Content = styled.div`
  word-wrap: break-word;
  max-width: 100%;
  height: 100%;
  padding: 20px 16px;
`;

type DrawerContentProps = {
  $headerHeight: number;
  $footerHeight: number;
};
export const DrawerContainer = styled.div<DrawerContentProps>`
  ${({ $headerHeight, $footerHeight }) => css`
    ${Content} {
      min-height: calc(100% - ${$headerHeight + $footerHeight}px);
      max-height: calc(100% - ${$headerHeight + $footerHeight}px);
    }
  `};
`;

export const baseDrawerCss = css<BaseDrawerProps>`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  background-color: ${({ theme }) => theme.colors.background};
  transition: all 0.2s cubic-bezier(0.7, 0.3, 0.1, 1);
  min-width: 240px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  ${({ $placement, visible }) => handlePlacement(visible, $placement)};

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  ${({ fullHeight }) =>
    fullHeight
      ? css`
          height: 100%;
          border-radius: 0;
          ${Content} {
            height: unset;
            max-height: unset;
          }
        `
      : css`
          ${Content} {
            min-height: unset;
          }
        `};
`;
