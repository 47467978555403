import { css } from "styled-components";
import ListItem from "../ListItem";
import type { ListProps } from ".";

const basicCss = css`
  ${ListItem}:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const defaultCss = css`
  ${ListItem} {
    &:not(:first-child):not(:last-child) {
      padding: 12px 0;
    }
    &:first-child:not(:only-child) {
      padding: 0 0 12px 0;
    }
    &:last-child:not(:only-child) {
      padding: 12px 0 0 0;
    }
    &:last-child {
      border-bottom: unset;
    }
  }
`;

export const listCss = css<ListProps>`
  ${({ $isBasic }) => ($isBasic ? basicCss : defaultCss)}
`;
