import { css } from "styled-components";
import type { DividerProps } from "./index";

const defaultMarginY = 12;

const hrDividerCss = css<DividerProps>`
  width: 100%;
  margin: ${({ $marginY }) => `${$marginY ?? defaultMarginY}px 0`};
  background-color: ${({ theme }) => theme.colors.border.default.subdued};
  height: 1px;
  border-width: 0;
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

const sectionDividerCss = css<DividerProps>`
  width: 100%;
  position: relative;
  height: ${({ $marginY }) => `${($marginY ?? defaultMarginY) * 2}px`};
  > hr {
    position: absolute;
    top: ${({ $marginY }) => `${$marginY ?? defaultMarginY}px`};
    left: -16px;
    right: -16px;
    background-color: ${({ theme }) => theme.colors.border.default.subdued};
    height: 1px;
    border-width: 0;
    border-radius: ${({ theme }) => theme.borderRadius.default};
  }
  @media ${({ theme }) => theme.breakpoints.mobile.css} {
    > hr {
      left: -16px;
      right: -16px;
    }
  }
`;

export const dividerCss = css<DividerProps>`
  ${({ sectionDivider }) =>
    !sectionDivider ? hrDividerCss : sectionDividerCss}
`;
