import type { LinkProps } from "next/link";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import { LayoutBaseContext } from "../context";

type LayoutWrapperProps = {
  children: ReactNode;
};
const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => {
  const [backButtonHref, setBackButtonHref] = useState<
    LinkProps["href"] | undefined
  >(undefined);

  return (
    <LayoutBaseContext.Provider
      value={{
        backButtonHref,
        setBackButtonHref,
      }}
    >
      {children}
    </LayoutBaseContext.Provider>
  );
};

export default LayoutWrapper;
