import BaseHeading from "./Heading";
import {
  Heading4XL,
  Heading3XL,
  Heading2XL,
  HeadingXL,
  HeadingLG,
  HeadingSM,
} from "./HeadingOptions";

export type HeadingProps = typeof BaseHeading & {
  XXXXL: typeof Heading4XL;
  XXXL: typeof Heading3XL;
  XXL: typeof Heading2XL;
  XL: typeof HeadingXL;
  LG: typeof HeadingLG;
  MD: typeof BaseHeading;
  SM: typeof HeadingSM;
};

const Heading = BaseHeading as HeadingProps;
Heading.XXXXL = Heading4XL;
Heading.XXXL = Heading3XL;
Heading.XXL = Heading2XL;
Heading.XL = HeadingXL;
Heading.LG = HeadingLG;
Heading.MD = BaseHeading;
Heading.SM = HeadingSM;

export default Heading;
