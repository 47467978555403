import baseUseTicketRequestTracker from "./useTicketRequestTracker";
import { TICKET_REQUEST_STATUSES } from "./utils";

type CompoundedHook = typeof baseUseTicketRequestTracker & {
  statuses: typeof TICKET_REQUEST_STATUSES;
};

const useTicketRequestTracker = baseUseTicketRequestTracker as CompoundedHook;
useTicketRequestTracker.statuses = TICKET_REQUEST_STATUSES;

export default useTicketRequestTracker;
