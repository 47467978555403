import styled, { css } from "styled-components";
import Icon from "../../../atoms/Icon";
import * as AbstractStyles from "../Abstract/styles";
import type { InputPasswordProps } from "./index";

export const EyeIcon = styled(Icon)``;

export const passwordCss = css<InputPasswordProps>`
  ${AbstractStyles.InnerContainer} {
    ${EyeIcon} {
      ${({ disabled }) =>
        !disabled &&
        css`
          cursor: pointer;
        `}
    }
  }
`;
