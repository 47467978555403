import NextHead from "next/head";
import type { VFC } from "react";
import { useShop } from "contexts";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

const Head: VFC = () => {
  const { t } = useTranslation("common");
  const { name, favicon, platformTitle } = useShop();

  return (
    <NextHead>
      <title>
        {name} | {platformTitle || t("head.title")}
      </title>
      <meta name="description" content={t("head.description")} />
      {favicon && <link rel="icon" href={favicon} />}
    </NextHead>
  );
};

export default Head;
