import type { FC } from "react";
import LayoutWrapper from "../Wrapper";
import Illustration from "./Illustration";
import { Row } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { Trans, useTranslation } = nextI18n;
const { Text, Title } = Typography;

const LoadingStore: FC = () => {
  const { t } = useTranslation("common");

  return (
    <LayoutWrapper>
      <Illustration />
      <Row $column $block $align="center" $gap={12}>
        <Title.DisplayL $align="center">
          {t("layout.error_loading.title")}
        </Title.DisplayL>
        <Title.Heading $align="center">
          {t("layout.error_loading.description")}
        </Title.Heading>
        <Text.Body $align="center">
          {Trans({
            t,
            i18nKey: "layout.error_loading.contact",
            components: {
              // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
              a: <a href="mailto:contacto@reversso.cl" />,
            },
            values: {
              email: "contacto@reversso.cl",
            },
          })}
        </Text.Body>
      </Row>
    </LayoutWrapper>
  );
};

export default LoadingStore;
