import type { FC, ReactNode } from "react";
import Badge from "../../atoms/Badge";
import Row from "../../atoms/Row";

export type TabBadgeWrapperProps = {
  active: boolean;
  badge: { value: number; limit?: number } | undefined;
  children: ReactNode;
};
const TabBadgeWrapper: FC<TabBadgeWrapperProps> = ({
  active,
  badge,
  children,
}) => (
  <Row $gap={4} $align="center" $justify="center">
    {children}
    {badge !== undefined && (
      <Badge
        $type={active ? "primary" : "informational"}
        text={
          badge.limit && badge.value > badge.limit
            ? `${badge.limit}+`
            : badge.value
        }
      />
    )}
  </Row>
);

export default TabBadgeWrapper;
