import classNames from "classnames";
import type { ReactNode, HTMLProps, FC } from "react";
import type { DefaultTheme } from "styled-components";
import styled from "styled-components";
import * as S from "./styles";

export type IconProps = Omit<HTMLProps<HTMLSpanElement>, "icon"> & {
  icon?: ReactNode;
  $animated?: boolean;
  type?: keyof DefaultTheme["colors"]["icon"];
};

const Icon: FC<IconProps> = ({ icon, className, ...props }) => (
  <span className={classNames("icon", className)} {...props}>
    {icon}
  </span>
);
export default styled(Icon)`
  ${S.iconCss}
`;
