import type { FC, ReactNode } from "react";
import { useState } from "react";
import * as S from "../styles";
import type {
  SiderReturnProductsTicketFragment,
  SiderReturnProductsTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import { ProductSider } from "components";
import { useShop } from "contexts";
import { Row } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text, Title } = Typography;

export type ReturnProductsListProps = {
  drawerContainer: boolean;
  data:
    | SiderReturnProductsTicketFragment
    | SiderReturnProductsTicketRequestFragment;
  extra: ReactNode;
};
const ReturnProductsList: FC<ReturnProductsListProps> = ({
  drawerContainer,
  data,
  extra,
}) => {
  const { t } = useTranslation("common");
  const [collapsed, setCollapsed] = useState(true);
  const { acceptExchanges, acceptReturns } = useShop();

  const { returnProductsTotalAmount } = data;

  let selectProductInfoSuffix = "";
  if (acceptExchanges && !acceptReturns)
    selectProductInfoSuffix = "_only_exchanges";
  if (!acceptExchanges && acceptReturns)
    selectProductInfoSuffix = "_only_returns";

  // typescript doesn't handle this correctly if we don't make the data.__typename === "..."
  const returnedProducts =
    // eslint-disable-next-line no-nested-ternary
    data.__typename === "Ticket"
      ? data.returnOrderProducts.filter(({ returnProduct }) => returnProduct)
      : data.__typename === "TicketRequest"
      ? data.returnOrderProducts.filter(({ returnProduct }) => returnProduct)
      : [];

  return (
    <>
      {!returnedProducts || returnedProducts.length === 0 ? (
        <Text.Body>
          {t(
            `components.sider.return.select_product.info${selectProductInfoSuffix}`
          )}
        </Text.Body>
      ) : (
        <S.StyledCollapse
          $drawerContainer={drawerContainer}
          open={{
            is: collapsed,
            set: () =>
              !drawerContainer ? setCollapsed(!collapsed) : undefined,
          }}
          showArrow={false}
          title={
            <Row $block $justify="space-between" $gap={4}>
              <Row $align="center" $noWrap $gap={4}>
                <Title.Subheading>
                  {t("components.sider.return.title", {
                    count: returnedProducts.length,
                  })}
                </Title.Subheading>
                {!drawerContainer && <S.Arrow $rotate={collapsed} />}
              </Row>
              <Title.Subheading>
                {returnProductsTotalAmount.formattedAmount}
              </Title.Subheading>
            </Row>
          }
        >
          <S.ContentContainer $drawerContainer={drawerContainer}>
            <S.ProductsThread gap={8} leftGap={8}>
              {returnedProducts.map((product) => {
                const reason = product.returnProduct?.reason;
                const orderProduct = {
                  ...product.orderProduct,
                  // eslint-disable-next-line no-nested-ternary
                  reasonTitle: reason
                    ? "reason" in reason
                      ? reason.reason
                      : reason.title
                    : undefined,
                };

                return (
                  <ProductSider
                    key={product.orderProduct.id}
                    product={{
                      ...orderProduct,
                      discounts: null,
                      listPrice: null,
                    }}
                  />
                );
              })}
              {returnedProducts.length > 0 && extra}
            </S.ProductsThread>
          </S.ContentContainer>
        </S.StyledCollapse>
      )}
    </>
  );
};

export default ReturnProductsList;
