import BaseTable from "./DesktopTable";
import Draggable from "./DraggableTable";
import MobileOrDesktopTable from "./MobileOrDesktopTable";

export type { TableProps } from "./DesktopTable";
export type { DraggableTableProps } from "./DraggableTable";
export type { MobileOrDesktopTableProps } from "./MobileOrDesktopTable";

type CompoundedComponent = typeof BaseTable & {
  Draggable: typeof Draggable;
  MobileOrDesktop: typeof MobileOrDesktopTable;
};

const Table = BaseTable as CompoundedComponent;
Table.Draggable = Draggable;
Table.MobileOrDesktop = MobileOrDesktopTable;

export default Table;
