import Actions from "./Actions";
import BaseSectionContainer from "./Section";

export * as SectionContainerStyles from "./Section/styles";
export * as SectionContainerActionsStyles from "./Actions/styles";

export type { SectionContainerProps } from "./Section";
export type { SectionContainerActionsProps } from "./Actions";

type CompoundedComponent = typeof BaseSectionContainer & {
  Actions: typeof Actions;
};

const SectionContainer = BaseSectionContainer as CompoundedComponent;
SectionContainer.Actions = Actions;

export default SectionContainer;
