import type { LinkProps } from "next/link";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";

type LayoutBaseContextProps = {
  backButtonHref: LinkProps["href"] | undefined;
  setBackButtonHref: Dispatch<SetStateAction<LinkProps["href"] | undefined>>;
};
export const LayoutBaseContext = createContext<
  LayoutBaseContextProps | undefined
>(undefined);

export const useLayout = () => {
  const values = useContext(LayoutBaseContext);
  if (!values) throw new Error("LayoutBaseContext not initialized");
  return values;
};
