import styled, { css } from "styled-components";
import Typography from "../../deprecated/Typography";
import Icon from "../Icon";
import Row from "../Row";
import type { InlineMessageProps } from "./index";

export const StyledIcon = styled(Icon)`
  top: 2px;
  font-size: 16px;
  max-width: 16px;
  width: 16px;
`;
export const Text = styled(Typography.Text.Body)``;

type Props = {
  type: InlineMessageProps["type"];
};
export const ContainerRow = styled(Row)<Props>`
  gap: 4px;
  flex-wrap: nowrap;
  ${({ type, theme }) => {
    switch (type) {
      case "success":
        return css`
          color: ${theme.colors.text.success};
          ${StyledIcon},
          ${Text} {
            color: ${theme.colors.text.success};
          }
        `;
      case "warning":
        return css`
          color: ${theme.colors.text.warning};
          ${StyledIcon},
          ${Text} {
            color: ${theme.colors.text.warning};
          }
        `;
      case "critical":
        return css`
          color: ${theme.colors.text.critical};
          ${StyledIcon},
          ${Text} {
            color: ${theme.colors.text.critical};
          }
        `;
      case "primary":
        return css`
          color: ${theme.colors.text.primary};
          ${StyledIcon},
          ${Text} {
            color: ${theme.colors.text.primary};
          }
        `;
      case "info":
      default:
        return css`
          color: ${theme.colors.text.informational};
          ${StyledIcon},
          ${Text} {
            color: ${theme.colors.text.informational};
          }
        `;
    }
  }}
`;
