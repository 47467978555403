import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import styled, { css } from "styled-components";
import Typography from "../../../deprecated/Typography";
import type { StepProps } from "./index";

export const StepTail = styled.div``;
export const StepContentContainer = styled.div``;
export const Subheading = styled(Typography.Title.Subheading)``;
export const Caption = styled(Typography.Text.Caption)``;

type Props = {
  $isDotsType: boolean;
};
export const StepIcon = styled.div<Props>`
  color: ${({ theme }) => theme.colors.icon.disabled};
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.border.default.disabled};
    ${({ $isDotsType }) =>
      $isDotsType
        ? css`
            width: calc(50% - 8px);
          `
        : css`
            width: calc(50% - 12px);
          `};
  }
  ::after {
    left: unset;
    right: 0;
  }
`;

export const StepContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${Subheading} {
    display: inline-block;
    word-break: break-word;
  }
`;

const HandleStatus: Record<
  NonNullable<StepProps["status"]>,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  process: css<StepProps>`
    ${StepIcon} {
      color: ${({ theme }) => theme.colors.icon.primary};
      background-color: ${({ theme, type }) =>
        type === "dots" ? theme.colors.surface.primary.subdued : "transparent"};
      border: 1px solid ${({ theme }) => theme.colors.border.primary.default};
      ::before {
        background-color: ${({ theme }) => theme.colors.border.primary.default};
      }
    }
    ${StepTail} {
      background-color: ${({ theme }) => theme.colors.border.default.disabled};
    }
  `,
  wait: css<StepProps>`
    ${StepIcon} {
      color: ${({ theme }) => theme.colors.icon.subdued};
      background-color: ${({ theme }) => theme.colors.surface.neutral.default};
    }
    ${StepTail} {
      background-color: ${({ theme }) => theme.colors.border.default.disabled};
    }
    ${StepContent} ${Typography} {
      color: ${({ theme }) => theme.colors.text.disabled};
    }
  `,
  error: css<StepProps>`
    ${StepIcon} {
      color: ${({ theme }) => theme.colors.icon.white};
      background-color: ${({ theme }) =>
        theme.colors.surface.critical.important};
      &::before {
        background-color: ${({ theme }) => theme.colors.border.primary.default};
      }
    }
    ${StepContent} ${Typography} {
      color: ${({ theme }) => theme.colors.text.critical};
    }
  `,
  finish: css<StepProps>`
    ${StepIcon} {
      color: ${({ theme }) => theme.colors.icon.white};
      background-color: ${({ theme }) =>
        theme.colors.surface.primary.important};
      &::before,
      &::after {
        background-color: ${({ theme }) => theme.colors.border.primary.default};
      }
    }
    ${StepTail} {
      background-color: ${({ theme }) => theme.colors.border.primary.default};
    }
  `,
};

export const stepCss = css<StepProps>`
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  outline: none;
  ${({ isVertical, type }) =>
    isVertical
      ? css`
          padding: unset;
          ${StepTail} {
            position: absolute;
            top: 0;
            width: 2px;
            height: 100%;
            padding: 38px 0 6px;
            background-color: ${({ theme }) =>
              theme.colors.border.default.disabled};
            transform: translateX(-1px);
          }
          ${StepContentContainer} {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: 8px;
            gap: 8px;
            ${StepIcon} {
              &::before,
              &::after {
                display: none;
              }
            }
          }
          ${type !== "dots" &&
          css`
            ${StepContent} {
              padding-top: 3px;
            }
          `}
          &:last-child {
            ${StepTail} {
              display: none;
            }
            ${StepContentContainer} {
              margin-bottom: unset;
            }
          }
        `
      : css`
          ${StepContentContainer} {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            ${StepContent} {
              align-items: center;
              ${Subheading} {
                text-align: center;
              }
            }
          }
        `};
  &:first-child {
    ${StepIcon}::before {
      display: none;
    }
  }
  &:last-child {
    ${StepIcon}::after {
      display: none;
    }
    ${StepTail} {
      display: none;
    }
  }
  ${({ $active }) =>
    $active &&
    css`
      ${StepIcon} {
        background-color: ${({ theme }) =>
          theme.colors.surface.neutral.default};
      }
    `}

  ${({ type }) =>
    type === "dots"
      ? css`
          ${StepIcon} {
            min-width: 16px;
            width: 16px;
            height: 16px;
            border-radius: 16px;
          }
          ${StepTail} {
            left: calc(16px / 2);
          }
        `
      : css`
          ${StepIcon} {
            min-width: 24px;
            width: 24px;
            height: 24px;
            border-radius: 24px;
          }
          ${StepTail} {
            left: calc(24px / 2);
          }
        `}

  ${({ status }) => HandleStatus[status || "wait"]}
`;
