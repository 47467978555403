import { HiCheck } from "react-icons/hi";
import styled, { css } from "styled-components";
import type { ListItemProps } from "./index";

export const BasicIcon = styled(HiCheck)`
  top: 3px;
  min-width: 14px;
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.icon.primary};
`;

const basicCss = css`
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.default};
`;

const defaultCss = css`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default.subdued};
`;

export const listItemCss = css<ListItemProps>`
  display: flex;
  list-style: none;
  ${({ isBasic }) => (isBasic ? basicCss : defaultCss)};
`;
