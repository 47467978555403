import _ from "lodash";
import type { UseFormWatch } from "react-hook-form";
import type { AddressZipcodeFragment } from "./graphql/generated/fragments.platform.generated";
import type { BaseFormValues, Region, City, County } from "./typing";
import { CountryZipcodeBehaviour } from "graphql/generated/schema.platform";

export const AFTER_LOCALITIES = "AFTER_LOCALITIES";
export type ZipcodePositions =
  | CountryZipcodeBehaviour
  | typeof AFTER_LOCALITIES;

export const zipcodePosition = (
  zipcodeBehaviors: CountryZipcodeBehaviour[]
): ZipcodePositions => {
  const positions = [
    CountryZipcodeBehaviour.BEFORE_ADDRESS,
    CountryZipcodeBehaviour.BEFORE_LOCALITIES,
    AFTER_LOCALITIES,
  ];
  return (
    zipcodeBehaviors?.find((b) => positions.includes(b)) || AFTER_LOCALITIES
  );
};

export const correctZipcode = <FormValues extends { zipcode?: string }>({
  positions,
  zipcodeBehaviors,
  loading,
  regions,
  watchValues,
}: {
  positions: ZipcodePositions[];
  zipcodeBehaviors: CountryZipcodeBehaviour[];
  loading: boolean;
  regions?: unknown[];
  watchValues: UseFormWatch<FormValues>;
}): boolean => {
  // true is a 'correct' zipcode, where as 'false' is to input it
  if (!positions.includes(zipcodePosition(zipcodeBehaviors))) return true;

  if (loading) return false;

  if (regions && !regions.length) return false;
  // @ts-expect-error no generic for react-hook-form
  if (!watchValues("zipcode")) return false;

  return true;
};

type BaseAddress = {
  country?: { id: string } | null;
  region?: { id: string } | null;
  city?: { id: string; name: string } | null;
  county?: { id: string; name: string } | null;
  phoneNumber?: string | null;
};
export const parseDefaultValues = <Address extends BaseAddress>(
  address?: Address | null,
  extraKeys?: Record<string, unknown>
) =>
  _.omitBy(
    {
      cityName: address?.city?.name,
      countyName: address?.county?.name,
      ...address,
      region: address?.region?.id,
      city: address?.city?.id,
      county: address?.county?.id,
      ...extraKeys,
    },
    _.isNil
  );

export const parseMutationValues = <FormValues extends BaseFormValues>(
  values: FormValues
) => ({
  ..._.omit(values, ["region", "city", "county"]),
  regionId: values.region,
  cityId: values.city || undefined,
  countyId: values.county || undefined,
});

export const parseZipcodesAsRegions = (zipcodes: AddressZipcodeFragment[]) => {
  const regions: { [key in string]: Region } = {};
  const cities: { [key in string]: City } = {};
  const counties: { [key in string]: County } = {};

  zipcodes.forEach((z) => {
    let region: Region;
    if (z.region.id in regions) {
      region = regions[z.region.id];
    } else {
      region = { ...z.region, counties: [], cities: [] };
      regions[z.region.id] = region;
    }

    const _city: City | null = z.cityName
      ? { id: z.cityName, name: z.cityName }
      : null;

    let city: City | undefined;
    if (_city) {
      if (_city.name in cities) {
        city = cities[_city.name];
      } else {
        city = { ..._city, counties: [] };
        region.cities?.push(city);
        cities[city.name] = city;
      }
    }

    const _county: County | null = z.countyName
      ? { id: z.countyName, name: z.countyName }
      : null;
    if (_county && !(_county.id in counties)) {
      region.counties?.push(_county);
      if (city) city.counties?.push(_county);
      counties[_county.id] = _county;
    }
  });

  return Object.values(regions);
};
