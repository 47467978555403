import Link from "next/link";
import type { FC, ReactNode } from "react";
import { HiArrowLeft } from "react-icons/hi";
import styled from "styled-components";
import { useLayout } from "../context";
import * as S from "./styles";
import { useShop } from "contexts";
import { Header as DesignHeader, useDesign } from "design";
import { isInsideIframe } from "utils";

type HeaderProps = {
  hideLink?: boolean;
  extra?: ReactNode;
  overrideLogo?: ReactNode;
};
const Header: FC<HeaderProps> = ({ hideLink, overrideLogo, extra }) => {
  const { backButtonHref } = useLayout();
  const {
    device: { isDesktop },
  } = useDesign();
  const { url: shopUrl, name: shopName, logo: shopLogo } = useShop();

  const logo =
    shopLogo ||
    "https://cdn.reversso.cl/logos/logo_reversso_fondo_transparente.png";

  const showLogo = !isInsideIframe();

  let logoComponent: ReactNode = overrideLogo;
  if (showLogo) {
    if (!hideLink) {
      logoComponent = (
        <a href={shopUrl} target="_blank" rel="noreferrer">
          <img src={logo} alt={shopName} />
        </a>
      );
    } else {
      logoComponent = <img src={logo} alt={shopName} />;
    }
  }

  return (
    <DesignHeader $withBorder={showLogo}>
      <S.ContentRow $noWrap $gap={8} $hideLink={!!hideLink}>
        {!isDesktop && backButtonHref && (
          <Link href={backButtonHref} passHref prefetch>
            <S.BackButton type="ghost" icon={<HiArrowLeft size={20} />} />
          </Link>
        )}
        {logoComponent}
        {extra}
      </S.ContentRow>
    </DesignHeader>
  );
};

export default styled(Header)``;
