import Tab from "./Tab";
import BaseTabs from "./Tabs";

export * as TabStyles from "./Tab/styles";
export * as TabsStyles from "./Tabs/styles";

export type { TabProps } from "./Tab";
export type { TabsProps } from "./Tabs";

type CompoundedTabs = typeof BaseTabs & {
  Tab: typeof Tab;
};

const Tabs = BaseTabs as CompoundedTabs;
Tabs.Tab = Tab;

export default Tabs;
