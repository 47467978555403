/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
export type SiderNewProductTicketFragment = { __typename?: 'TicketNewProduct', id: string, imageUrl: string | null, name: string, variantValues: string, options: Array<{ __typename?: 'TicketNewProductOption', name: string, value: string }>, discounts: Array<{ __typename?: 'TicketNewProductDiscount', discount: { __typename?: 'TicketDiscount', name: string, percentage: number, fixed: { __typename?: 'Money', amount: number, formattedAmount: string } } }>, price: { __typename?: 'Money', formattedAmount: string }, listPrice: { __typename?: 'Money', formattedAmount: string } };

export type SiderNewProductTicketRequestFragment = { __typename?: 'TicketRequestNewProduct', id: string, imageUrl: string | null, name: string, variantValues: string, options: Array<{ __typename?: 'TicketRequestNewProductOption', name: string, value: string }>, discounts: Array<{ __typename?: 'TicketRequestDiscount', name: string, percentage: number, fixed: { __typename?: 'Money', amount: number, formattedAmount: string } }>, listPrice: { __typename?: 'Money', formattedAmount: string }, price: { __typename?: 'Money', formattedAmount: string } };

export type SiderNewProductsTicketFragment = { __typename?: 'Ticket', id: string, newProducts: Array<{ __typename?: 'TicketNewProduct', id: string, imageUrl: string | null, name: string, variantValues: string, options: Array<{ __typename?: 'TicketNewProductOption', name: string, value: string }>, discounts: Array<{ __typename?: 'TicketNewProductDiscount', discount: { __typename?: 'TicketDiscount', name: string, percentage: number, fixed: { __typename?: 'Money', amount: number, formattedAmount: string } } }>, price: { __typename?: 'Money', formattedAmount: string }, listPrice: { __typename?: 'Money', formattedAmount: string } }>, newProductsTotalAmount: { __typename?: 'Money', formattedAmount: string } };

export type SiderNewProductsTicketRequestFragment = { __typename?: 'TicketRequest', id: string, newProducts: Array<{ __typename?: 'TicketRequestNewProduct', id: string, imageUrl: string | null, name: string, variantValues: string, options: Array<{ __typename?: 'TicketRequestNewProductOption', name: string, value: string }>, discounts: Array<{ __typename?: 'TicketRequestDiscount', name: string, percentage: number, fixed: { __typename?: 'Money', amount: number, formattedAmount: string } }>, listPrice: { __typename?: 'Money', formattedAmount: string }, price: { __typename?: 'Money', formattedAmount: string } }>, newProductsTotalAmount: { __typename?: 'Money', formattedAmount: string } };

export const SiderNewProductTicketFragmentDoc = gql`
    fragment SiderNewProductTicket on TicketNewProduct {
  id
  imageUrl
  name
  variantValues
  options {
    name
    value
  }
  discounts {
    discount {
      fixed {
        amount
        formattedAmount
      }
      name
      percentage
    }
  }
  price {
    formattedAmount
  }
  listPrice {
    formattedAmount
  }
}
    `;
export const SiderNewProductsTicketFragmentDoc = gql`
    fragment SiderNewProductsTicket on Ticket {
  id
  newProducts {
    ...SiderNewProductTicket
  }
  newProductsTotalAmount {
    formattedAmount
  }
}
    ${SiderNewProductTicketFragmentDoc}`;
export const SiderNewProductTicketRequestFragmentDoc = gql`
    fragment SiderNewProductTicketRequest on TicketRequestNewProduct {
  id
  imageUrl
  name
  variantValues
  options {
    name
    value
  }
  discounts {
    fixed {
      amount
      formattedAmount
    }
    name
    percentage
  }
  listPrice {
    formattedAmount
  }
  price {
    formattedAmount
  }
}
    `;
export const SiderNewProductsTicketRequestFragmentDoc = gql`
    fragment SiderNewProductsTicketRequest on TicketRequest {
  id
  newProducts {
    ...SiderNewProductTicketRequest
  }
  newProductsTotalAmount {
    formattedAmount
  }
}
    ${SiderNewProductTicketRequestFragmentDoc}`;