import { keyframes } from "styled-components";

export const ZoomIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const ZoomOut = keyframes`
  0% {
    transform: scale(1);
  }
  to {
    opacity: 0;
    position: absolute;
    transform: scale(0.2);
  }
`;

export const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FadeOut = keyframes`
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
`;

export const FadeInDown = keyframes`
  0% {
     opacity: 0;
     transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
  }
`;

export const FadeInUp = keyframes`
  0% {
     opacity: 1;
     transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
  }
`;

export const SlideUpIn = keyframes`
0% {
  opacity: 0;
  transform-origin: 0 0;
  transform: scaleY(0);
}
to {
  opacity: 1;
  transform-origin: 0 0;
  transform: scaleY(1);
}
`;

export const SlideUpOut = keyframes`
0% {
  opacity: 1;
  transform-origin: 0 0;
  transform: scaleY(1);
}
to {
  opacity: 0;
  transform-origin: 0 0;
  transform: scaleY(0);
}
`;

export const SpinnerRotateCounterClockwise = keyframes`
from {
  transform: rotate(0turn);
}
to {
  transform: rotate(-1turn);
}
`;

export const SpinnerRotate = keyframes`
from {
  transform: rotate(0turn);
}
to {
  transform: rotate(1turn);
}
`;
