import styled, { css } from "styled-components";
import Typography from "../../deprecated/Typography";
import { handleBadgeType } from "./utils";
import type { BadgeProps } from "./index";

export const CornerBadgeDiv = styled.div`
  position: relative;
`;

export const badgeCss = css<BadgeProps>`
  display: inline-flex;
  padding: 2px 8px;
  height: fit-content;
  width: max-content;
  border-radius: ${({ theme }) => theme.borderRadius.badge};
  ${Typography} {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    ${({ onClick }) =>
      onClick &&
      css`
        cursor: pointer;
        text-decoration: underline;
      `}
  }
  ${({ $type }) => handleBadgeType[$type || "neutral"]}

  ${({ children }) =>
    children &&
    css`
      pointer-events: none;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    `}
`;
