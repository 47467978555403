import styled, { css, keyframes } from "styled-components";
import Icon from "../../../atoms/Icon";
import Typography from "../../../deprecated/Typography";
import type { AbstractInputProps } from "./index";

export const OnAutoFillStart = keyframes`
  from {
    display: "block";
  }
`;
export const OnAutoFillCancel = keyframes`
  from {
    display: "block";
  }
`;

type CustomProps = {
  $isActive: boolean;
  $isFocused: boolean;
  $labelRequired: boolean;
  $hasPrefix: boolean;
  $hasSuffix: boolean;
};

type LabelProps = Pick<
  CustomProps,
  "$labelRequired" | "$isActive" | "$isFocused"
>;
export const Label = styled.label<LabelProps>`
  display: flex;
  -webkit-touch-callout: none;
  user-select: none;
  ${Typography} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
  }
  ${({ $labelRequired }) =>
    $labelRequired &&
    css`
      &::after {
        content: "*";
        margin-left: 2px;
        color: ${({ theme }) => theme.colors.text.critical};
      }
    `}

  ${({ $isActive }) =>
    $isActive &&
    css`
      &&& {
        transform: translate(10px, -9px) scale(1);
      }
    `}

  ${({ $isFocused }) =>
    $isFocused &&
    css`
      ${Typography} {
        color: ${({ theme }) => theme.colors.text.primary};
      }
    `}
`;

type InnerContainerProps = Pick<
  CustomProps,
  "$isFocused" | "$hasPrefix" | "$hasSuffix"
>;
export const InnerContainer = styled.span<InnerContainerProps>`
  position: relative;
  cursor: text;
  display: flex;
  align-items: center;
  padding: 0 12px;
  ${({ $hasPrefix, $hasSuffix }) =>
    css`
      ${$hasPrefix &&
      css`
        padding-left: 40px;
      `}
      ${$hasSuffix &&
      css`
        padding-right: 40px;
      `}
    `};

  height: 36px;
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  border: 1px solid ${({ theme }) => theme.colors.border.neutral.subdued};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  overflow: hidden;
  transition: border-color 0.2s ease-in-out;
  &:hover {
    border-color: ${({ theme }) => theme.colors.border.primary.default};
  }
  ${({ $isFocused }) =>
    $isFocused &&
    css`
      &&& {
        border-color: ${({ theme }) => theme.colors.border.primary.default};
      }
    `};

  ${Icon} {
    color: ${({ theme }) => theme.colors.icon.default};
    min-width: 20px;
  }

  input,
  textarea {
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.text.default};
    font-size: 14px;
    line-height: 20px;
    background-color: transparent;
    border: 0;
    &::placeholder {
      color: ${({ theme }) => theme.colors.text.subdued};
    }

    &:focus,
    &:active {
      outline: none;
    }
    &:disabled {
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.text.disabled};
      &::placeholder {
        color: ${({ theme }) => theme.colors.text.disabled};
      }
      &:-webkit-autofill::first-line,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        color: ${({ theme }) => theme.colors.text.disabled};
        box-shadow: 0 0 0 30px transparent inset;
        -webkit-box-shadow: 0 0 0 30px transparent inset;
      }
    }
    &:-webkit-autofill::first-line,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      animation-name: ${OnAutoFillStart};
      transition: background-color 50000s ease-in-out 0s;
      box-shadow: 0 0 0 30px transparent inset;
      -webkit-box-shadow: 0 0 0 30px transparent inset;
    }
    &:not(:-webkit-autofill) {
      animation-name: ${OnAutoFillCancel};
    }
  }
`;

type StyledIconProps = {
  $type: "prefix" | "suffix";
};
export const StyledIcon = styled(Icon)<StyledIconProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ $type }) =>
    $type === "prefix"
      ? css`
          left: 12px;
        `
      : css`
          right: 12px;
        `};
`;

type Props = { $block: boolean };
export const AbstractContainerDiv = styled.div<Props>`
  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
    `};
`;

type AbstractProps = AbstractInputProps<HTMLInputElement | HTMLTextAreaElement>;
export const abstractCss = css<AbstractProps>`
  ${({ label, outlined }) =>
    !!(label && outlined) &&
    css`
      ${InnerContainer} {
        padding: 12px;
        height: 44px;
      }
      &:hover ${Typography} {
        color: ${({ theme }) => theme.colors.text.primary};
      }
    `}

  ${Label} {
    ${({ label, outlined, hasError, disabled }) =>
      label &&
      outlined &&
      css`
        cursor: text;
        pointer-events: none;
        position: absolute;
        z-index: 1;
        padding: 0 4px;
        background-color: ${({ theme }) =>
          theme.colors.surface.default.default};
        transform-origin: top left;
        transform: translate(12px, 11px) scale(1.166);
        transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        ${hasError &&
        css`
          ${Typography} {
            color: ${({ theme }) => theme.colors.text.critical};
          }
        `};

        ${disabled &&
        css`
          cursor: not-allowed;
          ${Typography} {
            color: ${({ theme }) => theme.colors.text.disabled};
          }
          background-color: unset;
        `};
      `}
  }

  ${InnerContainer} {
    ${({ hasError }) =>
      hasError &&
      css`
        border-color: ${({ theme }) => theme.colors.border.critical.subdued};
        &:hover {
          border-color: ${({ theme }) => theme.colors.border.critical.subdued};
        }
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        background-color: ${({ theme }) =>
          theme.colors.surface.default.disabled};
        border-color: ${({ theme }) => theme.colors.border.default.disabled};
        &:hover {
          border-color: ${({ theme }) => theme.colors.border.default.disabled};
        }
        ${Icon} {
          color: ${({ theme }) => theme.colors.iconAction.default.disabled};
        }
      `}
  }
`;
