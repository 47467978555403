/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import { MoneyFragmentDoc } from '../../../../../graphql/generated/fragments.platform.generated';
export type SiderPaymentSectionTicketFragment = { __typename?: 'Ticket', id: string, isExchange: boolean, forcePayReturnLabel: boolean, forcePayNewShipping: boolean, returnTrackingStatus: Types.TicketReturnTrackingStatus, newShippingPrice: { __typename?: 'Money', amount: number, formattedAmount: string } | null, refundOriginalShipmentAmount: { __typename?: 'Money', amount: number, formattedAmount: string }, returnLabelPrice: { __typename?: 'Money', amount: number, formattedAmount: string }, totalProductsDifferenceAmount: { __typename?: 'Money', amount: number, formattedAmount: string }, totalToRefund: { __typename?: 'Money', amount: number, formattedAmount: string }, totalToPay: { __typename?: 'Money', amount: number, formattedAmount: string } };

export type SiderPaymentSectionTicketRequestFragment = { __typename?: 'TicketRequest', id: string, isExchange: boolean, forcePayReturnLabel: boolean, forcePayNewShipping: boolean, requiresReverseDelivery: boolean | null, newShippingPrice: { __typename?: 'Money', amount: number, formattedAmount: string } | null, refundOriginalShipmentAmount: { __typename?: 'Money', amount: number, formattedAmount: string }, returnLabelPrice: { __typename?: 'Money', amount: number, formattedAmount: string } | null, totalProductsDifferenceAmount: { __typename?: 'Money', amount: number, formattedAmount: string }, totalToRefund: { __typename?: 'Money', amount: number, formattedAmount: string }, totalToPay: { __typename?: 'Money', amount: number, formattedAmount: string } };

export const SiderPaymentSectionTicketFragmentDoc = gql`
    fragment SiderPaymentSectionTicket on Ticket {
  id
  isExchange
  newShippingPrice {
    ...Money
  }
  forcePayReturnLabel
  forcePayNewShipping
  refundOriginalShipmentAmount {
    ...Money
  }
  returnLabelPrice {
    ...Money
  }
  returnTrackingStatus
  totalProductsDifferenceAmount {
    ...Money
  }
  totalToRefund {
    ...Money
  }
  totalToPay {
    ...Money
  }
}
    ${MoneyFragmentDoc}`;
export const SiderPaymentSectionTicketRequestFragmentDoc = gql`
    fragment SiderPaymentSectionTicketRequest on TicketRequest {
  id
  isExchange
  forcePayReturnLabel
  forcePayNewShipping
  newShippingPrice {
    ...Money
  }
  refundOriginalShipmentAmount {
    ...Money
  }
  requiresReverseDelivery
  returnLabelPrice {
    ...Money
  }
  totalProductsDifferenceAmount {
    ...Money
  }
  totalToRefund {
    ...Money
  }
  totalToPay {
    ...Money
  }
}
    ${MoneyFragmentDoc}`;