import styled, { css } from "styled-components";
import Typography from "../../../deprecated/Typography";

export const LabelContainer = styled.div``;
export const Label = styled(Typography)`
  -webkit-touch-callout: none;
  user-select: none;
`;

export const RadioInner = styled.span`
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  border: 2px solid ${({ theme }) => theme.colors.border.default.default};
  border-radius: 50%;
  transition: all 0.2s;
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.action.primary.default};
    border-radius: 8px;
    transform: scale(0);
    translate: -50% -50%;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: "";
  }
`;

export const hoverRadioCss = css`
  ${RadioInner} {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.action.primary.default};
  }
`;

export const radioCss = css`
  position: relative;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  input {
    display: none;
    :checked + ${RadioInner} {
      border: 2px solid ${({ theme }) => theme.colors.action.primary.default};
      ::after {
        transform: scale(1);
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
    }
    :disabled + ${RadioInner} {
      /* background-color: ${({ theme }) =>
        theme.colors.border.default.disabled}; */
      border-color: ${({ theme }) => theme.colors.border.default.disabled};
      &:hover {
        cursor: not-allowed;
        border-color: ${({ theme }) => theme.colors.border.default.disabled};
      }
      &::after {
        width: 8px;
        height: 8px;
        box-shadow: 0px 0px 0px 4px
          ${({ theme }) => theme.colors.border.default.disabled};
        background-color: ${({ theme }) =>
          theme.colors.surface.default.default};
      }
    }
  }
  :hover {
    ${hoverRadioCss}
  }
  > span {
    position: relative;
    top: 1px;
    display: inline-block;
    outline: none;
  }
  label {
    cursor: pointer;
  }
`;
