import type { ReactNode, FC } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Typography from "../../deprecated/Typography";
import type { SpinnerProps } from "../Spinner";
import Spinner from "../Spinner";
import * as S from "./styles";

const { Text } = Typography;

export type LoadingProps = {
  className?: string;
  icon?: SpinnerProps["iconLoading"];
  isViewContainer?: boolean;
  tip?: ReactNode;
} & (
  | {
      horizontal: true;
    }
  | {
      horizontal?: false;
      size?: "small" | "medium" | "large";
    }
) &
  (
    | {
        children?: never;
      }
    | {
        children: ReactNode;
        loading: boolean;
        delay?: number;
      }
  );

const Loading: FC<LoadingProps> = ({
  children,
  className,
  horizontal,
  icon,
  isViewContainer,
  tip,
  ...props
}) => {
  const [_loading, setLoading] = useState(false);
  const size = "size" in props && props.size;
  const delay = "delay" in props && props.delay;
  const loading = "loading" in props && props.loading;

  useEffect(() => {
    let timer: NodeJS.Timer | undefined;
    if (loading && delay) {
      timer = setTimeout(() => setLoading(true), delay);
    } else {
      setLoading(loading);
      if (timer) clearTimeout(timer);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [loading]);

  const innerLoading = (
    <S.InnerLoadingContainer $horizontal={!!horizontal} $size={size || "small"}>
      <Spinner iconLoading={icon} />
      {tip && <Text.Body>{tip}</Text.Body>}
    </S.InnerLoadingContainer>
  );

  if (!children) return innerLoading;

  return (
    <S.LoadingContainer
      $isViewContainer={!!isViewContainer}
      $loading={_loading}
      className={className}
    >
      {children}
      {_loading && <S.MaskLoading>{innerLoading}</S.MaskLoading>}
    </S.LoadingContainer>
  );
};

export default styled(Loading)``;
