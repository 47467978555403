import type { FC, ReactNode } from "react";
import { ImSpinner8 } from "react-icons/im";
import styled from "styled-components";
import * as S from "./styles";

export type SpinnerProps = {
  iconLoading?: ReactNode;
  className?: string;
};

const Spinner: FC<SpinnerProps> = ({
  iconLoading = <ImSpinner8 />,
  className,
}) => <span className={className}>{iconLoading}</span>;

export default styled(Spinner)`
  ${S.spinnerCss}
`;
