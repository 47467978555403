import type { TreeProps as RcTreeProps } from "rc-tree";
import RcTree from "rc-tree";
import type { DataNode } from "rc-tree/lib/interface";
import type { ReactElement, ForwardedRef, Ref } from "react";
import { forwardRef } from "react";
import { HiChevronRight } from "react-icons/hi";
import styled from "styled-components";
import * as S from "./styles";

export type TreeDataNode<K extends string | number = string | number> =
  DataNode & {
    key: K;
    children?: TreeDataNode<K>[];
  };

export type TreeProps<TreeDataType extends TreeDataNode = TreeDataNode> = Omit<
  RcTreeProps<TreeDataType>,
  "prefixCls" | "switcherIcon" | "checkeable"
> & {
  checkable?: boolean;
};

const TreeInner = <TreeDataType extends TreeDataNode = TreeDataNode>(
  { checkable, ...props }: TreeProps<TreeDataType>,
  ref: ForwardedRef<RcTree<TreeDataType>>
): ReactElement => {
  const motion = {
    motionName: `${S.treeClass}-animation`,
    motionAppear: false,
    onAppearStart: () => ({ height: 0 }),
    onAppearActive: (node: { scrollHeight: number }) => ({
      height: node.scrollHeight,
    }),
    onLeaveStart: (node: { offsetHeight: number }) => ({
      height: node.offsetHeight,
    }),
    onLeaveActive: () => ({ height: 0 }),
  };

  return (
    <RcTree<TreeDataType>
      ref={ref}
      switcherIcon={<HiChevronRight fontSize={20} />}
      checkable={checkable && <S.InnerCheckbox />}
      motion={motion}
      {...props}
    />
  );
};

const Tree: (<TreeDataType extends TreeDataNode = TreeDataNode>(
  props: TreeProps<TreeDataType> & {
    ref?: Ref<RcTree<TreeDataType>>;
  }
) => ReactElement) &
  string = styled(forwardRef(TreeInner))`
  ${S.treeCss}
`;

export default Tree;
