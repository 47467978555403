import type { FC } from "react";
import styled from "styled-components";
import * as S from "./styles";
import { useShop } from "contexts";
import { FooterConfig } from "typing";
import nextI18n from "utils/i18n";

const { Trans, useTranslation } = nextI18n;

export type FooterProps = {
  className?: string;
  isStartView?: boolean;
  hasBackgroundImage?: boolean;
};
const Footer: FC<FooterProps> = ({
  className,
  hasBackgroundImage,
  isStartView,
}) => {
  const { t } = useTranslation("common");
  const { reverssoFooterPlatformConfig } = useShop();
  if (reverssoFooterPlatformConfig === FooterConfig.HIDDEN) return null;

  const BASE_URL = "https://cdn.reversso.cl/logos";

  const logoColor =
    // eslint-disable-next-line no-nested-ternary
    hasBackgroundImage
      ? "blanco_"
      : reverssoFooterPlatformConfig === FooterConfig.GRAYSCALE || isStartView
      ? "gris_"
      : "";

  const logoUrl = `${BASE_URL}/logo_reversso_${logoColor}fondo_transparente.svg`;

  return (
    <footer className={className}>
      <S.FooterText>
        {Trans({
          t,
          i18nKey: "technology_of",
          components: {
            Link: (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
              <a
                href="https://reversso.com/"
                target="_blank"
                rel="noreferrer"
              />
            ),
            Img: <img src={logoUrl} alt="Reversso" />,
          },
        })}
      </S.FooterText>
    </footer>
  );
};

export default styled(Footer)`
  ${S.footerCss}
`;
