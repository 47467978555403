import { createContext } from "react";
import type { AddressCountryFragment } from "./graphql/generated/fragments.platform.generated";
import type {
  AddressFields,
  BaseFormValues,
  City,
  County,
  Region,
} from "./typing";
import type { AFTER_LOCALITIES } from "./utils";
import type { CountryZipcodeBehaviour } from "graphql/generated/schema.platform";

type AddressContextProps = {
  country: AddressCountryFragment;
  regions: Region[];
  cities: City[];
  counties: County[];
  correctZipcode: (
    value: (CountryZipcodeBehaviour | typeof AFTER_LOCALITIES)[]
  ) => boolean;
  loadingLocalities: boolean;
  editableFields: AddressFields<BaseFormValues> | undefined;
};
export const AddressContext = createContext<AddressContextProps>(
  {} as AddressContextProps
);
