import { HiArrowLeft, HiX } from "react-icons/hi";
import styled, { css } from "styled-components";
import { FadeIn, FadeOut, ZoomIn, ZoomOut } from "../../../animations";
import BaseCard from "../../../atoms/Card/BaseCard";
import Row from "../../../atoms/Row";
import type { ModalSize } from "./utils";
import { handleSize } from "./utils";

export const animationTime = 200;

export const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000066;
  animation-duration: ${animationTime / 1000}s;
  animation-fill-mode: both;
  animation-timing-function: linear;
`;

export const ContentContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  overflow: auto;
  outline: 0;
`;

export const InnerWrap = styled.div`
  position: relative;
  top: 100px;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 50px;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
`;

const iconCss = css`
  position: relative;
  top: 2px;
  font-size: 20px;
  min-width: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.iconAction.default.default};
  :hover {
    color: ${({ theme }) => theme.colors.iconAction.default.hovered};
  }
`;
export const BackIcon = styled(HiArrowLeft)`
  ${iconCss}
`;

export const CloseIcon = styled(HiX)`
  ${iconCss}
`;

export const Header = styled(Row)`
  padding: 16px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default.subdued};
`;

export const Body = styled.div`
  padding: 16px 20px;
  word-wrap: break-word;
`;

export const Footer = styled(Row)`
  padding: 16px 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.border.default.subdued};
`;

export const ModalContainerCard = styled(BaseCard)`
  position: relative;
  pointer-events: all;
  animation-duration: ${animationTime / 1000}s;
  animation-fill-mode: both;
`;

export type ContainerProps = {
  size?: ModalSize;
  isLeaving: boolean;
  visible: boolean;
};
export const Container = styled.div<ContainerProps>`
  display: none;
  ${({ isLeaving, visible }) =>
    (isLeaving || visible) &&
    css`
      display: block;
      overflow: auto;
    `}

  ${Shadow} {
    ${({ isLeaving }) =>
      isLeaving
        ? css`
            animation-name: ${FadeOut};
          `
        : css`
            animation-name: ${FadeIn};
            opacity: 0;
          `}
  }

  ${InnerWrap} {
    ${({ size }) => handleSize[size || "medium"]}
    /* hide scroll bar when ZoomOut animation is running */
    ${({ isLeaving }) =>
      isLeaving &&
      css`
        overflow: hidden;
      `}
  }

  ${ModalContainerCard} {
    ${({ isLeaving }) =>
      isLeaving
        ? css`
            animation-name: ${ZoomOut};
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          `
        : css`
            animation-name: ${ZoomIn};
            transform: scale(0);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          `}
  }

  @media ${({ theme }) => theme.breakpoints.mobile.css} {
    ${Header},
    ${Body},
    ${Footer} {
      padding: 16px;
    }

    ${CloseIcon} {
      right: 16px;
    }
  }
`;
