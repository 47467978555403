import _ from "lodash";
import type { FC } from "react";
import type {
  ReturnInformationAddressTicketFragment,
  ReturnInformationAddressTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import { Row } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text, Title } = Typography;

type AddressContentProps = {
  data:
    | ReturnInformationAddressTicketFragment
    | ReturnInformationAddressTicketRequestFragment;
};
const AddressContent: FC<AddressContentProps> = ({ data }) => {
  const { t } = useTranslation("common");
  const { returnAddress } = data;

  const content = [returnAddress?.formatted, returnAddress?.phoneNumber].filter(
    (c) => !_.isEmpty(c)
  );

  return (
    <Row $block $column $gap={12}>
      <Title.Subheading $color="subduedBold">
        {t("components.return_details.address.title")}
      </Title.Subheading>
      <Text.Body $color="subdued">{content.join(", ")}</Text.Body>
    </Row>
  );
};

export default AddressContent;
