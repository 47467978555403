/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
export type AddressCountryFragment = { __typename?: 'Country', id: string, address2Name: string, alpha2: Types.CountryAlpha2, cityName: string, countyName: string, editableFields: Array<Types.AddressEditableField>, hiddenFields: Array<Types.AddressField>, regionName: string, requiredFields: Array<Types.AddressField>, zipcodeBehaviors: Array<Types.CountryZipcodeBehaviour> };

export type AddressZipcodeFragment = { __typename?: 'Zipcode', id: string, countyName: string | null, cityName: string | null, region: { __typename?: 'Region', id: string, name: string } };

export const AddressCountryFragmentDoc = gql`
    fragment AddressCountry on Country {
  id
  address2Name
  alpha2
  cityName
  countyName
  editableFields
  hiddenFields
  regionName
  requiredFields
  zipcodeBehaviors
}
    `;
export const AddressZipcodeFragmentDoc = gql`
    fragment AddressZipcode on Zipcode {
  id
  countyName
  cityName
  region {
    id
    name
  }
}
    `;