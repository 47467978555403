import type { FC, ReactNode } from "react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import styled from "styled-components";
import Typography from "../../deprecated/Typography";
import * as S from "./styles";

const { Text } = Typography;

export type FormItemProps = {
  description?: ReactNode;
  error?: ReactNode;
  className?: string;
  $block?: boolean;
  $hidden?: boolean;
  $disabled?: boolean;
  $noStyle?: boolean;
  children: ReactNode;
};

const FormItem: FC<FormItemProps> = ({
  description,
  error,
  className,
  children,
}) => (
  <div className={className}>
    {children}
    {error && (
      <S.ErrorMessage>
        <HiOutlineExclamationCircle />
        {error}
      </S.ErrorMessage>
    )}
    {description && <Text.Body $color="subdued">{description}</Text.Body>}
  </div>
);

export default styled(FormItem)`
  ${S.formItemCss}
`;
