/* tslint:disable */
/* eslint-disable */
import * as Types from './schema.platform';

import { gql } from '@apollo/client';
export type MoneyFragment = { __typename?: 'Money', amount: number, formattedAmount: string };

export const MoneyFragmentDoc = gql`
    fragment Money on Money {
  amount
  formattedAmount
}
    `;