import { HiCheck } from "react-icons/hi";
import styled, { css } from "styled-components";
import Row from "../../../atoms/Row";
import Typography from "../../../deprecated/Typography";

export const OptionIcon = styled(HiCheck)`
  color: ${({ theme }) => theme.colors.icon.default};
`;

export const OptionContent = styled(Typography.Text.Body)`
  color: ${({ theme }) => theme.colors.text.subdued};
`;

type Props = {
  $active: boolean;
  $disabled: boolean;
};
export const OptionContainerRow = styled(Row).attrs({
  $block: true,
  $noWrap: true,
  $align: "center",
  $justify: "center",
  $gap: 4,
})<Props>`
  cursor: pointer;
  padding: 4px 16px;
  height: 28px;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  :hover {
    background-color: ${({ theme }) => theme.colors.surface.default.hovered};
  }
  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.surface.primary.important};
      ${OptionIcon} {
        color: ${({ theme }) => theme.colors.icon.white};
      }
      ${OptionContent} {
        color: ${({ theme }) => theme.colors.text.white};
      }
      :hover {
        background-color: ${({ theme }) =>
          theme.colors.surface.primary.important};
      }
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      background-color: unset;
      ${OptionIcon} {
        color: ${({ theme }) => theme.colors.icon.disabled};
      }
      ${OptionContent} {
        color: ${({ theme }) => theme.colors.text.disabled};
      }
      :hover {
        background-color: unset;
      }
    `}
`;
