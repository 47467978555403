import BaseStart from "./Start";
import StartForm from "./StartForm";

type CompoundedStart = typeof BaseStart & {
  Form: typeof StartForm;
};

const Start = BaseStart as CompoundedStart;
Start.Form = StartForm;

export default Start;
