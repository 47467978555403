import type { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type MenuProps = HTMLAttributes<HTMLUListElement> & {
  children: ReactNode;
};
const Menu: FC<MenuProps> = ({ children, ...props }) => (
  <ul {...props}>{children}</ul>
);

export default styled(Menu)`
  ${S.menuCss}
`;
