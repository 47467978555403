import type { FC, ReactNode } from "react";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import * as S from "./styles";
import { useDelayedBoolean } from "hooks";

export type BaseDrawerProps = {
  visible: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
  $placement?: "right" | "bottom" | "left";
  onClose?: () => void;
  fullHeight?: boolean;
  $fullWidth?: boolean;
  children: ReactNode;
};
const BaseDrawer: FC<BaseDrawerProps> = ({
  visible,
  footer,
  header,
  onClose,
  className,
  children,
}) => {
  const { display, isLeaving } = useDelayedBoolean(visible, S.animationTime);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const headerHeight = headerRef.current?.offsetHeight || 0;
  const footerHeight = footerRef.current?.offsetHeight || 0;

  useEffect(() => {
    if (visible) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [visible]);

  return (
    <>
      {display && (
        <S.DrawerShadow role="none" onClick={onClose} isLeaving={isLeaving} />
      )}
      <S.DrawerContainer
        className={className}
        $headerHeight={headerHeight}
        $footerHeight={footerHeight}
      >
        <S.HeaderContainerDiv ref={headerRef}>{header}</S.HeaderContainerDiv>
        <S.Content>{children}</S.Content>
        {footer && (
          <S.FooterRow ref={footerRef} $column $block $gap={12}>
            {footer}
          </S.FooterRow>
        )}
      </S.DrawerContainer>
    </>
  );
};

export default styled(BaseDrawer)`
  ${S.baseDrawerCss}
`;
