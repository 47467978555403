import type { FC, ReactNode } from "react";
import AddressContent from "./AddressContent";
import CourierContent from "./CourierContent";
import type {
  ReturnInformationDetailsTicketFragment,
  ReturnInformationDetailsTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import { Card, Divider, Row } from "design";
import { TicketReturnTrackingStatus } from "graphql/generated/schema.platform";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

type ReturnInformationDetailsProps = {
  data:
    | ReturnInformationDetailsTicketFragment
    | ReturnInformationDetailsTicketRequestFragment;
  extraTitle?: ReactNode;
  extraContent?: ReactNode;
  showCourierContent: boolean;
};
const ReturnInformationDetails: FC<ReturnInformationDetailsProps> = ({
  data,
  extraTitle,
  extraContent,
  showCourierContent,
}) => {
  const { t } = useTranslation("common");
  const { isExchange } = data;

  const returnTrackingStatus =
    "returnTrackingStatus" in data ? data.returnTrackingStatus : null;

  // when is an exchange we always show the card, but depending on the showCourierContent prop we show
  // the courier content or not, but the other information is always shown

  // when is not an exchange and returnTrackingStatus is NOT_REQUIRED we don't show the card at all
  if (
    !isExchange &&
    returnTrackingStatus === TicketReturnTrackingStatus.NOT_REQUIRED
  )
    return null;

  return (
    <Card block title={t("components.return_details.title")} extra={extraTitle}>
      <Row $block $gap={12}>
        {showCourierContent && [
          <CourierContent key="return-details-courier" data={data} />,
          <Divider key="return-details-divider" $marginY={0} />,
        ]}
        <AddressContent data={data} />
        {extraContent}
      </Row>
    </Card>
  );
};

export default ReturnInformationDetails;
