import styled, { css } from "styled-components";
import { Typography } from "design/deprecated";

type Props = {
  smallWidth: boolean;
};
export const ToBeConfirmedText = styled(Typography.Text)<Props>`
  color: ${({ theme }) => theme.colors.text.subdued};
  text-align: right;
  ${({ smallWidth }) =>
    smallWidth &&
    css`
      max-width: 190px;
    `}
`;
