import styled from "styled-components";
import { Card, Row } from "design";

export const ContainerSection = styled.section`
  width: 100%;
  max-width: 420px;
`;

export const SiderContentContainerRow = styled(Row)`
  ${Card} {
    width: 100%;
  }
`;
