import type { DefaultTheme } from "styled-components";
import styled, { css } from "styled-components";
import {
  productAdded,
  productAddedIcon,
  dotRestingPosition,
} from "./animations";
import {
  Button,
  ButtonStyles,
  Icon,
  Popover,
  Row,
  TooltipStyles,
} from "design";

export const BAG_ANIMATION_TIME = 2; // seconds

export const StyledPopover = styled(Popover).attrs({
  showShadow: true,
})`
  &&&.${TooltipStyles.tooltipClass} {
    pointer-events: none;
    z-index: 999;
    width: 253px;
    border-radius: 8px;
    /* !important needed to override library inline styles */
    top: 50px !important;
    left: unset !important;
    right: 24px;
    .${TooltipStyles.tooltipClass}-inner {
      overflow: hidden;
      padding: unset;
    }
    &&&.${TooltipStyles.tooltipClass} .${TooltipStyles.tooltipClass}-arrow {
      right: 5%;
    }
  }
`;
export const PopoverContentRow = styled(Row)`
  padding: 8px 12px;
  gap: 8px;
`;

export const FooterRow = styled(Row)`
  min-height: 28px;
  flex-wrap: nowrap;
  gap: 4px;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface.success.important};
`;

export const BagContainerDiv = styled.div`
  position: absolute;
  right: 0;
`;

type BagButtonProps = {
  $animate: boolean;
  $productsOnBag: {
    hasProducts: boolean;
    count: number;
  };
};

const dot = (theme: DefaultTheme) => css`
  content: "";
  position: absolute;
  border: 1px solid ${theme.colors.icon.white};
  border-radius: 50%;
  background-color: ${theme.colors.surface.primary.important};
`;

export const BagButton = styled(Button)<BagButtonProps>`
  position: relative;
  ${ButtonStyles.ButtonContent} ${Icon} svg {
    color: ${({ theme }) => theme.colors.icon.default};
  }
  ${({ $animate, $productsOnBag, theme }) =>
    $animate
      ? css`
          ::before {
            ${dot(theme)}
            transform-origin: center;
            animation: ${productAdded($productsOnBag.count > 1)}
              ${BAG_ANIMATION_TIME}s;
          }
          ${ButtonStyles.ButtonContent} ${Icon} svg {
            animation: ${productAddedIcon(theme)} ${BAG_ANIMATION_TIME}s;
          }
        `
      : $productsOnBag.hasProducts &&
        css`
          ::before {
            ${dot(theme)}
            ${dotRestingPosition}
          }
        `}
`;
