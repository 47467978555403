import type { FC } from "react";
import styled from "styled-components";
import * as S from "./styles";
import { useShop } from "contexts";
import type { RowProps } from "design";
import { FooterConfig } from "typing";

type ViewActionsContainerProps = {
  className?: string;
  column?: RowProps["$column"];
  noWrap?: RowProps["$noWrap"];
  gap?: RowProps["$gap"];
  justify?: RowProps["$justify"];
  align?: RowProps["$align"];
  rowProps?: Omit<
    RowProps,
    "$column" | "$noWrap" | "$gap" | "$justify" | "$align"
  >;
};
const ViewActionsContainer: FC<ViewActionsContainerProps> = ({
  align,
  children,
  className,
  column,
  gap,
  justify,
  noWrap,
  rowProps,
}) => {
  const { reverssoFooterPlatformConfig } = useShop();

  return (
    <S.StyledRow
      className={className}
      $hiddenFooter={reverssoFooterPlatformConfig === FooterConfig.HIDDEN}
      $align={align}
      $column={column}
      $gap={gap}
      $justify={justify}
      $noWrap={noWrap}
      {...rowProps}
    >
      {children}
    </S.StyledRow>
  );
};

export default styled(ViewActionsContainer)``;
