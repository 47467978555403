import styled, { css } from "styled-components";
import Typography from "../../deprecated/Typography";
import Button from "../../molecules/Button";
import Row from "../Row";
import type { PageHeaderProps } from "./index";

const { Text } = Typography;

export const PageHeaderLeft = styled(Row)`
  position: relative;
`;
export const BackIconButton = styled(Button)``;
export const PageHeaderTitle = styled(Row)``;
export const DescriptionText = styled(Text.Body)`
  width: 100%;
`;
export const MainContainer = styled(Row)`
  width: 100%;
`;

const backIconCss = css`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  ${BackIconButton},
  ${Typography} {
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    ${BackIconButton},
    ${Typography} {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
  ${BackIconButton} {
    position: absolute;
    left: -36px;
    color: ${({ theme }) => theme.colors.icon.default};
  }
`;

export const pageHeaderCss = css<PageHeaderProps>`
  min-height: 75px;
  width: 100%;
  padding-bottom: 20px;
  ${({ $hideBorderBottom }) =>
    !$hideBorderBottom &&
    css`
      margin-bottom: 20px;
      border-bottom: 1px solid
        ${({ theme }) => theme.colors.border.default.subdued};
    `}

  ${PageHeaderLeft} {
    ${({ linkHref }) => linkHref && backIconCss}
    a {
      ${backIconCss}
    }
  }

  @media (max-width: 1290px) and (min-width: 1180px) {
    ${PageHeaderLeft} {
      ${BackIconButton} {
        position: unset;
        left: unset;
        margin-right: 8px;
      }
    }
  }
  @media (max-width: 740px) {
    ${PageHeaderLeft} {
      ${BackIconButton} {
        position: unset;
        left: unset;
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 576px) {
    ${PageHeaderLeft},
    ${PageHeaderTitle} {
      align-items: start;
      flex-direction: column;
    }
  }
`;
