import { NetworkStatus } from "@apollo/client";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiCheck } from "react-icons/hi";
import * as uuid from "uuid";
import type { SiderProps } from "../Sider";
import Sider from "../Sider";
import Steps from "../Steps";
import BaseWrapper from "../Wrappers/BaseWrapper";
import Cart from "./Cart";
import type { NotificationProductData } from "./Cart/interfaces";
import Content from "./Content";
import { TicketRequestLayoutContext } from "./context";
import type { TicketRequestShippingMethodInnerFragment } from "./graphql/generated/fragments.platform.generated";
import { useTicketRequestSiderNewProductsRemoveProductMutation } from "./graphql/generated/mutations.platform.generated";
import {
  useTicketRequestLayoutQuery,
  useTicketRequestShippingMethodsLazyQuery,
} from "./graphql/generated/queries.platform.generated";
import * as S from "./styles";
import type { ProductSiderProps } from "components";
import { Button, Loading, toast, useDesign } from "design";
import { useTicketRequestTracker } from "hooks";
import { firstElement, isInsideIframe } from "utils";
import nextI18n from "utils/i18n";

const { i18n, useTranslation } = nextI18n;

const TicketRequestLayout: FC<{
  className?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
  showSteps: boolean;
}> = ({ className, children, hideHeader, hideFooter, showSteps }) => {
  const { t } = useTranslation("common");
  useTicketRequestTracker();
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ticketRequestId = firstElement(router.query.ticketRequestId!);
  const {
    device: { isDesktop },
  } = useDesign();
  const [notificationProducts, setNotificationProducts] = useState<
    NotificationProductData[]
  >([]);

  const notifyProduct = (product: ProductSiderProps["product"]) =>
    setNotificationProducts((c) => [
      ...c,
      {
        id: uuid.v4(),
        product,
      },
    ]);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const isAddressRoute = router.pathname.includes("address");
  const isRetunRoute = router.pathname.includes("return");
  const isResolutionRoute = router.pathname.includes("resolution");

  const { data } = useTicketRequestLayoutQuery({
    variables: { ticketRequestId },
    skip: !ticketRequestId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    onCompleted: ({ ticketRequest }) => {
      if (!ticketRequest) return;
      const { ticket, language } = ticketRequest;
      if (ticket) {
        router.push(`/ticket/${ticket.id}/summary`);
      }
      if (language) {
        const lowerCaseLanguage = language.toLowerCase();
        if (i18n.language !== lowerCaseLanguage) {
          i18n.changeLanguage(lowerCaseLanguage);
        }
      }
    },
  });

  useEffect(() => {
    // prefetch sider links
    router.prefetch("/request/[ticketRequestId]/return");
    router.prefetch("/request/[ticketRequestId]/resolution");
  }, [router]);

  const {
    returnAddress,
    shippingMethodUpToDate,
    newShippingPrice,
    fetchableShippingMethods,
  } =
    (data?.ticketRequest as
      | TicketRequestShippingMethodInnerFragment
      | undefined) || {};
  const addressAllInformation = !!returnAddress?.allInformation;

  const [fetchShippingMethods, { refetch, called, networkStatus }] =
    useTicketRequestShippingMethodsLazyQuery({
      notifyOnNetworkStatusChange: true,
      variables: { ticketRequestId },
      // skip: !isAddressRoute || !addressAllInformation || shippingMethodUpToDate,
      onCompleted: ({ ticketRequestShippingMethods }) => {
        const {
          ticketRequest: {
            shippingMethodUpToDate: _shippingMethodUpToDate,
            newShippingPrice: _newShippingPrice,
          },
        } = ticketRequestShippingMethods;

        if (
          _newShippingPrice?.amount === newShippingPrice?.amount ||
          !_shippingMethodUpToDate
        )
          return;

        toast("info", t("components.sider.new_shipment_updated"), {
          icon: <HiCheck />,
        });
      },
    });

  const shouldFetchMethods =
    isAddressRoute && addressAllInformation && !shippingMethodUpToDate;
  const waitingFetchable = shouldFetchMethods && !fetchableShippingMethods;
  const newShippingPriceLoading =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.loading ||
    waitingFetchable;
  useEffect(() => {
    if (!shouldFetchMethods || !fetchableShippingMethods) return;
    if (!called) fetchShippingMethods();
    else refetch();
  }, [called, shouldFetchMethods, fetchableShippingMethods]);

  const [deleteNewProduct] =
    useTicketRequestSiderNewProductsRemoveProductMutation();

  const siderContent: SiderProps["contentProps"] = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    data: data?.ticketRequest!,
    paymentProps: {
      editted: undefined,
      footerExtra: undefined,
      onTop: false,
      title: undefined,
      newShippingPriceLoading,
    },
    productProps: {
      newShippingPriceLoading,
      returnProductsProps: {
        extra: !isRetunRoute && (
          <Button
            type="plain-primary"
            onClick={() => {
              router.push(`/request/${ticketRequestId}/return`);
              setDrawerVisible(false);
            }}
          >
            {t("components.sider.edit.return_products")}
          </Button>
        ),
      },
      newProductsProps: {
        onRemove: !isResolutionRoute
          ? undefined
          : (productId) => deleteNewProduct({ variables: { productId } }),
        extra: !isResolutionRoute && (
          <Button
            type="plain-primary"
            onClick={() => {
              router.push(`/request/${ticketRequestId}/resolution`);
              setDrawerVisible(false);
            }}
          >
            {t("components.sider.edit.new_products")}
          </Button>
        ),
      },
    },
  };

  const mobileInsideIframe = !isDesktop && isInsideIframe();
  const _showSteps = !mobileInsideIframe && showSteps;
  const showStepsHeader = mobileInsideIframe && showSteps;

  return (
    <TicketRequestLayoutContext.Provider
      value={{ notifyProduct, setDrawerVisible }}
    >
      <BaseWrapper
        hideFooter={hideFooter}
        hideHeader={hideHeader}
        headerOverrideLogo={showStepsHeader ? <Steps /> : null}
        headerExtra={
          <Cart
            productsOnBag={
              !data
                ? 0
                : data.ticketRequest.newProducts.length +
                  data.ticketRequest.returnOrderProducts.filter(
                    ({ returnProduct }) => !!returnProduct
                  ).length
            }
            setDrawerVisible={setDrawerVisible}
            notificationProducts={notificationProducts}
            setNotificationProducts={setNotificationProducts}
          />
        }
      >
        <S.LayoutContainerMain className={className}>
          {!data ? (
            <Loading />
          ) : (
            <>
              <S.ContentContainerDiv>
                <S.Container>
                  <Content showSteps={_showSteps} hideFooter={!!hideFooter}>
                    {children}
                  </Content>
                  {isDesktop && <Sider contentProps={siderContent} />}
                </S.Container>
              </S.ContentContainerDiv>
              {!isDesktop && (
                <Sider
                  contentProps={siderContent}
                  drawer={{
                    visible: drawerVisible,
                    setVisible: setDrawerVisible,
                  }}
                />
              )}
            </>
          )}
        </S.LayoutContainerMain>
      </BaseWrapper>
    </TicketRequestLayoutContext.Provider>
  );
};

export default TicketRequestLayout;
