import type { ForwardedRef, ReactElement, Ref } from "react";
import { forwardRef, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";
import type { InputProps } from "../Input";
import Input from "../Input";
import * as S from "./styles";
import { useClickOutside } from "hooks";

type Value = string | null | undefined;
export type ColorPickerProps<ValueType extends Value> = Omit<
  InputProps,
  "ref" | "prefix" | "onChange" | "value"
> & {
  value?: ValueType;
  onChange?: (value: string | undefined) => void;
};
const ColorPickerInner = <ValueType extends Value>(
  { className, value, onChange, ...props }: ColorPickerProps<ValueType>,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement => {
  const [isActive, setIsActive] = useState(false);

  const pickerRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(pickerRef, () => setIsActive(false));

  return (
    <div className={className}>
      <Input
        {...props}
        ref={ref}
        onFocus={() => setIsActive(true)}
        onChange={(e) => onChange?.(e.target.value)}
        value={value ?? undefined}
        prefix={<S.ColorPreview $color={`#${value}`} />}
      />
      {isActive && (
        <S.PickerContainer ref={pickerRef}>
          <ChromePicker
            color={`#${value}` || ""}
            onChange={(result: { hex: string }) =>
              onChange?.(result.hex.replace("#", ""))
            }
            disableAlpha
          />
        </S.PickerContainer>
      )}
    </div>
  );
};

const ColorPicker: (<ValueType extends Value>(
  props: ColorPickerProps<ValueType> & {
    ref?: Ref<HTMLInputElement>;
  }
) => ReactElement) &
  string = styled(forwardRef(ColorPickerInner))`
  ${S.colorPickerCss}
`;

export default ColorPicker;
