import composeRefs from "@seznam/compose-react-refs";
import { forwardRef, useRef } from "react";
import { MdClose, MdSearch } from "react-icons/md";
import styled from "styled-components";
import type { InputProps } from "../Input";
import Input from "../Input";
import * as S from "./styles";

export type InputSearchProps = Omit<InputProps, "type"> & {
  clearOnClick: () => void;
};
const Search = forwardRef<HTMLInputElement, InputSearchProps>(
  ({ disabled, clearOnClick, ...rest }, ref) => {
    const searchRef = useRef<HTMLInputElement>(null);
    const clearIconSearch = searchRef.current?.value && (
      <S.SearchIcon
        onClick={() => clearOnClick()}
        icon={<MdClose size={20} />}
      />
    );

    return (
      <Input
        ref={composeRefs(ref, searchRef)}
        type="text"
        disabled={disabled}
        {...rest}
        prefix={<MdSearch size={20} />}
        suffix={clearIconSearch}
      />
    );
  }
);

export default styled(Search)`
  ${S.searchCss}
`;
