import type { ReactElement, ForwardedRef } from "react";
import { forwardRef } from "react";
import type { Row } from "react-table";
import styled from "styled-components";
import * as S from "./styles";

export type BodyRowProps<D extends object> = {
  row: Row<D>;
  onClick?: (data: D) => void;
  dragger?: ReactElement;
  className?: string;
  $addBorderTop?: boolean;
  $dropUpward?: boolean;
  $dropDownward?: boolean;
};

const BodyRowInner = <D extends object>(
  { row, dragger, onClick, ...props }: BodyRowProps<D>,
  ref?: ForwardedRef<HTMLTableRowElement>
): ReactElement => (
  <tr
    ref={ref}
    {...props}
    {...row.getRowProps()}
    onClick={() => onClick?.(row.original)}
  >
    {dragger}
    {row.cells.map((cell) => (
      <td
        {...cell.getCellProps({
          // @ts-expect-error react-table types not finding className prop (but works)
          className: `${(cell.column.className as string | undefined) || ""}`,
        })}
      >
        {cell.render("Cell")}
      </td>
    ))}
  </tr>
);

const BodyRow: (<D extends object>(
  props: BodyRowProps<D> & {
    ref?: ForwardedRef<HTMLDivElement>;
  }
) => ReactElement) &
  string = styled(forwardRef(BodyRowInner))`
  ${S.bodyRowCss}
`;

export default BodyRow;
