import Body from "./Body";
import Heading from "./Heading";
import BaseTypography from "./Typography";

export * as TypographyStyles from "./styles";

export type { TypographyProps } from "./Typography";

type CompoundedTypography = typeof BaseTypography & {
  Heading: typeof Heading;
  Body: typeof Body;
};

const Typography = BaseTypography as CompoundedTypography;
Typography.Heading = Heading;
Typography.Body = Body;

export default Typography;
