/* eslint-disable @typescript-eslint/ban-ts-comment */
import composeRefs from "@seznam/compose-react-refs";
import type {
  ReactNode,
  InputHTMLAttributes,
  AnimationEvent,
  TextareaHTMLAttributes,
  ForwardedRef,
  ReactElement,
} from "react";
import { useRef, useState, forwardRef } from "react";
import styled from "styled-components";
import { v4 } from "uuid";
import Row from "../../../atoms/Row";
import TooltipIcon from "../../../atoms/TooltipIcon";
import Typography from "../../../deprecated/Typography";
import type { ButtonProps } from "../../Button";
import Button from "../../Button";
import * as S from "./styles";

const { Text } = Typography;

type ActionButton = {
  onClick?: ButtonProps["onClick"];
  props?: ButtonProps;
  text?: ReactNode;
};

export type AbstractInputProps<
  I extends HTMLInputElement | HTMLTextAreaElement
> = Omit<
  I extends HTMLInputElement
    ? InputHTMLAttributes<I>
    : TextareaHTMLAttributes<I>,
  "prefix" | "suffix" | "hasError" | "label" | "size"
> & {
  component: "input" | "textarea";
  prefix?: ReactNode;
  suffix?: ReactNode;
  outlined?: boolean;
  label?: ReactNode;
  subLabel?: ReactNode;
  labelRequired?: boolean;
  hasError?: boolean;
  type?: string;
  tooltip?: ReactNode;
  onAction?: ActionButton;
  normalize?: (value: string) => string;
  block?: boolean;
};

export const AbstractInputInner = <
  I extends HTMLInputElement | HTMLTextAreaElement
>(
  {
    component: Component,
    prefix,
    suffix,
    className,
    disabled,
    onFocus,
    id,
    normalize,
    onChange,
    onAnimationStart,
    outlined,
    label,
    labelRequired,
    placeholder,
    hasError,
    subLabel,
    tooltip,
    onAction,
    block,
    ...rest
  }: AbstractInputProps<I>,
  ref: ForwardedRef<I>
): ReactElement => {
  const [isFocus, setFocus] = useState(false);
  const [isEmpty, setEmpty] = useState(!rest.value || !rest.defaultValue);
  const innerRef = useRef<I>(null);
  const _id = id !== undefined ? id : v4();

  const handleAnimation = (e: AnimationEvent<I>) => {
    const { animationName } = e;
    if (animationName === S.OnAutoFillStart.getName()) setEmpty(false);
    else if (animationName === S.OnAutoFillCancel.getName()) {
      setEmpty(!innerRef?.current?.value);
    }
    // @ts-ignore
    return onAnimationStart?.(e);
  };

  return (
    <S.AbstractContainerDiv className={className} $block={!!block}>
      {(label || subLabel || !!onAction) && (
        <Row $justify="space-between" $align="end">
          {(label || subLabel) && (
            <Row $column>
              {label && (
                <S.Label
                  htmlFor={_id}
                  $isFocused={outlined ? isFocus && !!label : false}
                  $labelRequired={!!labelRequired}
                  $isActive={
                    outlined ? isFocus || (!isEmpty && !!label) : false
                  }
                >
                  <Text.Body $color={outlined ? "subdued" : "default"}>
                    {label}
                    {tooltip && <TooltipIcon tooltip={tooltip} />}
                  </Text.Body>
                </S.Label>
              )}
              {subLabel && !outlined && (
                <Text.Body $color="subdued">{subLabel}</Text.Body>
              )}
            </Row>
          )}
          {!!onAction && (
            <Button
              type="plain-primary"
              onClick={onAction.onClick}
              {...onAction.props}
            >
              {onAction.text}
            </Button>
          )}
        </Row>
      )}
      <S.InnerContainer
        role="none"
        $isFocused={isFocus && !hasError}
        $hasPrefix={!!prefix && !outlined}
        $hasSuffix={!!suffix}
        onClick={() => innerRef?.current?.focus()}
        onBlur={() => {
          if (!innerRef?.current?.value) setEmpty(true);
          setFocus(false);
        }}
      >
        {prefix && !outlined && <S.StyledIcon $type="prefix" icon={prefix} />}
        <Component
          // @ts-ignore
          ref={composeRefs(ref, innerRef)}
          onFocus={(e) => {
            setFocus(true);
            // @ts-ignore
            return onFocus?.(e);
          }}
          // @ts-ignore
          onAnimationStart={handleAnimation}
          onChange={(e) => {
            const value = normalize
              ? normalize(e.target.value)
              : e.target.value;
            e.target.value = value;
            if (value) setEmpty(false);
            // @ts-ignore
            return onChange?.(e);
          }}
          placeholder={outlined ? undefined : placeholder}
          id={_id}
          disabled={disabled}
          {...rest}
        />
        {suffix && <S.StyledIcon $type="suffix" icon={suffix} />}
      </S.InnerContainer>
    </S.AbstractContainerDiv>
  );
};

const AbstractInput = forwardRef(AbstractInputInner);

export default styled(AbstractInput)`
  ${S.abstractCss}
`;
