import path from "path";
import NextI18Next from "next-i18next";

import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/pt-br";

// const deployEnv = process.env.NEXT_PUBLIC_NODE_ENV;
// const shouldDebug = deployEnv === "local" || deployEnv === "development";

// i18n language with dayjs format
export const languages = {
  es: { dayjs: "es" },
  en: { dayjs: "en" },
  pt: { dayjs: "pt-br" },
};

const nextI18n = new NextI18Next({
  // debug: shouldDebug,
  browserLanguageDetection: false,
  otherLanguages: ["en", "pt"],
  defaultLanguage: "es",
  // languages: Object.keys(languages),
  localePath: path.resolve("./public/locales"),
  defaultNS: "common",
  ns: [
    "address",
    "checkout",
    "common",
    "errors",
    "history",
    "resolution",
    "return",
    "start",
    "summary",
    "survey",
  ],
  interpolation: {
    escapeValue: false,
    format(value, format) {
      if (format === "lowercase") return value.toLowerCase();
      return value;
    },
  },
});

export default nextI18n;
