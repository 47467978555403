import type { TFunction } from "next-i18next";

type Errors = string[];
type Money = { amount: number };

// return is a reserved word
const returnErrors = ({
  t,
  returnProducts,
}: {
  t: TFunction;
  returnProducts: unknown[];
}): Errors => {
  const errors: Errors = [];
  if (returnProducts.length === 0) errors.push(t("return.no_return_products"));
  return errors;
};

const resolution = ({
  t,
  acceptExchanges,
  acceptReturns,
  allowDownselling,
  allowUpselling,
  totalProductsDifference,
  newProducts,
  hasRefundInformation,
}: {
  t: TFunction;
  acceptExchanges: boolean;
  acceptReturns: boolean;
  allowDownselling: boolean;
  allowUpselling: boolean;
  totalProductsDifference: Money;
  newProducts: unknown[];
  hasRefundInformation: boolean;
}): { errors: Errors; changeError: boolean } => {
  const errors: Errors = [];
  let changeError = false;

  if (newProducts.length === 0 && !hasRefundInformation) {
    if (acceptReturns && acceptExchanges) {
      errors.push(t("resolution.resolution.both"));
    } else if (acceptReturns)
      errors.push(t("resolution.resolution.only_returns"));
    else errors.push(t("resolution.resolution.only_exchanges"));
    return { errors, changeError: false };
  }

  if (!allowUpselling && totalProductsDifference.amount > 0) {
    if (allowDownselling) errors.push(t("resolution.delete_products"));
    else errors.push(t("resolution.delete_products_equal_difference"));
  }

  if (!allowDownselling) {
    if (newProducts.length > 0 && totalProductsDifference.amount < 0) {
      errors.push(t("resolution.choose_more_products"));
    }
  } else if (!hasRefundInformation && totalProductsDifference.amount < 0) {
    errors.push(t("resolution.choose_more_products_or_refund"));
    changeError = true;
  }

  return { errors, changeError };
};

const address = ({
  t,
  customer,
  returnAddress,
  hasReturnMethod,
  requiresReturnAddress,
  requiresReverseDelivery,
}: {
  t: TFunction;
  customer: { allInformation: boolean } | null;
  returnAddress: { allInformation: boolean } | null;
  hasReturnMethod: boolean;
  requiresReverseDelivery: boolean | null;
  requiresReturnAddress: boolean | null;
}): Errors => {
  const errors: Errors = [];

  if (!customer?.allInformation) errors.push(t("address.fillout_customer"));
  if (requiresReturnAddress && !returnAddress?.allInformation)
    errors.push(t("address.fillout_address"));
  if (requiresReverseDelivery && !hasReturnMethod)
    errors.push(t("address.choose_return_method"));

  return errors;
};

const checkout = (
  props: Parameters<typeof returnErrors>[0] &
    Parameters<typeof resolution>[0] &
    Parameters<typeof address>[0]
): Errors => [
  ...returnErrors(props),
  ...resolution(props).errors,
  ...address(props),
];

export default { return: returnErrors, resolution, address, checkout };
