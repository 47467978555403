/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopPasswordMutationVariables = Types.Exact<{
  password: Types.Scalars['String'];
}>;


export type ShopPasswordMutation = { __typename?: 'Mutation', platformLogin: { __typename?: 'PlatformLoginPayload', success: boolean, errors: Array<{ __typename?: 'Error', field: string, messages: Array<string> }> | null } };


export const ShopPasswordDocument = gql`
    mutation ShopPassword($password: String!) {
  platformLogin(input: {password: $password}) {
    success
    errors {
      field
      messages
    }
  }
}
    `;
export type ShopPasswordMutationFn = Apollo.MutationFunction<ShopPasswordMutation, ShopPasswordMutationVariables>;

/**
 * __useShopPasswordMutation__
 *
 * To run a mutation, you first call `useShopPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopPasswordMutation, { data, loading, error }] = useShopPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useShopPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ShopPasswordMutation, ShopPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopPasswordMutation, ShopPasswordMutationVariables>(ShopPasswordDocument, options);
      }
export type ShopPasswordMutationHookResult = ReturnType<typeof useShopPasswordMutation>;
export type ShopPasswordMutationResult = Apollo.MutationResult<ShopPasswordMutation>;
export type ShopPasswordMutationOptions = Apollo.BaseMutationOptions<ShopPasswordMutation, ShopPasswordMutationVariables>;