import type { FC, ReactNode } from "react";
import styled from "styled-components";
import BaseWrapper from "../Wrappers/BaseWrapper";
import Content from "./Content";
import * as S from "./styles";
import { useShop } from "contexts";
import { useDesign } from "design";
import { useTicketRequestTracker } from "hooks";
import Steps from "layouts/Steps";
import { BackgroundOption } from "typing";
import { isInsideIframe } from "utils";

const LayoutBase: FC<{
  children?: ReactNode;
  className?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
  isStartView?: boolean;
  showBackgroundImage: boolean;
  layoutSize?: "small" | "medium" | "large";
  showSteps?: boolean;
}> = ({
  children,
  className,
  hideHeader,
  hideFooter,
  isStartView,
  showBackgroundImage,
  layoutSize,
  showSteps,
}) => {
  useTicketRequestTracker();
  const { backgroundOption, backgroundImage } = useShop();

  const {
    device: { isDesktop },
  } = useDesign();

  const hasBackgroundImage =
    showBackgroundImage &&
    backgroundImage &&
    backgroundOption === BackgroundOption.IMAGE
      ? backgroundImage
      : undefined;

  const mobileInsideIframe = !isDesktop && isInsideIframe();
  const showStepsHeader = mobileInsideIframe && showSteps;

  return (
    <BaseWrapper
      headerOverrideLogo={showStepsHeader ? <Steps /> : null}
      hideHeader={hideHeader}
      hideFooter={hideFooter || isStartView}
    >
      <S.LayoutContainerMain
        className={className}
        $backgroundUrl={hasBackgroundImage}
      >
        <S.ContentContainerDiv>
          <Content
            hideFooter={hideFooter}
            isStartView={isStartView}
            layoutSize={layoutSize}
            hasBackgroundImage={!!hasBackgroundImage}
          >
            {children}
          </Content>
        </S.ContentContainerDiv>
      </S.LayoutContainerMain>
    </BaseWrapper>
  );
};

export default styled(LayoutBase)``;
