import type { ReactNode, FC } from "react";
import { Row } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text } = Typography;

type AmountMoney = {
  amount: number;
  formattedAmount: string;
};
type ExpenseProps = {
  title?: ReactNode;
  amount: AmountMoney | ReactNode;
  editedAmount?: AmountMoney;
  showEdited?: boolean;
  showFree?: boolean;
  strikethroughAmount?: boolean;
  isTotal?: boolean;
  negate?: boolean;
};
const Expense: FC<ExpenseProps> = ({
  title,
  amount,
  editedAmount,
  showEdited,
  showFree,
  strikethroughAmount,
  isTotal,
  negate,
}) => {
  const { t } = useTranslation("common");
  const handleTextDecoration = strikethroughAmount ? "line-through" : undefined;

  const isFree = (total: AmountMoney | ReactNode) => {
    if (total && typeof total === "object" && "formattedAmount" in total)
      return total.amount === 0 && showFree;
    return false;
  };

  const handleNegate = (str: string) => {
    if (str.charAt(0) === "-") return str.substring(1);
    return `-${str}`;
  };

  const formattedAmount = (total: AmountMoney | ReactNode) =>
    // eslint-disable-next-line no-nested-ternary
    total && typeof total === "object" && "formattedAmount" in total
      ? negate
        ? handleNegate(total.formattedAmount)
        : total.formattedAmount
      : total;

  return (
    <Row $block $noWrap $justify="space-between" $gap={4}>
      <Row $align="center" $gap={4}>
        <Typography
          wrapper="span"
          $type={isTotal ? "heading" : "body"}
          $color={!isTotal ? "subdued" : undefined}
        >
          {title}
        </Typography>
        {showEdited && (
          <Text.Body $color="subdued">{`(${t("edited")})`}</Text.Body>
        )}
      </Row>
      <Row $align="center" $noWrap $gap={4}>
        {showEdited && !!editedAmount && (
          <Text.Body $color="subdued" $textDecoration={handleTextDecoration}>
            {formattedAmount(amount)}
          </Text.Body>
        )}
        <Typography
          wrapper="span"
          $type={isTotal ? "heading" : "body"}
          $weight={!isTotal ? 500 : undefined}
          $textDecoration={!editedAmount ? handleTextDecoration : undefined}
          $color={
            // eslint-disable-next-line no-nested-ternary
            isFree(editedAmount || amount)
              ? "success"
              : !isTotal
              ? "subdued"
              : undefined
          }
        >
          {isFree(editedAmount || amount)
            ? t("free")
            : formattedAmount(editedAmount || amount)}
        </Typography>
      </Row>
    </Row>
  );
};

export default Expense;
