import type { FC, HTMLAttributes } from "react";
import type { DefaultTheme } from "styled-components";
import styled from "styled-components";
import * as S from "./styles";

export type TypographyProps = Omit<
  HTMLAttributes<HTMLElement>,
  "color" | "prefix"
> & {
  wrapper: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  $color?: keyof DefaultTheme["colors"]["text"];
  $type:
    | "displayXL"
    | "displayL"
    | "displayM"
    | "displayS"
    | "heading"
    | "subheading"
    | "button"
    | "body"
    | "caption";
  $weight?: 400 | 500 | 600;
  $textDecoration?: "underline" | "overline" | "line-through";
  $cursor?: "pointer" | "not-allowed" | "default";
  $align?: "left" | "center" | "right";
};
export const Typography: FC<TypographyProps> = ({
  wrapper: Wrapper,
  children,
  ...props
}) => <Wrapper {...props}>{children}</Wrapper>;

export default styled(Typography)`
  ${S.typographyCss}
`;
