import type { LinkProps } from "next/link";
import Link from "next/link";
import type { FC, ReactNode } from "react";
import { HiArrowLeft } from "react-icons/hi";
import styled from "styled-components";
import Typography from "../../deprecated/Typography";
import Row from "../Row";
import * as S from "./styles";

const { Text, Title } = Typography;

export type PageHeaderProps = {
  className?: string;
  backIcon?: boolean;
  extra?: ReactNode;
  title: ReactNode;
  subTitle?: ReactNode;
  description?: ReactNode;
  linkHref?: LinkProps["href"];
  $hideBorderBottom?: boolean;
};
const PageHeader: FC<PageHeaderProps> = ({
  backIcon,
  className,
  extra,
  subTitle,
  title,
  description,
  linkHref,
}) => {
  const textContainer = (
    <S.PageHeaderTitle $align="center" $noWrap>
      {backIcon && (
        <S.BackIconButton type="ghost" icon={<HiArrowLeft size={20} />} />
      )}
      <Title.DisplayS>{title}</Title.DisplayS>
    </S.PageHeaderTitle>
  );
  return (
    <Row $column $gap={6} className={className}>
      <S.MainContainer $justify="space-between" $gap={8}>
        <S.PageHeaderLeft $align="center" $gap={8}>
          {linkHref ? (
            <Link href={linkHref}>{textContainer}</Link>
          ) : (
            textContainer
          )}
          {subTitle && <Text.Body $color="subdued">{subTitle}</Text.Body>}
        </S.PageHeaderLeft>
        {extra && <Row $align="center">{extra}</Row>}
      </S.MainContainer>
      {description && <S.DescriptionText>{description}</S.DescriptionText>}
    </Row>
  );
};

export default styled(PageHeader)`
  ${S.pageHeaderCss}
`;
