import BaseText from "./Text";
import { Button, Body, Caption } from "./TextOptions";

export type TextProps = typeof BaseText & {
  Button: typeof Button;
  Body: typeof Body;
  Caption: typeof Caption;
};

const Text = BaseText as TextProps;
Text.Button = Button;
Text.Body = Body;
Text.Caption = Caption;

export default Text;
