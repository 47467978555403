import type { HTMLAttributes, ReactNode } from "react";
import { forwardRef } from "react";
import styled from "styled-components";
import Typography from "../../../deprecated/Typography";
import type { CardProps } from "../../Card";
import type { RowProps } from "../../Row";
import Row from "../../Row";
import * as S from "./styles";

const { Text, Title } = Typography;

export type SectionContainerProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "title"
> & {
  leftContentClass?: string;
  title?: ReactNode;
  description?: ReactNode;
  contentClass?: string;
  rowProps?: RowProps;
  cardProps?: CardProps;
  withTable?: boolean;
  fillLeftContent?: boolean;
};

const SectionContainer = forwardRef<HTMLDivElement, SectionContainerProps>(
  (
    {
      className,
      leftContentClass,
      title,
      description,
      contentClass,
      children,
      rowProps,
      cardProps,
      withTable = false,
      fillLeftContent = false,
    },
    ref
  ) => (
    <Row
      $justify={
        title || description || fillLeftContent ? "space-between" : "center"
      }
      className={className}
      ref={ref}
      {...rowProps}
    >
      {(title || description || fillLeftContent) && (
        <S.LeftContent
          className={leftContentClass}
          fillEmpty={!title && !description ? fillLeftContent : false}
        >
          {title && <Title.Heading>{title}</Title.Heading>}
          {description && <Text.Body $color="subdued">{description}</Text.Body>}
        </S.LeftContent>
      )}
      <S.RightContent
        $withTable={withTable}
        className={contentClass}
        {...cardProps}
      >
        {children}
      </S.RightContent>
    </Row>
  )
);

export default styled(SectionContainer)`
  ${S.sectionContainerCss}
`;
