import { css } from "styled-components";
import type { RowProps } from "./index";

export const rowCss = css<RowProps>`
  display: flex;
  flex-flow: row wrap;
  &::before,
  &::after {
    display: flex;
  }
  flex-direction: ${({ $column }) => $column && "column"};
  flex-wrap: ${({ $noWrap }) => $noWrap && "nowrap"};
  justify-content: ${({ $justify }) => $justify || "start"};
  align-items: ${({ $align }) => $align || "start"};
  ${({ $gap }) =>
    $gap &&
    css`
      gap: ${$gap}px;
    `};
  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
    `};
`;
