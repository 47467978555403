import htmlParse from "html-react-parser";
import Link from "next/link";
import { useRouter } from "next/router";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import { useTheme } from "styled-components";
import FAQ from "./FAQ";
import * as S from "./styles";
import { useShop } from "contexts";
import { Button, Card, Modal, Row, useDesign } from "design";
import { Typography } from "design/deprecated";
import {
  BackgroundOption,
  FrequentlyAskedQuestionsPosition as FAQPos,
} from "typing";
import { isInsideIframe, nextI18n } from "utils";

const { Title, Text } = Typography;
const { useTranslation } = nextI18n;

const START_GIFT_PATH = "/gifts";

const FAQSection: FC = () => {
  const { t } = useTranslation("start");
  const {
    device: { isDesktop },
  } = useDesign();
  const [visible, setVisible] = useState(false);

  if (!isDesktop) {
    return (
      <>
        <Modal
          visible={visible}
          title={t("faq.title")}
          onClose={() => setVisible(false)}
          withoutFooter
          closable
        >
          <FAQ />
        </Modal>
        <Row $column $block $align="center" $justify="center">
          <Text.Body>{t("faq.section.help")}</Text.Body>
          <Button type="plain" onClick={() => setVisible(true)}>
            {t("faq.section.button")}
          </Button>
        </Row>
      </>
    );
  }

  return (
    <S.FAQContainer>
      <Card block title={t("faq.title")}>
        <FAQ />
      </Card>
    </S.FAQContainer>
  );
};

const Start: FC = ({ children }) => {
  const { t } = useTranslation("start");
  const theme = useTheme();
  const router = useRouter();

  const {
    device: { isDesktop },
  } = useDesign();

  const {
    acceptExchanges,
    acceptReturns,
    allowGiftFlow,
    backgroundOption,
    backgroundImage,
    frequentlyAskedQuestionsPosition: faqPos,
    frequentlyAskedQuestions,
    platformTicketStartTitle,
    platformWelcomeMessage1,
    platformWelcomeMessage2,
    platformWelcomeMessage3,
    url: shopUrl,
    name: shopName,
    logo: shopLogo,
  } = useShop();

  let startPageDescription =
    // eslint-disable-next-line no-nested-ternary
    acceptExchanges && acceptReturns
      ? t("description.exchange_or_return")
      : acceptExchanges && !acceptReturns
      ? t("description.exchange")
      : t("description.return");

  if (platformTicketStartTitle) startPageDescription = platformTicketStartTitle;

  const logo =
    shopLogo ||
    "https://cdn.reversso.cl/logos/logo_reversso_fondo_transparente.svg";

  const isGiftPath = router.pathname === START_GIFT_PATH;
  const showFrequentlyAskedQuestions = frequentlyAskedQuestions.length > 0;
  const hasBackgroundImage = !!(
    backgroundOption === BackgroundOption.IMAGE && backgroundImage
  );

  useEffect(() => {
    if (allowGiftFlow) {
      if (isGiftPath) router.prefetch("/");
      else router.prefetch(START_GIFT_PATH);
    }
  }, [router, allowGiftFlow, isGiftPath]);

  if (isGiftPath && !allowGiftFlow) router.push("/");

  return (
    <>
      {!isInsideIframe() && (
        <S.BackToStoreAnchor
          href={shopUrl}
          target="_blank"
          rel="noreferrer"
          $hasBackgroundImage={hasBackgroundImage}
        >
          <Row $align="center" $gap={8}>
            <HiArrowLeft
              size={20}
              color={hasBackgroundImage ? theme.colors.icon.white : undefined}
            />
            <Title.Heading $color={hasBackgroundImage ? "white" : undefined}>
              {t("back_to_store")}
            </Title.Heading>
          </Row>
        </S.BackToStoreAnchor>
      )}

      <S.ContainerRow
        $column
        $justify="center"
        $align="center"
        $block
        $gap={20}
      >
        {!!platformWelcomeMessage1 && (
          <S.CardWelcomeMessageDiv $hasBackgroundImage={hasBackgroundImage}>
            {htmlParse(platformWelcomeMessage1)}
          </S.CardWelcomeMessageDiv>
        )}

        <Row $align="center" $justify="center" $noWrap $gap={63}>
          {faqPos === FAQPos.LEFT &&
            isDesktop &&
            showFrequentlyAskedQuestions && <FAQSection />}

          <S.ContentContainerDiv>
            <Card.Base
              header={
                <S.LogoContainerRow $block>
                  <img src={logo} alt={shopName} />
                </S.LogoContainerRow>
              }
              hideHeaderDivider
              footer={
                !isDesktop && showFrequentlyAskedQuestions && <FAQSection />
              }
            >
              <S.CardContentRow $column $block $align="center" $gap={12}>
                {!!platformWelcomeMessage2 && (
                  <S.CardWelcomeMessageDiv $hasBackgroundImage={false}>
                    {htmlParse(platformWelcomeMessage2)}
                  </S.CardWelcomeMessageDiv>
                )}
                <Title.Subheading $color="subdued">
                  {startPageDescription}
                </Title.Subheading>
                {children}
                {allowGiftFlow && (
                  <Row $justify="center">
                    <Button type="ghost">
                      <Link href={isGiftPath ? "/" : START_GIFT_PATH}>
                        {isGiftPath ? t("button.ticket") : t("button.gift")}
                      </Link>
                    </Button>
                  </Row>
                )}
              </S.CardContentRow>
            </Card.Base>
            {/* TODO: We need the store support email */}
            {/* <S.SupportEmailContainerRow $align="center" $gap={4} $hasBackgroundImage={hasBackgroundImage}>
            <Text.Caption $color="white">{t("support")}</Text.Caption>
            <Text.Caption $color="white">
              <S.SupportEmail href="mailto:support@nameshop.cl">
                support@nameshop.cl
              </S.SupportEmail>
            </Text.Caption>
          </S.SupportEmailContainerRow> */}
          </S.ContentContainerDiv>

          {faqPos === FAQPos.RIGHT &&
            isDesktop &&
            showFrequentlyAskedQuestions && <FAQSection />}
        </Row>

        {!!platformWelcomeMessage3 && (
          <S.CardWelcomeMessageDiv $hasBackgroundImage={hasBackgroundImage}>
            {htmlParse(platformWelcomeMessage3)}
          </S.CardWelcomeMessageDiv>
        )}
      </S.ContainerRow>
    </>
  );
};

export default Start;
