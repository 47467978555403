import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";
import Typography from "../../deprecated/Typography";
import Icon from "../Icon";
import type { BadgeProps } from "./index";

export const handleBadgeType: Record<
  NonNullable<BadgeProps["$type"]>,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  critical: css`
    background-color: ${({ theme }) => theme.colors.surface.critical.default};
    ${Icon} {
      color: ${({ theme }) => theme.colors.icon.critical};
    }
  `,
  decorative: css`
    background-color: ${({ theme }) =>
      theme.colors.surface.decorative.important};
    ${Typography} {
      color: ${({ theme }) => theme.colors.text.white};
    }
    ${Icon} {
      color: ${({ theme }) => theme.colors.icon.white};
    }
  `,
  informational: css`
    background-color: ${({ theme }) =>
      theme.colors.surface.informational.default};
    ${Icon} {
      color: ${({ theme }) => theme.colors.icon.informational};
    }
  `,
  neutral: css`
    background-color: ${({ theme }) => theme.colors.surface.neutral.default};
    ${Icon} {
      color: ${({ theme }) => theme.colors.icon.default};
    }
  `,
  pending: css`
    background-color: ${({ theme }) => theme.colors.surface.pending.default};
    ${Icon} {
      color: ${({ theme }) => theme.colors.icon.pending};
    }
  `,
  primary: css`
    background-color: ${({ theme }) => theme.colors.surface.primary.important};
    ${Typography} {
      color: ${({ theme }) => theme.colors.text.white};
    }
    ${Icon} {
      color: ${({ theme }) => theme.colors.icon.white};
    }
  `,
  success: css`
    background-color: ${({ theme }) => theme.colors.surface.success.default};
    ${Icon} {
      color: ${({ theme }) => theme.colors.icon.success};
    }
  `,

  warning: css`
    background-color: ${({ theme }) => theme.colors.surface.warning.default};
    ${Icon} {
      color: ${({ theme }) => theme.colors.icon.warning};
    }
  `,
};
