import Step from "./Step";
import BaseSteps from "./Steps";

export * as StepStyles from "./Step/styles";
export * as StepsStyles from "./Steps/styles";

export type { StepProps } from "./Step";
export type { StepsProps } from "./Steps";

type CompoundedSteps = typeof BaseSteps & {
  Step: typeof Step;
};

const Steps = BaseSteps as CompoundedSteps;
Steps.Step = Step;

export default Steps;
