import BaseBody from "./Body";
import { BodyLG, BodySM } from "./BodyOptions";

export type BodyProps = typeof BaseBody & {
  LG: typeof BodyLG;
  MD: typeof BaseBody;
  SM: typeof BodySM;
};

const Body = BaseBody as BodyProps;
Body.LG = BodyLG;
Body.MD = BaseBody;
Body.SM = BodySM;

export default Body;
