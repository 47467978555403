/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
export type TicketRequestAddressCouriersFragment = { __typename?: 'TicketRequest', id: string, isClosed: boolean, quotesUpToDate: boolean, requiresReverseDelivery: boolean | null, courierConfig: { __typename?: 'CourierConfig', id: string } | null, returnAddress: { __typename?: 'TicketRequestReturnAddress', id: string, allInformation: boolean } | null };

export const TicketRequestAddressCouriersFragmentDoc = gql`
    fragment TicketRequestAddressCouriers on TicketRequest {
  id
  isClosed
  courierConfig {
    id
  }
  returnAddress {
    id
    allInformation
  }
  quotesUpToDate
  requiresReverseDelivery
}
    `;