import type { FC, ReactNode } from "react";
import type { DefaultTheme } from "styled-components";
import styled, { useTheme } from "styled-components";
import * as S from "./styles";

export type SkeletonProps = {
  children?: ReactNode;
  className?: string;
  $borderRadius?: number;
  $height?: number;
  $width?: number;
  $isCircle?: boolean;
  animation?: DefaultTheme["components"]["skeleton"]["animation"];
  $block?: boolean;
};
const Skeleton: FC<SkeletonProps> = ({ animation, className, children }) => {
  const theme = useTheme();
  return (
    <S.SkeletonContainer
      $animation={animation || theme.components.skeleton.animation}
      className={className}
    >
      {children}
    </S.SkeletonContainer>
  );
};

export default styled(Skeleton)`
  ${S.skeletonCss}
`;
