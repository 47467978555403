import Link from "next/link";
import type { FC, ReactNode } from "react";
import { HiArrowLeft } from "react-icons/hi";
import styled from "styled-components";
import { useLayout } from "../../context";
import Steps from "../../Steps";
import * as S from "./styles";
import { Row, useDesign } from "design";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

export type ContentProps = {
  children: ReactNode;
  className?: string;
  showSteps?: boolean;
  hideFooter: boolean;
};
const Content: FC<ContentProps> = ({
  children,
  className,
  showSteps,
  hideFooter,
}) => {
  const { t } = useTranslation("common");
  const {
    device: { isDesktop },
  } = useDesign();
  const { backButtonHref } = useLayout();

  return (
    <S.ContainerSection
      className={className}
      $hideFooter={hideFooter}
      $showSteps={!!showSteps}
    >
      {(showSteps || backButtonHref) && (
        <Row $column $block $gap={20}>
          {showSteps && <Steps />}
          {isDesktop && backButtonHref && (
            <Link href={backButtonHref} passHref>
              <S.DesktopBackButton
                type="ghost"
                icon={<HiArrowLeft size={20} />}
              >
                {t("return_to_previous_step")}
              </S.DesktopBackButton>
            </Link>
          )}
        </Row>
      )}
      {children}
    </S.ContainerSection>
  );
};

export default styled(Content)``;
