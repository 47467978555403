import { css } from "styled-components";
import type { SiderProps } from "./index";

export const siderCss = css<SiderProps>`
  position: sticky;
  top: 56px;
  min-height: fit-content;
  height: calc(100vh - 56px);
  min-width: 240px;
  max-width: 240px;
  ${({ $placement }) =>
    $placement === "right"
      ? css`
          border-left: 1px solid
            ${({ theme }) => theme.colors.border.default.subdued};
        `
      : css`
          border-right: 1px solid
            ${({ theme }) => theme.colors.border.default.subdued};
        `}
  background-color: ${({ theme }) => theme.colors.background};
`;
