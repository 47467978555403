import { HiX } from "react-icons/hi";
import styled, { css } from "styled-components";
import Menu from "../Menu";
import Row from "../Row";
import type { DrawerProps } from "./index";

export const CloseIcon = styled(HiX)`
  position: relative;
  top: 2px;
  font-size: 20px;
  min-width: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.iconAction.default.default};
  :hover {
    color: ${({ theme }) => theme.colors.iconAction.default.hovered};
  }
`;

export const ActionsRow = styled(Row)`
  gap: 8px;
  width: 100%;
`;

export const drawerCss = css<DrawerProps>`
  ${Menu} {
    padding: 0;
  }
`;
