import type { ReactNode, FC, Dispatch, SetStateAction } from "react";
import { MdDelete } from "react-icons/md";
import type { DesktopModalProps } from "../DesktopModal";
import Modal from "../Modal";
import type { ActionProps } from "../types";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

export type DeleteModalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  onDelete: Omit<ActionProps, "icon" | "type">;
  title: ReactNode;
  subTitle?: ReactNode;
  description: ReactNode;
  confirmLoading?: boolean;
  size?: DesktopModalProps["size"];
};

const DeleteModal: FC<DeleteModalProps> = ({
  visible,
  setVisible,
  onDelete,
  title,
  subTitle,
  description,
  size,
}) => {
  const { t } = useTranslation("common");
  return (
    <Modal
      visible={visible}
      onClose={() => setVisible(false)}
      onOk={{
        children: t("delete"),
        icon: <MdDelete />,
        type: "critical",
        ...onDelete,
      }}
      onCancel
      title={title}
      subTitle={subTitle}
      desktopProps={{ size }}
      destroyOnClose
    >
      {description}
    </Modal>
  );
};

export default DeleteModal;
