import type { DefaultTheme } from "styled-components";
import styled, { css } from "styled-components";
import { pulseAnimation, waveAnimation } from "./animations";
import type { SkeletonProps } from "./index";

type Props = {
  $animation: DefaultTheme["components"]["skeleton"]["animation"];
};
export const SkeletonContainer = styled.div<Props>`
  ${({ $animation }) =>
    $animation !== false &&
    css`
      ${$animation === "wave"
        ? css`
            position: relative;
            overflow: hidden;
            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              width: 100%;
              background: linear-gradient(
                90deg,
                transparent,
                rgba(255, 255, 255, 0.4),
                transparent
              );
              animation: ${waveAnimation} 1.5s ease-in-out infinite;
            }
          `
        : css`
            animation: ${pulseAnimation} 1.5s ease-in-out infinite;
          `}
    `}
`;

export const skeletonCss = css<SkeletonProps>`
  display: block;
  background-color: ${({ theme }) => theme.colors.surface.default.pressed};
  ${({ $height, $width }) =>
    css`
      height: ${$height ? `${$height}px` : "auto"};
      ${$width
        ? css`
            width: ${$width}px;
            max-width: 100%;
          `
        : css`
            max-width: fit-content;
          `};
    `}
  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
      max-width: 100%;
    `}

  ${({ $isCircle, $borderRadius }) =>
    $isCircle
      ? css`
          border-radius: 50%;
        `
      : css`
          border-radius: ${$borderRadius || 8}px;
        `}
`;
