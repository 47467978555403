import type { LinkProps } from "next/link";
import Link from "next/link";
import type { FC, ReactNode } from "react";
import styled from "styled-components";
import Row from "../../../Row";
import * as S from "./styles";

export type MetaProps = {
  className?: string;
  description?: ReactNode;
  linkHref?: LinkProps["href"];
  onClick?: () => void;
  title: ReactNode;
  badge?: ReactNode;
  extra?: ReactNode;
  disabled?: boolean;
};
const Meta: FC<MetaProps> = ({
  className,
  description,
  linkHref,
  onClick,
  title,
  badge,
  extra,
  disabled,
}) => {
  const titleContent = (
    <S.MetaTitle onClick={!linkHref && !disabled ? onClick : undefined}>
      {title}
    </S.MetaTitle>
  );
  return (
    <Row $column className={className} $block>
      <Row $align="center" $justify="space-between" $gap={8} $block>
        {linkHref && !disabled ? (
          <Link href={linkHref}>{titleContent}</Link>
        ) : (
          titleContent
        )}
        <S.BadgeRow $closeToTitle={!!description}>{badge}</S.BadgeRow>
        {extra}
      </Row>
      {description && <S.MetaDescription>{description}</S.MetaDescription>}
    </Row>
  );
};

export default styled(Meta)`
  ${S.MetaCss}
`;
