/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FileStageUploadMutationVariables = Types.Exact<{
  input: Types.FileStageInput;
}>;


export type FileStageUploadMutation = { __typename?: 'Mutation', fileStage: { __typename?: 'FileStagePayload', errors: Array<{ __typename?: 'Error', field: string, messages: Array<string> }> | null, files: Array<{ __typename?: 'FileStageFile', fileUrl: string, request: { __typename?: 'FileRequest', url: string, fields: unknown } }> | null } };


export const FileStageUploadDocument = gql`
    mutation FileStageUpload($input: FileStageInput!) {
  fileStage(input: $input) {
    errors {
      field
      messages
    }
    files {
      fileUrl
      request {
        url
        fields
      }
    }
  }
}
    `;
export type FileStageUploadMutationFn = Apollo.MutationFunction<FileStageUploadMutation, FileStageUploadMutationVariables>;

/**
 * __useFileStageUploadMutation__
 *
 * To run a mutation, you first call `useFileStageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileStageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileStageUploadMutation, { data, loading, error }] = useFileStageUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFileStageUploadMutation(baseOptions?: Apollo.MutationHookOptions<FileStageUploadMutation, FileStageUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileStageUploadMutation, FileStageUploadMutationVariables>(FileStageUploadDocument, options);
      }
export type FileStageUploadMutationHookResult = ReturnType<typeof useFileStageUploadMutation>;
export type FileStageUploadMutationResult = Apollo.MutationResult<FileStageUploadMutation>;
export type FileStageUploadMutationOptions = Apollo.BaseMutationOptions<FileStageUploadMutation, FileStageUploadMutationVariables>;