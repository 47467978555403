import { isValidCPF } from "@brazilian-utils/brazilian-utils";
import type { TFunction } from "next-i18next";
import { validate as validateRut } from "rut.js";
import peruDni from "./peru-dni";
import { IdentificationType } from "graphql/generated/schema.platform";

const validators: Record<
  IdentificationType,
  (t: TFunction) => (value: string | null) => string | undefined
> = {
  [IdentificationType.ARG_DNI]: (t) => (value) => {
    if (!value) return undefined;
    if (/^[A-z]?\d{6,8}$/.test(value)) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.ARG_DNI"),
    });
  },
  [IdentificationType.CHL_RUT]: (t) => (value) => {
    if (!value || value.length <= 11) return undefined; // rut de 10mm o mas
    if (validateRut(value)) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.CHL_RUT"),
    });
  },
  [IdentificationType.BRA_CPF]: (t) => (value) => {
    if (!value) return undefined;
    if (isValidCPF(value)) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.BRA_CPF"),
    });
  },
  [IdentificationType.COL_CC]: (t) => (value) => {
    if (value && value.length >= 6) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.COL_CC"),
    });
  },
  [IdentificationType.COL_NIT]: (t) => (value) => {
    if (value && value.length >= 6) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.COL_NIT"),
    });
  },
  [IdentificationType.ECU_DNI]: (t) => (value) => {
    if (value && value.length >= 10) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.ECU_DNI"),
    });
  },
  [IdentificationType.ECU_RUC]: (t) => (value) => {
    if (value && value.length === 13) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.ECU_RUC"),
    });
  },
  [IdentificationType.MXN_RFC]: (t) => (value) => {
    if (!value || value.length !== 11) return undefined; // 11 chars for people
    if (/^[A-Za-zñÑ&]{3,4}\d{6}\w{3}$/.test(value)) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.MXN_RFC"),
    });
  },
  [IdentificationType.PASSPORT]: () => () => undefined,
  [IdentificationType.PER_DNI]: (t) => (value) => {
    if (!value || value.length < 7) return undefined;
    if (peruDni.isValid(value)) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.PER_DNI"),
    });
  },
  [IdentificationType.PER_RUC]: (t) => (value) => {
    if (!value || value.length !== 11) return undefined;
    if (peruDni.isValid(value)) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.PER_RUC"),
    });
  },
  [IdentificationType.UYU_DNI]: (t) => (value) => {
    if (!value || value.length >= 7) return undefined;
    return t("components.form_input.customer.invalid_field", {
      fieldName: t("components.form_input.identification_types.UYU_DNI"),
    });
  },
};

export default validators;
