import LoadingStore from "./LoadingStore";
import NoPublic from "./NoPublic";
import NotFound from "./NotFound";

const Error = {
  LoadingStore,
  NoPublic,
  NotFound,
};

export default Error;
