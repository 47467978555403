import type { Dispatch, ReactElement, SetStateAction } from "react";
import { useState } from "react";
import styled from "styled-components";
import type { OptionProps as BaseOptionProps } from "./Option";
import Option from "./Option";
import * as S from "./styles";

type OptionProps<KeyType> = Omit<
  BaseOptionProps,
  "active" | "disabled" | "onClick"
> & {
  key: KeyType;
  disabled?: boolean;
};
export type SegmentProps<KeyType> = {
  className?: string;
  options: OptionProps<KeyType>[];
  onChange?: (key: KeyType) => void;
} & (
  | { defaultValue: KeyType }
  | {
      active: {
        is: KeyType;
        set: Dispatch<SetStateAction<KeyType>>;
      };
    }
);
const SegmentInner = <KeyType,>({
  className,
  options,
  onChange,
  ...props
}: SegmentProps<KeyType>): ReactElement => {
  const [_activeOption, _setActiveOption] = useState<KeyType | undefined>(
    "defaultValue" in props ? props.defaultValue : undefined
  );
  const activeOption = "active" in props ? props.active.is : _activeOption;
  const setActiveOption =
    "active" in props ? props.active.set : _setActiveOption;

  return (
    <S.SegmentContainerRow className={className} $block $noWrap>
      {options.map(({ key: optionKey, disabled, ...rest }) => (
        <Option
          key={`segment-option-${optionKey}`}
          active={activeOption === optionKey}
          disabled={!!disabled}
          onClick={() => {
            setActiveOption(optionKey);
            onChange?.(optionKey);
          }}
          {...rest}
        />
      ))}
    </S.SegmentContainerRow>
  );
};

const Segment: (<KeyType>(props: SegmentProps<KeyType>) => ReactElement) &
  string = styled(SegmentInner)``;

export default Segment;
