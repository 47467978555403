// Based on antd color generation: https://github.com/ant-design/ant-design-colors/blob/master/src/generate.ts
import { rgbToHsv, rgbToHex, inputToRGB } from "@ctrl/tinycolor";

export const LIGHT_COLOR_COUNT = 8; // Number of light colors, on the main color
export const DARK_COLOR_COUNT = 7; // Amount of dark color, under main color
export const DARK_COLOR_MAP = [
  // Dark theme color mapping table
  { index: 7, opacity: 0.15 },
  { index: 6, opacity: 0.25 },
  { index: 5, opacity: 0.3 },
  { index: 5, opacity: 0.45 },
  { index: 5, opacity: 0.65 },
  { index: 5, opacity: 0.85 },
  { index: 4, opacity: 0.9 },
  { index: 3, opacity: 0.95 },
  { index: 2, opacity: 0.97 },
  { index: 1, opacity: 0.98 },
];

type HsvObject = {
  h: number;
  s: number;
  v: number;
};

type RgbObject = {
  r: number;
  g: number;
  b: number;
};

// Wrapper function ported from TinyColor.prototype.toHsv
// Keep it here because of `hsv.h * 360`
const toHsv = ({ r, g, b }: RgbObject): HsvObject => {
  const hsv = rgbToHsv(r, g, b);
  return { h: hsv.h * 360, s: hsv.s, v: hsv.v };
};

// Wrapper function ported from TinyColor.prototype.toHexString
// Keep it here because of the prefix `#`
const toHex = ({ r, g, b }: RgbObject): string =>
  `#${rgbToHex(r, g, b, false)}`;

// Wrapper function ported from TinyColor.prototype.mix, not treeshakable.
// Amount in range [0, 1]
// Assume color1 & color2 has no alpha, since the following src code did so.
const mix = (rgb1: RgbObject, rgb2: RgbObject, amount: number): RgbObject => {
  const p = amount / 100;
  const rgb = {
    r: (rgb2.r - rgb1.r) * p + rgb1.r,
    g: (rgb2.g - rgb1.g) * p + rgb1.g,
    b: (rgb2.b - rgb1.b) * p + rgb1.b,
  };
  return rgb;
};

const getHue = (
  hueStep: number,
  hsv: HsvObject,
  i: number,
  light?: boolean
): number => {
  let hue: number;
  // Depending on the hue, the hue turns differently
  if (Math.round(hsv.h) >= 60 && Math.round(hsv.h) <= 240) {
    hue = light
      ? Math.round(hsv.h) - hueStep * i
      : Math.round(hsv.h) + hueStep * i;
  } else {
    hue = light
      ? Math.round(hsv.h) + hueStep * i
      : Math.round(hsv.h) - hueStep * i;
  }
  if (hue < 0) {
    hue += 360;
  } else if (hue >= 360) {
    hue -= 360;
  }
  return hue;
};

const getSaturation = (
  saturationStep1: number,
  saturationStep2: number,
  hsv: HsvObject,
  i: number,
  light?: boolean
): number => {
  // grey color don't change saturation
  if (hsv.h === 0 && hsv.s === 0) {
    return hsv.s;
  }
  let saturation: number;
  if (light) {
    saturation = hsv.s - saturationStep1 * i;
  } else if (i === DARK_COLOR_COUNT) {
    saturation = hsv.s + saturationStep1;
  } else {
    saturation = hsv.s + saturationStep2 * i;
  }
  // Boundary value correction
  if (saturation > 1) {
    saturation = 1;
  }
  // The s of the first cell is limited to 0.06-0.1
  if (light && i === LIGHT_COLOR_COUNT && saturation > 0.1) {
    saturation = 0.1;
  }
  if (saturation < 0.06) {
    saturation = 0.06;
  }
  return Number(saturation.toFixed(2));
};

const getValue = (
  brightnessStep1: number,
  brightnessStep2: number,
  hsv: HsvObject,
  i: number,
  light?: boolean
): number => {
  let value: number;
  if (light) {
    value = hsv.v + brightnessStep1 * i;
  } else {
    value = hsv.v - brightnessStep2 * i;
  }
  if (value > 1) {
    value = 1;
  }
  return Number(value.toFixed(2));
};

type GenerateDarkModeProps = {
  backgroundColor: string;
};
type GenerateProps = {
  color: string;
  hueStep: number;
  saturationStep1: number;
  saturationStep2: number;
  brightnessStep1: number;
  brightnessStep2: number;
  darkMode?: GenerateDarkModeProps;
};
export const generateGeneric = ({
  color,
  hueStep,
  saturationStep1,
  saturationStep2,
  brightnessStep1,
  brightnessStep2,
  darkMode,
}: GenerateProps): string[] => {
  const patterns: Array<string> = [];
  const pColor = inputToRGB(color);
  for (let i = LIGHT_COLOR_COUNT; i > 0; i -= 1) {
    const hsv = toHsv(pColor);
    const colorString: string = toHex(
      inputToRGB({
        h: getHue(hueStep, hsv, i, true),
        s: getSaturation(saturationStep1, saturationStep2, hsv, i, true),
        v: getValue(brightnessStep1, brightnessStep2, hsv, i, true),
      })
    );
    patterns.push(colorString);
  }
  patterns.push(toHex(pColor));
  for (let i = 1; i <= DARK_COLOR_COUNT; i += 1) {
    const hsv = toHsv(pColor);
    const colorString: string = toHex(
      inputToRGB({
        h: getHue(hueStep, hsv, i),
        s: getSaturation(saturationStep1, saturationStep2, hsv, i),
        v: getValue(brightnessStep1, brightnessStep2, hsv, i),
      })
    );
    patterns.push(colorString);
  }

  // dark theme patterns
  if (darkMode) {
    return DARK_COLOR_MAP.map(({ index, opacity }) => {
      const darkColorString: string = toHex(
        mix(
          inputToRGB(darkMode.backgroundColor),
          inputToRGB(patterns[index]),
          opacity * 100
        )
      );
      return darkColorString;
    });
  }
  return patterns;
};

export const reverssoDefaultColorProps = {
  brightnessStep1: 0.0625, // Brightness ladder, light part
  brightnessStep2: 0.08, // Brightness ladder, dark part
  hueStep: 0, // Hue Ladder
  saturationStep1: 0.2, // Saturation ladder, light part
  saturationStep2: 0.05, // Saturation steps, dark parts
};
export const generateReversso = (
  color: string,
  darkMode?: GenerateDarkModeProps
): string[] =>
  generateGeneric({
    ...reverssoDefaultColorProps,
    color,
    darkMode,
  });
