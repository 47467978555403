import composeRefs from "@seznam/compose-react-refs";
import { forwardRef, useRef } from "react";
import styled from "styled-components";
import type { InputProps } from "../Input";
import Input from "../Input";

export type InputCurrencyProps = Omit<
  InputProps,
  "type" | "normalize" | "prefix"
> & {
  currency: {
    /**
     * TODO: We are not using the decimalPoint prop
     * */
    decimalPoint: string;
    decimalPlaces: number;
    prefix?: string;
  };
};
const Currency = forwardRef<HTMLInputElement, InputCurrencyProps>(
  ({ currency, ...props }, ref) => {
    const { decimalPlaces, decimalPoint } = currency;
    const innerRef = useRef<HTMLInputElement>(null);

    const normalize = (v: string, decimals: number, separator: string) => {
      const _separator = decimals > 0 ? separator : ""; // dont allow separator if no decimals
      const regex = new RegExp(`[^0-9${_separator}-]`);
      const splitRegex = new RegExp(`[${_separator}]`);
      const temp = v
        /* removes anything that's not a number, the separator or a hyphen */
        .replace(regex, "")
        /* removes any dot and comma thats not the first */
        .split(splitRegex);
      return temp.length > 1
        ? `${temp[0]}${separator}${temp
            .slice(1)
            .join("")
            // define the number of decimals allowed
            .slice(0, decimals)}`
        : temp[0];
    };

    return (
      <Input
        ref={composeRefs(innerRef, ref)}
        normalize={(v) => normalize(v, decimalPlaces, decimalPoint)}
        prefix={currency.prefix}
        {...props}
      />
    );
  }
);

export default styled(Currency)``;
