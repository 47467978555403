import styled, { css } from "styled-components";
import type { FooterProps } from "./index";
import { Typography } from "design/deprecated";

export const FooterText = styled(Typography.Text.Caption)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 4px;
  a {
    display: grid;
    img {
      height: 14px;
    }
  }
`;

export const footerCss = css<FooterProps>`
  transition: all 0.2s ease-in-out;
  ${({ hasBackgroundImage, isStartView, theme }) =>
    isStartView
      ? css`
          margin: 0 auto;
          padding: 8px;
          width: fit-content;
          border-radius: 8px 8px 0 0;
          background-color: ${hasBackgroundImage
            ? `${theme.colors.surface.default.inverted}b3`
            : "unset"};
          ${FooterText} {
            color: ${theme.colors.text[
              hasBackgroundImage ? "white" : "subduedBold"
            ]};
          }
        `
      : css`
          position: sticky;
          bottom: 0;
          justify-content: center;
          padding: 8px 0;
          height: 32px;
          width: 100%;
          background-color: ${theme.colors.components.layout.background};
          ${FooterText} {
            justify-content: center;
            color: ${theme.colors.text.subdued};
          }
          @media ${theme.breakpoints.mobile.css} {
            padding: 8px 0 20px;
            height: 48px;
          }
        `}
`;
