import type { FC, ReactNode, CSSProperties } from "react";
import type { IconType } from "react-icons";
import { HiInformationCircle } from "react-icons/hi";
import type { DefaultTheme } from "styled-components";
import styled from "styled-components";
import type { TooltipProps } from "../Tooltip";
import Tooltip from "../Tooltip";
import * as S from "./styles";

export type TooltipIconProps = {
  icon?: IconType;
  tooltip: ReactNode;
  placement?: TooltipProps["placement"];
  className?: string;
  style?: CSSProperties;
  type?: keyof DefaultTheme["colors"]["icon"];
  iconSize?: number;
};
const TooltipIcon: FC<TooltipIconProps> = ({
  icon: PropIcon = HiInformationCircle,
  iconSize,
  tooltip,
  placement = "top",
  className,
  style,
}) => (
  <Tooltip title={tooltip} placement={placement} $showArrow>
    <PropIcon size={iconSize ?? 18} style={style} className={className} />
  </Tooltip>
);

export default styled(TooltipIcon)`
  ${S.tooltipIconCss}
`;
