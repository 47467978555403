import { forwardRef } from "react";
import styled from "styled-components";
import type { AbstractInputProps } from "../Abstract";
import AbstractInput from "../Abstract";

export type InputProps = Omit<
  AbstractInputProps<HTMLInputElement>,
  "component"
>;
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ type = "text", ...props }, ref) => (
    <AbstractInput ref={ref} type={type} component="input" {...props} />
  )
);

export default styled(Input)``;
