import { useWindowSize } from "@react-hook/window-size";
import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import type { DefaultTheme } from "styled-components";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "../atoms/Toast";
import { DesignContext } from "./context";
import { GlobalStyles } from "./globalStyles";
import type { DesignDevice } from "./utils";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "./utils";

type DesignWrapperProps = {
  theme: DefaultTheme;
  children: ReactNode;
};
const DesignWrapper: FC<DesignWrapperProps> = ({ children, theme }) => {
  const [width] = useWindowSize();
  const [device, setDevice] = useState<DesignDevice>("desktop");
  useEffect(() => {
    if (width <= MOBILE_BREAKPOINT) setDevice("mobile");
    else if (width <= TABLET_BREAKPOINT) setDevice("tablet");
    else setDevice("desktop");
  }, [width]);

  return (
    <DesignContext.Provider
      value={{
        theme,
        device: {
          type: device,
          isDesktop: device === "desktop",
          isDesktopOrTablet: device === "desktop" || device === "tablet",
          isMobile: device === "mobile",
          isMobileOrDesktop: device === "mobile" || device === "desktop",
          isMobileOrTablet: device === "mobile" || device === "tablet",
          isTablet: device === "tablet",
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
        <ToastContainer
          autoClose={3000}
          position={
            device === "mobile" || device === "tablet"
              ? "top-center"
              : "bottom-center"
          }
        />
      </ThemeProvider>
    </DesignContext.Provider>
  );
};
export default DesignWrapper;
