import { css } from "styled-components";
import Typography from "../../../deprecated/Typography";
import Badge from "../../Badge";
import Icon from "../../Icon";
import type { MenuItemProps } from "./index";

export const menuItemCss = css<MenuItemProps<unknown>>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  width: max-content;
  min-width: 100%;
  transition: background-color 0.2s ease-in-out;
  :hover {
    background-color: ${({ theme }) => theme.colors.surface.default.hovered};
  }
  ${Icon} {
    color: ${({ theme }) => theme.colors.icon.default};
    width: 20px;
    min-width: 20px;
    font-size: 20px;
  }

  ${Typography} + ${Icon} {
    margin-left: auto;
  }

  ${({ $keyId, $activeKey }) =>
    !!$keyId &&
    !!$activeKey &&
    $keyId === $activeKey &&
    css`
      background-color: ${({ theme }) => theme.colors.surface.primary.subdued};
      ${Icon} {
        color: ${({ theme }) => theme.colors.icon.primary};
      }
      ${Typography} {
        color: ${({ theme }) => theme.colors.text.primary};
        ${Badge} > ${Typography} {
          color: ${({ theme }) => theme.colors.text.white};
        }
      }
      :hover {
        background-color: ${({ theme }) =>
          theme.colors.surface.primary.subdued};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${({ theme }) => theme.colors.text.disabled};
      background-color: ${({ theme }) => theme.colors.surface.neutral.default};
      &:hover {
        background-color: ${({ theme }) =>
          theme.colors.surface.neutral.default};
      }
      ${Icon} {
        color: ${({ theme }) => theme.colors.icon.disabled};
      }
    `}
`;
