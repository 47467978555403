import type { FC, ReactNode } from "react";
import type {
  RefundMethodDetailsTicketFragment,
  RefundMethodDetailsTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import { Card } from "design";
import { Typography } from "design/deprecated";
import { filterNullish } from "utils";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text } = Typography;

type RefundMethodDetailsProps = {
  data:
    | RefundMethodDetailsTicketFragment
    | RefundMethodDetailsTicketRequestFragment;
  extra?: ReactNode;
};
const RefundMethodDetails: FC<RefundMethodDetailsProps> = ({ extra, data }) => {
  const { t } = useTranslation("common");
  const { refundInformation, totalProductsDifferenceAmount } = data;

  const { fullName, bank, bankAccountType, bankAccountNumber, identification } =
    refundInformation ?? {};
  const content = [
    fullName,
    identification,
    bank?.name,
    bankAccountType?.name,
    bankAccountNumber,
  ].filter(filterNullish);

  // if there is no refund information or totalProductsDifferenceAmount >= 0
  // then we don't show the card
  if (!content.length || totalProductsDifferenceAmount.amount >= 0) return null;

  return (
    <Card
      block
      title={t("components.refund_method_details.title")}
      extra={extra}
    >
      <Text.Body $color="subduedBold">{content.join(", ")}</Text.Body>
    </Card>
  );
};

export default RefundMethodDetails;
