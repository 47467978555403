import { createGlobalStyle, css } from "styled-components";
import { FadeIn, FadeOut, SlideUpIn, SlideUpOut } from "../animations";
import { selectDropdownCss } from "../molecules/Select/styles";

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    /* SLIDE_UP */
    .${theme.motionName.SLIDE_UP}-appear,
      .${theme.motionName.SLIDE_UP}-enter,
      .${theme.motionName.SLIDE_UP}-leave {
      animation-duration: 0.2s;
      animation-fill-mode: both;
      animation-play-state: paused;
    }
    .${theme.motionName.SLIDE_UP}-appear.${theme.motionName
        .SLIDE_UP}-appear-active,
      .${theme.motionName.SLIDE_UP}-enter.${theme.motionName
        .SLIDE_UP}-enter-active {
      animation-name: ${SlideUpIn};
      animation-play-state: running;
    }
    .${theme.motionName.SLIDE_UP}-leave.${theme.motionName
        .SLIDE_UP}-leave-active {
      animation-name: ${SlideUpOut};
      animation-play-state: running;
    }
    .${theme.motionName.SLIDE_UP}-appear, .${theme.motionName.SLIDE_UP}-enter {
      opacity: 0;
      animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }
    .${theme.motionName.SLIDE_UP}-leave {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    /* FADE */
    .${theme.motionName.FADE}-appear,
      .${theme.motionName.FADE}-enter,
      .${theme.motionName.FADE}-leave {
      animation-duration: 0.2s;
      animation-fill-mode: both;
      animation-play-state: paused;
    }
    .${theme.motionName.FADE}-appear.${theme.motionName.FADE}-appear-active,
      .${theme.motionName.FADE}-enter.${theme.motionName.FADE}-enter-active {
      animation-name: ${FadeIn};
      animation-play-state: running;
    }
    .${theme.motionName.FADE}-leave.${theme.motionName.FADE}-leave-active {
      animation-name: ${FadeOut};
      animation-play-state: running;
    }
    .${theme.motionName.FADE}-appear, .${theme.motionName.FADE}-enter {
      opacity: 0;
    }
    .${theme.motionName.FADE}-appear,
      .${theme.motionName.FADE}-enter,
      .${theme.motionName.FADE}-leave {
      animation-timing-function: linear;
    }
  `}

  /* Select dropdown */
  ${selectDropdownCss}
`;
