import styled, { css } from "styled-components";
import Icon from "../../atoms/Icon";
import type { CollapseProps } from "./index";

export const collapseClass = "ReactCollapse--collapse";

export const CollapseIcon = styled(Icon)`
  transform-origin: center;
  transition: all 0.3s ease-in-out;
`;

type CollapseHeaderProps = { $isOpen: boolean; disabled?: boolean };
export const CollapseHeader = styled.div<CollapseHeaderProps>`
  position: relative;
  cursor: pointer;
  min-height: 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.default};

  ${CollapseIcon} {
    ${({ $isOpen, disabled }) =>
      !!($isOpen && !disabled) &&
      css`
        transform: rotate(-180deg);
      `}
  }
`;

export const collapseCss = css<CollapseProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  .${collapseClass} {
    transition: height 0.2s;
  }
`;
