import type { FC, HTMLAttributes } from "react";
import type { IconType } from "react-icons";
import styled from "styled-components";
import * as S from "./styles";

export type ListItemProps = HTMLAttributes<HTMLLIElement> & {
  isBasic?: boolean;
  basicIcon?: IconType;
};
const ListItem: FC<ListItemProps> = ({
  basicIcon: PropIcon,
  children,
  ...props
}) => {
  const { isBasic } = props;

  return (
    <li {...props}>
      {isBasic && <S.BasicIcon as={PropIcon} size={14} />}
      {children}
    </li>
  );
};

export default styled(ListItem)`
  ${S.listItemCss}
`;
