import styled from "styled-components";
import Row from "../Row";

type Props = {
  $leftGap?: number;
};
export const ContainerRow = styled(Row)<Props>`
  position: relative;
  padding-left: ${({ $leftGap }) => `${$leftGap ?? 4}px`};
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 4px;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.surface.neutral.subdued};
    border-radius: 0 8px 8px 0;
  }
`;
