import type { FC, ReactNode } from "react";
import { useState } from "react";
import { PlatformContext, sharedContextDefaultValue } from "./context";
import { Loading } from "design";

const Platform: FC<{ children: ReactNode }> = ({ children }) => {
  const [sharedState, setSharedState] = useState(sharedContextDefaultValue);
  const [loadingTip, setLoadingTip] = useState<string | null>(null);

  return (
    <PlatformContext.Provider
      value={{ loadingTip, setLoadingTip, sharedState, setSharedState }}
    >
      <Loading
        isViewContainer
        loading={!!loadingTip}
        tip={loadingTip ?? undefined}
      >
        {children}
      </Loading>
    </PlatformContext.Provider>
  );
};
export default Platform;
