import { css } from "styled-components";
import type { StepsProps } from "./index";

export const stepsCss = css<StepsProps>`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: ${({ direction }) =>
    direction === "vertical" ? "column" : "row"};

  ${({ verticalOnMobile = true }) =>
    verticalOnMobile &&
    css`
      @media ${({ theme }) => theme.breakpoints.mobile.css} {
        flex-direction: column;
      }
    `}
`;
