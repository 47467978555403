import type { ReactElement } from "react";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useController } from "react-hook-form";
import { AddressContext } from "./context";
import type { AddressLocalitiesMountQuery } from "./graphql/generated/queries.platform.generated";
import {
  useAddressLocalitiesMountQuery,
  useAddressZipcodesLazyQuery,
} from "./graphql/generated/queries.platform.generated";
import { Cities, Counties, Regions } from "./LocalityFields";
import type {
  AddressFormProps,
  Region,
  City,
  County,
  BaseFormValues,
} from "./typing";
import type { ZipcodePositions } from "./utils";
import {
  parseZipcodesAsRegions,
  AFTER_LOCALITIES,
  zipcodePosition,
  correctZipcode as baseCorrectZipcode,
} from "./utils";
import Zipcode from "./Zipcode";
import { Loading, FormItem, Input, Row, useDesign } from "design";
import {
  AddressField,
  AddressEditableField,
  CountryZipcodeBehaviour,
} from "graphql/generated/schema.platform";
import { useDelayCallback } from "hooks";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const AddressForm = <FormValues extends BaseFormValues>({
  address,
  country,
  editableFields,
}: AddressFormProps): ReactElement => {
  const {
    hiddenFields,
    zipcodeBehaviors,
    regionName,
    cityName,
    countyName,
    requiredFields,
    editableFields: countryEditableFields,
  } = country;
  const countryId = address?.country?.id || country.id;
  const searchZipcodes = zipcodeBehaviors.includes(
    CountryZipcodeBehaviour.SEARCH
  );
  const { t } = useTranslation("common");
  const {
    device: { isMobile },
  } = useDesign();
  const {
    setValue,
    formState: { errors },
    control,
    watch,
  } = useFormContext<FormValues>();

  // initial values are the id, so initial locality is the one in address while it's loading
  const [regions, setRegions] = useState<Region[]>(
    address?.region ? [address.region] : []
  );
  const [cities, setCities] = useState<City[]>(
    address?.city ? [address.city] : []
  );
  const [counties, setCounties] = useState<County[]>(
    address?.county ? [address.county] : []
  );

  const { field: fieldRegion } = useController({
    // @ts-expect-error react-hook-form generic form values
    name: "region",
    control,
    rules: {
      required: t("components.address_form.locality.enter", {
        fieldName: regionName,
      }),
    },
  });
  const { field: fieldCity } = useController({
    // @ts-expect-error react-hook-form generic form values
    name: countryEditableFields.includes(AddressEditableField.CITY)
      ? "cityName"
      : "city",
    control,
    rules: {
      required: requiredFields.includes(AddressField.CITY)
        ? t("components.address_form.locality.enter", { fieldName: cityName })
        : false,
    },
  });
  const { field: fieldCounty } = useController({
    // @ts-expect-error react-hook-form generic form values
    name: countryEditableFields.includes(AddressEditableField.COUNTY)
      ? "countyName"
      : "county",
    control,
    rules: {
      required: requiredFields.includes(AddressField.COUNTY)
        ? t("components.address_form.locality.enter", { fieldName: countyName })
        : false,
    },
  });

  const showCity = !hiddenFields?.includes(AddressField.CITY);
  const showCounty = !hiddenFields?.includes(AddressField.COUNTY);

  const handleLoad = (_regions: Region[]) => {
    setRegions(_regions);

    let region = _regions.find((obj) => obj.id === fieldRegion.value);
    region = !region && _regions?.length === 1 ? _regions[0] : region;
    fieldRegion.onChange(region?.id ?? null);

    const { cities: _cities } = region || {};
    setCities(_cities || []);

    let city = _cities?.find((obj) => obj.id === fieldCity.value);
    if (!city) {
      city = _cities?.length === 1 ? _cities[0] : undefined;
      fieldCity.onChange(city?.id ?? null);
    }
    setCounties(city?.counties || region?.counties || []);
    let county = (city?.counties || region?.counties)?.find(
      (obj) => obj.id === fieldCounty.value
    );
    if (!county) {
      // eslint-disable-next-line no-nested-ternary
      county = city
        ? city.counties?.length === 1
          ? city.counties[0]
          : undefined
        : region?.counties?.length === 1
        ? region?.counties[0]
        : undefined;
      fieldCounty.onChange(county?.id ?? null);
    }
  };

  const handleLocalitiesMount = (data: AddressLocalitiesMountQuery) => {
    const { country: dataCountry, zipcodes } = data;
    if (dataCountry) {
      handleLoad(dataCountry.regions);
    }
    if (zipcodes) {
      handleLoad(parseZipcodesAsRegions(zipcodes));
    }
  };

  const { data: dataLocalitiesMount, loading } = useAddressLocalitiesMountQuery(
    {
      variables: {
        countryId,
        includeLocalities: !searchZipcodes,
        includeCitiesByRegion: showCity,
        includeCountiesByCity: showCity && showCounty,
        includeCountiesByRegion: !showCity && showCounty,
        zipcode: address?.zipcode || "",
        includeZipcodes: !!searchZipcodes && !!address?.zipcode,
      },
      onCompleted: handleLocalitiesMount,
    }
  );

  useEffect(() => {
    // when component is re-loaded and all data is cached, it could mounted with a different
    // address passed in prop and in that case we would like to reload all data
    if (!dataLocalitiesMount) return;
    handleLocalitiesMount(dataLocalitiesMount);
  }, [address]);

  const [queryZipcodes, { loading: loadingZipcodes }] =
    useAddressZipcodesLazyQuery({
      onCompleted: (data) => {
        const { zipcodes } = data;
        handleLoad(parseZipcodesAsRegions(zipcodes));
      },
    });

  const searchZipcode = (value: string | undefined) => {
    if (searchZipcodes) {
      queryZipcodes({
        variables: {
          countryId,
          zipcode: value || "",
        },
      });
    }
  };
  const [waitingSearchZipcode, waitSearchZipcode] = useDelayCallback(
    searchZipcode,
    { forceCallbackIfSameValue: true, timeout: 300 }
  );
  const loadingWaitingZipcodes = loadingZipcodes || waitingSearchZipcode;

  const correctZipcode = (positions: ZipcodePositions[]) =>
    baseCorrectZipcode({
      positions,
      watchValues: watch,
      loading: loadingWaitingZipcodes,
      regions,
      zipcodeBehaviors,
    });

  const zipcodeField = (
    <Zipcode<FormValues>
      // @ts-expect-error no generic for react-hook-form
      clearOnClick={() => setValue("zipcode", "")}
      onChange={(e) => waitSearchZipcode(e.target.value)}
    />
  );
  return (
    <AddressContext.Provider
      value={{
        country,
        regions,
        cities,
        counties,
        correctZipcode,
        loadingLocalities: loading,
        editableFields,
      }}
    >
      {zipcodePosition(zipcodeBehaviors) ===
        CountryZipcodeBehaviour.BEFORE_ADDRESS && zipcodeField}
      {correctZipcode([CountryZipcodeBehaviour.BEFORE_ADDRESS]) && (
        <>
          <FormItem $block error={errors.address1?.message}>
            <Controller
              control={control}
              // @ts-expect-error no generic for react-hook-form
              name="address1"
              rules={{
                required: t("components.address_form.address.required"),
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t("components.address_form.address.label")}
                  value={field.value || undefined}
                  hasError={!!errors.address1}
                  disabled={
                    editableFields && !editableFields.includes(field.name)
                  }
                />
              )}
            />
          </FormItem>
          <FormItem $block error={errors.address2?.message}>
            <Controller
              control={control}
              // @ts-expect-error no generic for react-hook-form
              name="address2"
              render={({ field }) => (
                <Input
                  {...field}
                  label={`${country.address2Name}:`}
                  value={field.value || undefined}
                  hasError={!!errors.address2}
                  disabled={
                    editableFields && !editableFields.includes(field.name)
                  }
                />
              )}
            />
          </FormItem>
        </>
      )}

      {zipcodePosition(zipcodeBehaviors) ===
        CountryZipcodeBehaviour.BEFORE_LOCALITIES && zipcodeField}

      {correctZipcode([
        CountryZipcodeBehaviour.BEFORE_ADDRESS,
        CountryZipcodeBehaviour.BEFORE_LOCALITIES,
      ]) ? (
        <>
          <FormItem $block $noStyle>
            <Row
              $block
              $column={isMobile}
              $noWrap={!isMobile}
              $gap={!isMobile ? 20 : undefined}
            >
              <FormItem $block error={errors.region?.message}>
                <Regions
                  label={regionName}
                  fieldSelect={fieldRegion}
                  hasError={!!errors.region}
                  selectOnChange={(value) => {
                    // loads cities and counties into the selects
                    const region = regions.find((obj) => obj.id === value);
                    // @ts-expect-error react-hook-form generic form values
                    setValue("city", null);
                    // @ts-expect-error react-hook-form generic form values
                    setValue("county", null);
                    setCities(region?.cities || []);
                    if (!showCity) setCounties(region?.counties || []);
                  }}
                />
              </FormItem>

              {showCity && (
                <FormItem
                  $block
                  error={(errors.city || errors.cityName)?.message}
                >
                  <Cities
                    label={cityName}
                    fieldSelect={fieldCity}
                    hasError={!!errors.city || !!errors.cityName}
                    selectOnChange={(value) => {
                      // loads counties into the select
                      const city = cities.find((obj) => obj.id === value);
                      // @ts-expect-error react-hook-form generic form values
                      setValue("county", null);
                      setCounties(city?.counties || []);
                    }}
                  />
                </FormItem>
              )}

              {showCounty && !showCity && (
                <FormItem
                  $block
                  error={(errors.county || errors.countyName)?.message}
                >
                  <Counties
                    label={countyName}
                    hasError={!!errors.county || !!errors.countyName}
                    fieldSelect={fieldCounty}
                  />
                </FormItem>
              )}
            </Row>
          </FormItem>
          {showCounty && showCity && (
            <FormItem
              $block
              error={(errors.county || errors.countyName)?.message}
            >
              <Counties
                label={countyName}
                hasError={!!errors.county || !!errors.countyName}
                fieldSelect={fieldCounty}
              />
            </FormItem>
          )}
        </>
      ) : (
        <FormItem $block>
          <Row $block $justify="center">
            {loadingWaitingZipcodes ? (
              <Loading tip={t("components.address_form.zipcode.loading")} />
            ) : (
              <>
                {!counties?.length
                  ? t("components.address_form.zipcode.not_found")
                  : t("components.address_form.zipcode.required")}
              </>
            )}
          </Row>
        </FormItem>
      )}

      {zipcodePosition(zipcodeBehaviors) === AFTER_LOCALITIES && zipcodeField}

      <FormItem $block error={errors.additionalInformation?.message} $noStyle>
        <Controller
          control={control}
          // @ts-expect-error no generic for react-hook-form
          name="additionalInformation"
          render={({ field }) => (
            <Input.TextArea
              {...field}
              label={t("components.address_form.additional_information")}
              hasError={!!errors.additionalInformation}
              value={field.value || undefined}
              disabled={editableFields && !editableFields.includes(field.name)}
            />
          )}
        />
      </FormItem>
    </AddressContext.Provider>
  );
};

export default AddressForm;
