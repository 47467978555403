import { isArray } from "lodash";
import type { ReactText } from "react";
import { IoCheckmarkCircle, IoCloseCircle, IoWarning } from "react-icons/io5";
import { RiInformationFill } from "react-icons/ri";
import type { ToastOptions } from "react-toastify";
import {
  toast as alert,
  Slide,
  ToastContainer as AlertContainer,
} from "react-toastify";
import styled from "styled-components";
import * as S from "./styles";

const ICON_TYPE = {
  info: <RiInformationFill />,
  error: <IoCloseCircle />,
  warning: <IoWarning />,
  success: <IoCheckmarkCircle />,
};

type FixedProps =
  | "closeButton"
  | "className"
  | "bodyClassName"
  | "progressClassName"
  | "hideProgressBar"
  | "closeOnClick"
  | "draggable"
  | "pauseOnFocusLoss"
  | "transition";

export const toast = (
  type: "success" | "warning" | "error" | "info",
  message: string | string[],
  options?: Omit<ToastOptions, FixedProps>
): ReactText | ReactText[] => {
  const timeoutHandle = (): number | false | undefined => {
    if (options?.autoClose === 0) return false;
    return options?.autoClose;
  };

  const toastCommon: Pick<
    ToastOptions,
    FixedProps | "icon" | "position" | "autoClose"
  > = {
    icon: options?.icon || ICON_TYPE[type],
    position: options?.position,
    autoClose: timeoutHandle(),
    closeButton: false,
    hideProgressBar: true,
    closeOnClick: false,
    draggable: false,
    pauseOnFocusLoss: false,
    transition: Slide,
  };

  return isArray(message)
    ? message.map((msg) =>
        alert[type](msg, {
          ...options,
          ...toastCommon,
        })
      )
    : alert[type](message, {
        ...options,
        ...toastCommon,
      });
};

export const ToastContainer = styled(AlertContainer)`
  ${S.toastContainerCss}
`;
