import styled, { css } from "styled-components";
import Typography from "../../deprecated/Typography";
import type { FormItemProps } from "./index";

export const ErrorMessage = styled(Typography.Text.Body)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.text.critical};
`;

export const formItemCss = css<FormItemProps>`
  margin-bottom: ${({ error, description }) =>
    error || description ? "8px" : "16px"};
  display: ${({ $hidden }) => $hidden && "none"};
  margin: ${({ $noStyle }) => $noStyle && 0};
  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
    `};
`;
