import type { FC } from "react";
import type { TypographyProps } from "../Typography";
import Typography from "../Typography";

export type TextProps = Omit<TypographyProps, "$type" | "wrapper"> & {
  wrapper?: "p" | "span";
  $type?: "button" | "body" | "caption";
};
const Text: FC<TextProps> = ({
  wrapper = "span",
  $type = "body",
  children,
  ...props
}) => (
  <Typography wrapper={wrapper} $type={$type} {...props}>
    {children}
  </Typography>
);

export default Text;
