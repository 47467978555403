import { useRouter } from "next/router";
import type { FC, ReactNode } from "react";
import Expense from "../Expenses";
import type {
  SiderPaymentSectionTicketFragment,
  SiderPaymentSectionTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import * as S from "./styles";
import { Card, Divider, InlineMessage, Row, Spinner } from "design";
import { Typography } from "design/deprecated";
import { TicketReturnTrackingStatus } from "graphql/generated/schema.platform";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text } = Typography;

export type PaymentSectionProps = {
  isDrawer: boolean;
  title: ReactNode;
  footerExtra: ReactNode;
  data:
    | SiderPaymentSectionTicketFragment
    | SiderPaymentSectionTicketRequestFragment;
  editted:
    | {
        amount: { amount: number; formattedAmount: string };
      }
    | undefined;
  newShippingPriceLoading: boolean;
};
const PaymentSection: FC<PaymentSectionProps> = ({
  isDrawer,
  title,
  footerExtra,
  data,
  editted,
  newShippingPriceLoading,
}) => {
  const { t } = useTranslation("common");
  const route = useRouter();

  const isAddressStep = route.pathname.includes("address");
  const isResolutionStep = route.pathname.includes("resolution");

  const {
    isExchange,
    forcePayNewShipping,
    forcePayReturnLabel,
    newShippingPrice,
    refundOriginalShipmentAmount,
    returnLabelPrice,
    totalProductsDifferenceAmount,
    totalToRefund,
    totalToPay,
  } = data;
  const requiresReverseDelivery =
    "requiresReverseDelivery" in data && data.requiresReverseDelivery;
  const returnTrackingStatus =
    "returnTrackingStatus" in data && data.returnTrackingStatus;

  const showReturnLabel =
    requiresReverseDelivery === null
      ? returnTrackingStatus !== TicketReturnTrackingStatus.NOT_REQUIRED
      : requiresReverseDelivery;

  const haveToRefund =
    totalToRefund.amount > 0 ||
    (totalToRefund.amount === 0 && totalToPay.amount <= 0 && !isExchange);

  const haveToPay =
    isExchange || newShippingPrice === null || totalToPay.amount > 0;

  const haveContentTotal =
    (!haveToRefund && haveToPay && totalProductsDifferenceAmount.amount < 0) ||
    totalProductsDifferenceAmount.amount > 0 ||
    (isExchange && forcePayNewShipping) ||
    forcePayReturnLabel;

  const total = (
    <Row key="payment-total" $column $block $gap={12}>
      <Expense
        title={t("components.sider.total_to_pay")}
        amount={totalToPay}
        strikethroughAmount={!!editted}
        showEdited={!!editted}
        editedAmount={editted?.amount}
        showFree={!haveContentTotal}
        isTotal
      />
      {footerExtra}
    </Row>
  );

  const hasNewShippingPrice =
    isExchange &&
    ((!haveToRefund && !forcePayNewShipping) || forcePayNewShipping);

  const SiderText = (
    <S.ToBeConfirmedText
      smallWidth={
        isDrawer ||
        (!!newShippingPrice && !returnLabelPrice && !isDrawer) ||
        (!newShippingPrice && !returnLabelPrice)
      }
      $color="subdued"
      $align="right"
    >
      {t(
        `components.sider.${
          // eslint-disable-next-line no-nested-ternary
          isAddressStep
            ? !!newShippingPrice && !returnLabelPrice
              ? "not_confirmed_courier"
              : "not_confirmed"
            : "on_next_step"
        }`
      )}
    </S.ToBeConfirmedText>
  );

  const content = (
    <Row key="payment-content" $column $block>
      {totalProductsDifferenceAmount.amount > 0 && (
        <Expense
          title={t("components.sider.subtotal_to_pay")}
          amount={totalProductsDifferenceAmount}
        />
      )}
      {/* This is temporal, we need to remove this when we have the new attributes */}
      {/* Backend needed */}
      {!haveToRefund &&
        haveToPay &&
        totalProductsDifferenceAmount.amount < 0 && (
          <Expense
            title={t("balance_in_favor")}
            amount={totalProductsDifferenceAmount}
          />
        )}
      {isExchange &&
        !haveToRefund &&
        refundOriginalShipmentAmount.amount > 0 && (
          <Expense
            title={t("components.sider.refund_original_shipment")}
            amount={refundOriginalShipmentAmount}
            negate
          />
        )}
      <Row $block $noWrap $gap={4}>
        <Row $column $block>
          {hasNewShippingPrice && (
            <Expense
              title={t("components.sider.new_products_delivery")}
              amount={
                // eslint-disable-next-line no-nested-ternary
                newShippingPrice ? (
                  newShippingPriceLoading ? (
                    <InlineMessage
                      type="info"
                      icon={<Spinner />}
                      message={
                        <Text $color="subduedBold">{t("updating")}</Text>
                      }
                    />
                  ) : (
                    newShippingPrice
                  )
                ) : null
              }
              showFree
            />
          )}
          {showReturnLabel &&
            ((!haveToRefund && !forcePayReturnLabel) ||
              forcePayReturnLabel) && (
              <Row $block $noWrap $gap={4}>
                <Expense
                  title={t("components.sider.return_label")}
                  amount={returnLabelPrice ?? null}
                  showFree
                />
                {requiresReverseDelivery === null ? (
                  <InlineMessage
                    type="warning"
                    message={t("components.sider.without_service")}
                  />
                ) : (
                  !returnLabelPrice && !!newShippingPrice && SiderText
                )}
              </Row>
            )}
        </Row>
        {(isAddressStep || isResolutionStep) &&
          !returnLabelPrice &&
          !newShippingPrice &&
          SiderText}
      </Row>
    </Row>
  );

  // Review this condition again when we have the new attributes
  if (!haveToPay || (!haveContentTotal && totalToPay.amount === 0)) return null;

  if (isDrawer)
    return (
      <>
        <Divider sectionDivider $marginY={20} />
        {haveContentTotal
          ? [title, content, <Divider key="drawer-payment-divider" />, total]
          : total}
      </>
    );

  return (
    <Card block title={title} footer={haveContentTotal ? total : undefined}>
      {content}
    </Card>
  );
};

export default PaymentSection;
