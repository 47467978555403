import type { DefaultTheme } from "styled-components";
import { generateReversso } from "./color-generation";

export type DesignDevice = "mobile" | "tablet" | "desktop";

export const MOBILE_BREAKPOINT = 576;
export const TABLET_BREAKPOINT = 992;

export const generateBreakpoint = (breakpoint: number) => ({
  size: breakpoint,
  css: `(max-width: ${breakpoint}px)`,
});

export type ComponentsProps = {
  button?: {
    primary?: {
      background: string;
      text: string;
    };
  };
  header?: {
    background: string;
    border: string;
    text: string;
  };
  layout?: {
    background: string;
  };
  skeleton?: {
    animation: "wave" | "pulse" | false;
  };
};

type GenerateThemeProps = {
  colors: {
    main: Record<keyof DefaultTheme["colors"]["base"], string>;
    components?: ComponentsProps;
  };
};

const toHex = (str: string | undefined) =>
  str !== undefined && !str.startsWith("#") ? `#${str}` : str;

const colorIndex = ({
  base,
  override,
}: {
  base: string[];
  override?: string;
}) => {
  const _base = override !== undefined ? generateReversso(override) : base;
  return (idx: number) => _base[idx - 1];
};

const generatePalette = ({
  main,
  components,
}: GenerateThemeProps["colors"]) => {
  const black = generateReversso(main.black);
  const blue = generateReversso(main.blue);
  const primary = generateReversso(main.primary);
  const yellow = generateReversso(main.yellow);
  const orange = generateReversso(main.orange);
  const red = generateReversso(main.red);
  const green = generateReversso(main.green);
  const violet = generateReversso(main.violet);

  const BASE_COLORS = {
    black,
    blue,
    primary,
    yellow,
    orange,
    red,
    green,
    violet,
  };

  const B = colorIndex({ base: blue });
  const N = colorIndex({ base: black });
  const P = colorIndex({ base: primary });
  const Y = colorIndex({ base: yellow });
  const O = colorIndex({ base: orange });
  const R = colorIndex({ base: red });
  const G = colorIndex({ base: green });
  const V = colorIndex({ base: violet });
  const BTN_PRIMARY = colorIndex({
    base: primary,
    override: toHex(components?.button?.primary?.background),
  });

  return { BASE_COLORS, B, N, P, Y, O, R, G, V, BTN_PRIMARY };
};

export const generateTheme = (
  props: GenerateThemeProps["colors"]
): DefaultTheme["colors"] => {
  const { BASE_COLORS, B, N, P, Y, O, R, G, V, BTN_PRIMARY } =
    generatePalette(props);

  return {
    base: BASE_COLORS,
    background: N(2),
    surface: {
      default: {
        default: N(1),
        hovered: N(3),
        pressed: N(4),
        disabled: N(2),
        inverted: N(14),
      },
      critical: {
        default: R(6),
        subdued: R(4),
        subduedHovered: R(4),
        subduedPressed: R(5),
        important: R(9),
        importantSubdued: R(7),
      },
      informational: {
        default: B(6),
        subdued: B(4),
        subduedPressed: B(5),
        important: B(9),
        importantSubdued: B(8),
        importantBold: B(11),
      },
      primary: {
        default: P(6),
        subdued: P(4),
        subduedPressed: P(5),
        important: P(9),
        importantSubdued: P(8),
        importantBold: P(11),
      },
      pending: {
        default: O(6),
        subdued: O(4),
        important: O(9),
        importantSubdued: O(8),
      },
      warning: {
        default: Y(6),
        subdued: Y(4),
        important: Y(9),
        importantSubdued: Y(8),
      },
      success: {
        default: G(6),
        subdued: G(4),
        important: G(9),
        importantSubdued: G(8),
      },
      neutral: {
        default: N(4),
        subdued: N(3),
        subduedBold: N(6),
        important: N(9),
      },
      decorative: {
        default: V(6),
        subdued: V(4),
        important: V(10),
        importantBold: V(12),
      },
    },
    text: {
      default: N(14),
      disabled: N(8),
      critical: R(9),
      informational: B(9),
      primary: P(9),
      subdued: N(10),
      subduedBold: N(12),
      success: G(9),
      warning: O(9),
      white: N(1),
    },
    icon: {
      default: N(11),
      disabled: N(5),
      critical: R(10),
      informational: B(10),
      primary: P(10),
      pending: Y(10),
      subdued: N(8),
      success: G(10),
      warning: O(10),
      white: N(1),
    },
    border: {
      default: {
        default: N(8),
        subdued: N(3),
        disabled: N(3),
      },
      critical: {
        default: R(9),
        subdued: R(7),
      },
      informational: {
        default: B(9),
        subdued: B(7),
      },
      primary: {
        default: P(9),
        subdued: P(7),
      },
      pending: {
        default: Y(9),
        subdued: Y(7),
      },
      warning: {
        default: O(9),
        subdued: O(7),
      },
      success: {
        default: G(9),
        subdued: G(7),
      },
      neutral: {
        subdued: N(6),
      },
    },
    action: {
      primary: {
        default: P(9),
        hovered: P(10),
        pressed: P(11),
        disabled: N(3),
      },
      critical: {
        default: R(9),
        hovered: R(10),
        pressed: R(11),
        disabled: N(3),
      },
      secondary: {
        default: N(1),
        hovered: N(2),
        pressed: N(3),
        disabled: N(2),
      },
    },
    iconAction: {
      default: {
        default: N(11),
        subdued: N(8),
        hovered: N(12),
        pressed: N(13),
        disabled: N(5),
      },
      primary: {
        default: P(9),
        hovered: P(10),
        pressed: P(11),
      },
      critical: {
        default: R(9),
        hovered: R(10),
        pressed: R(11),
      },
    },
    components: {
      button: {
        primary: {
          background: {
            default: BTN_PRIMARY(9),
            hovered: BTN_PRIMARY(10),
            pressed: BTN_PRIMARY(11),
          },
          text: toHex(props.components?.button?.primary?.text) || N(1),
        },
      },
      header: {
        background: toHex(props.components?.header?.background) || N(2),
        border: toHex(props.components?.header?.border) || N(3),
        text: toHex(props.components?.header?.text) || N(14),
      },
      layout: {
        background: toHex(props.components?.layout?.background) || N(2),
      },
    },
  };
};
