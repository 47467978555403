import { Carousel } from "antd";
import { HiX } from "react-icons/hi";
import styled from "styled-components";

export const StyledCarousel = styled(Carousel)`
  margin: -14px -18px;
  border-radius: 8px;
  overflow: hidden;
`;

export const CloseIcon = styled(HiX)`
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 16px;
  font-size: 20px;
  min-width: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.iconAction.default.default};
  :hover {
    color: ${({ theme }) => theme.colors.iconAction.default.hovered};
  }
`;
