import type { ReactElement } from "react";
import { useRef, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import styled from "styled-components";
import Popover from "../../../atoms/Popover";
import Row from "../../../atoms/Row";
import { useDesign } from "../../../setup/context";
import type { TabProps as BaseTabProps } from "../Tab";
import Tab from "../Tab";
import TabBadgeWrapper from "../TabBadgeWrapper";
import * as S from "./styles";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

export type TabProps<KeyType = unknown> = Omit<
  BaseTabProps,
  "onClick" | "active"
> & {
  key: KeyType;
};

export type TabsProps<KeyType> = {
  className?: string;
  tabs: TabProps<KeyType>[];
  onTabChange?: (k: KeyType) => void;
} & (
  | { defaultValue: KeyType }
  | {
      active: {
        is: KeyType;
        set: (value: KeyType) => void;
      };
    }
);

const TabsInner = <KeyType,>({
  className,
  tabs,
  onTabChange,
  ...props
}: TabsProps<KeyType>): ReactElement => {
  const { t } = useTranslation("common", { keyPrefix: "common" });
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tabsReferences = tabs.map(() => useRef<HTMLDivElement>(null));
  const moreTabsRef = useRef<HTMLDivElement>(null);
  const {
    device: { isDesktop },
  } = useDesign();

  const [_activeTab, _setActiveTab] = useState<KeyType | undefined>(
    "defaultValue" in props ? props.defaultValue : undefined
  );
  const activeTab = "active" in props ? props.active.is : _activeTab;
  const setActiveTab = "active" in props ? props.active.set : _setActiveTab;

  const wrapperWidth = wrapperRef.current?.clientWidth || 0;
  const moreTabsWidth = moreTabsRef.current?.clientWidth || 0;
  const tabsLength = tabs.length;

  const activeIndex = tabs.findIndex((tb) => tb.key === activeTab);

  let usedWidth = 0;
  const _maxVisible = tabs.findIndex((_, idx) => {
    // if it's not desktop, we dont use the 'more views' and use scroll
    if (!isDesktop) return false;

    const tabWidth = tabsReferences[idx].current?.clientWidth || 0;
    // if it's the last one, ignore the "more tabs" width
    const _moreTabsWidth = idx + 1 === tabsLength ? 0 : moreTabsWidth;
    // if current index is lower than active, then consider the width, but if's past, consider zero
    const activeTabWidth =
      idx < activeIndex
        ? tabsReferences[activeIndex].current?.clientWidth || 0
        : 0;
    const availableWidth = wrapperWidth - _moreTabsWidth - activeTabWidth;
    if (usedWidth + tabWidth <= availableWidth) {
      usedWidth += tabWidth;
      return false;
    }

    return true;
  });
  const maxVisible = _maxVisible !== -1 ? _maxVisible : tabsLength;

  const visibleTabs = tabs
    .map((tb, idx) => (idx < maxVisible || idx === activeIndex ? tb : null))
    .filter((tb): tb is TabProps<KeyType> => tb !== null);

  const onClick = (k: KeyType): void => {
    setActiveTab(k);
    onTabChange?.(k);
  };

  const moreTabs = tabs
    .map((tb, idx) =>
      idx >= maxVisible && idx !== activeIndex ? (
        <S.MenuItemTabs
          key={`tabs-more-${tb.key}`}
          $keyId={tb.key}
          $active={activeTab === tb.key}
          onClick={() => {
            onClick(tb.key);
            setIsVisible(false);
          }}
        >
          <TabBadgeWrapper active={activeTab === tb.key} badge={tb.badge}>
            {tb.children}
          </TabBadgeWrapper>
        </S.MenuItemTabs>
      ) : null
    )
    .filter((tb) => tb !== null);

  const moreTabsComponent = (
    <Row $align="center" $noWrap $gap={4}>
      {t("more_views")}
      <HiChevronDown size={20} />
    </Row>
  );

  return (
    <div className={className} ref={wrapperRef}>
      <S.RowHidden>
        {tabs.map((tab, idx) => (
          <Tab
            {...tab}
            key={`tabs-hidden-${tab.key}`}
            active={activeTab === tab.key}
            ref={tabsReferences[idx]}
            onClick={undefined}
          >
            {tab.children}
          </Tab>
        ))}
        <Tab active={false} ref={moreTabsRef} onClick={undefined}>
          {moreTabsComponent}
        </Tab>
      </S.RowHidden>

      <S.TabContainer>
        {visibleTabs.map((tab) => (
          <Tab
            {...tab}
            key={`tabs-visible-${tab.key}`}
            active={activeTab === tab.key}
            onClick={() => onClick(tab.key)}
          >
            {tab.children}
          </Tab>
        ))}
      </S.TabContainer>
      {moreTabs.length > 0 && (
        <Popover
          visible={{ is: isVisible, set: setIsVisible }}
          trigger="click"
          placement="bottomRight"
          content={moreTabs}
        >
          <S.MoreTabs active={false} $tabActive={isVisible} onClick={undefined}>
            {moreTabsComponent}
          </S.MoreTabs>
        </Popover>
      )}
    </div>
  );
};

const Tabs: (<KeyType>(props: TabsProps<KeyType>) => ReactElement) &
  string = styled(TabsInner)`
  ${S.tabsCss}
`;

export default Tabs;
