import { css } from "styled-components";
import { SpinnerRotate } from "../../animations";

export const spinnerCss = css`
  position: relative;
  display: grid;
  width: fit-content;
  justify-content: center;
  margin: auto 0;
  animation: ${SpinnerRotate} 1s linear infinite;
`;
