import styled, { css } from "styled-components";
import type { DivProps } from "./types";

type AffixFixedDivProps = {
  offsetTop: number;
  props: DivProps;
};
export const AffixFixedDiv = styled.div<AffixFixedDivProps>`
  overflow-y: auto; // if content is very big and window is not tall enough, we will scroll inside the fixed div
  ${({ props, offsetTop }) =>
    props.fixed
      ? css`
          position: fixed;
          top: ${offsetTop}px;
          height: min(${props.height}px, 100vh - ${offsetTop}px);
          width: ${props.width}px;
        `
      : undefined}
`;
