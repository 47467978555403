import { useRouter } from "next/router";
import type { FC } from "react";
import LayoutWrapper from "../Wrapper";
import Illustration from "./Illustration";
import { Button, Row } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Title } = Typography;

const NotFound: FC<{ hideLink?: boolean }> = ({ hideLink }) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  return (
    <LayoutWrapper>
      <Illustration />
      <Row $column $block $align="center" $gap={12}>
        <Title.DisplayL $align="center">
          {t("layout.not_found.title")}
        </Title.DisplayL>
        <Title.Heading $align="center">
          {t("layout.not_found.description")}
        </Title.Heading>
        {!hideLink && (
          <Button type="primary" onClick={() => router.push("/")}>
            {t("layout.not_found.button")}
          </Button>
        )}
      </Row>
    </LayoutWrapper>
  );
};

export default NotFound;
