import type { FC, ReactNode } from "react";
import type { RowProps } from "../../Row";
import * as S from "./styles";

export type SectionContainerActionsProps = {
  children: ReactNode;
  justify?: RowProps["$justify"];
};

const SectionContainerActions: FC<SectionContainerActionsProps> = ({
  children,
  justify = "space-between",
}) => (
  <S.ActionsFormContainer $justify="end">
    <S.ButtonsContainer $justify={justify} $align="start">
      {children}
    </S.ButtonsContainer>
  </S.ActionsFormContainer>
);

export default SectionContainerActions;
