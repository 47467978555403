import type { FC, ReactNode } from "react";
import { useState, useRef, useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { FiPlusCircle } from "react-icons/fi";
import styled from "styled-components";
import { v4 } from "uuid";
import Badge from "../../atoms/Badge";
import TooltipIcon from "../../atoms/TooltipIcon";
import Typography from "../../deprecated/Typography";
import Button from "../Button";
import Input from "../Input";
import * as S from "./styles";

const { Text } = Typography;

export type EditableElementsSelectorProps = {
  label?: ReactNode;
  tooltip?: ReactNode;
  id?: string;
  text?: string;
  className?: string;
  value?: string[];
  noButton?: boolean;
  vertical?: boolean;
  hasError?: boolean;
  onChange?: (v: string[]) => void;
  onAdd?: (v: string) => void;
};
const EditableElementsSelector: FC<EditableElementsSelectorProps> = ({
  label,
  tooltip,
  id,
  text,
  className,
  value = [],
  noButton,
  vertical,
  hasError,
  onChange,
  onAdd,
}) => {
  const [visibleInput, setVisibleInput] = useState(noButton);
  const [inputValue, setInputValue] = useState("");
  const [badges, setBadges] = useState(value);

  const handleInputEnter = () => {
    if (inputValue && badges?.indexOf(inputValue) === -1) {
      const newBadges = [...badges, inputValue];
      setBadges(newBadges);
      onChange?.(newBadges);
    }
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const filterBadges = (badge: string) => {
    const newBadges = badges.filter((item) => item !== badge);
    setBadges(newBadges);
    onChange?.(newBadges);
  };

  const handleSubmit = () => {
    if (hasError) return;
    setVisibleInput(false);
    handleInputEnter();
    if (inputRef?.current?.value) {
      inputRef.current.value = "";
      setInputValue("");
    }
  };

  useEffect(() => {
    if (visibleInput) {
      inputRef?.current?.focus();
    }
  }, [visibleInput]);

  const _id = id !== undefined ? id : v4();

  return (
    <div className={className}>
      <label htmlFor={_id}>
        <Text.Body>{label}</Text.Body>
        {tooltip && <TooltipIcon tooltip={tooltip} />}
      </label>
      <S.InnerContainer
        $align={!vertical ? "center" : undefined}
        $noWrap
        $gap={4}
        $column={vertical}
        vertical={vertical}
      >
        {!visibleInput && !noButton ? (
          <Button
            type="outline"
            $dashed
            icon={<FiPlusCircle />}
            onClick={() => setVisibleInput(true)}
          >
            {text}
          </Button>
        ) : (
          <Input
            id={_id}
            ref={inputRef}
            onBlur={() => handleSubmit()}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmit();
              }
            }}
            onChange={(e) => {
              onAdd?.(e.target.value);
              setInputValue(e.target.value);
            }}
            hasError={hasError}
          />
        )}
        <S.BadgesContainer $gap={4} vertical={vertical}>
          {badges?.map((badge) => (
            <Badge
              key={`badge-key-${badge}`}
              text={badge}
              icon={
                <S.CloseIcon
                  icon={<CgClose />}
                  onClick={() => filterBadges(badge)}
                />
              }
            />
          ))}
        </S.BadgesContainer>
      </S.InnerContainer>
    </div>
  );
};

export default styled(EditableElementsSelector)`
  ${S.editableElementsSelectorCss}
`;
