import type { FC } from "react";
import Affix from "../../../atoms/Affix";
import Typography from "../../../deprecated/Typography";
import * as S from "./styles";
import { useDelayedBoolean } from "hooks";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text } = Typography;

const LoadingTable: FC<{ loading: boolean | undefined; $isList: boolean }> = ({
  loading,
  $isList,
}) => {
  const { t } = useTranslation("common");
  const { display, isLeaving } = useDelayedBoolean(!!loading, S.animationTime);

  return (
    <S.LoadingRowContainer $active={display} $isList={$isList}>
      <Affix>
        <S.DivAnimations
          $active={display}
          $loading={loading || false}
          $isLeaving={isLeaving}
        >
          <S.LoadingRow>
            <S.LoadingContainer>
              <S.TableSpinner />
              <Text.Body>{t("loading_dots")}</Text.Body>
            </S.LoadingContainer>
          </S.LoadingRow>
        </S.DivAnimations>
      </Affix>
    </S.LoadingRowContainer>
  );
};

export default LoadingTable;
