import styled, { css } from "styled-components";

export const ColorPreview = styled.div<{ $color: string }>`
  height: 24px;
  width: 24px;
  border: 0.2px solid ${({ theme }) => theme.colors.border.default.subdued};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ $color }) => $color};
`;
export const PickerContainer = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.colorPicker};
  width: fit-content;

  .chrome-picker {
    & div:last-child .flexbox-fix:last-child {
      display: none !important;
    }
  }
`;

export const colorPickerCss = css`
  position: relative;
`;
