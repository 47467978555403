import RcTooltip from "rc-tooltip";
import type { TooltipProps as RcTooltipProps } from "rc-tooltip/lib/Tooltip";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { forwardRef } from "react";
import styled, { useTheme } from "styled-components";
import Typography from "../../deprecated/Typography";
import * as S from "./styles";

const { Text } = Typography;

export type TooltipProps = {
  visible?: {
    is: boolean;
    set: Dispatch<SetStateAction<boolean>>;
  };
  title: ReactNode;
  trigger?: "hover" | "click";
  placement?:
    | "left"
    | "leftTop"
    | "leftBottom"
    | "right"
    | "rightTop"
    | "rightBottom"
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight";
  className?: string;
  $showArrow?: boolean;
  block?: boolean;
  children: ReactNode;
  animationName?: string;
};
const Tooltip = forwardRef<HTMLElement, TooltipProps>(
  (
    {
      visible,
      placement = "top",
      className,
      title,
      trigger = "hover",
      children,
      animationName,
      block = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const visibleProps: Partial<RcTooltipProps> | undefined = visible
      ? {
          onVisibleChange: visible.set,
          visible: visible.is,
        }
      : undefined;

    return (
      <RcTooltip
        ref={ref}
        overlayClassName={className}
        placement={placement}
        overlay={<Text.Caption $color="white">{title}</Text.Caption>}
        trigger={[trigger]}
        motion={{
          motionName: animationName || theme.motionName.FADE,
        }}
        {...visibleProps}
      >
        <S.TooltipChildren $block={block}>{children}</S.TooltipChildren>
      </RcTooltip>
    );
  }
);

export default styled(Tooltip)`
  ${S.tooltipCss}
`;
