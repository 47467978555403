import type { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { HiOutlineShoppingBag } from "react-icons/hi";
import styled from "styled-components";
import * as S from "./styles";
import type { SiderContentProps } from "components";
import { SiderContent } from "components";
import { Drawer, Row } from "design";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

export type SiderProps = {
  className?: string;
  hideTitle?: boolean;
  title?: ReactNode;
  contentProps: Omit<SiderContentProps, "isDrawer">;
  drawer?: {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
  };
};
const Sider: FC<SiderProps> = ({
  className,
  hideTitle,
  title,
  contentProps,
  drawer,
}) => {
  const { t } = useTranslation("common");

  const titleContent = title || (
    <Row $noWrap $gap={8} $align="center">
      <HiOutlineShoppingBag size={18} />
      {t("summary")}
    </Row>
  );

  if (drawer)
    return (
      <Drawer
        title={!hideTitle && titleContent}
        visible={drawer.visible}
        placement="right"
        fullWidth
        onClose={() => drawer.setVisible(false)}
      >
        <SiderContent {...contentProps} isDrawer />
      </Drawer>
    );

  return (
    <S.ContainerSection className={className}>
      <S.SiderContentContainerRow $column $block $gap={20}>
        <SiderContent {...contentProps} isDrawer={false} />
      </S.SiderContentContainerRow>
    </S.ContainerSection>
  );
};

export default styled(Sider)``;
