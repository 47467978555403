import BaseRadio from "./Radio";
import Group from "./RadioGroup";

export * as RadioStyles from "./Radio/styles";
export * as RadioGroupStyles from "./RadioGroup/styles";

export type { RadioProps } from "./Radio";
export type { RadioGroupProps } from "./RadioGroup";

type CompoundedComponent = typeof BaseRadio & {
  Group: typeof Group;
};

const Radio = BaseRadio as CompoundedComponent;
Radio.Group = Group;

export default Radio;
