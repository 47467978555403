import styled, { css } from "styled-components";

export const ContentContainerDiv = styled.div`
  display: flex;
  flex: auto;
  padding: 20px 16px 0;
  @media ${({ theme }) => theme.breakpoints.mobile.css} {
    padding: 16px 16px 0;
  }
`;

type LayoutContainerMainProps = {
  $backgroundUrl: string | undefined;
};
export const LayoutContainerMain = styled.main<LayoutContainerMainProps>`
  flex: auto;
  display: flex;
  flex-direction: column;
  overscroll-behavior-y: none;
  ${({ theme, $backgroundUrl }) =>
    $backgroundUrl
      ? css`
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.6),
              rgba(0, 0, 0, 0.6)
            ),
            url("${$backgroundUrl}");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        `
      : css`
          background-color: ${theme.colors.components.layout.background};
        `};
`;
