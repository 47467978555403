import type { FC, ReactNode } from "react";
import { Children, cloneElement, isValidElement } from "react";
import styled from "styled-components";
import * as S from "./styles";
import { useDesign } from "design/setup/context";

export type StepsProps = {
  direction?: "horizontal" | "vertical";
  initial?: number;
  current?: number;
  status?: "error" | "process" | "finish" | "wait";
  verticalOnMobile?: boolean;
  type?: "dots" | "numbers" | "checks";
  className?: string;
  children?: ReactNode;
};
const Steps: FC<StepsProps> = ({
  children,
  direction = "horizontal",
  initial = 0,
  current = 0,
  verticalOnMobile = true,
  status = "process",
  type = "checks",
  className,
}) => {
  const {
    device: { isMobile },
  } = useDesign();

  const _isVertical = isMobile ? verticalOnMobile : direction === "vertical";

  return (
    <div className={className}>
      {/* props can come empty (and that's fine), we use this section to assign values to the necessary props for the Step component to work properly. */}
      {Children.map(children, (child: ReactNode, index) => {
        if (!isValidElement(child)) return undefined;

        const stepNumber = initial + index;
        const childProps = {
          stepNumber: `${stepNumber + 1}`,
          $stepIndex: stepNumber,
          key: stepNumber,
          isVertical: _isVertical,
          type,
          ...child.props,
        };
        if (!child.props.status) {
          if (stepNumber === current) {
            childProps.status = status;
          } else if (stepNumber < current) {
            childProps.status = "finish";
          } else {
            childProps.status = "wait";
          }
        }
        childProps.$active = stepNumber === current;

        return cloneElement(child, childProps);
      })}
    </div>
  );
};

export default styled(Steps)`
  ${S.stepsCss}
`;
