import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";
import type { TypographyProps } from "./Typography";

const handleType: Record<
  TypographyProps["$type"],
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  heading4xl: css`
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  heading3xl: css`
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 28px;
      line-height: 32px;
    }
  `,
  heading2xl: css`
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 24px;
      line-height: 28px;
    }
  `,
  headingXl: css`
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  headingLg: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 16px;
      line-height: 24px;
    }
  `,
  headingMd: css`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  headingSm: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  bodyLg: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `,
  bodyMd: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  bodySm: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  `,
};

export const typographyCss = css<TypographyProps>`
  transition: all 0.2s ease-in-out;
  ${({ $type }) => handleType[$type || "body"]};
  color: ${({ $color, theme }) =>
    $color ? theme.colors.text[$color] : theme.colors.text.default};
  font-weight: ${({ $weight }) => $weight};
  text-decoration: ${({ $textDecoration }) => $textDecoration};
  cursor: ${({ $cursor }) => $cursor};
  white-space: pre-line;
  ${({ $align }) =>
    $align &&
    $align !== "left" &&
    css`
      width: 100%;
      text-align: ${$align};
    `};
`;
