import type { VFC } from "react";
import LayoutWrapper from "../Wrapper";
import Illustration from "./Illustration";
import { Row } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { Trans, useTranslation } = nextI18n;
const { Title } = Typography;

const NoPublic: VFC = () => {
  const { t } = useTranslation("common");

  return (
    <LayoutWrapper>
      <Illustration />
      <Row $column $block $align="center" $gap={12}>
        <Title.DisplayL $align="center">
          {t("layout.no_public.title")}
        </Title.DisplayL>
        <Title.Heading $align="center">
          {Trans({
            t,
            i18nKey: "layout.no_public.description",
            components: {
              // TODO: We need the shop email
              // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
              a: <a href="mailto:contacto@reversso.cl" />,
            },
            // TODO: We need the shop email
            // values: {
            //   shopEmail: "contacto@reversso.cl",
            // },
          })}
        </Title.Heading>
      </Row>
    </LayoutWrapper>
  );
};

export default NoPublic;
