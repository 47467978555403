import type { Keyframes, ThemeProps } from "styled-components";
import { css, keyframes } from "styled-components";
import type { lightTheme } from "design/setup";

export const productAddedIcon = (
  theme: ThemeProps<typeof lightTheme>["theme"]
): Keyframes => keyframes`
  0% {
    color: ${theme.colors.icon.default};
  }
  15%, 85% {
    color: ${theme.colors.icon.white};
  }
  100% {
    color: ${theme.colors.icon.default};
  }
`;

const DOT_REST_SIZE = 8; // px
export const dotRestingPosition = css`
  top: 12px;
  right: 9px;
  z-index: 1;
  width: ${DOT_REST_SIZE}px;
  height: ${DOT_REST_SIZE}px;
`;

export const productAdded = (startFromDot: boolean): Keyframes =>
  keyframes`
  0% {
    ${
      startFromDot
        ? dotRestingPosition
        : css`
            top: 18px;
            right: 18px;
            z-index: 0;
            width: 0;
            height: 0;
          `
    }
  }
  17% {
    z-index: 0;
  }
  25%, 75% {
    top: 50%;
    right: 50%;
    width: 100%;
    height: 100%;
    margin-top: -50%;
    margin-right: -50%;
  }
  83% {
    z-index: 0;
  }
  100% {
    ${dotRestingPosition}
  }
`;
