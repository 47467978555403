import { css } from "styled-components";
import type { IconProps } from "./index";

export const iconCss = css<IconProps>`
  position: relative;
  display: grid;
  vertical-align: middle;
  justify-content: center;
  ${({ $animated }) =>
    $animated &&
    css`
      transition: transform 0.3s ease-in-out;
    `}
  color: ${({ theme, type }) => (type ? theme.colors.icon[type] : undefined)};
`;
