import styled, { css } from "styled-components";
import Typography from "../../../deprecated/Typography";
import { TableBody } from "../../../organisms/Table/DesktopTable/styles";
import Card from "../../Card";
import List from "../../List/List";

type LeftContentProps = { fillEmpty: boolean };
export const LeftContent = styled.div<LeftContentProps>`
  ${Typography} {
    margin-top: 16px;
  }
  ${Typography} + ${Typography} {
    margin-top: 8px;
  }

  margin: 0 8px ${({ fillEmpty }) => (fillEmpty ? 0 : 12)}px 0;
`;

export const rightContentCss = css`
  width: 607px;
`;
export const RightContentDiv = styled.div`
  ${rightContentCss}
`;

type Props = { $withTable?: boolean };
export const RightContent = styled(Card)<Props>`
  ${rightContentCss}
  ${({ $withTable }) =>
    $withTable &&
    css`
      padding: 0;

      ${List} {
        padding: 12px 20px;
      }
      ${TableBody} tr td {
        padding: 12px 0;
        &:first-child:after,
        &:last-child:after {
          width: calc(100% - 20px);
        }
        &:first-child {
          padding-left: 20px;
        }
        &:last-child {
          padding-right: 20px;
        }
      }
      @media (max-width: 576px) {
        ${List} {
          padding: 12px 16px;
        }
        ${TableBody} tr td {
          &:first-child {
            padding-left: 16px;
          }
          &:last-child {
            padding-right: 16px;
          }
        }
      }
    `}
`;

export const sectionContainerCss = css`
  flex-wrap: nowrap;
  ${LeftContent} {
    width: 270px;
  }
  @media (max-width: 1180px) {
    flex-wrap: wrap;
    ${LeftContent} {
      width: 607px;
    }
  }
`;
