import { css } from "styled-components";
import type { BaseDrawerProps } from "./index";

export const handlePlacement = (
  visible: boolean,
  placement: BaseDrawerProps["$placement"]
) => {
  const value = visible ? 0 : -100;
  switch (placement) {
    case "bottom":
      return css`
        bottom: ${value}%;
        width: 100%;
        left: 0;
        right: 0;
        border-radius: 8px 8px 0 0;
      `;
    case "right":
      return css`
        top: 0;
        right: ${value}%;
        height: 100%;
        border-left: 1px solid
          ${({ theme }) => theme.colors.border.default.subdued};
      `;
    case "left":
    default:
      return css`
        top: 0;
        left: ${value}%;
        height: 100%;
        border-right: 1px solid
          ${({ theme }) => theme.colors.border.default.subdued};
      `;
  }
};
