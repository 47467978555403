import type { UploadProps } from "antd";
import type { RcFile, UploadFileStatus } from "antd/lib/upload/interface";
import request from "rc-upload/lib/request";
import type { FC, ReactNode } from "react";
import { useFileStageUploadMutation } from "./graphql/generated/mutations.platform.generated";
import * as S from "./styles";

export type FileStageUploadFile = {
  status?: UploadFileStatus;
  url?: string;
  name: string;
};

type BaseFileStageUploadProps = {
  value?: FileStageUploadFile[];
  onChange?: (value: FileStageUploadFile[]) => void;
  accelerate?: boolean;
  children: ReactNode;
  readonly?: boolean;
};
type FileStageUploadProps = Omit<UploadProps, keyof BaseFileStageUploadProps> &
  BaseFileStageUploadProps;
const FileStageUpload: FC<FileStageUploadProps> = ({
  beforeUpload,
  value,
  onChange,
  accelerate,
  children,
  readonly,
  ...props
}) => {
  const [mutate] = useFileStageUploadMutation();

  const customRequest: UploadProps["customRequest"] = ({
    file,
    method = "POST",
    onSuccess,
    ...requestProps
  }) =>
    mutate({
      variables: {
        input: {
          filenames: [(file as RcFile).name],
          accelerate: Boolean(accelerate),
        },
      },
    }).then(({ data }) => {
      if (!data?.fileStage?.files) return undefined;
      const { files } = data.fileStage;

      const {
        fileUrl,
        request: { url, fields },
      } = files[0];

      return request({
        ...requestProps,
        method,
        file: file as RcFile,
        action: url,
        data: fields as object,
        onSuccess: (_, xhr) => onSuccess?.({ url: fileUrl }, xhr),
      });
    });

  const handleBeforeUpload: UploadProps["beforeUpload"] = async (
    file,
    fileList
  ) => {
    if (file.type === "image/heic" && typeof window !== "undefined") {
      // the library is imported here because heic2any use the window global variables.
      // and it breaks the app if it is imported normally
      // https://github.com/dropzone/dropzone/issues/1984#issuecomment-1564240972
      const heic2any = await import("heic2any");

      const converted = await heic2any.default({
        blob: file,
        toType: "image/jpeg",
        quality: 0.7,
      });
      const blobList = Array.isArray(converted) ? converted : [converted];
      return new File(blobList, file.name.replace(/\.[^.]+$/, ".jpeg"), {
        type: "image/jpeg",
      });
    }

    return beforeUpload?.(file, fileList);
  };

  return (
    <S.StyledUpload
      onChange={({ fileList }) =>
        onChange?.(
          fileList.map((file) => ({
            ...file,
            url: file.response?.url || file.url,
          }))
        )
      }
      fileList={value?.map((val) => ({
        uid: "",
        size: Number.NaN,
        type: "",
        ...val,
      }))}
      multiple
      showUploadList={{
        showDownloadIcon: true,
        showPreviewIcon: true,
        showRemoveIcon: true,
      }}
      {...props}
      beforeUpload={handleBeforeUpload}
      name={undefined} // dont know why, but if we put a different name, upload fails
      customRequest={customRequest}
    >
      {!readonly ? children : undefined}
    </S.StyledUpload>
  );
};

export default FileStageUpload;
