import type { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import * as S from "./styles";
import type { HeaderCssProps } from "./styles";

export type HeaderProps = HTMLAttributes<HTMLElement> & HeaderCssProps;

const Header: FC<HeaderProps> = ({ children, ...props }) => (
  <header {...props}>{children}</header>
);

export default styled(Header)`
  ${S.headerCss}
`;
