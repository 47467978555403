import { css } from "styled-components";

export type HeaderCssProps = { $withBorder: boolean };

export const headerCss = css<HeaderCssProps>`
  position: sticky;
  z-index: ${({ theme }) => theme.zIndex.header};
  top: 0;
  display: grid;
  min-height: 56px;
  padding: 0 24px;
  background-color: ${({ theme }) => theme.colors.components.header.background};
  border-bottom: ${({ theme, $withBorder = true }) =>
    $withBorder ? `1px solid ${theme.colors.components.header.border}` : ""};
  color: ${({ theme }) => theme.colors.components.header.text};
`;
