import styled from "styled-components";
import { Row } from "design";

type Props = {
  $hiddenFooter: boolean;
};
export const StyledRow = styled(Row)<Props>`
  position: sticky;
  z-index: 1;
  padding: 20px 0;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.border.default.subdued};
  background-color: ${({ theme }) => theme.colors.components.layout.background};
  bottom: ${({ $hiddenFooter }) => ($hiddenFooter ? 0 : 32)}px;

  @media ${({ theme }) => theme.breakpoints.tablet.css} {
    margin-top: auto;
    margin-left: -16px;
    padding: 12px 16px;
    left: 0;
    width: calc(100% + 32px);
  }

  @media ${({ theme }) => theme.breakpoints.mobile.css} {
    padding: 8px 16px;
    bottom: ${({ $hiddenFooter }) => ($hiddenFooter ? 0 : 48)}px;
  }
`;
