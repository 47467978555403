import styled, { css } from "styled-components";
import { tooltipClass } from "../Tooltip/styles";
import { handlePlacement } from "./utils";
import type { PopoverProps } from "./index";

export const Title = styled.div``;
export const Content = styled.div``;

export const popoverCss = css<PopoverProps>`
  &&&.${tooltipClass} {
    position: ${({ isPositionStatic }) =>
      isPositionStatic ? "static" : "fixed"};
    max-width: unset;
    border-radius: ${({ theme }) => theme.borderRadius.default};
    z-index: ${({ theme, isPositionStatic }) =>
      isPositionStatic ? 1 : theme.zIndex.popover};
    .${tooltipClass}-inner {
      padding: 8px 0;
      color: ${({ theme }) => theme.colors.text.default};
      box-shadow: ${({ showShadow }) =>
        showShadow
          ? "0px 2px 16px 0px rgba(0, 0, 0, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.20)"
          : "none"};
      background-color: ${({ theme }) => theme.colors.surface.default.default};
    }
    ${({ placement }) => handlePlacement[placement || "top"]}
  }
`;
