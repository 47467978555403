import Currency from "./Currency";
import BaseInput from "./Input";
import Password from "./Password";
import Search from "./Search";
import TextArea from "./Textarea";

export * as InputStyles from "./Abstract/styles";

export type { InputProps } from "./Input";
export type { InputCurrencyProps } from "./Currency";
export type { InputPasswordProps } from "./Password";
export type { InputSearchProps } from "./Search";
export type { InputTextAreaProps } from "./Textarea";

type CompoundedComponent = typeof BaseInput & {
  Currency: typeof Currency;
  Password: typeof Password;
  Search: typeof Search;
  TextArea: typeof TextArea;
};

const Input = BaseInput as CompoundedComponent;
Input.Currency = Currency;
Input.Password = Password;
Input.Search = Search;
Input.TextArea = TextArea;

export default Input;
