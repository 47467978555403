import type { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type SiderProps = HTMLAttributes<HTMLElement> & {
  $placement?: "left" | "right";
};
const Sider: FC<SiderProps> = ({ children, ...props }) => (
  <aside {...props}>{children}</aside>
);

export default styled(Sider)`
  ${S.siderCss}
`;
