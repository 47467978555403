import type { FC } from "react";
import { useTheme } from "styled-components";

const Illustration: FC = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={179}
      height={189}
      fill="none"
    >
      <path
        fill="#EAEAEA"
        d="M162.46 76.542c0 20.277-8.282 38.555-21.563 51.693-12.994 12.995-30.987 20.849-50.836 20.849-19.707 0-37.7-7.997-50.837-20.849-13.28-13.138-21.563-31.416-21.563-51.693C17.661 36.415 50.077 4 90.061 4c39.984 0 72.399 32.558 72.399 72.542Z"
      />
      <g filter="url(#a)">
        <path
          fill="#fff"
          d="M154.775 34.963v79.117a5.87 5.87 0 0 1-5.867 5.902H29.735c-3.232 0-5.91-2.652-5.91-5.902V34.963a5.905 5.905 0 0 1 5.91-5.902h119.173c3.231 0 5.867 2.651 5.867 5.902Z"
        />
        <path
          fill={theme.colors.surface.primary.importantSubdued}
          d="M154.775 34.963v8.852H23.825v-8.852a5.905 5.905 0 0 1 5.91-5.902h119.173c3.231 0 5.867 2.651 5.867 5.902Z"
        />
        <path
          fill="#AAB2C5"
          d="M32.073 38.64c1.221 0 2.211-.995 2.211-2.223 0-1.229-.99-2.224-2.21-2.224-1.222 0-2.212.995-2.212 2.224 0 1.228.99 2.223 2.211 2.223ZM39.3 38.64c1.222 0 2.212-.995 2.212-2.223 0-1.229-.99-2.224-2.211-2.224-1.221 0-2.21.995-2.21 2.224 0 1.228.989 2.223 2.21 2.223ZM46.487 38.64c1.22 0 2.21-.995 2.21-2.223 0-1.229-.99-2.224-2.21-2.224-1.221 0-2.211.995-2.211 2.224 0 1.228.99 2.223 2.21 2.223Z"
        />
      </g>
      <path
        stroke="#D9D9D9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2.856}
        d="M51.163 97.7h76.274M63.195 105.869h52.21"
      />
      <path
        fill="#8A8A8A"
        d="M76.545 74.692v4.405h-2.678v4.234H68.85v-4.234H57.795v-5.046l10.247-14.327h5.867v15.011h2.636v-.043Zm-7.695-9.109-6.378 9.11h6.378v-9.11ZM89.13 83.802c-5.357 0-10.076-4.705-10.076-12.317S83.73 59.168 89.13 59.168s10.076 4.705 10.076 12.317S94.53 83.802 89.13 83.802Zm0-4.534c2.508 0 5.017-2.523 5.017-7.74 0-5.218-2.509-7.741-5.017-7.741-2.508 0-5.017 2.523-5.017 7.74 0 5.218 2.508 7.741 5.017 7.741ZM120.847 74.692v4.405h-2.678v4.234h-5.017v-4.234h-11.055v-5.046l10.247-14.327h5.867v15.011h2.636v-.043Zm-7.738-9.109-6.377 9.11h6.377v-9.11Z"
      />
      <g
        fill={theme.colors.surface.primary.important}
        fillRule="evenodd"
        clipRule="evenodd"
        filter="url(#b)"
      >
        <path d="M142.894 162.173c4.409.832 8.872-1.048 11.269-4.747 2.396-3.7 2.241-8.472-.391-12.048l-23.177-31.693c-3.549-4.839-10.41-5.969-15.323-2.523a10.592 10.592 0 0 0-2.469 15.002l23.267 31.629a11.001 11.001 0 0 0 6.824 4.38Z" />
        <path d="M46.237 104.159c-14.897-20.652-12.925-48.66 4.71-66.903 17.636-18.244 46.027-21.645 67.819-8.124 21.792 13.52 30.918 40.2 21.8 63.728-9.117 23.529-33.941 37.358-59.296 33.035-14.124-2.417-26.726-10.236-35.032-21.736ZM64.29 38.544C44.742 52.296 40.352 79.06 54.482 98.338c14.13 19.276 41.432 23.771 60.995 10.041 19.563-13.73 23.984-40.49 9.878-59.782-14.156-19.285-41.477-23.783-61.066-10.053Z" />
      </g>
      <defs>
        <filter
          id="a"
          width={176.646}
          height={136.617}
          x={0.977}
          y={9.069}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2.856} />
          <feGaussianBlur stdDeviation={11.424} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2232_871"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2.856} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend
            in2="effect1_dropShadow_2232_871"
            result="effect2_dropShadow_2232_871"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2232_871"
            result="shape"
          />
        </filter>
        <filter
          id="b"
          width={165.29}
          height={187.172}
          x={13.416}
          y={0.9}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={2.856} />
          <feGaussianBlur stdDeviation={11.424} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2232_871"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2.856} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend
            in2="effect1_dropShadow_2232_871"
            result="effect2_dropShadow_2232_871"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2232_871"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Illustration;
