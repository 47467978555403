import type { PropsWithChildren, ReactElement, ReactNode } from "react";
import styled from "styled-components";
import List from "../../../atoms/List";
import LoadingTable from "../LoadingTable";
import * as S from "./styles";

export type MobileTableProps<D extends object> = {
  data: D[];
  className?: string;
  loading?: boolean;
  renderItem: (data: D) => ReactNode;
  onClick?: (data: D) => void;
};

const InnerMobileTable = <D extends object>({
  className,
  data,
  renderItem,
  onClick,
  loading,
}: MobileTableProps<D>): ReactElement => (
  <div className={className}>
    <LoadingTable loading={loading} $isList />

    <S.TableList $isLoading={loading}>
      {data.map((d) => (
        <List.Item onClick={() => onClick?.(d)}>{renderItem(d)}</List.Item>
      ))}
    </S.TableList>
  </div>
);

const MobileTable: (<D extends object>(
  props: PropsWithChildren<MobileTableProps<D>>
) => ReactElement) &
  string = styled(InnerMobileTable)`
  ${S.InnerMobileTableCss}
`;

export default MobileTable;
