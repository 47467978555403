import type { ReactNode } from "react";
import { forwardRef } from "react";
import styled from "styled-components";
import type { TabBadgeWrapperProps } from "../TabBadgeWrapper";
import TabBadgeWrapper from "../TabBadgeWrapper";
import * as S from "./styles";

export type TabProps = {
  active: boolean;
  badge?: TabBadgeWrapperProps["badge"];
  className?: string;
  children?: ReactNode;
  onClick: (() => void) | undefined;
};
const Tab = forwardRef<HTMLDivElement, TabProps>(
  ({ active, badge, className, children, onClick }, ref) => (
    <S.DivTabContainer
      ref={ref}
      className={className}
      $active={active}
      onClick={onClick}
    >
      <S.TextContainer>
        <TabBadgeWrapper active={active} badge={badge}>
          {children}
        </TabBadgeWrapper>
      </S.TextContainer>
      <S.DivActiveBar />
    </S.DivTabContainer>
  )
);

export default styled(Tab)``;
