import type { BaseTheme, DefaultTheme } from "styled-components";
import {
  generateBreakpoint,
  generateTheme,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "./utils";

// eslint-disable-next-line no-shadow
export enum MotionName {
  FADE = "fade",
  SLIDE_UP = "slideUp",
}

export const baseTheme: BaseTheme = {
  borderRadius: {
    badge: "100px",
    card: "8px",
    default: "4px",
    onboardingGuide: "8px",
    switch: "12px",
    toast: "8px",
  },
  breakpoints: {
    mobile: generateBreakpoint(MOBILE_BREAKPOINT),
    tablet: generateBreakpoint(TABLET_BREAKPOINT),
    viewContentSettings: generateBreakpoint(1180),
    viewContentTicket: generateBreakpoint(1149),
    editableElementsSelector: generateBreakpoint(420),
  },
  components: {
    skeleton: {
      animation: "wave",
    },
  },
  motionName: MotionName,
  zIndex: {
    calendar: 1050,
    colorPicker: 900,
    drawer: 1000,
    header: 998,
    modal: 1000,
    popover: 1000,
    select: 1000,
    shadow: 999,
    tooltip: 1027,
  },
};

export const lightTheme: DefaultTheme = {
  ...baseTheme,
  colors: generateTheme({
    main: {
      black: "#8A8A8A",
      blue: "#0173E7",
      primary: "#0173E7",
      yellow: "#D0AF00",
      orange: "#ED9809",
      red: "#DA1010",
      green: "#00AF70",
      violet: "#960BD6",
    },
  }),
};
