import type { PropsWithChildren, ReactElement, ReactNode } from "react";
import { useState } from "react";
import type {
  TableProps as RTTableProps,
  TableBodyProps,
  Row,
  HeaderGroup,
  UseTableInstanceProps,
} from "react-table";
import styled from "styled-components";
import List from "../../../atoms/List";
import { useDesign } from "../../../setup/context";
import type { BodyRowProps } from "../BodyRow";
import BodyRow from "../BodyRow";
import LoadingTable from "../LoadingTable";
import * as S from "./styles";

export type TableProps<D extends object> = {
  headerGroups?: HeaderGroup<D>[];
  tableProps: RTTableProps;
  tableBodyProps?: TableBodyProps;
  rows?: Row<D>[];
  prepareRow?: UseTableInstanceProps<D>["prepareRow"];
  children?: ReactNode;
  className?: string;
  loading?: boolean;
  mobileList?: ReactNode;
  addBorderTop?: boolean;
  onRowClick?: BodyRowProps<D>["onClick"];
};

const InnerTable = <D extends object>({
  headerGroups,
  tableProps,
  tableBodyProps,
  rows,
  prepareRow,
  children,
  className,
  loading,
  mobileList,
  addBorderTop,
  onRowClick,
}: TableProps<D>): ReactElement => {
  const {
    device: { isMobile },
  } = useDesign();

  if (isMobile && !!mobileList) return <List>{mobileList}</List>;

  const [showShadow, setShowShadow] = useState(false);

  return (
    <div className={className}>
      <LoadingTable loading={loading} $isList={false} />
      <S.InnerTable
        onScroll={({ currentTarget }) =>
          setShowShadow(currentTarget.scrollLeft !== 0)
        }
        {...tableProps}
        $isLoading={loading}
        className={showShadow ? "sticky" : undefined}
      >
        <S.TableHead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </S.TableHead>
        <S.TableBody
          $hasHeaderGroup={!!headerGroups}
          $addBorderTop={addBorderTop}
          {...tableBodyProps}
        >
          {children ||
            rows?.map((row) => {
              prepareRow?.(row);
              return <BodyRow key={row.id} row={row} onClick={onRowClick} />;
            })}
        </S.TableBody>
      </S.InnerTable>
    </div>
  );
};

const DesktopTable: (<D extends object>(
  props: PropsWithChildren<TableProps<D>>
) => ReactElement) &
  string = styled(InnerTable)`
  ${S.tableCss}
`;

export default DesktopTable;
