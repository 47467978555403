import type { FC, ReactNode } from "react";
import type { IconType } from "react-icons";
import styled from "styled-components";
import Badge from "../../../atoms/Badge";
import Tooltip from "../../../atoms/Tooltip";
import * as S from "./styles";

export type OptionProps = {
  active: boolean;
  badge?: { value: number; limit?: number };
  className?: string;
  children: ReactNode;
  disabled: boolean;
  tooltip?: ReactNode;
  icon?: IconType;
  onClick?: () => void;
  onClickDisabled?: () => void;
};
const Option: FC<OptionProps> = ({
  active,
  badge,
  className,
  children,
  disabled,
  tooltip,
  icon: PropIcon,
  onClick,
  onClickDisabled,
}) => {
  const content = (
    <S.OptionContainerRow
      className={className}
      onClick={!disabled ? onClick : onClickDisabled}
      $active={active}
      $disabled={disabled}
    >
      {PropIcon && <S.OptionIcon as={PropIcon} size={20} />}
      <S.OptionContent>{children}</S.OptionContent>
      {badge !== undefined && (
        <Badge
          $type={active ? "informational" : "neutral"}
          text={
            badge.limit && badge.value > badge.limit
              ? `${badge.limit}+`
              : badge.value
          }
        />
      )}
    </S.OptionContainerRow>
  );

  if (!tooltip) return content;

  return (
    <Tooltip title={tooltip} placement="top" block $showArrow>
      {content}
    </Tooltip>
  );
};

export default styled(Option)``;
