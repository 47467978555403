import type { LinkProps } from "next/link";
import Link from "next/link";
import type { HTMLAttributes, ReactElement, ReactNode } from "react";
import type { IconType } from "react-icons";
import styled from "styled-components";
import Typography from "../../../deprecated/Typography";
import Icon from "../../Icon";
import * as S from "./styles";

const { Text } = Typography;

export type MenuItemProps<KeyType> = HTMLAttributes<HTMLLIElement> & {
  $keyId?: KeyType;
  $activeKey?: KeyType | null;
  icon?: IconType;
  iconRight?: IconType;
  linkHref?: LinkProps["href"];
  disabled?: boolean;
  children: ReactNode;
};
const MenuItem = <KeyType,>({
  icon: PropIcon,
  iconRight: PropIconRight,
  linkHref,
  disabled,
  children,
  ...props
}: MenuItemProps<KeyType>): ReactElement => {
  const menuItemContent = (
    <li role="none" {...props}>
      {PropIcon && <Icon icon={<PropIcon size={20} />} />}
      <Text.Body>{children}</Text.Body>
      {PropIconRight && <Icon icon={<PropIconRight size={20} />} />}
    </li>
  );

  if (!linkHref || disabled) return menuItemContent;
  return <Link href={linkHref}>{menuItemContent}</Link>;
};

export default styled(MenuItem)`
  ${S.menuItemCss}
`;
