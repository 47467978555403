import type { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import Divider from "../../Divider";
import * as S from "./styles";

export type BaseCardProps = HTMLAttributes<HTMLDivElement> & {
  block?: boolean;
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  hideHeaderDivider?: boolean;
  hideFooterDivider?: boolean;
};
export const BaseCard: FC<BaseCardProps> = ({
  block,
  className,
  children,
  footer,
  header,
  hideHeaderDivider,
  hideFooterDivider,
  ...props
}) => (
  <S.BaseCardContainerDiv className={className} $block={!!block} {...props}>
    {header && [
      header,
      !hideHeaderDivider && (
        <Divider key="base-card-header-divider" sectionDivider />
      ),
    ]}
    {children}
    {footer && [
      !hideFooterDivider && (
        <Divider key="base-card-footer-divider" sectionDivider />
      ),
      footer,
    ]}
  </S.BaseCardContainerDiv>
);

export default styled(BaseCard)`
  ${S.baseCardCss}
`;
