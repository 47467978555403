import styled, { css } from "styled-components";
import Spinner from "../../atoms/Spinner";
import Typography from "../../deprecated/Typography";
import type { SelectProps } from "./index";

export const selectClass = "rc-select";

export const Container = styled.div``;
export const LoadingIcon = styled(Spinner)`
  color: ${({ theme }) => theme.colors.icon.primary};
`;

type Props = { $labelRequired?: boolean };
export const Label = styled.label<Props>`
  display: flex;
  -webkit-touch-callout: none;
  user-select: none;
  ${Typography} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
  }
  ${({ $labelRequired }) =>
    $labelRequired &&
    css`
      &::after {
        content: "*";
        margin-left: 2px;
        color: ${({ theme }) => theme.colors.text.critical};
      }
    `}
`;

export const selectCss = css<SelectProps>`
  &&&.${selectClass} {
    display: inline-block;
    width: 100%;
    position: relative;
    cursor: pointer;
    .${selectClass}-selection-search-input {
      cursor: pointer;
      background-color: transparent;
    }
    &-disabled {
      &,
      & input {
        cursor: not-allowed;
      }
    }

    .${selectClass}-selection-placeholder {
      opacity: 0.4;
      pointer-events: none;
    }
    .${selectClass}-selection-search-input {
      appearance: none;
      &::-webkit-search-cancel-button {
        display: none;
        appearance: none;
      }
    }

    // --------------- Single ----------------
    &-single {
      .${selectClass}-selector {
        display: flex;
        position: relative;
        border: 1px solid ${({ theme }) => theme.colors.border.neutral.subdued};
        .${selectClass}-selection-search {
          width: 100%;
          &-input {
            width: 100%;
          }
        }
        .${selectClass}-selection-item, .${selectClass}-selection-placeholder {
          position: absolute;
          pointer-events: none;
        }
      }

      &:not(.${selectClass}-customize-input) {
        .${selectClass}-selector {
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: center;
          padding: 8px 12px;
          color: ${({ theme }) => theme.colors.text.default};
          background: ${({ theme }) => theme.colors.surface.default.default};
          border-radius: ${({ theme }) => theme.borderRadius.default};
          transition: border-color 0.2s ease-in-out;
          .${selectClass}-selection-search-input {
            border: none;
            outline: none;
            width: 100%;
          }
          :hover {
            border-color: ${({ theme }) => theme.colors.border.primary.default};
          }
          ${({ hasError }) =>
            hasError &&
            css`
              border-color: ${({ theme }) =>
                theme.colors.border.critical.default};
              :hover {
                border-color: ${({ theme }) =>
                  theme.colors.border.critical.default};
              }
            `}

          ${({ disabled }) =>
            disabled &&
            css`
              color: ${({ theme }) => theme.colors.text.disabled};
              border-color: ${({ theme }) =>
                theme.colors.border.default.disabled};
              background-color: ${({ theme }) =>
                theme.colors.surface.default.disabled};
              :hover {
                border-color: ${({ theme }) =>
                  theme.colors.border.default.disabled};
              }
              input {
                cursor: not-allowed;
              }
            `}
        }
      }
    }

    // -------------- Multiple ---------------
    &-multiple .${selectClass}-selector {
      display: flex;
      flex-wrap: wrap;
      padding: 1px;
      border-color: ${({ theme }) => theme.colors.border.neutral.subdued};
      .${selectClass}-selection-item {
        flex: none;
        background: #bbb;
        border-radius: ${({ theme }) => theme.borderRadius.default};
        margin-right: 2px;
        padding: 0 8px;
        &-disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      .${selectClass}-selection-overflow {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &-item {
          flex: none;
          max-width: 100%;
        }
      }

      .${selectClass}-selection-search {
        position: relative;
        max-width: 100%;
        &-input,
        &-mirror {
          padding: 1px;
        }
        &-mirror {
          position: absolute;
          z-index: 999;
          white-space: nowrap;
          position: none;
          left: 0;
          top: 0;
          visibility: hidden;
        }
      }
      .${selectClass}-selection-search-input {
        border: none;
        outline: none;
        width: 100%;
      }
    }
    &-show-arrow {
      &.${selectClass}-multiple .${selectClass}-selector {
        padding-right: 20px;
      }

      .${selectClass}-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 11px;
        pointer-events: none;
        color: ${({ theme, disabled }) =>
          theme.colors.icon[disabled ? "disabled" : "default"]};
        transition: all 0.3s ease-in-out;
      }
      &.${selectClass}-open .${selectClass}-arrow {
        transform: translateY(-50%) rotate(-180deg);
      }
      ${({ disabled }) =>
        disabled &&
        css`
          ${LoadingIcon} {
            color: ${({ theme }) => theme.colors.icon.disabled};
          }
        `}
    }
    &-focused {
      .${selectClass}-selector {
        border-color: ${({ theme }) => theme.colors.border.primary.default};
      }
    }
  }
`;

export const selectDropdownCss = css`
  &&&.${selectClass} {
    // ============== Dropdown ===============
    &-dropdown {
      position: absolute;
      z-index: ${({ theme }) => theme.zIndex.select};
      width: 100%;
      overflow: hidden;
      background-color: ${({ theme }) => theme.colors.surface.default.default};
      border: 1px solid ${({ theme }) => theme.colors.border.neutral.subdued};
      border-radius: ${({ theme }) => theme.borderRadius.default};
      &-hidden {
        display: none;
      }
    }
    // =============== Option ================
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 12px;
      &-group {
        color: ${({ theme }) => theme.colors.text.disabled};
        font-weight: bold;
        font-size: 80%;
      }
      &-option {
        cursor: pointer;
        position: relative;
        transition: background-color 0.3s ease-in-out;
        &-grouped {
          padding-left: 24px;
        }
        .${selectClass}-item-option-state {
          display: none;
        }
        &-active {
          background-color: ${({ theme }) =>
            theme.colors.surface.default.hovered};
        }
        &-disabled {
          color: ${({ theme }) => theme.colors.text.disabled};
        }
      }
      &-empty {
        cursor: default;
        padding: 8px 12px;
        color: ${({ theme }) => theme.colors.text.disabled};
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }
  }
`;
