import composeRefs from "@seznam/compose-react-refs";
import type {
  ForwardedRef,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
} from "react";
import { forwardRef, useContext, useRef } from "react";
import styled from "styled-components";
import { v4 } from "uuid";
import Typography from "../../../deprecated/Typography";
import type { RadioGroupContextValues } from "../context";
import RadioGroupContext from "../context";
import * as S from "./styles";

const { Text } = Typography;

type InputRadioProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "value" | "title" | "children"
>;
export type RadioProps<ValueType> = Omit<InputRadioProps, "onChange"> & {
  value: ValueType;
  subLabel?: ReactNode;
  onChange?: (e: ValueType) => void;
  children: ReactNode;
};

const RadioInner = <ValueType,>(
  props: RadioProps<ValueType>,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement => {
  const innerRef = useRef<HTMLInputElement>(null);
  const context = useContext<RadioGroupContextValues<ValueType> | undefined>(
    RadioGroupContext
  );

  const {
    className,
    children,
    value,
    subLabel,
    id,
    onChange: propsOnChange,
    ...restProps
  } = props;

  const _id = id !== undefined ? id : v4();

  const onChange = () => {
    propsOnChange?.(value);
    context?.onChange(value);
  };
  const radioProps: InputRadioProps = { ...restProps };
  if (context) {
    radioProps.name = context.name;
    // eslint-disable-next-line react/destructuring-assignment
    radioProps.checked = props.value === context.value;
    // eslint-disable-next-line react/destructuring-assignment
    radioProps.disabled = props.disabled || context.disabled;
  }

  return (
    <div className={className}>
      <span role="none" onClick={() => innerRef?.current?.click()}>
        <input
          ref={composeRefs(ref, innerRef)}
          type="radio"
          onChange={onChange}
          id={_id}
          {...radioProps}
        />
        <S.RadioInner />
      </span>
      <S.LabelContainer>
        <label htmlFor={_id}>
          <S.Label
            wrapper="p"
            $color={radioProps.disabled ? "disabled" : "default"}
            $cursor={radioProps.disabled ? "not-allowed" : "pointer"}
          >
            {children}
          </S.Label>
        </label>
        {subLabel && (
          <Text.Body
            wrapper="p"
            $color={radioProps.disabled ? "disabled" : "subdued"}
            $cursor={radioProps.disabled ? "not-allowed" : "default"}
          >
            {subLabel}
          </Text.Body>
        )}
      </S.LabelContainer>
    </div>
  );
};

const Radio: (<ValueType = unknown>(
  props: PropsWithChildren<RadioProps<ValueType>> & {
    ref?: Ref<HTMLInputElement>;
  }
) => ReactElement) &
  string = styled(forwardRef(RadioInner))`
  ${S.radioCss}
`;

export default Radio;
