import type { FC } from "react";
import type { TypographyProps } from "../Typography";
import Typography from "../Typography";

export type TitleProps = Omit<TypographyProps, "$type" | "wrapper"> & {
  wrapper?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  $type?:
    | "displayXL"
    | "displayL"
    | "displayM"
    | "displayS"
    | "heading"
    | "subheading";
};
export const Title: FC<TitleProps> = ({
  wrapper = "h1",
  $type = "displayXL",
  children,
  ...props
}) => (
  <Typography wrapper={wrapper} $type={$type} {...props}>
    {children}
  </Typography>
);

export default Title;
