import type { ReactElement } from "react";
import { useContext } from "react";
import type { ControllerRenderProps, Path } from "react-hook-form";
import { AddressContext } from "./context";
import * as S from "./styles";
import type { BaseFormValues } from "./typing";
import { Input, Select } from "design";
import {
  AddressEditableField,
  AddressField,
} from "graphql/generated/schema.platform";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

export const Regions = <FormValues extends BaseFormValues>({
  label,
  hasError,
  fieldSelect,
  selectOnChange,
}: {
  label: string;
  fieldSelect: ControllerRenderProps<FormValues, Path<FormValues>>;
  selectOnChange: (value: string) => void;
  hasError?: boolean;
  labelRequired?: boolean;
}): ReactElement => {
  const {
    loadingLocalities,
    country: { regionName },
    regions,
    editableFields,
  } = useContext(AddressContext);
  const { t } = useTranslation("common");
  return (
    <Select<string>
      label={label}
      loading={loadingLocalities}
      disabled={
        loadingLocalities ||
        (editableFields && !editableFields.includes(fieldSelect.name))
      }
      placeholder={t("components.address_form.field_select", {
        fieldName: regionName,
      })}
      showSearch
      hasError={hasError}
      optionFilterProp="label"
      options={
        regions?.map(({ id: instanceId, name }) => ({
          label: name,
          value: instanceId,
        })) || []
      }
      {...fieldSelect}
      onChange={(v) => {
        selectOnChange(v);
        return fieldSelect.onChange(v);
      }}
      value={fieldSelect.value || undefined}
    />
  );
};

export const Cities = <FormValues extends BaseFormValues>({
  label,
  hasError,
  fieldSelect,
  selectOnChange,
}: {
  label: string;
  fieldSelect: ControllerRenderProps<FormValues, Path<FormValues>>;
  selectOnChange: (value: string) => void;
  hasError?: boolean;
  labelRequired?: boolean;
}): ReactElement => {
  const {
    loadingLocalities,
    country: { cityName, editableFields: countryEditableFields, regionName },
    cities,
    editableFields,
  } = useContext(AddressContext);
  const { t } = useTranslation("common");
  if (
    countryEditableFields.includes(AddressEditableField.CITY) &&
    !countryEditableFields.includes(AddressEditableField.COUNTY)
  ) {
    return (
      <S.LocationAutoComplete
        {...fieldSelect}
        value={fieldSelect.value || ""}
        options={
          cities?.map(({ name }) => ({
            label: name,
            value: name,
          })) || []
        }
      >
        <Input
          label={label}
          hasError={hasError}
          disabled={
            editableFields && !editableFields.includes(fieldSelect.name)
          }
        />
      </S.LocationAutoComplete>
    );
  }
  return (
    <Select<string>
      label={label}
      hasError={hasError}
      loading={loadingLocalities}
      disabled={
        loadingLocalities ||
        cities.length === 0 ||
        (editableFields && !editableFields.includes(fieldSelect.name))
      }
      placeholder={
        cities.length === 0
          ? t("components.address_form.locality.select_first", {
              fieldName: regionName,
            })
          : t("components.address_form.field_select", { fieldName: cityName })
      }
      showSearch
      optionFilterProp="label"
      options={
        cities?.map(({ id: instanceId, name }) => ({
          label: name,
          value: instanceId,
        })) || []
      }
      {...fieldSelect}
      onChange={(v) => {
        selectOnChange(v);
        return fieldSelect.onChange(v);
      }}
      value={fieldSelect.value || undefined}
    />
  );
};

export const Counties = <FormValues extends BaseFormValues>({
  label,
  hasError,
  fieldSelect,
}: {
  label: string;
  fieldSelect: ControllerRenderProps<FormValues, Path<FormValues>>;
  hasError?: boolean;
  labelRequired?: boolean;
}): ReactElement => {
  const {
    loadingLocalities,
    country: {
      cityName,
      countyName,
      editableFields: countryEditableFields,
      hiddenFields,
      regionName,
    },
    counties,
    editableFields,
  } = useContext(AddressContext);
  const { t } = useTranslation("common");
  if (countryEditableFields.includes(AddressEditableField.COUNTY)) {
    return (
      <S.LocationAutoComplete
        {...fieldSelect}
        value={fieldSelect.value || undefined}
        options={
          counties?.map(({ name }) => ({
            label: name,
            value: name,
          })) || []
        }
      >
        <Input label={label} hasError={hasError} />
      </S.LocationAutoComplete>
    );
  }
  const showCity = !hiddenFields?.includes(AddressField.CITY);
  return (
    <Select<string>
      label={label}
      hasError={hasError}
      loading={loadingLocalities}
      disabled={
        loadingLocalities ||
        counties.length === 0 ||
        (editableFields && !editableFields.includes(fieldSelect.name))
      }
      placeholder={
        counties.length === 0
          ? t("components.address_form.locality.select_first", {
              fieldName: showCity ? cityName : regionName,
            })
          : t("components.address_form.field_select", {
              fieldName: countyName,
            })
      }
      showSearch
      optionFilterProp="label"
      options={
        counties?.map(({ id: instanceId, name }) => ({
          label: name,
          value: instanceId,
        })) || []
      }
      {...fieldSelect}
      value={fieldSelect.value || undefined}
    />
  );
};
