import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";
import type { TypographyProps } from "./Typography";

const handleType: Record<
  TypographyProps["$type"],
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  displayXL: css`
    font-size: 42px;
    font-weight: 600;
    line-height: 44px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;
    }
  `,
  displayL: css`
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
    }
  `,
  displayM: css`
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  `,
  displayS: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  `,
  heading: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  `,
  subheading: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  `,
  button: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
  body: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  caption: css`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  `,
};

export const typographyCss = css<TypographyProps>`
  transition: all 0.2s ease-in-out;
  ${({ $type }) => handleType[$type || "body"]};
  color: ${({ $color, theme }) =>
    $color ? theme.colors.text[$color] : theme.colors.text.default};
  font-weight: ${({ $weight }) => $weight};
  text-decoration: ${({ $textDecoration }) => $textDecoration};
  white-space: pre-line;
  ${({ $align }) =>
    $align &&
    $align !== "left" &&
    css`
      width: 100%;
      text-align: ${$align};
    `};
`;
