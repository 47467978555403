import type { ButtonHTMLAttributes, ReactElement, ReactNode } from "react";
import { forwardRef } from "react";
import { HiChevronDown } from "react-icons/hi";
import styled from "styled-components";
import Icon from "../../atoms/Icon";
import Spinner from "../../atoms/Spinner";
import * as S from "./styles";

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "type" | "color"
> & {
  $size?: "small" | "medium" | "large";
  type?:
    | "basic"
    | "disabled"
    | "disabled-basic"
    | "primary"
    | "critical"
    | "ghost"
    | "ghost-primary"
    | "outline"
    | "outline-critical"
    | "plain"
    | "plain-primary"
    | "plain-critical"
    | "plain-inverted";
  htmlType?: "submit" | "button" | "reset";
  loading?: boolean;
  iconLoading?: ReactNode;
  icon?: ReactNode;
  iconAnimated?: boolean;
  rightIcon?:
    | {
        icon: ReactNode;
        animated: boolean;
        asDropdown?: false;
      }
    | {
        icon?: ReactNode;
        animated?: boolean;
        asDropdown: true;
      }
    | ReactElement;
  $block?: boolean;
  $dashed?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      htmlType = "button",
      type = "basic",
      loading,
      iconLoading,
      icon,
      iconAnimated,
      rightIcon,
      disabled,
      children,
      ...props
    },
    ref
  ) => {
    const isPlainType = type?.includes("plain");
    return (
      <button
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={htmlType}
        disabled={loading || disabled}
        {...props}
      >
        <S.ButtonContent
          $color={disabled ? "disabled" : undefined}
          $weight={isPlainType ? 400 : undefined}
        >
          {icon && <Icon icon={icon} $animated={iconAnimated} />}
          {children}
          {rightIcon &&
            // the inverse of this is React.isValidElement(rightIcon), but it's broken since ts 5.0
            ("icon" in rightIcon || "asDropdown" in rightIcon ? (
              <S.RightIcon
                icon={rightIcon?.icon || <HiChevronDown />}
                $animated={rightIcon?.animated}
              />
            ) : (
              rightIcon
            ))}
        </S.ButtonContent>
        {loading && <Spinner iconLoading={iconLoading} />}
      </button>
    );
  }
);

export default styled(Button)`
  ${S.buttonCss}
`;
