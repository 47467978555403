import htmlParse from "html-react-parser";
import type { FC } from "react";
import { useEffect, useState } from "react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { useTheme } from "styled-components";
import * as S from "./styles";
import { useShop } from "contexts";
import { Modal } from "design";
import { removeActiveFocus } from "utils";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

const HelpStartModal: FC = () => {
  const { t } = useTranslation("start");
  const [visible, setVisible] = useState(false);
  const { orderExampleText, orderExampleImage } = useShop();
  const theme = useTheme();

  useEffect(() => {
    if (visible) removeActiveFocus();
  }, [visible]);

  const handleOnClick = () => setVisible(false);
  return (
    <>
      <Modal
        visible={visible}
        title={t("help_modal.title")}
        onClose={handleOnClick}
        onOk={{
          children: t("help_modal.button"),
          onClick: handleOnClick,
          type: "basic",
        }}
        onCancel={false}
        closable
      >
        {htmlParse(orderExampleText)}
        {orderExampleImage && (
          <S.ModalImage
            src={orderExampleImage}
            alt={t("help_modal.image_alt")}
          />
        )}
      </Modal>
      <HiQuestionMarkCircle
        size={20}
        cursor="pointer"
        color={theme.colors.icon.default}
        onClick={() => setVisible(true)}
      />
    </>
  );
};

export default HelpStartModal;
