/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import { AddressCountryFragmentDoc, AddressZipcodeFragmentDoc } from './fragments.platform.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddressCountryQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type AddressCountryQuery = { __typename?: 'QueryRoot', country: { __typename?: 'Country', id: string, address2Name: string, alpha2: Types.CountryAlpha2, cityName: string, countyName: string, editableFields: Array<Types.AddressEditableField>, hiddenFields: Array<Types.AddressField>, regionName: string, requiredFields: Array<Types.AddressField>, zipcodeBehaviors: Array<Types.CountryZipcodeBehaviour> } };

export type AddressLocalitiesMountQueryVariables = Types.Exact<{
  countryId: Types.Scalars['ID'];
  includeLocalities: Types.Scalars['Boolean'];
  includeCitiesByRegion: Types.Scalars['Boolean'];
  includeCountiesByCity: Types.Scalars['Boolean'];
  includeCountiesByRegion: Types.Scalars['Boolean'];
  zipcode: Types.Scalars['String'];
  includeZipcodes: Types.Scalars['Boolean'];
}>;


export type AddressLocalitiesMountQuery = { __typename?: 'QueryRoot', country?: { __typename?: 'Country', id: string, regions: Array<{ __typename?: 'Region', id: string, name: string, cities?: Array<{ __typename?: 'City', id: string, name: string, counties?: Array<{ __typename?: 'County', id: string, name: string }> }>, counties?: Array<{ __typename?: 'County', id: string, name: string }> }> }, zipcodes?: Array<{ __typename?: 'Zipcode', id: string, countyName: string | null, cityName: string | null, region: { __typename?: 'Region', id: string, name: string } }> };

export type AddressZipcodesQueryVariables = Types.Exact<{
  countryId: Types.Scalars['ID'];
  zipcode: Types.Scalars['String'];
}>;


export type AddressZipcodesQuery = { __typename?: 'QueryRoot', zipcodes: Array<{ __typename?: 'Zipcode', id: string, countyName: string | null, cityName: string | null, region: { __typename?: 'Region', id: string, name: string } }> };


export const AddressCountryDocument = gql`
    query AddressCountry($id: ID!) {
  country(id: $id) {
    ...AddressCountry
  }
}
    ${AddressCountryFragmentDoc}`;

/**
 * __useAddressCountryQuery__
 *
 * To run a query within a React component, call `useAddressCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressCountryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddressCountryQuery(baseOptions: Apollo.QueryHookOptions<AddressCountryQuery, AddressCountryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressCountryQuery, AddressCountryQueryVariables>(AddressCountryDocument, options);
      }
export function useAddressCountryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressCountryQuery, AddressCountryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressCountryQuery, AddressCountryQueryVariables>(AddressCountryDocument, options);
        }
export type AddressCountryQueryHookResult = ReturnType<typeof useAddressCountryQuery>;
export type AddressCountryLazyQueryHookResult = ReturnType<typeof useAddressCountryLazyQuery>;
export type AddressCountryQueryResult = Apollo.QueryResult<AddressCountryQuery, AddressCountryQueryVariables>;
export const AddressLocalitiesMountDocument = gql`
    query AddressLocalitiesMount($countryId: ID!, $includeLocalities: Boolean!, $includeCitiesByRegion: Boolean!, $includeCountiesByCity: Boolean!, $includeCountiesByRegion: Boolean!, $zipcode: String!, $includeZipcodes: Boolean!) {
  country(id: $countryId) @include(if: $includeLocalities) {
    id
    regions {
      id
      name
      cities @include(if: $includeCitiesByRegion) {
        id
        name
        counties @include(if: $includeCountiesByCity) {
          id
          name
        }
      }
      counties @include(if: $includeCountiesByRegion) {
        id
        name
      }
    }
  }
  zipcodes(countryId: $countryId, code: $zipcode) @include(if: $includeZipcodes) {
    ...AddressZipcode
  }
}
    ${AddressZipcodeFragmentDoc}`;

/**
 * __useAddressLocalitiesMountQuery__
 *
 * To run a query within a React component, call `useAddressLocalitiesMountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressLocalitiesMountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressLocalitiesMountQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      includeLocalities: // value for 'includeLocalities'
 *      includeCitiesByRegion: // value for 'includeCitiesByRegion'
 *      includeCountiesByCity: // value for 'includeCountiesByCity'
 *      includeCountiesByRegion: // value for 'includeCountiesByRegion'
 *      zipcode: // value for 'zipcode'
 *      includeZipcodes: // value for 'includeZipcodes'
 *   },
 * });
 */
export function useAddressLocalitiesMountQuery(baseOptions: Apollo.QueryHookOptions<AddressLocalitiesMountQuery, AddressLocalitiesMountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressLocalitiesMountQuery, AddressLocalitiesMountQueryVariables>(AddressLocalitiesMountDocument, options);
      }
export function useAddressLocalitiesMountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressLocalitiesMountQuery, AddressLocalitiesMountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressLocalitiesMountQuery, AddressLocalitiesMountQueryVariables>(AddressLocalitiesMountDocument, options);
        }
export type AddressLocalitiesMountQueryHookResult = ReturnType<typeof useAddressLocalitiesMountQuery>;
export type AddressLocalitiesMountLazyQueryHookResult = ReturnType<typeof useAddressLocalitiesMountLazyQuery>;
export type AddressLocalitiesMountQueryResult = Apollo.QueryResult<AddressLocalitiesMountQuery, AddressLocalitiesMountQueryVariables>;
export const AddressZipcodesDocument = gql`
    query AddressZipcodes($countryId: ID!, $zipcode: String!) {
  zipcodes(countryId: $countryId, code: $zipcode) {
    ...AddressZipcode
  }
}
    ${AddressZipcodeFragmentDoc}`;

/**
 * __useAddressZipcodesQuery__
 *
 * To run a query within a React component, call `useAddressZipcodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressZipcodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressZipcodesQuery({
 *   variables: {
 *      countryId: // value for 'countryId'
 *      zipcode: // value for 'zipcode'
 *   },
 * });
 */
export function useAddressZipcodesQuery(baseOptions: Apollo.QueryHookOptions<AddressZipcodesQuery, AddressZipcodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressZipcodesQuery, AddressZipcodesQueryVariables>(AddressZipcodesDocument, options);
      }
export function useAddressZipcodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressZipcodesQuery, AddressZipcodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressZipcodesQuery, AddressZipcodesQueryVariables>(AddressZipcodesDocument, options);
        }
export type AddressZipcodesQueryHookResult = ReturnType<typeof useAddressZipcodesQuery>;
export type AddressZipcodesLazyQueryHookResult = ReturnType<typeof useAddressZipcodesLazyQuery>;
export type AddressZipcodesQueryResult = Apollo.QueryResult<AddressZipcodesQuery, AddressZipcodesQueryVariables>;