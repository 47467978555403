import styled from "styled-components";
import { Button } from "design";

export const DesktopBackButton = styled(Button)`
  margin-bottom: 20px;
`;

export type ContainerSectionProps = {
  $hideFooter: boolean;
  $showSteps: boolean;
};
export const ContainerSection = styled.section<ContainerSectionProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 0;
  width: 100%;
  max-height: 100%;
  padding-bottom: ${({ $hideFooter }) => ($hideFooter ? "20px" : "0")};

  @media ${({ theme }) => theme.breakpoints.tablet.css} {
    gap: ${({ $showSteps }) => ($showSteps ? "20px" : "0")};
  }
`;
