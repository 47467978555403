import styled, { css } from "styled-components";
import Menu from "../../../atoms/Menu";
import Row from "../../../atoms/Row";
import Tab from "../Tab";
import * as TabStyles from "../Tab/styles";

type MenuItemProps = {
  $active: boolean;
};
export const MenuItemTabs = styled(Menu.Item)<MenuItemProps>`
  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.colors.surface.default.hovered};
      :hover {
        background-color: ${({ theme }) =>
          theme.colors.surface.default.hovered};
      }
    `}
`;

export const RowHidden = styled(Row)`
  visibility: hidden;
  position: absolute;
`;

export const TabContainer = styled.div`
  display: flex;
  margin-right: auto;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

type MoreTabsProps = {
  $tabActive: boolean;
};
export const MoreTabs = styled(Tab)<MoreTabsProps>`
  ${({ $tabActive }) =>
    $tabActive &&
    css`
      ${TabStyles.TextContainer} {
        color: ${({ theme }) => theme.colors.text.default};
      }
      ${TabStyles.DivActiveBar} {
        background-color: ${({ theme }) => theme.colors.border.default.default};
      }
    `}
`;

export const tabsCss = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.default.subdued};
  flex-wrap: nowrap;
  flex-direction: row;
`;
