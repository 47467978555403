import styled, { css } from "styled-components";
import List from "../../../atoms/List";

type InnerTableListProps = {
  $isLoading?: boolean;
};

export const TableList = styled(List)<InnerTableListProps>`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      opacity: 0.3;
      background-color: ${({ theme }) => theme.colors.surface.default.default};
    `}
`;

export const InnerMobileTableCss = css`
  position: relative;
`;
