import styled, { css } from "styled-components";

export const treeClass = "rc-tree";

// Checkbox Styles -> Base style
export const InnerCheckbox = styled.div`
  position: relative;
  display: block;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right: 9px;
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  border: 2px solid ${({ theme }) => theme.colors.border.default.default};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  transition: background-color 0.3s;
  &::after {
    display: none;
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid ${({ theme }) => theme.colors.icon.white};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    transition: transform 0.1s ease-in-out;
    content: "";
  }
`;

export const treeCss = css`
  .${treeClass} {
    &-animation {
      transition: all 0.3s;
      overflow-y: hidden;
    }
    &-treenode {
      display: flex;
      align-items: center;
      height: 32px;
      &.${treeClass}-treenode-leaf-last:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &-title {
      font-size: 14px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.text.default};
      -webkit-touch-callout: none;
      user-select: none;
    }

    // Cursor depending on if the text performs an action or not
    &-switcher {
      & ~ .${treeClass}-node-content-wrapper .${treeClass}-title {
        cursor: pointer;
      }
    }
    &-checkbox {
      &-disabled {
        & ~ .${treeClass}-node-content-wrapper .${treeClass}-title {
          cursor: not-allowed;
        }
      }
    }
    &-indent-unit {
      display: inline-block;
      width: 24px;
    }
  }

  // Style of the first elements in the list (top)
  .${treeClass}-switcher-top {
    height: 40px;
    &:not(:first-child) {
      &.${treeClass}-treenode-switcher-close,
        &.${treeClass}-treenode-switcher-open {
        &.${treeClass}-treenode {
          border-top: 1px solid
            ${({ theme }) => theme.colors.border.default.subdued};
        }
      }
    }
  }

  // Checkbox Styles
  // -> Checkbox status
  .${treeClass}-checkbox {
    &-disabled {
      & ${InnerCheckbox} {
        background-color: ${({ theme }) =>
          theme.colors.surface.default.default};
        border-color: ${({ theme }) => theme.colors.border.default.disabled};
        cursor: not-allowed;
      }
    }
    &-checked,
    &-indeterminate {
      & ${InnerCheckbox} {
        background-color: ${({ theme }) => theme.colors.action.primary.default};
        border: none;
        :hover {
          background-color: ${({ theme }) =>
            theme.colors.action.primary.hovered};
        }
      }
    }
  }
  // -> Checked / Indeterminate (active)
  .${treeClass}-checkbox {
    &-checked {
      & ${InnerCheckbox} {
        &::after {
          display: block;
        }
      }
    }
    &-indeterminate {
      & ${InnerCheckbox} {
        &::after {
          display: block;
          left: 4px;
          top: 8px;
          width: 10px;
          border-width: 0 2px 0 0;
          transform: rotate(-90deg);
        }
      }
    }
  }

  // Arrow icon (Switcher)
  .${treeClass}-switcher {
    position: relative;
    top: 3px;
    margin-right: 3px;
    width: 24px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    user-select: none;
    svg {
      transition: transform 0.2s ease-in-out;
    }
    &.${treeClass}-switcher_open svg {
      transform: rotate(90deg);
    }
    &.${treeClass}-switcher-noop svg {
      display: none;
    }
    &-noop {
      display: none;
    }
  }
`;
