import kountSDK from "./sdk";

// nextjs doesn't work is destructuring props
// eslint-disable-next-line prefer-destructuring
const NEXT_PUBLIC_KOUNT_CLIENT_ID = process.env.NEXT_PUBLIC_KOUNT_CLIENT_ID;
// eslint-disable-next-line prefer-destructuring
const NEXT_PUBLIC_KOUNT_HOSTNAME = process.env.NEXT_PUBLIC_KOUNT_HOSTNAME;

export const initializeKount = (
  sessionId: string
): ReturnType<typeof kountSDK> => {
  if (!NEXT_PUBLIC_KOUNT_CLIENT_ID || !NEXT_PUBLIC_KOUNT_HOSTNAME) return null;

  return kountSDK(
    {
      clientID: process.env.NEXT_PUBLIC_KOUNT_CLIENT_ID,
      hostname: process.env.NEXT_PUBLIC_KOUNT_HOSTNAME,
      isSinglePageApp: true,
    },
    sessionId.replace(/-/gi, "")
  );
};
