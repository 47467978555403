import { css } from "styled-components";

type CustomRowProps = {
  $addBorderTop?: boolean;
  $dropUpward?: boolean;
  $dropDownward?: boolean;
};

export const bodyRowCss = css<CustomRowProps>`
  ${({ $addBorderTop }) =>
    $addBorderTop &&
    css`
      td:after {
        content: "";
        position: absolute;
        top: -0.5px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.border.default.subdued};
      }
    `}

  /* Draggable Table Row Styles */
    ${({ $dropUpward }) =>
    $dropUpward &&
    css`
      td {
        border-top: 1px dashed
          ${({ theme }) => theme.colors.border.primary.default};
      }
    `}
    ${({ $dropDownward }) =>
    $dropDownward &&
    css`
      td {
        border-bottom: 1px dashed
          ${({ theme }) => theme.colors.border.primary.default};
      }
    `}
`;
