import styled, { css } from "styled-components";
import Typography from "../../deprecated/Typography";
import { handleLabelPosition } from "./utils";
import type { SwitchProps } from "./index";

export const SwitchBall = styled.span`
  position: absolute;
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.colors.icon.white};
  border-radius: 50%;
  top: 50%;
  box-shadow: 0px 1px 3px #1018281a, 0px 1px 2px #1018280f;
  transform: translate(2px, -50%);
  transition: transform 0.3s ease-in-out;
`;

export const SwitchContainer = styled.div`
  cursor: pointer;
  display: block;
  position: relative;
  height: 20px;
  width: 36px;
  background-color: ${({ theme }) => theme.colors.surface.default.hovered};
  border-radius: ${({ theme }) => theme.borderRadius.switch};
  transition: background-color 0.3s ease-in-out;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.surface.default.pressed};
  }

  &:has(> input:checked) {
    background-color: ${({ theme }) => theme.colors.action.primary.default};
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.action.primary.hovered};
    }
  }
  label ${Typography} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
  }

  input {
    display: none;
    &:checked {
      & ~ ${SwitchBall} {
        transform: translate(18px, -50%);
      }
    }
  }
`;

export const switchCss = css<SwitchProps>`
  ${({ label, $labelPosition }) =>
    label && handleLabelPosition[$labelPosition || "right"]};
  ${({ disabled }) =>
    disabled &&
    css`
      label ${Typography}:hover {
        cursor: not-allowed;
      }
      ${SwitchContainer} {
        cursor: not-allowed;
        &,
        &:hover,
        &:focus {
          background-color: ${({ theme }) =>
            theme.colors.surface.default.disabled};
        }
      }
    `}
`;
