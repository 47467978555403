import type { UploadProps as RcUploadProps } from "rc-upload";
import RcUpload from "rc-upload";
import type { ReactNode, FC } from "react";
import { MdArrowCircleUp, MdDelete } from "react-icons/md";
import styled from "styled-components";
import { v4 } from "uuid";
import Row from "../../atoms/Row";
import TooltipIcon from "../../atoms/TooltipIcon";
import Typography from "../../deprecated/Typography";
import Button from "../Button";
import * as S from "./styles";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text } = Typography;

export type UploadProps = RcUploadProps & {
  onDelete?: () => void;
  description?: string;
  label?: ReactNode;
  tooltip?: ReactNode;
  subLabel?: ReactNode;
  hasError?: boolean;
};
const Upload: FC<UploadProps> = ({
  children,
  className,
  description,
  label,
  tooltip,
  subLabel,
  id,
  onDelete,
  hasError,
  ...props
}) => {
  const { t } = useTranslation("common");
  const containerProps = { hasError, className };

  const _id = id !== undefined ? id : v4();

  return (
    <S.Container {...containerProps}>
      <Row $column>
        <label htmlFor={_id}>
          <Text.Body>
            {label}
            {tooltip && <TooltipIcon tooltip={tooltip} />}
          </Text.Body>
        </label>
        {subLabel && <Text.Body $color="subdued">{subLabel}</Text.Body>}
      </Row>
      <S.UploadContainer $align="center">
        <S.UploadInner>
          <RcUpload id={_id} {...props}>
            {children || (
              <S.DefaultChild>
                <MdArrowCircleUp style={{ marginRight: "4px" }} />
                {t("upload")}
              </S.DefaultChild>
            )}
          </RcUpload>
        </S.UploadInner>
        <S.UploadActions>
          {children && (
            <Button
              type="plain-critical"
              onClick={onDelete}
              icon={<MdDelete />}
            >
              {t("delete")}
            </Button>
          )}
          <Text.Body $color="subdued">{description}</Text.Body>
        </S.UploadActions>
      </S.UploadContainer>
    </S.Container>
  );
};

export default styled(Upload)``;
