import styled, { css } from "styled-components";
import { Button, ButtonStyles, Icon, Row } from "design";

type Props = {
  $hideLink: boolean;
};
export const ContentRow = styled(Row)<Props>`
  position: relative;
  height: 56px;
  justify-content: center;
  align-items: center;
  padding: 0 36px;
  ${({ $hideLink }) =>
    $hideLink
      ? css`
          img {
            max-height: 36px;
            max-width: 100%;
          }
        `
      : css`
          a {
            max-height: 36px;
            img {
              max-height: 36px;
              max-width: 100%;
            }
          }
        `}

  > span {
    position: absolute;
    pointer-events: none;
  }
`;

export const BackButton = styled(Button)`
  position: absolute;
  left: 0;
  ${ButtonStyles.ButtonContent} ${Icon} svg {
    color: ${({ theme }) => theme.colors.icon.default};
  }
`;
