import { isArray } from "lodash";
import type {
  FC,
  Dispatch,
  SetStateAction,
  ReactNode,
  HTMLAttributes,
} from "react";
import { useState, useEffect } from "react";
import {
  HiX,
  HiCheckCircle,
  HiExclamation,
  HiExclamationCircle,
  HiInformationCircle,
} from "react-icons/hi";
import styled from "styled-components";
import Typography from "../../deprecated/Typography";
import type { ButtonProps } from "../../molecules/Button";
import Button from "../../molecules/Button";
import Row from "../Row";
import * as S from "./styles";

const { Text, Title } = Typography;

const ICON_TYPE = {
  default: <HiInformationCircle />,
  info: <HiInformationCircle />,
  primary: <HiInformationCircle />,
  critical: <HiExclamationCircle />,
  warning: <HiExclamation />,
  success: <HiCheckCircle />,
};

type BannerActionButton = {
  onClick?: ButtonProps["onClick"];
  props?: ButtonProps;
  text?: ReactNode;
};

export type BannerProps = Omit<HTMLAttributes<HTMLDivElement>, "title"> & {
  type?: "critical" | "default" | "info" | "primary" | "success" | "warning";
  display?: boolean;
  title?: ReactNode;
  message: ReactNode | ReactNode[];
  icon?: ReactNode;
  closable?: boolean;
  showIcon?: boolean;
  timeout?: number;
  onClose?: () => void;
  afterClose?: () => void;
  animated?: boolean;
  primaryAction?: BannerActionButton;
  secondaryAction?: BannerActionButton;
};

export type ComponentProps = BannerProps & {
  entering?: boolean;
  leaving?: boolean;
  setLeaving: Dispatch<SetStateAction<boolean>>;
};

const Component: FC<ComponentProps> = ({
  className,
  closable,
  display = true,
  icon,
  title,
  message,
  showIcon,
  timeout = 0,
  onClose,
  afterClose,
  primaryAction,
  secondaryAction,
  leaving,
  setLeaving,
  type,
}) => {
  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    let timerTimeout: NodeJS.Timer | null = null;
    let timerLeave1: NodeJS.Timer | null = null;
    let timerLeave2: NodeJS.Timer | null = null;
    if (display && !leaving) {
      setLeaving(false);
      setIsDisplayed(true);
      if (timeout !== 0) {
        timerTimeout = setTimeout(() => {
          setLeaving(true);
          onClose?.();
          timerLeave1 = setTimeout(() => {
            setIsDisplayed(false);
            afterClose?.();
          }, 200);
        }, timeout);
      }
    }
    if (leaving) {
      onClose?.();
      timerLeave2 = setTimeout(() => {
        setIsDisplayed(false);
        afterClose?.();
      }, 200);
    }

    return () => {
      timerTimeout?.unref?.();
      timerLeave1?.unref?.();
      timerLeave2?.unref?.();
    };
  }, [display, leaving]);

  if (!isDisplayed) return null;

  return (
    <Row $justify="space-between" $noWrap className={className}>
      <Row $noWrap $gap={12}>
        {showIcon && (
          <S.BannerIcon icon={icon || ICON_TYPE[type || "default"]} />
        )}
        <Row $column $gap={4}>
          {title && <Title.Heading>{title}</Title.Heading>}
          {message && (
            <Text.Body>
              {isArray(message)
                ? message.map((_message) => (
                    <Row $block $column $noWrap $gap={4}>
                      {_message}
                    </Row>
                  ))
                : message}
            </Text.Body>
          )}
          {(primaryAction || secondaryAction) && (
            <S.ButtonsContainer>
              {primaryAction && (
                <Button
                  type="outline"
                  onClick={primaryAction.onClick}
                  {...primaryAction.props}
                >
                  {primaryAction.text}
                </Button>
              )}
              {secondaryAction && (
                <Button
                  type="plain"
                  onClick={secondaryAction.onClick}
                  {...secondaryAction.props}
                >
                  {secondaryAction.text}
                </Button>
              )}
            </S.ButtonsContainer>
          )}
        </Row>
      </Row>
      {closable && (
        <S.CloseIcon icon={<HiX onClick={() => setLeaving(true)} />} />
      )}
    </Row>
  );
};

export default styled(Component)`
  ${S.bannerCss}
`;
