import BaseList from "./List";
import ListItem from "./ListItem";

export * as ListStyles from "./List/styles";
export { ListItemStyles, ListItemMetaStyles } from "./ListItem";

export type { ListProps } from "./List";
export type { ListItemProps, ListItemMetaProps } from "./ListItem";

type CompoundedComponent = typeof BaseList & {
  Item: typeof ListItem;
};

const List = BaseList as CompoundedComponent;
List.Item = ListItem;

export default List;
