import type { FC } from "react";
import type { TypographyProps } from "../Typography";
import Typography from "../Typography";

export type HeadingProps = Omit<TypographyProps, "$type" | "wrapper"> & {
  wrapper?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  $type?:
    | "heading4xl"
    | "heading3xl"
    | "heading2xl"
    | "headingXl"
    | "headingLg"
    | "headingMd"
    | "headingSm";
};
export const Heading: FC<HeadingProps> = ({
  wrapper = "h1",
  $type = "headingMd",
  children,
  ...props
}) => (
  <Typography wrapper={wrapper} $type={$type} {...props}>
    {children}
  </Typography>
);

export default Heading;
