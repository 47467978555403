import styled, { css } from "styled-components";
import type { ProgressProps } from "./index";

export const ProgressBar = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.surface.primary.important};
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
`;

export const InnerContainer = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.surface.neutral.default};
  border-radius: 100px;
`;

export const progressCss = css<ProgressProps>`
  width: 100%;
  height: 16px;
  border-radius: 100px;

  ${ProgressBar} {
    width: ${({ $percent }) => Math.min(Math.max($percent ?? 0, 0), 100)}%;
  }
`;
