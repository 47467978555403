import composeRefs from "@seznam/compose-react-refs";
import type { InputHTMLAttributes, ReactNode } from "react";
import { forwardRef, useRef } from "react";
import styled from "styled-components";
import { v4 } from "uuid";
import TooltipIcon from "../../atoms/TooltipIcon";
import Typography from "../../deprecated/Typography";
import * as S from "./styles";

export type CheckboxProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "children"
> & {
  label?: ReactNode;
  labelRequired?: boolean;
  tooltip?: ReactNode;
};
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { id, disabled, className, label, labelRequired, tooltip, ...props },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const _id = id !== undefined ? id : v4();

    return (
      <div className={className}>
        <S.CheckboxContainer
          onClick={() => innerRef.current?.click()}
          role="none"
        >
          <input
            ref={composeRefs(ref, innerRef)}
            id={_id}
            type="checkbox"
            disabled={disabled}
            {...props}
          />
          <S.Checkmark />
        </S.CheckboxContainer>
        <S.Label htmlFor={_id} $labelRequired={!!labelRequired}>
          <Typography.Text.Body $color={disabled ? "disabled" : "default"}>
            {label}
            {tooltip && <TooltipIcon tooltip={tooltip} />}
          </Typography.Text.Body>
        </S.Label>
      </div>
    );
  }
);

export default styled(Checkbox)`
  ${S.checkboxCss}
`;
