import styled, { css } from "styled-components";
import Typography from "../../deprecated/Typography";
import type { CheckboxProps } from "./index";

type LabelProps = {
  $labelRequired: boolean;
};
export const Label = styled.label<LabelProps>`
  display: flex;
  -webkit-touch-callout: none;
  user-select: none;
  ${Typography} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
  }
  ${({ $labelRequired }) =>
    $labelRequired &&
    css`
      &::after {
        content: "*";
        margin-left: 2px;
        color: ${({ theme }) => theme.colors.text.critical};
      }
    `}
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 2px;
  width: 18px;
  height: 18px;
  background-color: ${({ theme }) => theme.colors.action.secondary.default};
  border: 2px solid ${({ theme }) => theme.colors.border.default.default};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  transition: background-color 0.3s ease-in-out;
  ::after {
    content: "";
    position: absolute;
    display: none;
    border: solid ${({ theme }) => theme.colors.icon.white};
  }
`;

export const CheckboxContainer = styled.div`
  position: relative;
  display: block;
  input {
    display: none;
  }
`;

export const checkboxCss = css<CheckboxProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  ${CheckboxContainer} {
    ${({ disabled }) =>
      disabled
        ? css`
            cursor: not-allowed;
            input {
              ~ ${Checkmark} {
                background-color: ${({ theme }) =>
                  theme.colors.action.secondary.disabled};
                border-color: ${({ theme }) =>
                  theme.colors.border.default.disabled};
                :hover {
                  background-color: ${({ theme }) =>
                    theme.colors.action.secondary.disabled};
                }
              }
              :checked ~ ${Checkmark} {
                background-color: ${({ theme }) =>
                  theme.colors.action.primary.disabled};
                border: none;
                :hover {
                  background-color: ${({ theme }) =>
                    theme.colors.action.primary.disabled};
                }
                ::after {
                  display: block;
                }
              }
            }
          `
        : css`
            cursor: pointer;
            input {
              :checked ~ ${Checkmark} {
                background-color: ${({ theme }) =>
                  theme.colors.action.primary.default};
                border: none;
                :hover {
                  background-color: ${({ theme }) =>
                    theme.colors.action.primary.hovered};
                }
                ::after {
                  display: block;
                }
              }
              :not(:checked) ~ ${Checkmark}:hover {
                background-color: ${({ theme }) =>
                  theme.colors.action.secondary.hovered};
              }
            }
          `}

    ${Checkmark}::after {
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  label {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    margin-left: 26px;
    -webkit-touch-callout: none;
    user-select: none;
    ${Typography} {
      display: inline-flex;
      align-items: center;
      gap: 4px;
    }
  }
`;
