import BaseTitle from "./Title";
import {
  DisplayXL,
  DisplayL,
  DisplayM,
  DisplayS,
  Heading,
  Subheading,
} from "./TitleOptions";

export type TitleProps = typeof BaseTitle & {
  DisplayXL: typeof DisplayXL;
  DisplayL: typeof DisplayL;
  DisplayM: typeof DisplayM;
  DisplayS: typeof DisplayS;
  Heading: typeof Heading;
  Subheading: typeof Subheading;
};

const Title = BaseTitle as TitleProps;
Title.DisplayXL = DisplayXL;
Title.DisplayL = DisplayL;
Title.DisplayM = DisplayM;
Title.DisplayS = DisplayS;
Title.Heading = Heading;
Title.Subheading = Subheading;

export default Title;
