import { forwardRef, useState } from "react";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import styled from "styled-components";
import type { InputProps } from "../Input";
import Input from "../Input";
import * as S from "./styles";

export type InputPasswordProps = Omit<InputProps, "type">;
const Password = forwardRef<HTMLInputElement, InputPasswordProps>(
  ({ disabled, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const changeType = () => {
      if (!disabled) setShowPassword(!showPassword);
    };

    return (
      <Input
        ref={ref}
        type={showPassword ? "text" : "password"}
        disabled={disabled}
        {...rest}
        suffix={
          <S.EyeIcon
            onClick={changeType}
            icon={
              showPassword ? (
                <AiFillEye size={20} />
              ) : (
                <AiOutlineEye size={20} />
              )
            }
          />
        }
      />
    );
  }
);

export default styled(Password)`
  ${S.passwordCss}
`;
