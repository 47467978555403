import _ from "lodash";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const camelize = <TResult extends object = any>(obj: object): TResult =>
  _.transform(obj, (acc, value, key, target) => {
    // eslint-disable-next-line no-nested-ternary
    const camelKey = _.isArray(target)
      ? key
      : _.isString(key)
      ? _.camelCase(key)
      : key;

    // @ts-expect-error ignore
    acc[camelKey] = _.isObject(value) ? camelize(value) : value;
  });
