import type { LinkProps } from "next/link";
import Link from "next/link";
import type { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import Typography from "../../../deprecated/Typography";
import Row from "../../Row";
import BaseCard from "../BaseCard";
import * as S from "./styles";

export type CardProps = Omit<HTMLAttributes<HTMLDivElement>, "title"> & {
  linkHref?: LinkProps["href"];
  title?: ReactNode;
  description?: ReactNode;
  subTitle?: ReactNode;
  extra?: ReactNode;
  footer?: ReactNode;
  $backgroundColor?: string;
  $withTable?: boolean;
  block?: boolean;
};

const Card: FC<CardProps> = ({
  className,
  linkHref,
  children,
  title,
  description,
  extra,
  subTitle,
  footer,
  ...rest
}) => {
  const container = (
    <BaseCard
      {...rest}
      className={className}
      header={
        (title || extra) && (
          <Row key="card-header" $column $block $gap={4}>
            <Row
              $justify={!title && extra ? "end" : "space-between"}
              $block
              $gap={4}
            >
              {title && <S.Heading>{title}</S.Heading>}
              {extra}
            </Row>
            {description && (
              <Typography.Title.Subheading $color="subdued">
                {description}
              </Typography.Title.Subheading>
            )}
          </Row>
        )
      }
      footer={footer}
    >
      {subTitle && <S.Subheading>{subTitle}</S.Subheading>}
      {children}
    </BaseCard>
  );

  if (!linkHref) return container;
  return <Link href={linkHref}>{container}</Link>;
};

export default styled(Card)`
  ${S.cardCss}
`;
