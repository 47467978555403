import type { FC } from "react";
import BlankScreenWrapper from "./BlankScreenWrapper";
import Head from "./Head";
import ShopPassword from "./Password";
import type { ShopConfigType } from "./ssr-loader";
import { ShopContext } from "contexts";
import { LayoutError } from "layouts";

type ShopType = {
  ssrConfig: ShopConfigType;
};
export const Shop: FC<ShopType> = ({ ssrConfig, children }) => {
  if (ssrConfig?.status === "internal-error")
    return <LayoutError.LoadingStore />;
  if (ssrConfig?.status === "not-found") {
    return <LayoutError.NotFound hideLink />;
  }
  if (ssrConfig?.status === "no-permission") return <ShopPassword />;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { config } = ssrConfig.data!;
  return (
    <BlankScreenWrapper>
      <ShopContext.Provider value={config}>
        <Head />
        {!config.public ? <LayoutError.NoPublic /> : children}
      </ShopContext.Provider>
    </BlankScreenWrapper>
  );
};
