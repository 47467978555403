import styled from "styled-components";
import { Typography } from "design/deprecated";

export const CourierImg = styled.img`
  height: 24px;
`;

/* This is to override the styles comming with the text from the backend */
export const CourierDescription = styled(Typography.Text.Body)`
  color: ${({ theme }) => theme.colors.text.subdued};
  b {
    font-weight: 600;
  }
  a {
    font-weight: 400;
    text-decoration: none !important;
    color: ${({ theme }) => theme.colors.text.subdued};
    :hover {
      color: ${({ theme }) => theme.colors.text.subdued};
      text-decoration: underline !important;
    }
  }
`;
