import Link from "next/link";
import type { FC, ReactNode } from "react";
import { HiArrowLeft } from "react-icons/hi";
import styled from "styled-components";
import { useLayout } from "../../context";
import Footer from "../../Footer";
import * as S from "./styles";
import { Row, useDesign } from "design";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

export type ContentProps = {
  children: ReactNode;
  className?: string;
  hideFooter?: boolean;
  hasBackgroundImage?: boolean;
  isStartView?: boolean;
  layoutSize?: "small" | "medium" | "large";
};
const Content: FC<ContentProps> = ({
  children,
  className,
  hideFooter,
  hasBackgroundImage,
  isStartView,
  layoutSize,
}) => {
  const { t } = useTranslation("common");
  const {
    device: { isDesktop },
  } = useDesign();
  const { backButtonHref } = useLayout();

  return (
    <S.ContainerSection
      className={className}
      $startView={!!isStartView}
      $hideFooter={!!hideFooter}
      $layoutSize={layoutSize || "medium"}
    >
      {!isStartView && backButtonHref && (
        <Row $column $block>
          {isDesktop && backButtonHref && (
            <Link href={backButtonHref} passHref>
              <S.DesktopBackButton
                type="ghost"
                icon={<HiArrowLeft size={20} />}
              >
                {t("return_to_previous_step")}
              </S.DesktopBackButton>
            </Link>
          )}
        </Row>
      )}
      {children}
      {!hideFooter && isStartView && (
        <Footer
          isStartView={isStartView}
          hasBackgroundImage={hasBackgroundImage}
        />
      )}
    </S.ContainerSection>
  );
};

export default styled(Content)``;
