import DeleteModal from "./DeleteModal";
import DesktopModal from "./DesktopModal";
import MobileModal from "./MobileModal";
import ModalBase from "./Modal";

export type { ModalProps } from "./Modal";
export type { DesktopModalProps } from "./DesktopModal";
export type { MobileModalProps } from "./MobileModal";
export * as ModalStyles from "./DesktopModal/styles";

export type BaseModalProps = typeof ModalBase & {
  Delete: typeof DeleteModal;
  Desktop: typeof DesktopModal;
  Mobile: typeof MobileModal;
};

const Modal = ModalBase as BaseModalProps;
Modal.Delete = DeleteModal;
Modal.Desktop = DesktopModal;
Modal.Mobile = MobileModal;

export default Modal;
