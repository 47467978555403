import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import styled, { css } from "styled-components";
import { SlideUpIn, SlideUpOut } from "../../animations";
import Icon from "../Icon";
import Row from "../Row";
import type { ComponentProps } from "./Component";

export const ButtonsContainer = styled(Row)`
  margin-top: 12px;
  align-items: center;
  gap: 14px;
`;
export const BannerIcon = styled(Icon)``;
export const CloseIcon = styled(Icon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.iconAction.default.default};
  transition: color 0.2s ease-in-out;
  :hover {
    color: ${({ theme }) => theme.colors.iconAction.default.hovered};
  }
`;

const handleBannerType: Record<
  NonNullable<ComponentProps["type"]>,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  critical: css`
    background-color: ${({ theme }) => theme.colors.surface.critical.subdued};
    border-color: ${({ theme }) => theme.colors.border.critical.subdued};
    ${BannerIcon} {
      color: ${({ theme }) => theme.colors.icon.critical};
    }
  `,
  default: css`
    background-color: ${({ theme }) => theme.colors.surface.neutral.subdued};
    border-color: ${({ theme }) => theme.colors.border.neutral.subdued};
    ${BannerIcon} {
      color: ${({ theme }) => theme.colors.icon.default};
    }
  `,
  info: css`
    background-color: ${({ theme }) =>
      theme.colors.surface.informational.subdued};
    border-color: ${({ theme }) => theme.colors.border.informational.subdued};
    ${BannerIcon} {
      color: ${({ theme }) => theme.colors.icon.informational};
    }
  `,
  primary: css`
    background-color: ${({ theme }) => theme.colors.surface.primary.subdued};
    border-color: ${({ theme }) => theme.colors.border.primary.subdued};
    ${BannerIcon} {
      color: ${({ theme }) => theme.colors.icon.primary};
    }
  `,
  success: css`
    background-color: ${({ theme }) => theme.colors.surface.success.subdued};
    border-color: ${({ theme }) => theme.colors.border.success.subdued};
    ${BannerIcon} {
      color: ${({ theme }) => theme.colors.icon.success};
    }
  `,
  warning: css`
    background-color: ${({ theme }) => theme.colors.surface.warning.subdued};
    border-color: ${({ theme }) => theme.colors.border.warning.subdued};
    ${BannerIcon} {
      color: ${({ theme }) => theme.colors.icon.warning};
    }
  `,
};

export const bannerCss = css<ComponentProps>`
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  border: 1px solid;
  border-left: 5px solid;
  width: 100%;
  gap: 16px;
  ${BannerIcon},
  ${CloseIcon} {
    width: 25px;
    min-width: 25px;
    font-size: 20px;
  }
  ${({ type }) => handleBannerType[type || "default"]}

  ${({ leaving }) =>
    leaving &&
    css`
      animation: ${SlideUpOut} 0.2s both;
    `}
  ${({ entering }) =>
    entering &&
    css`
      animation: ${SlideUpIn} 0.2s both;
    `}
`;
