import Portal from "rc-util/lib/Portal";
import type { FC, CSSProperties, ReactNode } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Row from "../../../atoms/Row";
import Typography from "../../../deprecated/Typography";
import Button from "../../Button";
import type { BaseProps } from "../types";
import * as S from "./styles";
import type { ModalSize } from "./utils";
import { useDelayedBoolean } from "hooks";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Title } = Typography;

export type DesktopModalProps = BaseProps & {
  size?: ModalSize;
  style?: CSSProperties;
};

export type DesktopModalComponentProps = S.ContainerProps & DesktopModalProps;

export const DesktopModalComponent: FC<DesktopModalComponentProps> = ({
  title,
  subTitle,
  onBack,
  onClose,
  style,
  children,
  isLeaving,
  size,
  visible,
  ...props
}) => {
  const { t } = useTranslation("common");

  const closable: boolean = "closable" in props && !!props.closable;

  const footer: ReactNode[] = [];
  if (!props.withoutFooter) {
    const { onOk, onCancel, extraFooter } = props;
    if (extraFooter) footer.push(extraFooter);
    if (onCancel) {
      const { skipOnClose, ..._onCancel } =
        typeof onCancel === "boolean" ? { skipOnClose: false } : onCancel;
      footer.push(
        <Button
          key="desktop-modal-cancel"
          type="outline"
          {..._onCancel}
          onClick={(e) => {
            if (!skipOnClose) onClose?.();
            return _onCancel?.onClick?.(e);
          }}
        >
          {_onCancel?.children || t("cancel")}
        </Button>
      );
    }
    if (onOk) {
      footer.push(
        <Button key="desktop-modal-ok" type="primary" {...onOk}>
          {onOk?.children || t("confirm")}
        </Button>
      );
    }
  }

  return (
    <S.Container isLeaving={isLeaving} visible={visible} size={size}>
      <S.ContentContainer>
        <S.Shadow role="none" onClick={onClose} />
        <S.InnerWrap style={style}>
          <S.ModalContainerCard
            header={
              (title || onBack || closable || subTitle) && (
                <Row key="desktop-modal-header" $column $block $gap={4}>
                  <Row
                    $justify={
                      !title && !onBack && closable ? "end" : "space-between"
                    }
                    $noWrap
                    $block
                    $gap={12}
                  >
                    <Row $noWrap $gap={12}>
                      {onBack && (
                        <S.BackIcon
                          size={20}
                          onClick={onBack}
                          cursor="pointer"
                        />
                      )}
                      {title && <Title.Heading>{title}</Title.Heading>}
                    </Row>
                    {closable && (
                      <S.CloseIcon
                        size={20}
                        onClick={onClose}
                        cursor="pointer"
                      />
                    )}
                  </Row>
                  {subTitle && (
                    <Title.Subheading $color="subdued">
                      {subTitle}
                    </Title.Subheading>
                  )}
                </Row>
              )
            }
            footer={
              footer.length > 0 ? (
                <Row key="desktop-modal-footer" $justify="end" $block $gap={8}>
                  {footer}
                </Row>
              ) : undefined
            }
          >
            {children}
          </S.ModalContainerCard>
        </S.InnerWrap>
      </S.ContentContainer>
    </S.Container>
  );
};

const DesktopModal: FC<DesktopModalProps> = (props) => {
  const { visible, destroyOnClose } = props;
  const [rendered, setRendered] = useState(false);
  const { display, isLeaving } = useDelayedBoolean(visible, S.animationTime);

  useEffect(() => {
    if (visible) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
    if (!rendered && visible) setRendered(true);
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [visible]);

  // destroyOnClose will unmount the component
  if (destroyOnClose && !display) return null;
  if (!rendered) return null;

  return (
    <Portal
      getContainer={() =>
        document.body.appendChild(document.createElement("div"))
      }
    >
      <DesktopModalComponent {...props} isLeaving={isLeaving} />
    </Portal>
  );
};

export default styled(DesktopModal)``;
