import type { FC, ReactNode } from "react";
import { useState, useEffect } from "react";

type BlankScreenWrapperProps = {
  children: ReactNode;
};
const BlankScreenWrapper: FC<BlankScreenWrapperProps> = ({ children }) => {
  const [render, setRender] = useState(false);

  useEffect(() => {
    setTimeout(() => setRender(true), 1000);
  }, []);

  if (!render) return null;

  return <>{children}</>;
};

export default BlankScreenWrapper;
