import styled from "styled-components";
import { Row } from "design";

export const ContentRow = styled(Row).attrs({
  $gap: 20,
  $align: "center",
  $justify: "center",
  $column: true,
  $block: true,
})`
  flex: 1;
  margin: 0 auto;
  max-width: 603px;
`;
