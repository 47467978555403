import _ from "lodash";
import type {
  FieldValues,
  Path,
  UseFormGetValues,
  UseFormSetError,
} from "react-hook-form";
import { toast } from "design";
import type {
  Error,
  RequestStartValidationValues,
  ShopRequestStartValidation,
} from "typing";

export const validateRequestStartValidations = (
  requestStartValidationsShop: ShopRequestStartValidation[],
  requestStartValidationValues: RequestStartValidationValues[]
) => {
  const acceptedIds = requestStartValidationValues.map(({ id }) => id);
  return requestStartValidationsShop
    .filter(({ required }) => required)
    .every(({ id }) => acceptedIds.includes(id));
};

export const handleErrors = <TFieldValues extends FieldValues>(
  errors: Error[],
  getValues: UseFormGetValues<TFieldValues>,
  setError: UseFormSetError<TFieldValues>
): void => {
  errors.forEach(({ field, messages }) => {
    if (Object.keys(getValues()).includes(field)) {
      // @ts-expect-error no generic for react-hook-form
      setError(field, {
        type: "manual",
        message: messages[0],
      });
    } else {
      toast("error", messages);
    }
  });
};

export const handleErrorsV2 = <TFieldValues extends FieldValues>({
  errors,
  getValues,
  setError,
  errorsPrefix,
}: {
  errors: Error[];
  getValues: UseFormGetValues<TFieldValues>;
  setError: UseFormSetError<TFieldValues>;
  errorsPrefix?: string;
}): void => {
  /*
  We should only set errors on fields that are mounted, bc revalidations only occurs
  on mounted fields. We try to check if a field is mounted by checking it's not an
  array or object. This is not perfect, but it's better than nothing.
  react-hook-form doesn't have a way to get a list of mounted field
  */
  const formValues = getValues();
  errors.forEach(({ field, messages }) => {
    const _field = !errorsPrefix
      ? field
      : field.replace(new RegExp(`^${errorsPrefix}`), "");

    // we check if the form has key, in case that the value is undefiend
    const hasKey = _.has(formValues, _field);
    const formVal = _.get(formValues, _field);
    const fieldName =
      hasKey && (formVal === null || typeof formVal !== "object")
        ? _field
        : "root";
    setError(fieldName as Path<TFieldValues>, {
      type: "manual",
      message: messages[0],
    });
  });
};
