import type { SelectProps as RcSelectProps, BaseSelectRef } from "rc-select";
import RcSelect from "rc-select";
import type { DefaultOptionType, BaseOptionType } from "rc-select/lib/Select";
import type {
  ForwardedRef,
  ReactNode,
  ReactElement,
  PropsWithChildren,
  Ref,
} from "react";
import { forwardRef } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import styled, { useTheme } from "styled-components";
import { v4 } from "uuid";
import Icon from "../../atoms/Icon";
import Row from "../../atoms/Row";
import TooltipIcon from "../../atoms/TooltipIcon";
import Typography from "../../deprecated/Typography";
import * as S from "./styles";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text } = Typography;

export type SelectOptionType<ValueType> = Omit<DefaultOptionType, "value"> & {
  value?: ValueType;
};

export type SelectProps<
  ValueType = unknown,
  OptionType extends BaseOptionType = SelectOptionType<ValueType>
> = Omit<
  RcSelectProps<ValueType, OptionType>,
  "prefixCls" | "inputIcon" | "size"
> & {
  id?: string;
  label?: ReactNode;
  tooltip?: ReactNode;
  subLabel?: ReactNode;
  labelRequired?: boolean;
  hasError?: boolean;
};

const SelectInner = <
  ValueType,
  OptionType extends BaseOptionType = DefaultOptionType
>(
  {
    id,
    label,
    tooltip,
    subLabel,
    labelRequired,
    showSearch,
    loading,
    ...rest
  }: SelectProps<ValueType, OptionType>,
  ref: ForwardedRef<BaseSelectRef>
): ReactElement => {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const _id = id !== undefined ? id : v4();

  const showSearchProps = showSearch
    ? { showSearch, autoComplete: "nope" }
    : {};

  return (
    <S.Container>
      <Row $column>
        {label && (
          <S.Label htmlFor={_id} $labelRequired={labelRequired}>
            <Text.Body>
              {label}
              {tooltip && <TooltipIcon tooltip={tooltip} />}
            </Text.Body>
          </S.Label>
        )}
        {subLabel && <Text.Caption $color="subdued">{subLabel}</Text.Caption>}
      </Row>
      <RcSelect<ValueType, OptionType>
        ref={ref}
        id={_id}
        notFoundContent={t("no_results")}
        transitionName={theme.motionName.SLIDE_UP}
        inputIcon={
          loading ? <S.LoadingIcon /> : <Icon icon={<HiOutlineChevronDown />} />
        }
        {...showSearchProps}
        {...rest}
      />
    </S.Container>
  );
};

const Select: (<
  ValueType = unknown,
  OptionType extends BaseOptionType = SelectOptionType<ValueType>
>(
  props: PropsWithChildren<SelectProps<ValueType, OptionType>> & {
    ref?: Ref<BaseSelectRef>;
  }
) => ReactElement) &
  string = styled(forwardRef(SelectInner))`
  ${S.selectCss}
`;

export default Select;
