import { forwardRef } from "react";
import styled from "styled-components";
import type { AbstractInputProps } from "../Abstract";
import AbstractInput from "../Abstract";
import * as S from "./styles";

export type InputTextAreaProps = Omit<
  AbstractInputProps<HTMLTextAreaElement>,
  "component" | "type" | "size"
>;
const TextArea = forwardRef<HTMLInputElement, InputTextAreaProps>(
  (props, ref) => <AbstractInput ref={ref} component="textarea" {...props} />
);

export default styled(TextArea)`
  ${S.textAreaCss}
`;
