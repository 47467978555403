import type { FC } from "react";
import { useTheme } from "styled-components";

const Illustration: FC = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={258}
      height={205}
      fill="none"
    >
      <path
        fill="#EBEBEB"
        d="M121.299 200c53.172 0 96.279-43.107 96.279-96.468 0-53.362-43.297-96.469-96.279-96.469-53.172 0-96.279 43.107-96.279 96.469C25.02 156.893 68.129 200 121.3 200Z"
      />
      <g filter="url(#a)">
        <path
          fill="#fff"
          d="M213.041 52.861v111.732c0 3.278-2.732 6.01-6.01 6.01H37.248c-3.278.136-6.01-2.595-6.01-5.874V52.861c0-3.415 2.732-6.01 6.01-6.01H207.03c3.415 0 6.01 2.732 6.01 6.01Z"
        />
        <path
          fill={theme.colors.surface.primary.important}
          fillRule="evenodd"
          d="M101.739 107.862v-8.355c0-11.537 9.353-20.889 20.889-20.889 11.537 0 20.889 9.352 20.889 20.889v8.355a8.356 8.356 0 0 1 8.356 8.356v20.889a8.356 8.356 0 0 1-8.356 8.355h-41.778a8.355 8.355 0 0 1-8.355-8.355v-20.889a8.356 8.356 0 0 1 8.355-8.356Zm33.423-8.355v8.355h-25.067v-8.355c0-6.922 5.611-12.534 12.533-12.534s12.534 5.612 12.534 12.534Z"
          clipRule="evenodd"
        />
        <path
          fill={theme.colors.surface.primary.subduedPressed}
          d="M213.041 52.861v8.605H31.238v-8.605c0-3.415 2.732-6.01 6.01-6.01H207.03c3.415 0 6.01 2.732 6.01 6.01Z"
        />
        <path
          fill="#9FA8BA"
          d="M39.297 56.685a2.049 2.049 0 1 0 0-4.097 2.049 2.049 0 0 0 0 4.097ZM45.717 56.685a2.049 2.049 0 1 0 0-4.097 2.049 2.049 0 0 0 0 4.097ZM52.273 56.685a2.049 2.049 0 1 0 0-4.097 2.049 2.049 0 0 0 0 4.097Z"
        />
      </g>
      <path
        fill={theme.colors.surface.primary.importantSubdued}
        d="M205.665 32.372a2.448 2.448 0 0 1-2.458-2.459V2.46A2.448 2.448 0 0 1 205.665 0a2.449 2.449 0 0 1 2.459 2.459v27.454a2.449 2.449 0 0 1-2.459 2.46ZM225.607 53.407a2.449 2.449 0 0 1 2.459-2.458h27.455a2.449 2.449 0 0 1 2.459 2.458 2.449 2.449 0 0 1-2.459 2.459h-27.455a2.449 2.449 0 0 1-2.459-2.459ZM223.422 35.65c-.956-.956-.956-2.595 0-3.551l19.533-19.396c.956-.956 2.595-.956 3.551 0s.956 2.595 0 3.551L227.11 35.65c-1.093.956-2.732.956-3.688 0Z"
      />
      <defs>
        <filter
          id="a"
          width={242.571}
          height={184.524}
          x={0.854}
          y={20.265}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3.798} />
          <feGaussianBlur stdDeviation={15.192} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2192_2840"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={3.798} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend
            in2="effect1_dropShadow_2192_2840"
            result="effect2_dropShadow_2192_2840"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2192_2840"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Illustration;
