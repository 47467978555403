import styled, { css } from "styled-components";
import Typography from "../../../deprecated/Typography";

const { Text } = Typography;

export const TextContainer = styled(Text.Body)`
  color: ${({ theme }) => theme.colors.text.subdued};
`;

export const DivActiveBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 4px;
  height: 2px;
  width: calc(100% - 8px);
  border-radius: 8px;
  transition: background-color 0.2s ease-in-out;
`;

const barStyles = (active: boolean) => css`
  ${TextContainer} {
    color: ${({ theme }) => theme.colors.text.default};
  }
  ${DivActiveBar} {
    background-color: ${({ theme }) =>
      theme.colors.border[active ? "primary" : "default"].default};
  }
`;

type CustomProps = {
  $active: boolean;
};
export const DivTabContainer = styled.div<CustomProps>`
  cursor: pointer;
  position: relative;
  padding: 12px 16px;
  height: 44px;
  min-width: max-content;
  :focus,
  :hover {
    ${barStyles(false)}
  }
  ${({ $active }) =>
    $active &&
    css`
      :focus,
      :hover {
        ${barStyles(true)}
      }
      ${barStyles(true)}
    `}
`;
