import styled, { css } from "styled-components";
import Typography from "../../../deprecated/Typography";
import type { RadioGroupProps } from "./index";

type Props = {
  $labelRequired: boolean;
};
export const Label = styled(Typography)<Props>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  -webkit-touch-callout: none;
  user-select: none;
  ${({ $labelRequired }) =>
    $labelRequired &&
    css`
      &::after {
        content: "*";
        margin-left: 2px;
        color: ${({ theme }) => theme.colors.text.critical};
      }
    `}
`;
export const SubLabel = styled(Typography)`
  margin-bottom: 8px;
`;

export const radioGroupCss = css<RadioGroupProps<unknown>>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ $column }) => ($column ? "4px" : "8px")};
  ${({ $column }) => $column && "flex-direction: column;"}
  ${({ $block }) => $block && "width: 100%;"}
`;
