import type { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type ViewContentProps = HTMLAttributes<HTMLDivElement> & {
  $view?: "small" | "medium" | "large";
};
const ViewContent: FC<ViewContentProps> = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);

export default styled(ViewContent)`
  ${S.viewContentCss}
`;
