import type {
  FC,
  ReactNode,
  Dispatch,
  SetStateAction,
  ReactElement,
} from "react";
import { useState } from "react";
import { Collapse as RcCollapse } from "react-collapse";
import { HiOutlineChevronDown } from "react-icons/hi";
import styled from "styled-components";
import Row from "../../atoms/Row";
import * as S from "./styles";

export type CollapseProps = {
  className?: string;
  isDefaultOpen?: boolean;
  title?: ((arrow: ReactElement) => ReactNode) | ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  showArrow?: boolean;
  children: ReactNode;
  open?: {
    is: boolean;
    set: Dispatch<SetStateAction<boolean>>;
  };
};
const Collapse: FC<CollapseProps> = ({
  children,
  isDefaultOpen,
  showArrow = true,
  icon,
  title,
  open,
  ...props
}) => {
  const { disabled } = props;

  const [_isOpen, _setIsOpen] = useState(!!isDefaultOpen);
  const isOpen = open ? open.is : _isOpen;
  const setIsOpen = open ? open.set : _setIsOpen;

  const arrow = <S.CollapseIcon icon={icon || <HiOutlineChevronDown />} />;

  return (
    <div {...props}>
      <S.CollapseHeader
        $isOpen={isOpen}
        disabled={disabled}
        role="none"
        onClick={() => setIsOpen(!disabled ? !isOpen : false)}
      >
        {title instanceof Function ? (
          title(arrow)
        ) : (
          <Row $block $justify="space-between" $align="start" $noWrap $gap={8}>
            {title}
            {showArrow && arrow}
          </Row>
        )}
      </S.CollapseHeader>
      <RcCollapse isOpened={isOpen}>{children}</RcCollapse>
    </div>
  );
};

export default styled(Collapse)`
  ${S.collapseCss}
`;
