import { createContext, useContext } from "react";
import type {
  CountryAlpha2,
  AddressField,
  CountryZipcodeBehaviour,
  IdentificationType,
} from "graphql/generated/schema.platform";
import type { ReturnProductQuestion, ShopRequestStartValidation } from "typing";
import {
  StartTicketValidation,
  FrequentlyAskedQuestionsPosition,
  BackgroundOption,
  FooterConfig,
} from "typing";

const defaultValues = {
  name: "Reversso",
  acceptExchanges: false,
  acceptReturns: false,
  acceptOtherAsReturnReason: false,
  allowCustomerEdits: true,
  allowNewAddressEdits: true,
  allowReturnAddressEdits: true,
  backgroundOption: BackgroundOption.COLOR_ONLY,
  backgroundImage: null as string | null,
  confirmTicketInstructions: "",
  orderExampleText: "",
  orderExampleImage: null as string | null,
  orderNamePlaceholder: "",
  platformTicketStartTitle: "",
  platformTitle: "",
  platformWelcomeMessage1: "",
  platformWelcomeMessage2: "",
  platformWelcomeMessage3: "",
  public: true,
  returnProductAdditionalInformationRequired: "",
  returnProductConfirmationText: "",
  returnProductPhotoRequired: "",
  returnProductPhotoDescription: [] as string[],
  returnProductPhotoExample: null as string | null,
  returnProductMinimumPhotos: 0,
  reverssoFooterPlatformConfig: FooterConfig.COLORED,
  ticketAuthorizationPendingText: "",
  url: "",
  allowedIdentifications: [] as IdentificationType[],
  defaultIdentification: "" as IdentificationType,
  defaultLanguage: "es", // enum
  allowGiftFlow: false,
  useProductRecommender: false,
  useSearchNewProductsByCollections: false,
  useNewProductsSearcher: false,
  logo: null as string | null,
  favicon: null as string | null,
  requestStartValidations: [] as ShopRequestStartValidation[],
  returnProductQuestions: [] as ReturnProductQuestion[],
  country: {} as {
    id: number;
    address2Name: string;
    alpha2: CountryAlpha2;
    cityName: string;
    countyName: string;
    hiddenFields: AddressField[];
    regionName: string;
    requiredFields: AddressField[];
    zipcodeBehaviors: CountryZipcodeBehaviour[];
  },
  ticketStartValidation: StartTicketValidation.CUSTOMER_EMAIL,
  ticketStartValidationAllowsEmpty: false,
  frequentlyAskedQuestionsPosition: FrequentlyAskedQuestionsPosition.RIGHT,
  frequentlyAskedQuestions: [] as { question: string; answer: string }[],
};

export type ShopContextType = typeof defaultValues;
export const ShopContext = createContext<ShopContextType>(
  defaultValues as ShopContextType
);
export const useShop = (): ShopContextType => useContext(ShopContext);
