import type { FC, ReactNode } from "react";
import { useState } from "react";
import * as S from "../styles";
import type {
  SiderNewProductTicketFragment,
  SiderNewProductTicketRequestFragment,
  SiderNewProductsTicketFragment,
  SiderNewProductsTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import { ProductSider } from "components";
import { Row } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Title } = Typography;

type ProductContainerProps = {
  onRemove: ((id: string) => Promise<unknown>) | undefined;
  product: SiderNewProductTicketFragment | SiderNewProductTicketRequestFragment;
};
const ProductContainer: FC<ProductContainerProps> = ({ onRemove, product }) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  return (
    <ProductSider
      key={product.id}
      product={{
        ...product,
        discounts: product.discounts.map((discount) =>
          "discount" in discount ? discount.discount : discount
        ),
      }}
      onRemove={
        onRemove
          ? {
              onClick: async () => {
                setLoadingDelete(true);
                return onRemove(product.id).catch(() =>
                  setLoadingDelete(false)
                );
              },
              loading: loadingDelete,
            }
          : undefined
      }
    />
  );
};

export type NewProductsListProps = {
  drawerContainer: boolean;
  data: SiderNewProductsTicketFragment | SiderNewProductsTicketRequestFragment;
  onRemove: ProductContainerProps["onRemove"];
  extra: ReactNode;
};
const NewProductsList: FC<NewProductsListProps> = ({
  drawerContainer,
  data,
  onRemove,
  extra,
}) => {
  const { t } = useTranslation("common");
  const [collapsed, setCollapsed] = useState(true);

  const { newProducts, newProductsTotalAmount } = data;

  return (
    <S.StyledCollapse
      $drawerContainer={drawerContainer}
      open={{
        is: collapsed,
        set: () => (!drawerContainer ? setCollapsed(!collapsed) : undefined),
      }}
      showArrow={false}
      title={
        <Row $block $justify="space-between" $gap={4}>
          <Row $align="center" $noWrap $gap={4}>
            <Title.Subheading>
              {t("components.sider.resolution.title", {
                count: newProducts.length,
              })}
            </Title.Subheading>
            {!drawerContainer && <S.Arrow $rotate={collapsed} />}
          </Row>
          <Title.Subheading>
            {newProductsTotalAmount.formattedAmount}
          </Title.Subheading>
        </Row>
      }
    >
      <S.ContentContainer $drawerContainer={drawerContainer}>
        <S.ProductsThread gap={8} leftGap={8}>
          {newProducts.map((product) => (
            <ProductContainer
              key={product.id}
              product={product}
              onRemove={onRemove}
            />
          ))}
          {newProducts.length > 0 && extra}
        </S.ProductsThread>
      </S.ContentContainer>
    </S.StyledCollapse>
  );
};

export default NewProductsList;
