import styled, { css } from "styled-components";
import Icon from "../../../atoms/Icon";
import * as AbstractStyles from "../Abstract/styles";

export const SearchIcon = styled(Icon)``;

export const searchCss = css`
  ${AbstractStyles.InnerContainer} {
    ${SearchIcon} {
      cursor: pointer;
    }
  }
`;
