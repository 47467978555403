import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";

export type LoadingSize = "small" | "medium" | "large";

export const handleSize: Record<
  LoadingSize,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  small: css`
    width: 24px;
    height: 24px;
    svg {
      font-size: 24px;
    }
  `,
  medium: css`
    width: 36px;
    height: 36px;
    svg {
      font-size: 36px;
    }
  `,
  large: css`
    width: 48px;
    height: 48px;
    svg {
      font-size: 48px;
    }
  `,
};
