import * as Sentry from "@sentry/nextjs";
import type { AppContext } from "next/app";
import type { ShopContextType } from "contexts";
import { camelize } from "utils";

const apiHost = process.env.NEXT_PUBLIC_APP_API_HOST; // no variable deconstruction for envs

type StatusType = "success" | "not-found" | "no-permission" | "internal-error";
class LoaderError extends Error {
  public errorType: StatusType;

  constructor(errorType: StatusType) {
    super();
    this.errorType = errorType;
  }
}

type ColorType = {
  primary_color: string; // eslint-disable-line camelcase
  text_color: string; // eslint-disable-line camelcase
  layout_body_background: string; // eslint-disable-line camelcase
  reversso_header_color: string; // eslint-disable-line camelcase
  reversso_header_background_color: string; // eslint-disable-line camelcase
  reversso_header_border_color: string; // eslint-disable-line camelcase
  btn_primary_bg: string; // eslint-disable-line camelcase
  btn_primary_color: string; // eslint-disable-line camelcase
};
export type DataType = {
  config: ShopContextType;
  colors: ColorType;
};
export type ShopConfigType = {
  status: StatusType;
  data?: DataType;
};
export const getShopConfig = async (
  appContext: AppContext
): Promise<ShopConfigType> => {
  const { req } = appContext.ctx;
  const { host, cookie } = req?.headers || {};

  try {
    const res = await fetch(`${apiHost}/platform/config/${host}`, {
      credentials: "include",
      // pass cookies to include auth
      headers: cookie ? { cookie } : undefined,
    });
    if (!res.ok) {
      if (res.status === 404) throw new LoaderError("not-found");
      if (res.status === 401) throw new LoaderError("no-permission");
      throw new LoaderError("internal-error");
    }

    const _data = await res.json();
    const data = { ..._data, config: camelize(_data.config) };

    // @ts-expect-error i18n library we use doesn't have good typescript
    await req?.i18n?.changeLanguage(data.config.defaultLanguage);

    return { status: "success", data };
  } catch (error) {
    if (error instanceof LoaderError) {
      // eslint-disable-next-line no-console
      console.error(`Error ${error.errorType}`);
      return { status: error.errorType, data: undefined };
    }

    // eslint-disable-next-line no-console
    console.error("Internal error");
    // eslint-disable-next-line no-console
    console.error(error);

    Sentry.withScope((scope) => {
      // See https://www.npmjs.com/package/@sentry/node
      scope.setTag("ssr", true);
      scope.setTag("host", host);
      scope.setTag("url", req?.url);
      scope.setTag("method", req?.method);
      scope.setContext("headers", req?.headers || null);
      //   scope.setContext("query", req?.query || null);
      //   scope.setContext("body", req?.body || null);
      Sentry.captureException(error);
    });

    return { status: "internal-error", data: undefined };
  }
};
