import type { FC } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type ImageContainerProps = {
  className?: string;
  size?: number;
  src: string;
  alt: string;
};
const ImageContainer: FC<ImageContainerProps> = ({
  className,
  size,
  src,
  alt,
}) => (
  <S.ContainerDiv className={className} $size={size || 50}>
    <img src={src} alt={alt} />
  </S.ContainerDiv>
);

export default styled(ImageContainer)``;
