/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketRequestTrackerQueryVariables = Types.Exact<{
  ticketRequestId: Types.Scalars['ID'];
}>;


export type TicketRequestTrackerQuery = { __typename?: 'QueryRoot', ticketRequest: { __typename?: 'TicketRequest', id: string, inputStatus: Types.TicketRequestInputStatus } };


export const TicketRequestTrackerDocument = gql`
    query TicketRequestTracker($ticketRequestId: ID!) {
  ticketRequest(id: $ticketRequestId) {
    id
    inputStatus
  }
}
    `;

/**
 * __useTicketRequestTrackerQuery__
 *
 * To run a query within a React component, call `useTicketRequestTrackerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketRequestTrackerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketRequestTrackerQuery({
 *   variables: {
 *      ticketRequestId: // value for 'ticketRequestId'
 *   },
 * });
 */
export function useTicketRequestTrackerQuery(baseOptions: Apollo.QueryHookOptions<TicketRequestTrackerQuery, TicketRequestTrackerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TicketRequestTrackerQuery, TicketRequestTrackerQueryVariables>(TicketRequestTrackerDocument, options);
      }
export function useTicketRequestTrackerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TicketRequestTrackerQuery, TicketRequestTrackerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TicketRequestTrackerQuery, TicketRequestTrackerQueryVariables>(TicketRequestTrackerDocument, options);
        }
export type TicketRequestTrackerQueryHookResult = ReturnType<typeof useTicketRequestTrackerQuery>;
export type TicketRequestTrackerLazyQueryHookResult = ReturnType<typeof useTicketRequestTrackerLazyQuery>;
export type TicketRequestTrackerQueryResult = Apollo.QueryResult<TicketRequestTrackerQuery, TicketRequestTrackerQueryVariables>;