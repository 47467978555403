/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import { TicketRequestAddressCouriersFragmentDoc } from '../../Couriers/graphql/generated/fragments.platform.generated';
import { TicketRequestAddressDetailsFragmentDoc } from '../../Details/graphql/generated/fragments.platform.generated';
export type TicketRequestAddressInnerFragment = { __typename?: 'TicketRequest', id: string, requiresReturnAddress: boolean | null, requiresReverseDelivery: boolean | null, courierConfig: { __typename?: 'CourierConfig', id: string, name: string | null } | null, customer: { __typename?: 'TicketRequestCustomer', id: string, allInformation: boolean } | null, returnAddress: { __typename?: 'TicketRequestReturnAddress', id: string, allInformation: boolean } | null };

export type TicketRequestAddressFragment = { __typename?: 'TicketRequest', id: string, requiresReturnAddress: boolean | null, requiresReverseDelivery: boolean | null, isClosed: boolean, quotesUpToDate: boolean, isExchange: boolean, courierConfig: { __typename?: 'CourierConfig', id: string, name: string | null } | null, customer: { __typename?: 'TicketRequestCustomer', id: string, allInformation: boolean, email: string | null, fullName: string | null, identification: string | null } | null, returnAddress: { __typename?: 'TicketRequestReturnAddress', id: string, allInformation: boolean, formatted: string | null, phoneNumber: string | null, country: { __typename?: 'Country', id: string } } | null };

export const TicketRequestAddressInnerFragmentDoc = gql`
    fragment TicketRequestAddressInner on TicketRequest {
  id
  courierConfig {
    id
    name
  }
  customer {
    id
    allInformation
  }
  requiresReturnAddress
  requiresReverseDelivery
  returnAddress {
    id
    allInformation
  }
}
    `;
export const TicketRequestAddressFragmentDoc = gql`
    fragment TicketRequestAddress on TicketRequest {
  ...TicketRequestAddressInner
  ...TicketRequestAddressCouriers
  ...TicketRequestAddressDetails
}
    ${TicketRequestAddressInnerFragmentDoc}
${TicketRequestAddressCouriersFragmentDoc}
${TicketRequestAddressDetailsFragmentDoc}`;