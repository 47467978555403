import styled, { css } from "styled-components";
import { handlePlacement, baseClass } from "./utils";
import type { TooltipProps } from "./index";

export const tooltipClass = baseClass;

type Props = {
  $block: boolean;
};
export const TooltipChildren = styled.span<Props>`
  display: inline-flex;
  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
    `}
`;

export const tooltipCss = css<TooltipProps>`
  &&&.${tooltipClass} {
    position: absolute;
    z-index: ${({ theme }) => theme.zIndex.tooltip};
    display: block;
    visibility: visible;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px 0 #00000014,
      0 9px 28px 8px #00000005;
    max-width: 250px;
    &-hidden {
      display: none;
    }

    .${tooltipClass}-inner {
      padding: 8px 12px;
      text-decoration: none;
      border-radius: ${({ theme }) => theme.borderRadius.default};
      color: ${({ theme }) => theme.colors.text.white};
      background-color: ${({ theme }) => theme.colors.surface.default.inverted};
      min-height: 34px;
    }

    .${tooltipClass}-arrow, .${tooltipClass}-arrow-inner {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }

    ${({ placement }) => handlePlacement[placement || "top"]}
    ${({ $showArrow }) =>
      !$showArrow &&
      css`
        .${tooltipClass}-arrow {
          display: none;
        }
      `}
  }
`;
