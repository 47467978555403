import type {
  ForwardedRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
} from "react";
import { forwardRef, useState } from "react";
import styled from "styled-components";
import TooltipIcon from "../../../atoms/TooltipIcon";
import type { RadioGroupContextValues } from "../context";
import RadioGroupContext from "../context";
import * as S from "./styles";

export type RadioGroupProps<ValueType> = {
  $block?: boolean;
  className?: string;
  $column?: boolean;
  children?: ReactNode;
  value?: RadioGroupContextValues<ValueType>["value"];
  name?: RadioGroupContextValues<ValueType>["name"];
  disabled?: RadioGroupContextValues<ValueType>["disabled"];
  onChange?: RadioGroupContextValues<ValueType>["onChange"];
  label?: ReactNode;
  labelRequired?: boolean;
  tooltip?: ReactNode;
  subLabel?: ReactNode;
};
const RadioGroupInner = <ValueType,>(
  {
    children,
    label,
    labelRequired,
    tooltip,
    subLabel,
    ...props
  }: RadioGroupProps<ValueType>,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement => {
  const [value, setValue] = useState<ValueType>();

  const onRadioChange = (val: ValueType) => {
    const lastValue = value;
    if (!("value" in props)) {
      setValue(val);
    }
    const { onChange } = props;
    if (onChange && val !== lastValue) {
      onChange(val);
    }
  };

  const { className } = props;

  return (
    <RadioGroupContext.Provider
      value={{
        onChange: onRadioChange,
        value: props.value,
        disabled: props.disabled,
        name: props.name,
        ref,
      }}
    >
      {label && (
        <S.Label $labelRequired={labelRequired} wrapper="p">
          {label}
          {tooltip && <TooltipIcon tooltip={tooltip} />}
        </S.Label>
      )}
      {subLabel && (
        <S.SubLabel wrapper="p" $color="subdued">
          {subLabel}
        </S.SubLabel>
      )}
      <div className={className}>{children}</div>
    </RadioGroupContext.Provider>
  );
};

const RadioGroup: (<ValueType = unknown>(
  props: PropsWithChildren<RadioGroupProps<ValueType>> & {
    ref?: Ref<HTMLInputElement>;
  }
) => ReactElement) &
  string = styled(forwardRef(RadioGroupInner))`
  ${S.radioGroupCss}
`;

export default RadioGroup;
