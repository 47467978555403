import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useShopPasswordMutation } from "./graphql/generated/mutations.platform.generated";
import * as S from "./styles";
import { Input, Button, FormItem, Card } from "design";
import { LayoutBase } from "layouts";
import { handleErrors } from "utils";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

const ShopPassword: FC = () => {
  const { t } = useTranslation("common");
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm<{ password: string }>();

  const [mutate, { loading }] = useShopPasswordMutation({
    onCompleted: ({ platformLogin: { errors: _errors, success } }) => {
      if (_errors?.length) handleErrors(_errors, getValues, setError);
      if (success) window.location.reload();
    },
  });

  return (
    <LayoutBase showBackgroundImage={false}>
      <S.RowMain>
        <Card>
          <form
            onSubmit={handleSubmit((values) => mutate({ variables: values }))}
          >
            <FormItem error={errors.password?.message}>
              <Input.Password
                label={t("password_wrapper.label")}
                {...register("password", {
                  required: t("password_wrapper.input_required") as string,
                })}
                hasError={!!errors.password}
              />
            </FormItem>
            <Button type="primary" htmlType="submit" $block loading={loading}>
              {t("password_wrapper.button")}
            </Button>
          </form>
        </Card>
      </S.RowMain>
    </LayoutBase>
  );
};

export default ShopPassword;
