import type { TFunction } from "next-i18next";
import type { ReactElement } from "react";
import { forwardRef, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AddressContext } from "./context";
import type { BaseFormValues, County } from "./typing";
import type { AFTER_LOCALITIES } from "./utils";
import type { InputSearchProps } from "design";
import { FormItem, Input } from "design";
import {
  AddressField,
  CountryZipcodeBehaviour,
} from "graphql/generated/schema.platform";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

const validateZipcode = (
  value: string | null | undefined,
  t: TFunction,
  correctZipcode: (
    v: (CountryZipcodeBehaviour | typeof AFTER_LOCALITIES)[]
  ) => boolean,
  counties: County[]
): string | undefined => {
  if (!value) return undefined;
  if (!counties?.length) return undefined; // if no counties it will show other error
  if (
    correctZipcode([
      CountryZipcodeBehaviour.BEFORE_ADDRESS,
      CountryZipcodeBehaviour.BEFORE_LOCALITIES,
    ])
  )
    return undefined;
  return t("components.address_form.zipcode.invalid") as string;
};

const ZipcodeInner = forwardRef<HTMLInputElement, InputSearchProps>(
  (props, ref) => {
    const { t } = useTranslation("common");
    const {
      country: { zipcodeBehaviors },
      editableFields,
      correctZipcode,
      counties,
    } = useContext(AddressContext);

    if (!zipcodeBehaviors?.length)
      return (
        <Input
          ref={ref}
          {...props}
          disabled={editableFields && !editableFields.includes(props.name)}
        />
      );
    return (
      <Input.Search
        ref={ref}
        // allowClear
        // onSearch={onSearch}
        {...props}
        autoComplete="nope"
        disabled={
          editableFields &&
          !editableFields.includes(props.name) &&
          !!validateZipcode(
            props.value ? String(props.value) : undefined,
            t,
            correctZipcode,
            counties
          )
        }
      />
    );
  }
);

const Zipcode = <FormValues extends BaseFormValues = BaseFormValues>({
  onChange,
  ...rest
}: InputSearchProps): ReactElement | null => {
  const { t } = useTranslation("common");
  const {
    formState: { errors },
    control,
  } = useFormContext<FormValues>();
  const {
    country: { hiddenFields },
    correctZipcode,
    counties,
  } = useContext(AddressContext);
  const showZipcode = !hiddenFields?.includes(AddressField.ZIPCODE);

  if (!showZipcode) return null;
  return (
    <FormItem $block error={errors.zipcode?.message}>
      <Controller
        control={control}
        // @ts-expect-error no generic for react-hook-form
        name="zipcode"
        rules={{
          required: t("components.address_form.zipcode.required") as string,
          validate: (value) =>
            validateZipcode(value, t, correctZipcode, counties),
        }}
        render={({ field }) => (
          <ZipcodeInner
            {...rest}
            {...field}
            label={t("components.address_form.zipcode.label")}
            hasError={!!errors.zipcode}
            value={field.value || undefined}
            onChange={(e) => {
              onChange?.(e);
              return field.onChange(e);
            }}
          />
        )}
      />
    </FormItem>
  );
};

export default Zipcode;
