import { useRouter } from "next/router";
import type { FC } from "react";
import styled from "styled-components";
import * as S from "./styles";
import { Steps as ReverssoSteps } from "design";
import { useTicketRequestTracker } from "hooks";

const { Step } = ReverssoSteps;
const { statuses } = useTicketRequestTracker;

type StepsProps = {
  className?: string;
};
const Steps: FC<StepsProps> = ({ className }) => {
  const { route } = useRouter();
  const currentStepIdx = statuses.findIndex(({ path }) => path === route);

  return (
    <S.ContainerRow className={className} $block $justify="center">
      <ReverssoSteps
        current={Math.max(currentStepIdx, 0)}
        verticalOnMobile={false}
        direction="horizontal"
        type="dots"
      >
        {statuses.map((_, idx) => (
          <Step key={`step-${idx.toString()}`} />
        ))}
      </ReverssoSteps>
    </S.ContainerRow>
  );
};

export default styled(Steps)``;
