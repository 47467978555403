/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
export type TicketRequestAddressDetailsAddressFragment = { __typename?: 'TicketRequest', id: string, isClosed: boolean, isExchange: boolean, requiresReverseDelivery: boolean | null, returnAddress: { __typename?: 'TicketRequestReturnAddress', id: string, formatted: string | null, phoneNumber: string | null, allInformation: boolean } | null };

export const TicketRequestAddressDetailsAddressFragmentDoc = gql`
    fragment TicketRequestAddressDetailsAddress on TicketRequest {
  id
  isClosed
  isExchange
  requiresReverseDelivery
  returnAddress {
    id
    formatted(includeAddress2: true, validate: true)
    phoneNumber
    allInformation
  }
}
    `;