import type { ReactElement } from "react";
import type { Column, TableOptions } from "react-table";
import { useTable, useFlexLayout } from "react-table";
import { useSticky } from "react-table-sticky";
import { useDesign } from "../../../setup/context";
import DesktopTable from "../DesktopTable";
import type { MobileTableProps } from "../MobileTable";
import MobileTable from "../MobileTable";

export type MobileOrDesktopTableProps<D extends object> = {
  className?: string;
  data: D[];
  loading?: boolean;
  mobileTable: Omit<MobileTableProps<D>, "data" | "loading" | "onClick">;
  desktopTable: {
    columns: ReadonlyArray<Column<D>>;
    addBorderTop?: boolean;
    useTableOptions?: Omit<TableOptions<D>, "data" | "columns">;
  };
  onRowClick?: (data: D) => void;
  cacheDataIfUndefined?: boolean;
};

const Table = <D extends object>({
  className,
  data,
  mobileTable,
  desktopTable,
  loading,
  onRowClick,
}: MobileOrDesktopTableProps<D>): ReactElement => {
  const {
    device: { isDesktop },
  } = useDesign();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns: desktopTable.columns,
        ...desktopTable.useTableOptions,
      },
      useFlexLayout,
      useSticky
    );

  if (!isDesktop) {
    return (
      <MobileTable
        className={className}
        data={data}
        loading={loading}
        onClick={onRowClick}
        {...mobileTable}
      />
    );
  }

  return (
    <div className={className}>
      <DesktopTable
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        addBorderTop={desktopTable?.addBorderTop}
        onRowClick={onRowClick}
        loading={loading}
        className={className}
      />
    </div>
  );
};

export default Table;
