import type { ReactNode, FC } from "react";
import type { IconType } from "react-icons";
import { HiCheck, HiX } from "react-icons/hi";
import styled from "styled-components";
import Icon from "../../../atoms/Icon";
import * as S from "./styles";

export type StepProps = {
  isVertical?: boolean;
  status?: "error" | "process" | "finish" | "wait";
  stepNumber?: number;
  className?: string;
  $active?: boolean;
  title?: ReactNode;
  description?: ReactNode;
  icon?: IconType;
  type?: "dots" | "numbers" | "checks";
};
const Step: FC<StepProps> = ({
  isVertical,
  title,
  description,
  status,
  stepNumber,
  icon: PropIcon,
  type,
  ...props
}) => {
  const iconSize = type === "dots" ? 10 : 18;
  const stepIcon = type === "numbers" ? stepNumber : <div />;
  const handleIcon = () => {
    if (status === "finish") return <HiCheck size={iconSize} />;
    if (status === "error") return <HiX size={iconSize} />;
    if (PropIcon) return <PropIcon size={iconSize} />;
    return stepIcon;
  };

  return (
    <div key={`rev-step-${stepNumber}`} {...props}>
      {isVertical && <S.StepTail />}
      <S.StepContentContainer>
        <S.StepIcon $isDotsType={type === "dots"}>
          <Icon icon={handleIcon()} />
        </S.StepIcon>
        {(title || description) && (
          <S.StepContent>
            {title && <S.Subheading>{title}</S.Subheading>}
            {description && (
              <S.Caption $color="subdued">{description}</S.Caption>
            )}
          </S.StepContent>
        )}
      </S.StepContentContainer>
    </div>
  );
};

export default styled(Step)`
  ${S.stepCss}
`;
