import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";
import type { RequestStartValidationValues } from "typing";

export type SharedContext = {
  requestStartValidation: RequestStartValidationValues[];
};
export const sharedContextDefaultValue: SharedContext = {
  requestStartValidation: [],
};
type PlatformContextValues = {
  loadingTip: string | null;
  setLoadingTip: Dispatch<SetStateAction<string | null>>;
  sharedState: SharedContext;
  setSharedState: Dispatch<SetStateAction<SharedContext>>;
};
export const PlatformContext = createContext<PlatformContextValues>({
  loadingTip: null,
  setLoadingTip: () => null,
  sharedState: sharedContextDefaultValue,
  setSharedState: () => null,
});
export const usePlatform: () => PlatformContextValues = () =>
  useContext(PlatformContext);
