import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";
import { tooltipClass } from "../Tooltip/styles";
import type { PopoverProps } from "./index";

const basePlacementCss = (placement: PopoverProps["placement"]) => {
  switch (placement) {
    case "bottom":
    case "bottomLeft":
    case "bottomRight":
      return css`
        .${tooltipClass}-arrow {
          border-bottom-color: ${({ theme }) =>
            theme.colors.surface.default.default};
        }
      `;
    case "left":
    case "leftTop":
    case "leftBottom":
      return css`
        .${tooltipClass}-arrow {
          border-left-color: ${({ theme }) =>
            theme.colors.surface.default.default};
        }
      `;
    case "right":
    case "rightTop":
    case "rightBottom":
      return css`
        .${tooltipClass}-arrow {
          border-right-color: ${({ theme }) =>
            theme.colors.surface.default.default};
        }
      `;
    case "top":
    case "topLeft":
    case "topRight":
    default:
      return css`
        .${tooltipClass}-arrow {
          border-top-color: ${({ theme }) =>
            theme.colors.surface.default.default};
        }
      `;
  }
};

export const handlePlacement: Record<
  NonNullable<PopoverProps["placement"]>,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  top: css`
    &&&.${tooltipClass}-placement-top {
      ${basePlacementCss("top")}
    }
  `,
  topLeft: css`
    &&&.${tooltipClass}-placement-topLeft {
      ${basePlacementCss("topLeft")}
    }
  `,
  topRight: css`
    &&&.${tooltipClass}-placement-topRight {
      ${basePlacementCss("topRight")}
    }
  `,
  bottom: css`
    &&&.${tooltipClass}-placement-bottom {
      ${basePlacementCss("bottom")}
    }
  `,
  bottomLeft: css`
    &&&.${tooltipClass}-placement-bottomLeft {
      ${basePlacementCss("bottomLeft")}
    }
  `,
  bottomRight: css`
    &&&.${tooltipClass}-placement-bottomRight {
      ${basePlacementCss("bottomRight")}
    }
  `,
  left: css`
    &&&.${tooltipClass}-placement-left {
      ${basePlacementCss("left")}
    }
  `,
  leftTop: css`
    &&&.${tooltipClass}-placement-leftTop {
      ${basePlacementCss("leftTop")}
    }
  `,
  leftBottom: css`
    &&&.${tooltipClass}-placement-leftBottom {
      ${basePlacementCss("leftBottom")}
    }
  `,
  right: css`
    &&&.${tooltipClass}-placement-right {
      ${basePlacementCss("right")}
    }
  `,
  rightTop: css`
    &&&.${tooltipClass}-placement-rightTop {
      ${basePlacementCss("rightTop")}
    }
  `,
  rightBottom: css`
    &&&.${tooltipClass}-placement-rightBottom {
      ${basePlacementCss("rightBottom")}
    }
  `,
};
