import Base from "./BaseCard";
import BaseCard from "./Card";

export * as BaseCardStyles from "./BaseCard/styles";
export * as CardStyles from "./Card/styles";

export type { BaseCardProps } from "./BaseCard";
export type { CardProps } from "./Card";

type CompoundedComponent = typeof BaseCard & {
  Base: typeof Base;
};

const Card = BaseCard as CompoundedComponent;
Card.Base = Base;

export default Card;
