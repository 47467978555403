import { useRouter } from "next/router";
import { useEffect } from "react";
import { useTicketRequestUpdateInputStatusMutation } from "./graphql/generated/mutations.platform.generated";
import { useTicketRequestTrackerQuery } from "./graphql/generated/queries.platform.generated";
import { TICKET_REQUEST_STATUSES } from "./utils";
import { firstElement } from "utils";

const useTicketRequestTracker = () => {
  const {
    pathname,
    query: { ticketRequestId },
  } = useRouter();

  const _ticketRequestId = firstElement(ticketRequestId);

  const [mutate] = useTicketRequestUpdateInputStatusMutation();
  const { data } = useTicketRequestTrackerQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    variables: { ticketRequestId: _ticketRequestId! },
    skip: !_ticketRequestId,
    fetchPolicy: "cache-only",
  });
  const { inputStatus: currentInputStatus } = data?.ticketRequest || {};

  useEffect(() => {
    if (!currentInputStatus || !_ticketRequestId) return;

    const currentStatusIdx = TICKET_REQUEST_STATUSES.findIndex(
      ({ status }) => status === currentInputStatus
    );

    if (currentStatusIdx === -1) return;

    const urlStatusIdx = TICKET_REQUEST_STATUSES.findIndex(
      ({ path }) => path === pathname
    );

    if (urlStatusIdx > currentStatusIdx) {
      const newStatus = TICKET_REQUEST_STATUSES[urlStatusIdx].status;

      mutate({
        variables: { ticketRequestId: _ticketRequestId, status: newStatus },
        optimisticResponse: {
          ticketRequestUpdate: {
            ticketRequest: {
              id: _ticketRequestId,
              inputStatus: newStatus,
              __typename: "TicketRequest",
            },
            __typename: "TicketRequestUpdatePayload",
          },
          __typename: "Mutation",
        },
      });
    }
  }, [pathname, currentInputStatus]);
};

export default useTicketRequestTracker;
