import styled, { css } from "styled-components";
import Typography from "../../../../deprecated/Typography";
import Row from "../../../Row";
import type { MetaProps } from "./index";

export const MetaTitle = styled(Typography.Text.Body)``;
export const MetaDescription = styled(Typography.Text.Body)`
  color: ${({ theme }) => theme.colors.text.subdued};
`;

export const MetaCss = css<MetaProps>`
  ${({ linkHref, onClick, disabled }) =>
    (linkHref || onClick) &&
    css`
      ${MetaTitle} {
        cursor: ${disabled ? "not-allowed" : "pointer"};
        color: ${({ theme }) =>
          disabled
            ? theme.colors.text.disabled
            : theme.colors.action.primary.default};
        :hover {
          color: ${({ theme }) =>
            !disabled && theme.colors.action.primary.hovered};
        }
      }
    `}
`;

type BadgeRowProps = {
  $closeToTitle?: boolean;
};
export const BadgeRow = styled(Row)<BadgeRowProps>`
  ${({ $closeToTitle }) =>
    $closeToTitle
      ? css`
          margin-right: auto;
        `
      : css`
          margin-left: auto;
        `}
`;
