// File from nextjs: https://github.com/vercel/next.js/tree/canary/examples/with-apollo

/* eslint-disable no-console */
import type { NormalizedCacheObject } from "@apollo/client";
import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  createHttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useMemo } from "react";
import { toast } from "design";

const development = process.env.NEXT_PUBLIC_NODE_ENV === "development";

const links = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      toast(
        "error",
        "Hubo un error de conexión. Por favor intenta nuevamente."
      );
    }
    if (development) {
      console.log("GraphqlError", graphQLErrors);
      console.log("NetworkErros", networkError);
    }
  }),
  createHttpLink({
    credentials: "include",
    uri: "/graphql",
  }),
]);

const createApolloClient = (initialData?: NormalizedCacheObject) =>
  // https://www.apollographql.com/docs/react/performance/server-side-rendering/#overriding-fetch-policies-during-initialization
  new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: links,
    cache: !initialData
      ? new InMemoryCache()
      : new InMemoryCache().restore(initialData),
  });

let apolloClient: ReturnType<typeof createApolloClient>;
export const getClient = (
  initialState?: NormalizedCacheObject
): typeof apolloClient => {
  const _apolloClient = apolloClient ?? createApolloClient(initialState);

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

export const addApolloState = (
  client: typeof apolloClient,
  pageProps: {
    props?: {
      [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
    };
  }
) => {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export const useInitializeClient = (pageProps: {
  [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
}): typeof apolloClient => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return useMemo(() => getClient(state), [state]);
};
