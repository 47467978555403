import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";
import type { ProductSiderProps } from "components";

type TicketRequestLayoutContextProps = {
  notifyProduct: (product: ProductSiderProps["product"]) => void;
  setDrawerVisible: Dispatch<SetStateAction<boolean>>;
};
export const TicketRequestLayoutContext = createContext<
  TicketRequestLayoutContextProps | undefined
>(undefined);

export const useTicketRequestLayout = () => {
  const values = useContext(TicketRequestLayoutContext);
  if (!values) throw new Error("TicketRequestLayoutContext not initialized");
  return values;
};
