import Item from "./Item";
import Meta from "./Meta";

export * as ListItemStyles from "./Item/styles";
export * as ListItemMetaStyles from "./Meta/styles";

export type { ListItemProps } from "./Item";
export type { MetaProps as ListItemMetaProps } from "./Meta";

type CompoundedComponent = typeof Item & {
  Meta: typeof Meta;
};

const ListItem = Item as CompoundedComponent;
ListItem.Meta = Meta;

export default ListItem;
