import type { FC } from "react";
import styled from "styled-components";
import { useDesign } from "../../../setup/context";
import type { DesktopModalProps } from "../DesktopModal";
import DesktopModal from "../DesktopModal";
import type { MobileModalProps } from "../MobileModal";
import MobileModal from "../MobileModal";
import type { BaseProps } from "../types";

export type ModalProps = BaseProps & {
  desktopProps?: Omit<DesktopModalProps, keyof BaseProps>;
  mobileProps?: Omit<MobileModalProps, keyof BaseProps>;
};
const Modal: FC<ModalProps> = ({ desktopProps, mobileProps, ...rest }) => {
  const {
    device: { isMobile },
  } = useDesign();

  if (isMobile) return <MobileModal {...mobileProps} {...rest} />;

  return <DesktopModal {...desktopProps} {...rest} />;
};

export default styled(Modal)``;
