import styled from "styled-components";
import Row from "../../Row";

export const ActionsFormContainer = styled(Row)`
  margin: 20px auto 0;
  max-width: 944px;
  @media (max-width: 1180px) {
    margin: 20px auto 0;
    max-width: 607px;
  }
`;

export const ButtonsContainer = styled(Row)`
  width: 607px;
`;
