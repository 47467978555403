import styled, { css } from "styled-components";
import Row from "../../atoms/Row";
import Typography from "../../deprecated/Typography";
import type { UploadProps } from "./index";

const uploadClass = "rc-upload";

export const UploadContainer = styled(Row)`
  margin-top: 8px;
  max-width: 271px;
`;

export const UploadInner = styled.div`
  width: 76px;
  height: 76px;
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  overflow: hidden;
  transition: border-color 0.2s ease-in-out;
  .${uploadClass} {
    display: grid;
    align-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const DefaultChild = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.text.subdued};
  transition: color 0.2s ease-in-out;
`;

export const UploadActions = styled.div`
  margin-left: 20px;
`;

export const Container = styled.div<UploadProps>`
  label {
    display: flex;
    -webkit-touch-callout: none;
    user-select: none;
    ${Typography} {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px;
    }
  }

  ${UploadInner} {
    border: 1px solid ${({ theme }) => theme.colors.border.neutral.subdued};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    &:hover {
      cursor: pointer;
      border-color: ${({ theme }) => theme.colors.border.primary.default};
      ${DefaultChild} {
        color: ${({ theme }) => theme.colors.text.primary};
      }
    }
    ${({ hasError }) =>
      hasError &&
      css`
        border-color: ${({ theme }) => theme.colors.border.critical.default}
          ${DefaultChild} {
          color: ${({ theme }) => theme.colors.text.critical};
        }
        &:hover {
          border-color: ${({ theme }) => theme.colors.border.critical.default}
            ${DefaultChild} {
            color: ${({ theme }) => theme.colors.text.critical};
          }
        }
      `};
  }
`;
