import { formatCPF } from "@brazilian-utils/brazilian-utils";
import { format as formatRut } from "rut.js";
import { IdentificationType } from "graphql/generated/schema.platform";

const formatters: Record<IdentificationType, (v: string) => string> = {
  [IdentificationType.ARG_DNI]: (value) => value,
  [IdentificationType.BRA_CPF]: formatCPF,
  [IdentificationType.CHL_RUT]: formatRut,
  [IdentificationType.COL_CC]: (value) => value,
  [IdentificationType.COL_NIT]: (value) => value,
  [IdentificationType.ECU_DNI]: (value) => value,
  [IdentificationType.ECU_RUC]: (value) => value,
  [IdentificationType.MXN_RFC]: (value) => value,
  [IdentificationType.PASSPORT]: (value) => value,
  [IdentificationType.PER_DNI]: (value) => value,
  [IdentificationType.PER_RUC]: (value) => value,
  [IdentificationType.UYU_DNI]: (value) => value,
};

export default formatters;
