let lastVersion: string | undefined;
let lastCheck: number | undefined;

// do not check if new page was before 30 seconds ago
const DELTA = 30 * 1000;

const versionReload = (): void => {
  const now = new Date().valueOf();
  if (lastCheck && now - lastCheck < DELTA) return;
  lastCheck = now;

  fetch("/version.json")
    .then((res) => res.text())
    .then((text) => {
      if (!text) return;
      if (lastVersion && lastVersion !== text) window.location.reload();
      lastVersion = text;
    })
    .catch();
};

export default versionReload;
