import htmlParse from "html-react-parser";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import styled from "styled-components";
import * as S from "./styles";
import { useShop } from "contexts";
import { Divider } from "design";
import { Typography } from "design/deprecated";

const { Title } = Typography;

type FAQProps = {
  className?: string;
};
const FAQ: FC<FAQProps> = ({ className }) => {
  const { frequentlyAskedQuestions } = useShop();
  const [openCollapse, setOpenCollapse] = useState<number | null>(null);

  return (
    <>
      {frequentlyAskedQuestions
        .map(({ question, answer }, i) => (
          <S.FAQCollapse
            className={className}
            open={{
              is: openCollapse === i,
              set: () => setOpenCollapse(openCollapse === i ? null : i),
            }}
            title={<Title.Subheading>{question}</Title.Subheading>}
            // eslint-disable-next-line react/no-array-index-key
            key={`${question}-${i}`}
          >
            {htmlParse(answer)}
          </S.FAQCollapse>
        ))
        .reduce(
          (acc: ReactNode, x) =>
            acc === null ? [x] : [acc, <Divider $marginY={4} />, x],
          null
        )}
    </>
  );
};
export default styled(FAQ)``;
