import { HiOutlineChevronDown } from "react-icons/hi";
import styled, { css } from "styled-components";
import { Collapse, CollapseStyles, Thread } from "design";

type Props = {
  $drawerContainer: boolean;
};
export const StyledCollapse = styled(Collapse).attrs({
  showArrow: false,
})<Props>`
  width: 100%;
  ${CollapseStyles.CollapseHeader} {
    cursor: ${({ $drawerContainer }) =>
      $drawerContainer ? "text" : "pointer"};
  }
`;

type ArrowProps = {
  $rotate: boolean;
};
export const Arrow = styled(HiOutlineChevronDown)<ArrowProps>`
  transition: transform 0.3s ease-in-out;
  ${({ $rotate }) =>
    $rotate
      ? css`
          transform: rotate(-180deg);
        `
      : css`
          transform: rotate(0deg);
        `};
`;

export const ProductsThread = styled(Thread)`
  margin-top: 12px;
`;

export const ContentContainer = styled.div<Props>`
  ${({ $drawerContainer }) =>
    !$drawerContainer &&
    css`
      max-height: 316px;
    `}
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${({ theme }) => theme.breakpoints.tablet.css} {
    max-height: unset;
  }
`;
