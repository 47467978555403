import Text from "./Text";
import Title from "./Title";
import BaseTypography from "./Typography";

export * as TypographyStyles from "./styles";

export type { TypographyProps } from "./Typography";

type CompoundedTypography = typeof BaseTypography & {
  Title: typeof Title;
  Text: typeof Text;
};

const Typography = BaseTypography as CompoundedTypography;
Typography.Title = Title;
Typography.Text = Text;

export default Typography;
