import type { FC, ReactElement } from "react";
import { HiOutlineTrash } from "react-icons/hi";
import * as S from "./styles";
import { ImageContainer } from "components";
import { Button, Row, Tooltip, Typography } from "design";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Body } = Typography;

export type ProductSiderProps = {
  product: {
    discounts:
      | {
          name: string;
          percentage: number;
          fixed: { amount: number; formattedAmount: string };
        }[]
      | null;
    imageUrl: string | null;
    listPrice: {
      formattedAmount: string;
    } | null;
    name: string;
    options: {
      name: string;
      value: string;
    }[];
    reasonTitle?: string;
    price: {
      formattedAmount: string;
    };
  };
  onRemove?: {
    onClick: () => void;
    loading?: boolean;
  };
};
const ProductSider: FC<ProductSiderProps> = ({ onRemove, product }) => {
  const { t } = useTranslation("common");
  const {
    imageUrl,
    name: productName,
    options,
    reasonTitle,
    price,
    discounts,
    listPrice,
  } = product;
  const hasDiscounts = discounts && discounts.length > 0;
  return (
    <Row $noWrap $block $gap={8}>
      {imageUrl && (
        <ImageContainer size={44} src={imageUrl} alt={productName} />
      )}
      <Row $column $block>
        <S.ContentDiv>
          <Row $column>
            <Body.MD>{productName}</Body.MD>
            {hasDiscounts && (
              <S.DiscountsRow $column $gap={4}>
                {discounts.map(({ name, fixed, percentage }) => {
                  const discountBadges: ReactElement[] = [];
                  if (percentage !== 0) {
                    const discountText = `${percentage * 100}% ${name}`;
                    discountBadges.push(
                      <Tooltip title={discountText}>
                        <S.DiscountBadge text={discountText} $type="primary" />
                      </Tooltip>
                    );
                  }
                  if (fixed.amount > 0) {
                    const discountText = `${fixed.formattedAmount} ${name}`;
                    discountBadges.push(
                      <Tooltip title={discountText}>
                        <S.DiscountBadge text={discountText} $type="primary" />
                      </Tooltip>
                    );
                  }
                  return discountBadges;
                })}
              </S.DiscountsRow>
            )}
            {options.map(({ name, value }) => (
              <Body.SM key={`product-sider-option-${name}`} $color="subdued">
                {name}: {value}
              </Body.SM>
            ))}
            {reasonTitle && (
              <Body.SM $color="subdued">
                {t("components.sider.reason")}
                {reasonTitle}
              </Body.SM>
            )}
            {onRemove && (
              <Button
                type="plain-critical"
                rightIcon={<HiOutlineTrash size={20} />}
                loading={onRemove?.loading}
                onClick={onRemove.onClick}
              >
                {t("components.sider.remove.button")}
              </Button>
            )}
          </Row>
          <Row $column $align="end">
            <Body.MD $color="subduedBold">{price.formattedAmount}</Body.MD>
            {hasDiscounts &&
              listPrice &&
              listPrice.formattedAmount !== price.formattedAmount && (
                <Body.SM $color="subdued" $textDecoration="line-through">
                  {listPrice.formattedAmount}
                </Body.SM>
              )}
          </Row>
        </S.ContentDiv>
      </Row>
    </Row>
  );
};

export default ProductSider;
