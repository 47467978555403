import { css } from "styled-components";

const containerClass = "Toastify__toast-container";
const toastClass = "Toastify__toast";
const bodyClass = "Toastify__toast-body";
const iconClass = "Toastify__toast-icon";

export const toastContainerCss = css`
  &&&.${containerClass} {
    pointer-events: none;
    &--top-center {
      top: 8px;
      .${toastClass} {
        margin: 0 auto 8px;
      }
    }
    &--bottom-center {
      bottom: 8px;
      .${toastClass} {
        margin: 8px auto 0;
      }
    }
    &--top-left {
      top: 8px;
      left: 8px;
      .${toastClass} {
        margin: 0 auto 8px 0;
      }
    }
    &--bottom-left {
      bottom: 8px;
      left: 8px;
      .${toastClass} {
        margin: 8px auto 0 0;
      }
    }
    &--top-right {
      top: 8px;
      right: 8px;
      .${toastClass} {
        margin: 0 0 8px auto;
      }
    }
    &--bottom-right {
      bottom: 8px;
      right: 8px;
      .${toastClass} {
        margin: 8px 0 0 auto;
      }
    }

    /* toast Component styles */
    .${toastClass} {
      padding: 12px;
      border-radius: ${({ theme }) => theme.borderRadius.toast};
      box-shadow: unset;
      font-size: 14px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.text.white};
      min-height: 24px;
      width: fit-content;
      max-width: 320px;
      pointer-events: all;
      cursor: unset;
      &.${toastClass} {
        &--success {
          background-color: ${({ theme }) =>
            theme.colors.surface.success.important};
        }
        &--error {
          background-color: ${({ theme }) =>
            theme.colors.surface.critical.important};
        }
        &--warning {
          background-color: ${({ theme }) =>
            theme.colors.surface.warning.important};
        }
        &--info {
          background-color: ${({ theme }) =>
            theme.colors.surface.default.inverted};
        }
      }
      .${bodyClass} {
        margin: unset;
        padding: unset;
        .${iconClass} {
          font-size: 20px;
          margin-right: 12px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .${containerClass} {
      width: 100vw;
      padding-top: 8px;
      .${toastClass} {
        margin: 0 auto;
      }
    }
  }
`;
