import type { ReactElement } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useController } from "react-hook-form";
import { formatters, validators } from "./utils";
import { useShop } from "contexts";
import { FormItem, Input, Select } from "design";
import type { IdentificationType } from "graphql/generated/schema.platform";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

export type FormInputIdentificationFormValues = {
  identificationType: IdentificationType;
  identification: string | null;
};

type InnerInputProps<FV extends FormInputIdentificationFormValues> = {
  identificationType: IdentificationType;
  formMethods: UseFormReturn<FV>;
  showLabel: boolean;
  required: boolean;
  disabled: boolean;
};
const InnerInput = <FV extends FormInputIdentificationFormValues>({
  identificationType,
  formMethods,
  showLabel,
  required,
  disabled,
}: InnerInputProps<FV>): ReactElement => {
  const { t } = useTranslation("common");
  const { defaultIdentification } = useShop();

  const {
    register,
    formState: { errors },
  } = formMethods;

  const _identificationType = identificationType || defaultIdentification;

  return (
    <FormItem $block error={errors.identification?.message}>
      <Input
        // @ts-expect-error no generic for react hook form
        {...register("identification", {
          required: {
            value: required,
            message: t("components.form_input.customer.enter_field", {
              fieldName: t(
                `components.form_input.identification_types.${_identificationType}`
              ),
            }),
          },
          validate: validators[_identificationType](t),
        })}
        label={
          showLabel &&
          t(`components.form_input.identification_types.${_identificationType}`)
        }
        normalize={formatters[_identificationType]}
        hasError={!!errors.identification}
        disabled={disabled}
      />
    </FormItem>
  );
};

type FormInputIdentificationInputProps<
  FV extends FormInputIdentificationFormValues
> = {
  formMethods: UseFormReturn<FV>;
  required?: boolean;
  editableFields: string[] | undefined;
};
const FormInputIdentificationInput = <
  FV extends FormInputIdentificationFormValues
>({
  formMethods,
  required = true,
  editableFields,
}: FormInputIdentificationInputProps<FV>): ReactElement | null => {
  const { t } = useTranslation("common");
  const { allowedIdentifications, defaultIdentification } = useShop();

  const {
    control,
    formState: { errors },
  } = formMethods;

  if (allowedIdentifications.length === 0) return null;

  const { field: fieldIdentificationType } = useController({
    control,
    // @ts-expect-error no generic for react hook form
    name: "identificationType",
    // @ts-expect-error no generic for react hook form
    defaultValue: defaultIdentification,
  });

  if (allowedIdentifications.length === 1)
    return (
      <InnerInput
        identificationType={fieldIdentificationType.value as IdentificationType}
        formMethods={formMethods}
        showLabel
        required={required}
        disabled={
          !!(editableFields && !editableFields.includes("identification"))
        }
      />
    );

  return (
    <>
      <FormItem error={errors.identificationType?.message}>
        <Select
          {...fieldIdentificationType}
          options={allowedIdentifications.map((identification) => ({
            key: `identification-type-${identification}`,
            value: identification,
            label: t(
              `components.form_input.identification_types.${identification}`
            ),
          }))}
          hasError={!!errors.identificationType}
        />
      </FormItem>
      <InnerInput
        identificationType={fieldIdentificationType.value as IdentificationType}
        formMethods={formMethods}
        required={required}
        showLabel={false}
        disabled={
          !!(editableFields && !editableFields.includes("identification"))
        }
      />
    </>
  );
};

export default FormInputIdentificationInput;
