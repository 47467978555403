import Portal from "rc-util/lib/Portal";
import type { FC, ReactNode } from "react";
import styled from "styled-components";
import Typography from "../../deprecated/Typography";
import type { BaseDrawerProps } from "./BaseDrawer";
import BaseDrawer from "./BaseDrawer";
import * as S from "./styles";

const { Title } = Typography;

export type DrawerProps = Omit<
  BaseDrawerProps,
  "header" | "$placement" | "$fullHeight"
> & {
  title?: ReactNode;
  subTitle?: ReactNode;
  placement?: "right" | "left";
  fullWidth?: boolean;
};
const DrawerComponent: FC<DrawerProps> = ({
  footer,
  title,
  subTitle,
  onClose,
  children,
  placement,
  fullWidth,
  ...props
}) => (
  <BaseDrawer
    {...props}
    $placement={placement}
    $fullWidth={fullWidth}
    onClose={onClose}
    header={
      <S.ActionsRow
        $justify={title || subTitle ? "space-between" : "end"}
        $noWrap
      >
        {(title || subTitle) && (
          <div>
            {title && <Title.Heading>{title}</Title.Heading>}
            {subTitle && (
              <Title.Subheading $color="subdued">{subTitle}</Title.Subheading>
            )}
          </div>
        )}
        <S.CloseIcon onClick={onClose} cursor="pointer" />
      </S.ActionsRow>
    }
    footer={footer}
  >
    {children}
  </BaseDrawer>
);

const Drawer: FC<DrawerProps> = (props) => (
  <Portal
    getContainer={() =>
      document.body.appendChild(document.createElement("div"))
    }
  >
    <DrawerComponent {...props} />
  </Portal>
);

export default styled(Drawer)`
  ${S.drawerCss}
`;
