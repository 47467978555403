import styled, { css } from "styled-components";
import { FadeInDown, FadeInUp } from "../../../animations";
import Affix from "../../../atoms/Affix";
import * as AffixStyles from "../../../atoms/Affix/styles";
import Row from "../../../atoms/Row";
import Spinner from "../../../atoms/Spinner";

export const animationTime = 200;
const boxHeight = 44;
const boxShadow = 2;

type LoadingRowContainerProps = {
  $isList?: boolean;
  $active?: boolean;
};
export const LoadingRowContainer = styled.div<LoadingRowContainerProps>`
  position: absolute;
  width: 100%;
  opacity: 0;
  ${({ $active }) =>
    $active &&
    css`
      opacity: 1;
      z-index: 4;
    `}
  ${Affix} {
    width: 100%;
    ${AffixStyles.AffixFixedDiv} {
      height: ${boxHeight + boxShadow}px;
    }
  }
  top: ${({ $isList }) => ($isList ? "0" : "38px")};
  pointer-events: ${({ $active }) => ($active ? "all" : "none")};
`;

type DivAnimationsProps = {
  $loading: boolean;
  $active?: boolean;
  $isLeaving: boolean;
};

export const DivAnimations = styled.div<DivAnimationsProps>`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  ${({ $loading }) =>
    $loading &&
    css`
      animation: ${FadeInDown} ${animationTime / 1000}s both;
    `}
  ${({ $isLeaving }) =>
    $isLeaving &&
    css`
      animation: ${FadeInUp} ${animationTime / 1000}s both;
    `}
`;

export const LoadingRow = styled(Row).attrs({
  $justify: "center",
  $align: "center",
})`
  width: 100%;
  height: ${boxHeight}px;
  padding: 8px 16px;
  top: 0;
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  transition: opacity ${animationTime / 1000}s ease-in-out;
`;

export const LoadingContainer = styled(Row).attrs({
  $justify: "start",
  $align: "center",
  $gap: 10,
})`
  padding: 4px 8px;
  height: 28px;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.primary};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.surface.primary.subdued};
`;

export const TableSpinner = styled(Spinner)`
  color: ${({ theme }) => theme.colors.icon.primary};
`;
