import type { FC, ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDesign } from "../../setup/context";
import type { DesignDevice } from "../../setup/utils";
import * as S from "./styles";
import type { DivProps } from "./types";

const handleDivPropsChange = (
  shouldFixed: boolean,
  current: DivProps,
  div: HTMLDivElement
): DivProps => {
  const { clientHeight, clientWidth } = div;
  if (shouldFixed && !current.fixed) {
    return {
      fixed: true,
      height: clientHeight,
      width: clientWidth,
    };
  }
  if (!shouldFixed && current.fixed) {
    return {
      fixed: false,
    };
  }
  return current;
};

export type AffixProps = {
  className?: string;
  children: ReactNode;
  offset?: {
    top?: number;
  };
  skipOn?: DesignDevice[];
};
const Affix: FC<AffixProps> = ({ className, children, offset, skipOn }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [divProps, setDivProps] = useState<DivProps>({
    fixed: false,
  });

  const [_offsetTop, setOffsetTop] = useState<number | undefined>(0);
  const [isOffsetTop, setIsOffsetTop] = useState(false);
  // TODO: we add 56 to offset, bc 56 is the height of the header that is sticky
  const offsetTop = (offset?.top || 0) + 56;

  useEffect(() => {
    const _Offset = ref.current?.getBoundingClientRect();
    setOffsetTop(_Offset?.top);
    setIsOffsetTop(true);
  }, []);

  const handleScroll = (_device: DesignDevice) => () => {
    if (skipOn && skipOn.includes(_device)) return;

    if (!isOffsetTop) return;

    const { current: _div } = ref;
    if (!_div) return;

    const containerOffsetTop = (_offsetTop || 0) - offsetTop;
    const { scrollY } = window;

    const shouldFixed = scrollY >= containerOffsetTop;
    setDivProps((current) => handleDivPropsChange(shouldFixed, current, _div));
  };

  const {
    device: { type: deviceType },
  } = useDesign();
  useEffect(() => {
    const _handleScroll = handleScroll(deviceType);
    window.addEventListener("scroll", _handleScroll, true);

    return () => {
      window.removeEventListener("scroll", _handleScroll, true);
    };
  }, [deviceType, isOffsetTop]);

  return (
    <div ref={ref} className={className}>
      <S.AffixFixedDiv props={divProps} offsetTop={offsetTop}>
        {children}
      </S.AffixFixedDiv>
    </div>
  );
};

export default styled(Affix)``;
