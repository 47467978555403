import styled, { css } from "styled-components";
import Typography from "../../../deprecated/Typography";
import FormItem from "../../../molecules/FormItem";
import type { CardProps } from "./index";

export const Heading = styled(Typography.Title.Heading)`
  align-self: center;
`;

export const Subheading = styled(Typography.Title.Subheading)`
  margin-bottom: 12px;
`;

export const cardCss = css<CardProps>`
  ${({ $withTable }) =>
    $withTable &&
    css`
      padding: 0px;
    `};
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `}

  > ${FormItem}:last-child {
    margin-bottom: 0;
  }
`;
