import styled from "styled-components";
import { Row } from "design";

export const SegmentContainerRow = styled(Row)`
  padding: 4px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  overflow-x: auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 16px rgba(0, 0, 0, 0.1);
`;
