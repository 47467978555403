/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TicketRequestUpdateInputStatusMutationVariables = Types.Exact<{
  ticketRequestId: Types.Scalars['ID'];
  status: Types.TicketRequestInputStatus;
}>;


export type TicketRequestUpdateInputStatusMutation = { __typename?: 'Mutation', ticketRequestUpdate: { __typename?: 'TicketRequestUpdatePayload', ticketRequest: { __typename?: 'TicketRequest', id: string, inputStatus: Types.TicketRequestInputStatus } | null } };


export const TicketRequestUpdateInputStatusDocument = gql`
    mutation TicketRequestUpdateInputStatus($ticketRequestId: ID!, $status: TicketRequestInputStatus!) {
  ticketRequestUpdate(input: {id: $ticketRequestId, inputStatus: $status}) {
    ticketRequest {
      id
      inputStatus
    }
  }
}
    `;
export type TicketRequestUpdateInputStatusMutationFn = Apollo.MutationFunction<TicketRequestUpdateInputStatusMutation, TicketRequestUpdateInputStatusMutationVariables>;

/**
 * __useTicketRequestUpdateInputStatusMutation__
 *
 * To run a mutation, you first call `useTicketRequestUpdateInputStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketRequestUpdateInputStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketRequestUpdateInputStatusMutation, { data, loading, error }] = useTicketRequestUpdateInputStatusMutation({
 *   variables: {
 *      ticketRequestId: // value for 'ticketRequestId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTicketRequestUpdateInputStatusMutation(baseOptions?: Apollo.MutationHookOptions<TicketRequestUpdateInputStatusMutation, TicketRequestUpdateInputStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TicketRequestUpdateInputStatusMutation, TicketRequestUpdateInputStatusMutationVariables>(TicketRequestUpdateInputStatusDocument, options);
      }
export type TicketRequestUpdateInputStatusMutationHookResult = ReturnType<typeof useTicketRequestUpdateInputStatusMutation>;
export type TicketRequestUpdateInputStatusMutationResult = Apollo.MutationResult<TicketRequestUpdateInputStatusMutation>;
export type TicketRequestUpdateInputStatusMutationOptions = Apollo.BaseMutationOptions<TicketRequestUpdateInputStatusMutation, TicketRequestUpdateInputStatusMutationVariables>;