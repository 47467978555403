/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import { TicketRequestResolutionNewProductsFragmentDoc } from '../../NewProducts/graphql/generated/fragments.platform.generated';
export type TicketRequestResolutionInnerContentFragment = { __typename?: 'TicketRequest', id: string, isExchange: boolean, isClosed: boolean, newProducts: Array<{ __typename?: 'TicketRequestNewProduct', id: string }>, refundInformation: { __typename?: 'TicketRequestRefundInformation', id: string } | null, returnOrderProducts: Array<{ __typename?: 'TicketRequestReturnOrderProduct', id: string, returnProduct: { __typename?: 'TicketRequestReturnProduct', id: string } | null }>, newProductsTotalAmount: { __typename?: 'Money', amount: number, formattedAmount: string }, totalProductsDifferenceAmount: { __typename?: 'Money', amount: number, formattedAmount: string } };

export type TicketRequestResolutionFragment = { __typename?: 'TicketRequest', id: string, isExchange: boolean, isClosed: boolean, allowUpselling: boolean, allowDownselling: boolean, newProducts: Array<{ __typename?: 'TicketRequestNewProduct', id: string }>, refundInformation: { __typename?: 'TicketRequestRefundInformation', id: string } | null, returnOrderProducts: Array<{ __typename?: 'TicketRequestReturnOrderProduct', id: string, returnProduct: { __typename?: 'TicketRequestReturnProduct', id: string } | null }>, newProductsTotalAmount: { __typename?: 'Money', amount: number, formattedAmount: string }, totalProductsDifferenceAmount: { __typename?: 'Money', amount: number, formattedAmount: string } };

export type TicketRequestResolutionVariableFragment = { __typename?: 'TicketRequest', id: string, acceptExchanges: boolean, acceptReturns: boolean, allowDownselling: boolean, allowUpselling: boolean };

export const TicketRequestResolutionInnerContentFragmentDoc = gql`
    fragment TicketRequestResolutionInnerContent on TicketRequest {
  id
  isExchange
  isClosed
  newProducts {
    id
  }
  refundInformation {
    id
  }
  returnOrderProducts {
    id
    returnProduct {
      id
    }
  }
  newProductsTotalAmount {
    amount
    formattedAmount
  }
  totalProductsDifferenceAmount {
    amount
    formattedAmount
  }
}
    `;
export const TicketRequestResolutionFragmentDoc = gql`
    fragment TicketRequestResolution on TicketRequest {
  ...TicketRequestResolutionInnerContent
  ...TicketRequestResolutionNewProducts
}
    ${TicketRequestResolutionInnerContentFragmentDoc}
${TicketRequestResolutionNewProductsFragmentDoc}`;
export const TicketRequestResolutionVariableFragmentDoc = gql`
    fragment TicketRequestResolutionVariable on TicketRequest {
  id
  acceptExchanges
  acceptReturns
  allowDownselling
  allowUpselling
}
    `;