import type { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type ListProps = HTMLAttributes<HTMLUListElement> & {
  $isBasic?: boolean;
};
const List: FC<ListProps> = ({ children, ...props }) => (
  <ul {...props}>{children}</ul>
);

export default styled(List)`
  ${S.listCss}
`;
