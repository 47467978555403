import styled, { css } from "styled-components";
import Icon from "../../atoms/Icon";
import Row from "../../atoms/Row";
import Button from "../Button";
import FormItem from "../FormItem";
import Input from "../Input";

type SharedStyledProps = {
  vertical: boolean | undefined;
};

export const CloseIcon = styled(Icon)`
  cursor: pointer;
`;

export const BadgesContainer = styled(Row)<SharedStyledProps>`
  max-width: ${({ vertical }) => (vertical ? "100%" : "400px")};
`;

export const InnerContainer = styled(Row)<SharedStyledProps>`
  ${Button},
  ${Input} {
    max-width: ${({ vertical }) => (vertical ? "100%" : "180px")};
    min-width: ${({ vertical }) => (vertical ? "100%" : "180px")};
  }

  @media ${({ theme }) => theme.breakpoints.editableElementsSelector.css} {
    ${Button},
    ${Input} {
      max-width: 100%;
    }
  }
`;

export const editableElementsSelectorCss = css`
  label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
  }
`;

export const Item = styled(FormItem)<SharedStyledProps>`
  max-width: ${({ vertical }) => (vertical ? "100%" : "180px")};
  min-width: ${({ vertical }) => (vertical ? "100%" : "180px")};
`;
