import type { FC } from "react";
import type { HeadingProps } from "./Heading";
import Heading from "./Heading";

type HeadingOptionsProps = Omit<HeadingProps, "$type">;

export const Heading4XL: FC<HeadingOptionsProps> = ({ children, ...props }) => (
  <Heading $type="heading4xl" {...props}>
    {children}
  </Heading>
);

export const Heading3XL: FC<HeadingOptionsProps> = ({ children, ...props }) => (
  <Heading $type="heading3xl" {...props}>
    {children}
  </Heading>
);

export const Heading2XL: FC<HeadingOptionsProps> = ({ children, ...props }) => (
  <Heading $type="heading2xl" {...props}>
    {children}
  </Heading>
);

export const HeadingXL: FC<HeadingOptionsProps> = ({ children, ...props }) => (
  <Heading $type="headingXl" {...props}>
    {children}
  </Heading>
);

export const HeadingLG: FC<HeadingOptionsProps> = ({ children, ...props }) => (
  <Heading $type="headingLg" {...props}>
    {children}
  </Heading>
);

export const HeadingSM: FC<HeadingOptionsProps> = ({ children, ...props }) => (
  <Heading $type="headingSm" {...props}>
    {children}
  </Heading>
);
