import styled, { css } from "styled-components";
import { handleSize } from "./utils";
import { Button } from "design";

export const DesktopBackButton = styled(Button)`
  margin-bottom: 20px;
`;

export type ContainerSectionProps = {
  $startView: boolean;
  $hideFooter: boolean;
  $layoutSize: "small" | "medium" | "large";
};
export const ContainerSection = styled.section<ContainerSectionProps>`
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: column;
  margin: 0 auto;
  min-height: 0;
  width: 100%;
  ${({ $layoutSize }) => $layoutSize && handleSize[$layoutSize]}
  ${({ $startView, $hideFooter }) =>
    $startView
      ? css`
          justify-content: space-between;
          gap: 20px;
        `
      : css`
          padding-bottom: ${$hideFooter ? "20px" : "0"};
        `};
`;
