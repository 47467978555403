import type { UploadProps as RcDropzoneProps } from "rc-upload";
import RcUpload from "rc-upload";
import type { ReactNode, FC } from "react";
import { HiTrash } from "react-icons/hi";
import styled from "styled-components";
import { v4 } from "uuid";
import Row from "../../atoms/Row";
import TooltipIcon from "../../atoms/TooltipIcon";
import Typography from "../../deprecated/Typography";
import Button from "../Button";
import * as S from "./styles";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text } = Typography;

export type DropzoneProps = RcDropzoneProps & {
  onDelete?: () => void;
  description?: string;
  label?: ReactNode;
  tooltip?: ReactNode;
  subLabel?: ReactNode;
  id?: string;
  hasError?: boolean;
  deletePosition?: "up" | "down";
};
const Dropzone: FC<DropzoneProps> = ({
  children,
  className,
  description,
  label,
  tooltip,
  subLabel,
  id,
  onDelete,
  hasError,
  deletePosition,
  ...props
}) => {
  const { t } = useTranslation("common");
  const containerProps = { hasError, className };

  const _id = id !== undefined ? id : v4();

  const deleteButton = (
    <Button type="plain-critical" onClick={onDelete} icon={<HiTrash />}>
      {t("delete")}
    </Button>
  );

  return (
    <S.Container {...containerProps}>
      <Row $column>
        <S.ActionsContainer $align="center" $justify="space-between">
          <label htmlFor={_id}>
            <Text.Body>
              {label}
              {tooltip && <TooltipIcon tooltip={tooltip} />}
            </Text.Body>
          </label>
          <div>{children && deletePosition === "up" && deleteButton}</div>
        </S.ActionsContainer>
        {subLabel && <Text.Caption $color="subdued">{subLabel}</Text.Caption>}
      </Row>
      <S.DropzoneContainer $align="center">
        <S.DropzoneInner>
          <RcUpload id={_id} {...props}>
            {children ? (
              <S.DropzoneChildContainer>{children}</S.DropzoneChildContainer>
            ) : (
              <S.DropzoneDefaultChild
                $align="start"
                $justify="center"
                $gap={10}
              >
                {t("dropzone")}
              </S.DropzoneDefaultChild>
            )}
          </RcUpload>
        </S.DropzoneInner>
      </S.DropzoneContainer>
      <S.ActionsContainer $align="center" $justify="space-between">
        <Text.Caption $color="subdued">{description}</Text.Caption>
        {children && deletePosition === "down" && deleteButton}
      </S.ActionsContainer>
    </S.Container>
  );
};

export default styled(Dropzone)``;
