import type { FC } from "react";
import { useState } from "react";
import styled from "styled-components";
import type { BannerProps } from "./Component";
import Component from "./Component";

const Banner: FC<BannerProps> = (props) => {
  const { animated } = props;
  const [leaving, setLeaving] = useState(false);

  return (
    <Component
      {...props}
      entering={animated && !leaving}
      leaving={leaving}
      setLeaving={setLeaving}
    />
  );
};

export default styled(Banner)``;
