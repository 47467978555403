import type { FC, ReactNode } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type ThreadProps = {
  className?: string;
  children: ReactNode;
  leftGap?: number;
  gap?: number;
};
const Thread: FC<ThreadProps> = ({
  className,
  children,
  leftGap = 4,
  gap = 4,
}) => (
  <S.ContainerRow className={className} $leftGap={leftGap} $gap={gap}>
    {children}
  </S.ContainerRow>
);

export default styled(Thread)``;
