import composeRefs from "@seznam/compose-react-refs";
import type { InputHTMLAttributes, ReactNode } from "react";
import { useRef, forwardRef } from "react";
import styled from "styled-components";
import { v4 } from "uuid";
import TooltipIcon from "../../atoms/TooltipIcon";
import Typography from "../../deprecated/Typography";
import * as S from "./styles";

export type SwitchProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "children"
> & {
  label?: ReactNode;
  tooltip?: ReactNode;
  $labelPosition?: "top" | "right";
};
const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ id, className, label, tooltip, ...props }, ref) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const _id = id !== undefined ? id : v4();

    return (
      <div className={className}>
        {label && (
          <label htmlFor={_id}>
            <Typography.Text.Body>
              {label}
              {tooltip && <TooltipIcon tooltip={tooltip} />}
            </Typography.Text.Body>
          </label>
        )}
        <S.SwitchContainer
          onClick={() => innerRef.current?.click()}
          role="none"
        >
          <input
            ref={composeRefs(ref, innerRef)}
            id={_id}
            type="checkbox"
            {...props}
          />
          <S.SwitchBall />
        </S.SwitchContainer>
      </div>
    );
  }
);

export default styled(Switch)`
  ${S.switchCss}
`;
