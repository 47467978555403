import styled from "styled-components";
import { Card, Row } from "design";

export const RowMain = styled(Row).attrs({
  $justify: "center",
  $align: "center",
})`
  margin: auto;
  ${Card} {
    width: 608px;
  }
`;
