import styled, { css } from "styled-components";

type ContainerProps = {
  $size: number;
};
export const ContainerDiv = styled.div<ContainerProps>`
  ${({ $size }) =>
    $size &&
    css`
      width: ${$size}px;
      height: auto;
      min-width: ${$size}px;
      max-height: ${$size}px;
      img {
        max-width: ${$size}px;
        max-height: ${$size}px;
      }
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
`;
