import type { CarouselRef } from "antd/lib/carousel";
import { forwardRef, useEffect } from "react";
import * as S from "./styles";
import { Modal } from "design";

type ImageModalProps = {
  handleClose: () => void;
  images: string[];
  visible: boolean;
};

const ImageModal = forwardRef<CarouselRef, ImageModalProps>(
  ({ visible, handleClose, images }, carousel) => {
    const handleKeyForCarousel = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        // @ts-expect-error this works
        if (carousel?.current) carousel.current.prev();
      }
      if (e.key === "ArrowRight") {
        // @ts-expect-error this works
        if (carousel?.current) carousel.current.next();
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", handleKeyForCarousel);
      return () => {
        document.removeEventListener("keydown", handleKeyForCarousel);
      };
    });

    return (
      <Modal.Desktop visible={visible} onClose={handleClose} withoutFooter>
        <S.CloseIcon size={20} onClick={handleClose} cursor="pointer" />
        <S.StyledCarousel ref={carousel} draggable>
          {images.map((image) => (
            <img key={`image-${image}`} src={image} alt={image} />
          ))}
        </S.StyledCarousel>
      </Modal.Desktop>
    );
  }
);

export default ImageModal;
