/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import { TicketRequestReturnProductCardModalFragmentDoc } from '../../ReturnModal/graphql/generated/fragments.platform.generated';
export type TicketRequestReturnProductCardFragment = { __typename?: 'TicketRequestReturnOrderProduct', isCheckeable: boolean, notReturnableReason: string | null, notExchangeableReason: string | null, notCheckeableWithNoWarrantyReason: string | null, notCheckeableWithWarrantyReason: string | null, returnProduct: { __typename?: 'TicketRequestReturnProduct', id: string } | null, orderProduct: { __typename?: 'OrderProduct', id: string, name: string, imageUrl: string | null, options: Array<{ __typename?: 'OrderProductOption', name: string, value: string }>, price: { __typename?: 'Money', formattedAmount: string } } };

export const TicketRequestReturnProductCardFragmentDoc = gql`
    fragment TicketRequestReturnProductCard on TicketRequestReturnOrderProduct {
  ...TicketRequestReturnProductCardModal
  isCheckeable
  returnProduct {
    id
  }
}
    ${TicketRequestReturnProductCardModalFragmentDoc}`;