import dayjs from "dayjs";
import htmlParse from "html-react-parser";
import type { FC } from "react";
import { useShop } from "contexts";
import { Checkbox, FormItem, Row } from "design";
import { usePlatform } from "wrappers";

const RequestStartValidationsCheckboxes: FC = () => {
  const { requestStartValidations } = useShop();
  const { sharedState, setSharedState } = usePlatform();

  return (
    <FormItem $block>
      <Row $block $column $gap={8}>
        {requestStartValidations.map((rsv) => (
          <Checkbox
            checked={
              !!sharedState.requestStartValidation.find((e) => e.id === rsv.id)
            }
            onChange={({ target: { checked } }) => {
              const currentState = checked
                ? [
                    ...sharedState.requestStartValidation,
                    { id: rsv.id, acceptedAt: dayjs().toISOString() },
                  ]
                : sharedState.requestStartValidation.filter(
                    (e) => e.id !== rsv.id
                  );
              setSharedState({ requestStartValidation: currentState });
            }}
            label={htmlParse(rsv.content)}
            labelRequired={rsv.required}
          />
        ))}
      </Row>
    </FormItem>
  );
};

export default RequestStartValidationsCheckboxes;
