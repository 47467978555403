/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import { TicketRequestAddressDetailsAddressFragmentDoc } from '../../Address/graphql/generated/fragments.platform.generated';
import { TicketRequestAddressDetailsCustomerFragmentDoc } from '../../Customer/graphql/generated/fragments.platform.generated';
export type TicketRequestAddressDetailsFragment = { __typename?: 'TicketRequest', id: string, isClosed: boolean, isExchange: boolean, requiresReverseDelivery: boolean | null, returnAddress: { __typename?: 'TicketRequestReturnAddress', id: string, formatted: string | null, phoneNumber: string | null, allInformation: boolean, country: { __typename?: 'Country', id: string } } | null, customer: { __typename?: 'TicketRequestCustomer', id: string, allInformation: boolean, email: string | null, fullName: string | null, identification: string | null } | null };

export const TicketRequestAddressDetailsFragmentDoc = gql`
    fragment TicketRequestAddressDetails on TicketRequest {
  id
  returnAddress {
    id
    country {
      id
    }
  }
  ...TicketRequestAddressDetailsAddress
  ...TicketRequestAddressDetailsCustomer
}
    ${TicketRequestAddressDetailsAddressFragmentDoc}
${TicketRequestAddressDetailsCustomerFragmentDoc}`;