import { HiArrowLeft, HiX } from "react-icons/hi";
import styled, { css } from "styled-components";

export const animationTime = 200;

const iconCss = css`
  position: relative;
  top: 2px;
  font-size: 20px;
  min-width: 30px;
  width: 30px;
  color: ${({ theme }) => theme.colors.iconAction.default.default};
  :hover {
    color: ${({ theme }) => theme.colors.iconAction.default.hovered};
  }
`;

export const BackIcon = styled(HiArrowLeft)`
  ${iconCss}
`;
export const CloseIcon = styled(HiX)`
  ${iconCss}
`;
