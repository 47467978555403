import type { FC, ReactNode, HTMLAttributes } from "react";
import styled from "styled-components";
import type { TypographyProps } from "../../deprecated/Typography";
import Typography from "../../deprecated/Typography";
import Icon from "../Icon";
import * as S from "./styles";

const { Text } = Typography;

export type BadgeProps = HTMLAttributes<HTMLSpanElement> & {
  $type?:
    | "neutral"
    | "informational"
    | "success"
    | "pending"
    | "primary"
    | "warning"
    | "critical"
    | "decorative";
  text: ReactNode;
  textColor?: TypographyProps["$color"];
  icon?: ReactNode;
  leftIcon?: ReactNode;
  children?: ReactNode;
};

const Badge: FC<BadgeProps> = ({
  text,
  textColor,
  icon,
  leftIcon,
  children,
  ...props
}) => {
  const badge = (
    <span {...props}>
      <Text.Caption $color={textColor}>
        {leftIcon && <Icon icon={leftIcon} />}
        {text}
        {icon && <Icon icon={icon} />}
      </Text.Caption>
    </span>
  );

  if (!children) return badge;
  return (
    <S.CornerBadgeDiv>
      {badge}
      {children}
    </S.CornerBadgeDiv>
  );
};
export default styled(Badge)`
  ${S.badgeCss}
`;
