import { css } from "styled-components";
import * as AbstractStyles from "../Abstract/styles";

export const textAreaCss = css`
  ${AbstractStyles.InnerContainer} {
    height: auto;
    padding: 8px 12px;
    textarea {
      min-height: 100px;
      height: calc(100px - 18px);
    }
  }
`;
