import { Upload } from "antd";
import styled from "styled-components";

export const StyledUpload = styled(Upload)`
  > div {
    .ant-upload-select-picture-card:hover {
      border-color: ${({ theme }) => theme.colors.border.primary.default};
    }
  }
`;
