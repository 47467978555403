import type { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type AvatarProps = HTMLAttributes<HTMLDivElement> & {
  icon?: ReactNode;
  $shape?: "round" | "square";
};

const Avatar: FC<AvatarProps> = ({ icon, children, ...props }) => (
  <div {...props}>
    <span>{icon || children}</span>
  </div>
);

export default styled(Avatar)`
  ${S.avatarCss}
`;
