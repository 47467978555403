import htmlParse from "html-react-parser";
import type { FC, ReactNode } from "react";
import { Checkbox, Input, Radio, Select } from "design";
import { QuestionType } from "graphql/generated/schema.platform";
import type { DeepOmit } from "typing";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;

export type SharedProps = {
  title: ReactNode;
  description: string;
  value: string | undefined;
  required: boolean;
  options: string[];
} & (
  | {
      onChange: (value: string) => void;
    }
  | { readonly: true }
);

export type BaseQuestionSelectOneProps = DeepOmit<SharedProps, "options"> & {
  options: { label: string; value: string }[];
  column: boolean;
};

const BaseQuestionSelectOne: FC<BaseQuestionSelectOneProps> = ({
  required,
  title,
  description,
  value,
  options,
  column,
  ...props
}) => (
  <Radio.Group
    label={title}
    subLabel={htmlParse(description)}
    labelRequired={required}
    disabled={"readonly" in props && props.readonly}
    onChange={(val) => ("onChange" in props ? props.onChange(val) : undefined)}
    value={value}
    $column={column}
  >
    {options.map(({ label, value: optionValue }) => (
      <Radio value={optionValue}>{label}</Radio>
    ))}
  </Radio.Group>
);

const QuestionBoolean: FC<SharedProps> = (props) => {
  const { t } = useTranslation("common");

  return (
    <BaseQuestionSelectOne
      {...props}
      options={[
        {
          label: t("yes"),
          value: "true",
        },
        {
          label: t("no"),
          value: "false",
        },
      ]}
      column={false}
    />
  );
};

const QuestionDropdown: FC<SharedProps> = ({
  options,
  required,
  description,
  title,
  value,
  ...props
}) => (
  <Select
    label={title}
    labelRequired={required}
    subLabel={htmlParse(description)}
    value={value}
    disabled={"readonly" in props && props.readonly}
    onChange={(val) => ("onChange" in props ? props.onChange(val) : undefined)}
    options={options.map((o) => ({
      label: o,
      value: o,
    }))}
  />
);
const QuestionInputLong: FC<SharedProps> = ({
  required,
  description,
  title,
  value,
  ...props
}) => (
  <Input.TextArea
    label={title}
    labelRequired={required}
    subLabel={htmlParse(description)}
    value={value}
    disabled={"readonly" in props && props.readonly}
    onChange={(e) =>
      "onChange" in props ? props.onChange(e.target.value) : undefined
    }
  />
);
const QuestionInputShort: FC<SharedProps> = ({
  required,
  description,
  title,
  value,
  ...props
}) => (
  <Input
    label={title}
    labelRequired={required}
    subLabel={htmlParse(description)}
    value={value}
    disabled={"readonly" in props && props.readonly}
    onChange={(e) =>
      "onChange" in props ? props.onChange(e.target.value) : undefined
    }
  />
);
const QuestionSelectMultiple: FC<SharedProps> = ({
  required,
  description,
  title,
  value,
  options,
  ...props
}) => {
  const _value =
    value !== undefined ? (JSON.parse(value) as string[]) : undefined;
  const parseOnChangeValue = (option: string) => {
    const newVal = _value?.includes(option)
      ? _value.filter((v) => v !== option)
      : [...(_value || []), option];
    return JSON.stringify(newVal);
  };

  return (
    <div>
      {required && "*"}
      {title}
      {htmlParse(description)}
      {options.map((o) => (
        <Checkbox
          checked={_value?.includes(o)}
          value={o}
          label={o}
          disabled={"readonly" in props && props.readonly}
          onChange={() =>
            "onChange" in props
              ? props.onChange(parseOnChangeValue(o))
              : undefined
          }
        />
      ))}
    </div>
  );
};
const QuestionSelectOne: FC<SharedProps> = ({ options, ...props }) => (
  <BaseQuestionSelectOne
    {...props}
    options={options.map((o) => ({ label: o, value: o }))}
    column
  />
);

const QUESTION_TYPE: Record<QuestionType, FC<SharedProps>> = {
  [QuestionType.BOOLEAN]: QuestionBoolean,
  [QuestionType.DROPDOWN]: QuestionDropdown,
  [QuestionType.INPUT_LONG]: QuestionInputLong,
  [QuestionType.INPUT_SHORT]: QuestionInputShort,
  [QuestionType.SELECT_MULTIPLE]: QuestionSelectMultiple,
  [QuestionType.SELECT_ONE]: QuestionSelectOne,
};

type GenericQuestionProps = SharedProps & {
  questionType: QuestionType;
};
const GenericQuestion: FC<GenericQuestionProps> = ({
  questionType,
  ...props
}) => {
  const QuestionComponent = QUESTION_TYPE[questionType];
  return <QuestionComponent {...props} />;
};

export default GenericQuestion;
