import type { FC } from "react";
import type { TitleProps } from "./Title";
import Title from "./Title";

type TitleOptionsProps = Omit<TitleProps, "$type">;

export const DisplayXL: FC<TitleOptionsProps> = ({ children, ...props }) => (
  <Title $type="displayXL" {...props}>
    {children}
  </Title>
);

export const DisplayL: FC<TitleOptionsProps> = ({ children, ...props }) => (
  <Title $type="displayL" {...props}>
    {children}
  </Title>
);

export const DisplayM: FC<TitleOptionsProps> = ({ children, ...props }) => (
  <Title $type="displayM" {...props}>
    {children}
  </Title>
);

export const DisplayS: FC<TitleOptionsProps> = ({ children, ...props }) => (
  <Title $type="displayS" {...props}>
    {children}
  </Title>
);

export const Heading: FC<TitleOptionsProps> = ({ children, ...props }) => (
  <Title $type="heading" {...props}>
    {children}
  </Title>
);

export const Subheading: FC<TitleOptionsProps> = ({ children, ...props }) => (
  <Title $type="subheading" {...props}>
    {children}
  </Title>
);
