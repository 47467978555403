import _ from "lodash";
import type { FC, ReactNode } from "react";
import type {
  CustomerDetailsTicketFragment,
  CustomerDetailsTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import { Card } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Text } = Typography;

type CustomerDetailsProps = {
  data: CustomerDetailsTicketFragment | CustomerDetailsTicketRequestFragment;
  extra?: ReactNode;
};
const CustomerDetails: FC<CustomerDetailsProps> = ({ extra, data }) => {
  const { t } = useTranslation("common");
  const { customer } = data;

  const content = [
    customer?.fullName,
    customer?.email,
    customer?.identification,
  ].filter((c) => !_.isEmpty(c));

  return (
    <Card block title={t("components.customer_details.title")} extra={extra}>
      <Text.Body $color="subduedBold">{content.join(", ")}</Text.Body>
    </Card>
  );
};

export default CustomerDetails;
