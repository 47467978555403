import type { HtmlHTMLAttributes } from "react";
import { forwardRef } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type RowProps = HtmlHTMLAttributes<HTMLDivElement> & {
  $align?: "start" | "center" | "end" | "stretch";
  $justify?:
    | "start"
    | "end"
    | "center"
    | "space-around"
    | "space-between"
    | "space-evenly";
  $gap?: number;
  $noWrap?: boolean;
  $column?: boolean;
  $block?: boolean;
};

const Row = forwardRef<HTMLDivElement, RowProps>(
  ({ children, ...props }, ref) => (
    <div ref={ref} {...props}>
      {children}
    </div>
  )
);

export default styled(Row)`
  ${S.rowCss}
`;
