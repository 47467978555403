/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import { TicketRequestReturnProductCardFragmentDoc } from '../../ProductCard/graphql/generated/fragments.platform.generated';
export type TicketRequestReturnFragment = { __typename?: 'TicketRequest', id: string, returnOrder: { __typename?: 'Order', id: string, name: string, ecommerceCreatedAt: string | null }, returnOrderProducts: Array<{ __typename?: 'TicketRequestReturnOrderProduct', id: string, isCheckeable: boolean, notReturnableReason: string | null, notExchangeableReason: string | null, notCheckeableWithNoWarrantyReason: string | null, notCheckeableWithWarrantyReason: string | null, returnProduct: { __typename?: 'TicketRequestReturnProduct', id: string } | null, orderProduct: { __typename?: 'OrderProduct', id: string, name: string, imageUrl: string | null, options: Array<{ __typename?: 'OrderProductOption', name: string, value: string }>, price: { __typename?: 'Money', formattedAmount: string } } }> };

export type TicketRequestReturnStaticFragment = { __typename?: 'TicketRequest', id: string, returnOrderProducts: Array<{ __typename?: 'TicketRequestReturnOrderProduct', id: string, returnReasons: Array<{ __typename?: 'ReturnReason', id: string, reason: string, warranty: boolean }> }> };

export type TicketRequestReturnReasonStaticFragment = { __typename?: 'ReturnReason', id: string, reason: string, warranty: boolean };

export const TicketRequestReturnFragmentDoc = gql`
    fragment TicketRequestReturn on TicketRequest {
  id
  returnOrder {
    id
    name
    ecommerceCreatedAt
  }
  returnOrderProducts {
    id
    returnProduct {
      id
    }
    ...TicketRequestReturnProductCard
  }
}
    ${TicketRequestReturnProductCardFragmentDoc}`;
export const TicketRequestReturnReasonStaticFragmentDoc = gql`
    fragment TicketRequestReturnReasonStatic on ReturnReason {
  id
  reason
  warranty
}
    `;
export const TicketRequestReturnStaticFragmentDoc = gql`
    fragment TicketRequestReturnStatic on TicketRequest {
  id
  returnOrderProducts {
    id
    returnReasons {
      ...TicketRequestReturnReasonStatic
    }
  }
}
    ${TicketRequestReturnReasonStaticFragmentDoc}`;