import htmlParse from "html-react-parser";
import type { FC } from "react";
import type {
  ReturnInformationCourierTicketFragment,
  ReturnInformationCourierTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import * as S from "./styles";
import { Row } from "design";
import { Typography } from "design/deprecated";

const { Title } = Typography;

type CourierContentProps = {
  data:
    | ReturnInformationCourierTicketFragment
    | ReturnInformationCourierTicketRequestFragment;
};
const CourierContent: FC<CourierContentProps> = ({ data }) => {
  const { isExchange, courierConfig } = data;

  const {
    platformDescriptionAgile,
    platformDescriptionReturn,
    logo,
    name: courierName,
  } = courierConfig || {};

  return (
    <Row $block $column>
      {logo ? (
        <S.CourierImg src={logo.url} alt={courierName ?? ""} />
      ) : (
        <Title.Subheading>{courierName}</Title.Subheading>
      )}
      <S.CourierDescription>
        {htmlParse(
          isExchange && platformDescriptionAgile
            ? platformDescriptionAgile
            : platformDescriptionReturn ?? ""
        )}
      </S.CourierDescription>
    </Row>
  );
};

export default CourierContent;
