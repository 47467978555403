/* eslint-disable no-console */
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const isDevelopment = process.env.NEXT_PUBLIC_NODE_ENV === "development";

Sentry.init({
  // we fake the dsn to log on beforeSend
  dsn: SENTRY_DSN || "https://fakedsn@sentry.io/000000",
  environment: process.env.NEXT_PUBLIC_NODE_ENV,
  tracesSampleRate: isDevelopment ? 0 : 0.1,
  beforeSend: (event, hint) => {
    if (isDevelopment) {
      console.error(hint?.originalException || hint?.syntheticException);
      return null; // this drops the event and nothing will be sent to sentry
    }
    return event;
  },
});
