import styled, { css } from "styled-components";
import Spinner from "../Spinner";
import type { LoadingSize } from "./utils";
import { handleSize } from "./utils";

type InnerProps = {
  $horizontal: boolean;
  $size: LoadingSize;
};
export const InnerLoadingContainer = styled.div<InnerProps>`
  display: flex;
  gap: 4px;
  border-radius: 4px;
  margin-bottom: 20px;
  margin: 20px 0;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.icon.primary};
  ${({ $horizontal }) =>
    $horizontal
      ? css`
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: nowrap;
        `
      : css`
          flex-direction: column;
          align-items: center;
          text-align: center;
        `}
  ${Spinner} {
    ${({ $size }) => handleSize[$size || "small"]}
  }
`;

export const MaskLoading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  opacity: 0.7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  $isViewContainer: boolean;
  $loading: boolean;
};
export const LoadingContainer = styled.div<Props>`
  position: relative;
  transition: all 0.3s ease-in-out;
  width: inherit;
  ${({ $loading, $isViewContainer }) =>
    $loading &&
    css`
      clear: both;
      user-select: none;
      pointer-events: none;
      ${$isViewContainer &&
      css`
        max-height: 100vh;
        overflow: hidden;
      `}
    `}
`;
