import styled, { css } from "styled-components";

type InnerTableProps = {
  $isLoading?: boolean;
};

export const InnerTable = styled.table<InnerTableProps>`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 14px;
  overflow: hidden;
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      tbody {
        background-color: ${({ theme }) =>
          theme.colors.surface.default.disabled};
        opacity: 0.3;
      }
    `}
`;

export const TableHead = styled.thead`
  tr {
    background-color: ${({ theme }) => theme.colors.background};
    th {
      padding: 16px 12px;
      color: ${({ theme }) => theme.colors.text.default};
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

type Props = { $hasHeaderGroup: boolean; $addBorderTop?: boolean };
export const TableBody = styled.tbody<Props>`
  ${({ $hasHeaderGroup, $addBorderTop }) =>
    $hasHeaderGroup || $addBorderTop
      ? css`
          tr {
            td:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 1px;
              width: 100%;
              background-color: ${({ theme }) =>
                theme.colors.border.default.subdued};
            }
          }
        `
      : css`
          tr {
            &:not(:first-child) {
              td:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 1px;
                width: 100%;
                background-color: ${({ theme }) =>
                  theme.colors.border.default.subdued};
              }
            }
          }
        `}
  tr {
    vertical-align: middle;
    td {
      position: relative;
      padding: 8px 12px;
      color: ${({ theme }) => theme.colors.text.default};
      font-size: 14px;
      line-height: 20px;
      transition: background-color 0.2s ease-in-out;
      &:first-child:after {
        right: 0;
        left: auto;
      }
    }
    &:hover td {
      background-color: ${({ theme }) => theme.colors.surface.neutral.subdued};
    }
  }
`;

export const tableCss = css`
  position: relative;
  width: 100%;
`;
