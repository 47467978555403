import type { NextComponentType, NextPageContext } from "next";
import type { ComponentProps, FC } from "react";

/* eslint-disable no-shadow */
export type Error = {
  field: string;
  messages: string[];
};

export type ShopRequestStartValidation = {
  id: number;
  content: string;
  order: number;
  required: boolean;
};

export type RequestStartValidationValues = {
  id: number;
  acceptedAt: string | null;
};

export enum StartTicketValidation {
  // BILLING_ZIPCODE = "BILLING_ZIPCODE",
  CUSTOMER_EMAIL = "CUSTOMER_EMAIL",
  // CUSTOMER_PHONE_NUMBER = "CUSTOMER_PHONE_NUMBER",
  NO_VALIDATION = "NO_VALIDATION",
  // SHIPPING_ZIPCODE = "SHIPPING_ZIPCODE",
}

export enum FooterConfig {
  COLORED = "COLORED",
  GRAYSCALE = "GRAYSCALE",
  HIDDEN = "HIDDEN",
}

export enum FrequentlyAskedQuestionsPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum BackgroundOption {
  COLOR_ONLY = "COLOR_ONLY",
  IMAGE = "IMAGE",
}

export type ReturnProductQuestion = {
  id: number;
  question: {
    title: string;
    questionType: string;
    options: string[];
    required: boolean;
  };
};

export type ReverssoPage<
  TLayout extends FC<TLayoutProps>,
  TLayoutProps extends object = ComponentProps<TLayout>
> = NextComponentType<NextPageContext> & {
  layout?: { Component: TLayout; props: Omit<TLayoutProps, "children"> };
};

// From https://stackoverflow.com/a/55539616
type Primitive =
  | string
  // eslint-disable-next-line @typescript-eslint/ban-types
  | Function
  | number
  | boolean
  | symbol
  | undefined
  | null;
type DeepOmitHelper<T, K extends keyof T> = {
  [P in K]: T[P] extends infer TP // extra level of indirection needed to trigger homomorhic behavior // distribute over unions
    ? TP extends Primitive
      ? TP // leave primitives and functions alone
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      TP extends any[]
      ? DeepOmitArray<TP, K> // Array special handling
      : DeepOmit<TP, K>
    : never;
};
export type DeepOmit<T, K> = T extends Primitive
  ? T
  : DeepOmitHelper<T, Exclude<keyof T, K>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};
