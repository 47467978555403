import { useCallback, useRef, useState } from "react";

const DEFAULT_TIMEOUT = 500;

type UseDelayCallbackOptions = {
  timeout?: number;
  forceCallbackIfSameValue?: boolean;
  skipWaitIfEmpty?: boolean;
};

/**
 * Received a callback as argument and executes it after the timeout
 * only if the value hasn't changed during the timeout
 * Returns a boolean if it's waiting for the timout and the callback to update the value
 */
const useDelayCallback = <Arg, Type>(
  callback: (arg: Arg) => Type,
  options: UseDelayCallbackOptions | undefined = undefined
): [boolean, (value: Arg) => void] => {
  const [waiting, setWaiting] = useState(false);

  const searchTimeout = useRef(setTimeout(() => "", 0));
  const lastSearch = useRef<Arg>();

  const waitSearch = useCallback((value: Arg): void => {
    setWaiting(true);

    if (searchTimeout.current) clearTimeout(searchTimeout.current);

    const executeCallback = () => {
      setWaiting(false);
      if (!options?.forceCallbackIfSameValue && lastSearch.current === value)
        return null;
      lastSearch.current = value;
      return callback(value);
    };

    if (options?.skipWaitIfEmpty && !value) {
      clearTimeout(searchTimeout.current);
      executeCallback();
    } else {
      searchTimeout.current = setTimeout(
        executeCallback,
        options?.timeout ?? DEFAULT_TIMEOUT
      );
    }
  }, []);

  return [waiting, waitSearch];
};

export default useDelayCallback;
