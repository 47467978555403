import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";
import Icon from "../Icon";
import PageHeader from "../PageHeader";
import * as PHStyles from "../PageHeader/styles";
import type { ViewContentProps } from "./index";

const handleViewType: Record<
  NonNullable<ViewContentProps["$view"]>,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  small: css`
    max-width: 655px;
    margin: 0 auto;
  `,
  medium: css`
    max-width: 944px;
    margin: 0 auto;
    @media (max-width: 1500px) and (min-width: 1149px) {
      ${PageHeader} {
        ${PHStyles.PageHeaderLeft} {
          ${Icon} {
            position: unset;
            left: unset;
            margin-right: 8px;
          }
        }
      }
    }
    @media ${({ theme }) => theme.breakpoints.viewContentTicket.css} {
      max-width: 607px;
    }
  `,
  large: css`
    max-width: 1136px;
    margin: 0 auto;

    @media (max-width: 1500px) and (min-width: 1149px) {
      ${PageHeader} {
        ${PHStyles.PageHeaderLeft} {
          ${Icon} {
            position: unset;
            left: unset;
            margin-right: 8px;
          }
        }
      }
    }
    @media ${({ theme }) => theme.breakpoints.viewContentTicket.css} {
      max-width: 607px;
    }
  `,
};

export const viewContentCss = css<ViewContentProps>`
  ${({ $view }) => handleViewType[$view || "medium"]};
`;
