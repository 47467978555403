import styled, { css } from "styled-components";
import Icon from "../../atoms/Icon";
import Spinner from "../../atoms/Spinner";
import Typography from "../../deprecated/Typography";
import { handleButtonSize, handleButtonType } from "./utils";
import type { ButtonProps } from "./index";

export const ButtonContent = styled(Typography.Text.Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const RightIcon = styled(Icon)``;

export const buttonCss = css<ButtonProps>`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  justify-content: ${({ rightIcon }) =>
    rightIcon && "asDropdown" in rightIcon && rightIcon.asDropdown
      ? "unset"
      : "center"};
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: ${({ children, icon, theme }) =>
    !children && icon ? "50%" : theme.borderRadius.default};
  width: ${({ $block }) => $block && "100%"};

  ${({ children, icon, $size, type }) => {
    const onlyIcon = !!(!children && icon);
    const isPlainType = type?.includes("plain");
    return handleButtonSize($size, onlyIcon, isPlainType);
  }}

  ${({ type }) => handleButtonType[type || "basic"]}

  ${({ $dashed, type }) =>
    $dashed &&
    type?.includes("outline") &&
    css`
      border-style: dashed;
    `}

  ${({ rightIcon }) =>
    rightIcon &&
    "asDropdown" in rightIcon &&
    rightIcon.asDropdown &&
    css`
      padding-right: 32px;
      ${ButtonContent} {
        display: block;
        width: 100%;
        justify-content: space-between;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        ${RightIcon} {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    `}

  ${({ loading }) =>
    loading &&
    css`
      ${ButtonContent} {
        justify-content: center;
        opacity: 0;
      }
      ${Spinner} {
        position: absolute;
        color: ${({ theme }) => theme.colors.text.disabled};
      }
    `}
`;
