import styled, { css } from "styled-components";

type Props = { $block: boolean };
export const BaseCardContainerDiv = styled.div<Props>`
  ${({ $block }) =>
    $block &&
    css`
      width: 100%;
    `}
`;

export const baseCardCss = css`
  padding: 12px 16px;
  max-width: calc(100vw - 32px);
  word-wrap: break-word;
  border-radius: ${({ theme }) => theme.borderRadius.card};
  border: 1px solid ${({ theme }) => theme.colors.border.default.subdued};
  background-color: ${({ theme }) => theme.colors.surface.default.default};
`;
