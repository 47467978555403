import type { FC, HTMLAttributes } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type ProgressProps = HTMLAttributes<HTMLDivElement> & {
  $percent?: number;
};
const Progress: FC<ProgressProps> = (props) => (
  <div {...props}>
    <S.InnerContainer>
      <S.ProgressBar />
    </S.InnerContainer>
  </div>
);
export default styled(Progress)`
  ${S.progressCss}
`;
