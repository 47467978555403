import type { FC, ReactNode } from "react";
import {
  HiCheckCircle,
  HiExclamation,
  HiExclamationCircle,
  HiInformationCircle,
} from "react-icons/hi";
import styled from "styled-components";
import * as S from "./styles";

export type InlineMessageProps = {
  className?: string;
  message: ReactNode;
  type: "critical" | "info" | "primary" | "success" | "warning";
  icon?: ReactNode;
};
const InlineMessage: FC<InlineMessageProps> = ({
  className,
  message,
  type,
  icon,
}) => {
  const ICON_TYPE = {
    critical: <HiExclamationCircle />,
    info: <HiInformationCircle />,
    primary: <HiInformationCircle />,
    success: <HiCheckCircle />,
    warning: <HiExclamation />,
  };

  return (
    <S.ContainerRow className={className} type={type}>
      <S.StyledIcon icon={icon || ICON_TYPE[type]} />
      <S.Text>{message}</S.Text>
    </S.ContainerRow>
  );
};

export default styled(InlineMessage)``;
