import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";
import type { TooltipProps } from "./index";

export const baseClass = "rc-tooltip";

const placementTop = css`
  margin-left: -6px;
  border-width: 6px 6px 0;
`;
const placementRight = css`
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
`;
const placementLeft = css`
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
`;
const placementBottom = css`
  margin-left: -6px;
  border-width: 0 6px 6px;
`;

const basePlacementCss = (placement: TooltipProps["placement"]) => {
  switch (placement) {
    case "bottom":
    case "bottomLeft":
    case "bottomRight":
      return css`
        .${baseClass}-arrow {
          top: calc(-6px + 1px);
          border-bottom-color: ${({ theme }) =>
            theme.colors.surface.default.inverted};
          ${placementBottom}
        }
        .${baseClass}-arrow-inner {
          top: 1px;
          ${placementBottom}
        }
      `;
    case "left":
    case "leftTop":
    case "leftBottom":
      return css`
        .${baseClass}-arrow {
          right: calc(-6px + 1px);
          border-left-color: ${({ theme }) =>
            theme.colors.surface.default.inverted};
          ${placementLeft};
        }
        .${baseClass}-arrow-inner {
          right: 1px;
          ${placementLeft};
        }
      `;
    case "right":
    case "rightTop":
    case "rightBottom":
      return css`
        .${baseClass}-arrow {
          left: calc(-6px + 1px);
          border-right-color: ${({ theme }) =>
            theme.colors.surface.default.inverted};
          ${placementRight};
        }
        .${baseClass}-arrow-inner {
          left: 1px;
          ${placementRight}
        }
      `;
    case "top":
    case "topLeft":
    case "topRight":
    default:
      return css`
        .${baseClass}-arrow {
          bottom: calc(-6px + 1px);
          border-top-color: ${({ theme }) =>
            theme.colors.surface.default.inverted};
          ${placementTop}
        }
        .${baseClass}-arrow-inner {
          bottom: 1px;
          ${placementTop}
        }
      `;
  }
};

export const handlePlacement: Record<
  NonNullable<TooltipProps["placement"]>,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  top: css`
    &&&.${baseClass}-placement-top {
      ${basePlacementCss("top")}
      .${baseClass}-arrow {
        left: 50%;
      }
    }
  `,
  topLeft: css`
    &&&.${baseClass}-placement-topLeft {
      ${basePlacementCss("topLeft")}
      .${baseClass}-arrow {
        left: 15%;
      }
    }
  `,
  topRight: css`
    &&&.${baseClass}-placement-topRight {
      ${basePlacementCss("topRight")}
      .${baseClass}-arrow {
        right: 15%;
      }
    }
  `,
  bottom: css`
    &&&.${baseClass}-placement-bottom {
      ${basePlacementCss("bottom")}
      .${baseClass}-arrow {
        left: 50%;
      }
    }
  `,
  bottomLeft: css`
    &&&.${baseClass}-placement-bottomLeft {
      ${basePlacementCss("bottomLeft")}
      .${baseClass}-arrow {
        left: 15%;
      }
    }
  `,
  bottomRight: css`
    &&&.${baseClass}-placement-bottomRight {
      ${basePlacementCss("bottomRight")}
      .${baseClass}-arrow {
        right: 15%;
      }
    }
  `,
  left: css`
    &&&.${baseClass}-placement-left {
      ${basePlacementCss("left")}
      .${baseClass}-arrow {
        top: 50%;
      }
    }
  `,
  leftTop: css`
    &&&.${baseClass}-placement-leftTop {
      ${basePlacementCss("leftTop")}
      .${baseClass}-arrow {
        top: 15%;
        margin-top: 0;
      }
    }
  `,
  leftBottom: css`
    &&&.${baseClass}-placement-leftBottom {
      ${basePlacementCss("leftBottom")}
      .${baseClass}-arrow {
        bottom: 15%;
      }
    }
  `,
  right: css`
    &&&.${baseClass}-placement-right {
      ${basePlacementCss("right")}
      .${baseClass}-arrow {
        top: 50%;
      }
    }
  `,
  rightTop: css`
    &&&.${baseClass}-placement-rightTop {
      ${basePlacementCss("rightTop")}
      .${baseClass}-arrow {
        top: 15%;
        margin-top: 0;
      }
    }
  `,
  rightBottom: css`
    &&&.${baseClass}-placement-rightBottom {
      ${basePlacementCss("rightBottom")}
      .${baseClass}-arrow {
        bottom: 15%;
      }
    }
  `,
};
