import type { FC, ReactNode } from "react";
import styled from "styled-components";
import Footer from "../Footer";
import Header from "../Header";
import ContextWrapper from "./ContextWrapper";
import * as S from "./styles";

export type LayoutBaseProps = {
  children: ReactNode;
  hideFooter?: boolean;
  hideHeader?: boolean;
  headerOverrideLogo?: ReactNode;
  headerExtra?: ReactNode;
};
const LayoutBase: FC<LayoutBaseProps> = ({
  children,
  hideFooter,
  hideHeader,
  headerOverrideLogo,
  headerExtra,
}) => (
  <ContextWrapper>
    <S.BaseWrapperSection>
      {!hideHeader && (
        <Header overrideLogo={headerOverrideLogo} extra={headerExtra} />
      )}
      {children}
      {!hideFooter && <Footer />}
    </S.BaseWrapperSection>
  </ContextWrapper>
);

export default styled(LayoutBase)``;
