import styled, { css } from "styled-components";
import Row from "../../atoms/Row";
import Typography from "../../deprecated/Typography";
import type { DropzoneProps } from "./index";

const uploadClass = "rc-upload";

export const ActionsContainer = styled(Row)`
  width: 100%;
`;

export const DropzoneContainer = styled(Row)`
  max-width: 100%;
`;

export const DropzoneInner = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.surface.default.default};
  overflow: hidden;
  transition: border-color 0.2s ease-in-out;
  .${uploadClass} {
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const DropzoneChildContainer = styled.div`
  height: 71px;
  width: auto;
  display: flex;
`;

export const DropzoneDefaultChild = styled(Row)`
  padding: 4px 6px;
  width: 58px;
  height: 40px;
  color: ${({ theme }) => theme.colors.text.subdued};
  transition: color 0.2s ease-in-out;
  border-radius: ${({ theme }) => theme.borderRadius.default};
`;

export const Container = styled.div<DropzoneProps>`
  max-width: 271px;

  label {
    display: flex;
    -webkit-touch-callout: none;
    user-select: none;
    ${Typography} {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px;
    }
  }

  ${DropzoneInner} {
    border: 1px dashed ${({ theme }) => theme.colors.border.neutral.subdued};
    border-radius: ${({ theme }) => theme.borderRadius.default};
    &:hover {
      cursor: pointer;
      border-color: ${({ theme }) => theme.colors.border.primary.default};
      ${DropzoneDefaultChild} {
        color: ${({ theme }) => theme.colors.text.primary};
      }
    }
    ${({ hasError }) =>
      hasError &&
      css`
        border-color: ${({ theme }) => theme.colors.border.critical.default};
        ${DropzoneDefaultChild} {
          color: ${({ theme }) => theme.colors.text.critical};
        }
        &:hover {
          border-color: ${({ theme }) => theme.colors.border.critical.default};
          ${DropzoneDefaultChild} {
            color: ${({ theme }) => theme.colors.text.critical};
          }
        }
      `};
  }
`;
