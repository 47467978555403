import type { ForwardedRef } from "react";
import { createContext } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RadioGroupContextValues<ValueType = any> = {
  value?: ValueType;
  disabled?: boolean;
  onChange: (value: ValueType) => void;
  name?: string;
  ref?: ForwardedRef<HTMLInputElement>;
};
const RadioGroupContext = createContext<RadioGroupContextValues | undefined>(
  undefined
);

export default RadioGroupContext;
