import type { Dispatch, FC, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { HiCheckCircle, HiOutlineShoppingBag } from "react-icons/hi";
import type { NotificationProductData } from "./interfaces";
import * as S from "./styles";
import type { ProductSiderProps } from "components";
import { ProductSider } from "components";
import { useDesign } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Title } = Typography;

const DISPLAY_TIME = 3000; // 3 seconds
const ANIMATION_TIME = 1000;

type NotificationProductProps = {
  product: ProductSiderProps["product"];
  onAnimationEnd: () => void;
};
const NotificationProduct: FC<NotificationProductProps> = ({
  product,
  onAnimationEnd,
}) => {
  const { t } = useTranslation("common");
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);

      setTimeout(() => {
        onAnimationEnd();
      }, ANIMATION_TIME);
    }, DISPLAY_TIME);
  }, []);

  const content = (
    <>
      <S.PopoverContentRow $block>
        <ProductSider product={product} />
      </S.PopoverContentRow>
      <S.FooterRow>
        <HiCheckCircle size={20} />
        <Title.Subheading $color="white">
          {t("product_selected")}
        </Title.Subheading>
      </S.FooterRow>
    </>
  );

  return (
    <S.StyledPopover
      content={content}
      visible={{
        is: visible,
        set: () => null, // make not closeable
      }}
      placement="bottomRight"
      showArrow
    >
      {null}
    </S.StyledPopover>
  );
};

type CartProps = {
  productsOnBag: number;
  setDrawerVisible: Dispatch<SetStateAction<boolean>>;
  notificationProducts: NotificationProductData[];
  setNotificationProducts: Dispatch<SetStateAction<NotificationProductData[]>>;
};
const Cart: FC<CartProps> = ({
  productsOnBag,
  setDrawerVisible,
  notificationProducts,
  setNotificationProducts,
}) => {
  const {
    device: { isDesktop },
  } = useDesign();
  const [bagAnimationProducts, setBagAnimationProducts] = useState(0);
  const [bagAnimationDisplay, setBagAnimationDisplay] = useState(false);

  const showCart = !isDesktop;

  useEffect(() => {
    // empty notification if not showing cart
    if (showCart || notificationProducts.length === 0) return;
    setNotificationProducts([]);
  }, [showCart, notificationProducts]);

  useEffect(() => {
    // if lowering notification products (because animation ended) just reduce the count
    // if creasing, update and display
    const newCount = notificationProducts.length;
    if (!showCart || newCount <= bagAnimationProducts) {
      setBagAnimationProducts(newCount);
    } else {
      // we need to hide and redisplay so animation starts from zero
      // the timeout with 1 millisecond of wait is just a hack so it rerenders twice
      setBagAnimationDisplay(false);
      setTimeout(() => setBagAnimationDisplay(true), 1);
      setBagAnimationProducts(newCount);
    }
  }, [showCart, bagAnimationProducts, notificationProducts]);

  useEffect(() => {
    const timer = setTimeout(
      () => setBagAnimationDisplay(false),
      S.BAG_ANIMATION_TIME * 1000
    );
    return () => clearTimeout(timer);
  }, [bagAnimationDisplay]);

  if (!showCart) return null;
  return (
    <>
      <S.BagContainerDiv key="header-bag-container">
        <S.BagButton
          $animate={bagAnimationDisplay}
          $productsOnBag={{
            hasProducts: productsOnBag > 0,
            count: productsOnBag,
          }}
          type="ghost"
          onClick={() => setDrawerVisible(true)}
          icon={<HiOutlineShoppingBag size={20} />}
        />
      </S.BagContainerDiv>

      {notificationProducts.map(({ id, product }) => (
        <NotificationProduct
          key={id}
          product={product}
          onAnimationEnd={() =>
            setNotificationProducts((prs) => prs.filter((p) => p.id !== id))
          }
        />
      ))}
    </>
  );
};

export default Cart;
