/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
export type TicketRequestAddressDetailsCustomerFragment = { __typename?: 'TicketRequest', id: string, isClosed: boolean, customer: { __typename?: 'TicketRequestCustomer', id: string, allInformation: boolean, email: string | null, fullName: string | null, identification: string | null } | null };

export const TicketRequestAddressDetailsCustomerFragmentDoc = gql`
    fragment TicketRequestAddressDetailsCustomer on TicketRequest {
  id
  isClosed
  customer {
    id
    allInformation
    email
    fullName
    identification
  }
}
    `;