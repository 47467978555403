import type { FC } from "react";
import type {
  SiderTicketFragment,
  SiderTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import type { PaymentSectionProps } from "./PaymentSection";
import PaymentSection from "./PaymentSection";
import type { ProductsSectionProps } from "./ProductsSection";
import ProductsSection from "./ProductsSection";

export type SiderContentProps = {
  isDrawer: boolean;
  data: SiderTicketFragment | SiderTicketRequestFragment;
  paymentProps: Omit<PaymentSectionProps, "data" | "isDrawer"> & {
    onTop: boolean;
  };
  productProps: Omit<ProductsSectionProps, "data" | "isDrawer">;
};
const SiderContent: FC<SiderContentProps> = ({
  isDrawer,
  data,
  paymentProps,
  productProps,
}) => {
  const { onTop: paymentOnTop, ...restPaymentProps } = paymentProps;
  const paymentSection = (
    <PaymentSection {...restPaymentProps} data={data} isDrawer={isDrawer} />
  );

  return (
    <>
      {paymentOnTop && paymentSection}
      <ProductsSection {...productProps} data={data} isDrawer={isDrawer} />
      {!paymentOnTop && paymentSection}
    </>
  );
};

export default SiderContent;
