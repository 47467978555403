import { css } from "styled-components";
import type { AvatarProps } from "./index";

export const avatarCss = css<AvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  font-size: 12px;
  line-height: 16px;
  background-color: ${({ theme }) => theme.colors.surface.primary.default};
  color: ${({ theme }) => theme.colors.text.default};
  border-radius: ${({ $shape, theme }) =>
    $shape === "square" ? theme.borderRadius.default : "50%"};
  overflow: hidden;

  ${({ icon }) =>
    icon &&
    css`
      > span {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
    `}
`;
