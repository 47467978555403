import { useEffect, useState } from "react";

const useDelayedBoolean = (
  track: boolean,
  delay: number
): { display: boolean; isLeaving: boolean } => {
  const [state, setState] = useState<{
    display: boolean;
    isLeaving: boolean | undefined;
  }>({
    display: track,
    isLeaving: undefined,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timer: any;
  // let timer: NodeJS.Timeout | undefined;

  useEffect(() => {
    if (track) {
      clearTimeout(timer);
      setState({ display: true, isLeaving: false });
    } else if (state.isLeaving !== undefined) {
      setState((v) => ({ ...v, isLeaving: true }));
      timer = setTimeout(() => {
        setState({ display: false, isLeaving: false });
      }, delay);
    }
  }, [track]);

  return { display: state.display, isLeaving: state.isLeaving || false };
};

export default useDelayedBoolean;
