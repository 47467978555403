import type { FC } from "react";
import styled from "styled-components";
import * as S from "./styles";

export type DividerProps = {
  className?: string;
  sectionDivider?: boolean;
  $marginY?: number;
};
const Divider: FC<DividerProps> = ({ className, sectionDivider }) => {
  if (!sectionDivider) return <hr className={className} />;

  return (
    <div className={className}>
      <hr />
    </div>
  );
};

export default styled(Divider)`
  ${S.dividerCss}
`;
