import type { ReactNode } from "react";
import { forwardRef } from "react";
import styled, { useTheme } from "styled-components";
import Divider from "../Divider";
import type { TooltipProps } from "../Tooltip";
import Tooltip from "../Tooltip";
import * as S from "./styles";

export type PopoverProps = Omit<TooltipProps, "title" | "$showArrow"> & {
  title?: ReactNode;
  content: ReactNode;
  showArrow?: boolean;
  showShadow?: boolean;
  isPositionStatic?: boolean;
};
const Popover = forwardRef<HTMLElement, PopoverProps>(
  (
    { children, title, showArrow, content, trigger = "click", ...props },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Tooltip
        ref={ref}
        title={[
          title && <S.Title>{title}</S.Title>,
          title && content && <Divider $marginY={4} />,
          <S.Content>{content}</S.Content>,
        ]}
        trigger={trigger}
        animationName={theme.motionName.SLIDE_UP}
        $showArrow={showArrow}
        {...props}
      >
        {children}
      </Tooltip>
    );
  }
);

export default styled(Popover)<PopoverProps>`
  ${S.popoverCss}
`;
