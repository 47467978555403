import type { FlattenSimpleInterpolation } from "styled-components";
import { css } from "styled-components";
import type { SwitchProps } from "./index";

export const handleLabelPosition: Record<
  NonNullable<SwitchProps["$labelPosition"]>,
  FlattenSimpleInterpolation
> = {
  right: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    label {
      order: 1;
    }
  `,
  top: css`
    display: flex;
    flex-direction: column;
  `,
};
