import type { FC, ReactNode } from "react";
import Expense from "../Expenses";
import type {
  SiderProductsSectionTicketFragment,
  SiderProductsSectionTicketRequestFragment,
} from "./graphql/generated/fragments.platform.generated";
import type { NewProductsListProps } from "./NewProductsList";
import NewProductsList from "./NewProductsList";
import type { ReturnProductsListProps } from "./ReturnProductsList";
import ReturnProductsList from "./ReturnProductsList";
import { Card, Divider, InlineMessage, Row, Spinner } from "design";
import { Typography } from "design/deprecated";
import nextI18n from "utils/i18n";

const { Trans, useTranslation } = nextI18n;
const { Text } = Typography;

export type ProductsSectionProps = {
  isDrawer: boolean;
  data:
    | SiderProductsSectionTicketFragment
    | SiderProductsSectionTicketRequestFragment;
  title?: ReactNode;
  returnProductsProps: Omit<
    ReturnProductsListProps,
    "data" | "drawerContainer"
  >;
  newProductsProps: Omit<NewProductsListProps, "data" | "drawerContainer">;
  newShippingPriceLoading: boolean;
};
const ProductsSection: FC<ProductsSectionProps> = ({
  isDrawer,
  data,
  title,
  returnProductsProps,
  newProductsProps,
  newShippingPriceLoading,
}) => {
  const { t } = useTranslation("common");

  const {
    forcePayNewShipping,
    forcePayReturnLabel,
    isExchange,
    newProducts,
    newShippingPrice,
    refundInformation,
    returnOrderProducts,
    refundOriginalShipmentAmount,
    returnLabelPrice,
    totalProductsDifferenceAmount,
    totalToRefund,
    totalToPay,
  } = data;

  const newShippingPaymentMixed = !forcePayNewShipping;
  const returnLabelPaymentMixed = !forcePayReturnLabel;

  const returnProducts = returnOrderProducts
    ?.map((p) => p.returnProduct)
    .filter((rp) => !!rp);

  const haveToRefund =
    totalToRefund.amount > 0 ||
    (totalToRefund.amount === 0 && totalToPay.amount <= 0 && !isExchange);

  const haveToPay =
    isExchange || newShippingPrice === null || totalToPay.amount > 0;

  const haveRefundContent =
    refundOriginalShipmentAmount.amount > 0 ||
    newShippingPaymentMixed ||
    returnLabelPaymentMixed;

  const hasAllPrices = returnLabelPrice !== null && newShippingPrice !== null;

  const content = (
    <Row $block $gap={12}>
      <ReturnProductsList
        {...returnProductsProps}
        data={data}
        drawerContainer={isDrawer}
      />
      {newProducts.length > 0 && (
        <NewProductsList
          {...newProductsProps}
          data={data}
          drawerContainer={isDrawer}
        />
      )}
      {/* extra refund amounts */}
      {haveToRefund &&
        haveRefundContent && [
          <Divider key="sider-products-divider" $marginY={0} />,
          <Row
            key="sider-products-expenses"
            $block
            $justify="space-between"
            $gap={4}
          >
            {totalProductsDifferenceAmount.amount < 0 && (
              <Expense
                title={t("balance_in_favor")}
                amount={totalProductsDifferenceAmount}
                negate
              />
            )}
            {refundOriginalShipmentAmount.amount > 0 && (
              <Expense
                title={t("components.sider.refund_original_shipment")}
                amount={refundOriginalShipmentAmount}
              />
            )}
            {isExchange && newShippingPaymentMixed && (
              <Expense
                title={t("components.sider.new_products_delivery")}
                amount={
                  newShippingPriceLoading ? (
                    <InlineMessage
                      type="info"
                      icon={<Spinner />}
                      message={
                        <Text $color="subduedBold">{t("updating")}</Text>
                      }
                    />
                  ) : (
                    newShippingPrice
                  )
                }
                showFree
              />
            )}
            {returnLabelPaymentMixed && (
              <Expense
                title={t("components.sider.return_label")}
                amount={returnLabelPrice}
                showFree
              />
            )}
          </Row>,
        ]}
    </Row>
  );

  const refundFooter = (
    <>
      <Expense
        title={t("components.sider.total_to_refund")}
        amount={totalToRefund}
        isTotal
      />
      {!!refundInformation?.refundTypeConfig && (
        <Text.Body $color="subdued">
          {Trans({
            t,
            i18nKey: "components.sider.refund_type",
            components: {
              Bold: <Text.Body $color="subdued" $weight={500} />,
            },
            values: {
              type: refundInformation.refundTypeConfig.name,
            },
          })}
        </Text.Body>
      )}
    </>
  );

  const footer =
    returnProducts.length === 0 ? null : (
      <Row key="sider-products-footer" $column $block>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!haveToRefund || !returnLabelPrice || !newShippingPrice ? (
          totalProductsDifferenceAmount.amount < 0 ? (
            refundFooter
          ) : (
            <Expense
              title={
                totalProductsDifferenceAmount.amount <= 0
                  ? t("balance_in_favor")
                  : t("subtotal_to_pay")
              }
              amount={totalProductsDifferenceAmount}
              isTotal
            />
          )
        ) : (
          refundFooter
        )}
      </Row>
    );

  if (isDrawer)
    return (
      <>
        {content}
        {/* eslint-disable-next-line no-nested-ternary */}
        {!haveToRefund && haveToPay
          ? (totalProductsDifferenceAmount.amount < 0 &&
              totalToRefund.amount > 0) ||
            !hasAllPrices
            ? [<Divider key="drawer-divider" />, footer]
            : null
          : [<Divider key="sider-divider" />, footer]}
      </>
    );

  return (
    <Card
      block
      title={title}
      footer={
        // eslint-disable-next-line no-nested-ternary
        !haveToRefund && haveToPay
          ? (totalProductsDifferenceAmount.amount < 0 &&
              totalToRefund.amount > 0) ||
            !hasAllPrices
            ? footer
            : null
          : footer
      }
    >
      {content}
    </Card>
  );
};

export default ProductsSection;
