import type { ReactElement, ReactNode, RefObject } from "react";
import { useState, isValidElement, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import type { UseFormReturn } from "react-hook-form";
import * as S from "./styles";
import { Banner, Button } from "design";
import nextI18n from "utils/i18n";

const { Trans, useTranslation } = nextI18n;

type StartFormOnValidSubmitProps<FormValues extends object> = (
  values: FormValues & { captcha: string | null | undefined }
) => Promise<string[] | null>;

type StartFormProps<FormValues extends object> = {
  buttonRef?: RefObject<HTMLButtonElement>;
  loadingMutation: boolean;
  useCaptcha?: boolean;
  children: ReactNode;
  form: UseFormReturn<FormValues>;
  checkboxesValid?: boolean;
  onValidSubmit: StartFormOnValidSubmitProps<FormValues>;
};
const StartForm = <FormValues extends object>({
  buttonRef,
  form,
  onValidSubmit,
  checkboxesValid,
  children,
  useCaptcha,
  loadingMutation,
}: StartFormProps<FormValues>): ReactElement => {
  const { t } = useTranslation("start");
  const [alertErrors, setAlertErrors] = useState<ReactNode[]>([]);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const validateCaptcha = async (): Promise<string | null | undefined> => {
    if (!useCaptcha) return Promise.resolve(undefined);
    const captcha = recaptchaRef.current;
    if (!captcha) throw new Error("Recaptcha not referenced");
    return captcha.executeAsync();
  };

  const onFinish = form.handleSubmit(async (values) => {
    const captcha = await validateCaptcha();
    if (checkboxesValid === false)
      return setAlertErrors([
        t("form.messages.request_start_validation.some_required"),
      ]);
    return onValidSubmit({ ...values, captcha })
      .then((errs) => {
        if (!errs) return;
        setAlertErrors(errs);
      })
      .catch((err) => {
        setAlertErrors([
          <Trans
            t={t}
            i18nKey="form.messages.error"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
              a: <a href="mailto:ayuda@reversso.cl" />,
            }}
          />,
        ]);
        return Promise.reject(err);
      });
  });

  return (
    <>
      {useCaptcha && (
        <ReCAPTCHA
          badge="bottomleft"
          ref={recaptchaRef}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          sitekey={process.env.NEXT_PUBLIC_CAPTCHA_KEY!}
          size="invisible"
        />
      )}
      {alertErrors.length > 0 && (
        <Banner
          message={alertErrors.map((_message) =>
            isValidElement(_message) ? (
              _message
            ) : (
              // eslint-disable-next-line react/no-danger
              <div dangerouslySetInnerHTML={{ __html: `${_message}` }} />
            )
          )}
          afterClose={() => setAlertErrors([])}
          type="critical"
          closable
        />
      )}

      <S.ContainerForm onSubmit={onFinish}>
        {children}
        <Button
          type="primary"
          $block
          loading={loadingMutation}
          htmlType="submit"
          ref={buttonRef}
        >
          {t("form.button")}
        </Button>
      </S.ContainerForm>
    </>
  );
};

export default StartForm;
