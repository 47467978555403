import styled from "styled-components";
import { DESKTOP_L_BREAKPOINT, DESKTOP_M_BREAKPOINT } from "../constants";
import Content from "./Content";

export const ContentContainerDiv = styled.div`
  flex: auto;
  display: flex;
  padding: 20px 16px 0;
  width: 100%;
  @media ${({ theme }) => theme.breakpoints.mobile.css} {
    padding: 16px 16px 0;
  }
`;

export const Container = styled.div`
  flex: auto;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;

  @media (min-width: ${DESKTOP_M_BREAKPOINT}px) and (max-width: ${DESKTOP_L_BREAKPOINT}px) {
    max-width: 1224px;
    ${Content} {
      min-width: 784px;
    }
  }
  @media (min-width: ${DESKTOP_L_BREAKPOINT}px) {
    max-width: 1422px;
    ${Content} {
      min-width: 982px;
    }
  }
`;

export const LayoutContainerMain = styled.main`
  flex: auto;
  display: flex;
  flex-direction: column;
  overscroll-behavior-y: none;
  background-color: ${({ theme }) => theme.colors.components.layout.background};
`;
