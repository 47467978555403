import styled from "styled-components";
import { Badge, Row } from "design";
import { Typography } from "design/deprecated";

export const ContentDiv = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 1fr;
  gap: 8px;
`;

export const DiscountsRow = styled(Row)`
  margin: 4px 0px;
`;

export const DiscountBadge = styled(Badge)`
  max-width: 240px;
  ${Typography} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
`;
