import type { FC } from "react";
import type { BodyProps } from "./Body";
import Body from "./Body";

type BodyOptionsProps = Omit<BodyProps, "$type">;

export const BodyLG: FC<BodyOptionsProps> = ({ children, ...props }) => (
  <Body $type="bodyLg" {...props}>
    {children}
  </Body>
);

export const BodySM: FC<BodyOptionsProps> = ({ children, ...props }) => (
  <Body $type="bodySm" {...props}>
    {children}
  </Body>
);
