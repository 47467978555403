import update from "immutability-helper";
import type { ReactElement } from "react";
import { useRef, useState, useCallback, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { MdDragIndicator } from "react-icons/md";
import type { TableOptions, Row as RowType, Column } from "react-table";
import { useTable } from "react-table";
import styled from "styled-components";
import BodyRow from "../BodyRow";
import Table from "../DesktopTable";
import * as S from "./styles";

type BaseRow = { id: string | number };

const itemType = "Row";
type RowProps<D extends BaseRow> = {
  row: RowType<D>;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
};

const Row = <D extends BaseRow>({
  row,
  index,
  moveRow,
}: RowProps<D>): ReactElement => {
  const [dropDirection, setDropDirection] = useState({
    dropUpward: false,
    dropDownward: false,
  });
  const dropRef = useRef<HTMLTableRowElement>(null);
  const dragRef = useRef<HTMLTableDataCellElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: itemType,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return { canDrop: monitor.canDrop() };
      }
      setDropDirection({
        dropUpward: dragIndex > index,
        dropDownward: dragIndex < index,
      });
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      };
    },
    drop: (item) => {
      // @ts-expect-error don't really know
      // eslint-disable-next-line no-param-reassign
      moveRow(item.index, index);
    },
  });

  const [, drag, preview] = useDrag({
    item: { type: itemType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <BodyRow
      ref={dropRef}
      dragger={
        <td ref={dragRef}>
          <S.DragIcon icon={<MdDragIndicator size={22} />} />
        </td>
      }
      row={row}
      $dropDownward={isOver && dropDirection.dropDownward}
      $dropUpward={isOver && dropDirection.dropUpward}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type DraggableTableProps<D extends BaseRow> = {
  // NOTE: It is very important to give an id to each row in order for it to work
  columns: Column<D>[];
  data: TableOptions<D>["data"];
  onChange?: (v: readonly D[]) => TableOptions<D>["data"];
  className?: string;
  addBorderTop?: boolean;
  addHeaders?: boolean;
  loading?: boolean;
};

const InnerDraggableTable = <D extends BaseRow>({
  columns,
  data: defaultData,
  onChange,
  className,
  addHeaders,
  loading,
  addBorderTop,
}: DraggableTableProps<D>): ReactElement => {
  const [data, setData] = useState(defaultData);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data,
      columns,
      getRowId: (row) => row.id.toString(),
    });

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRecord = data[dragIndex];
      const newData = update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      });
      setData(newData);
      onChange?.(newData);
    },
    [data, onChange]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        className={className}
        loading={loading}
        tableProps={getTableProps()}
        tableBodyProps={getTableBodyProps()}
        headerGroups={addHeaders ? headerGroups : undefined}
        addBorderTop={addBorderTop}
      >
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <Row
              index={index}
              row={row}
              moveRow={moveRow}
              {...row.getRowProps()}
            />
          );
        })}
      </Table>
    </DndProvider>
  );
};

const DraggableTable: (<D extends BaseRow>(
  props: DraggableTableProps<D>
) => ReactElement) &
  string = styled(InnerDraggableTable)``;

export default DraggableTable;
