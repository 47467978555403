import type { FC, ReactNode } from "react";
import Layout from "../Base";
import * as S from "./styles";

type LayoutWrapperProps = {
  children: ReactNode;
};
const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => (
  <Layout showBackgroundImage={false} layoutSize="large">
    <S.ContentRow>{children}</S.ContentRow>
  </Layout>
);

export default LayoutWrapper;
