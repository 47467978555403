import type { CountryCode } from "libphonenumber-js";
import { AsYouType, getCountryCallingCode } from "libphonenumber-js";

export const normalizePhoneNumber =
  (code?: CountryCode) =>
  (value: string): string => {
    let formattedValue = value;
    if (code && value.length > 0 && value.charAt(0) !== "+") {
      formattedValue = `+${getCountryCallingCode(code)}${value}`;
    }
    return new AsYouType(code).input(formattedValue);
  };
