import { ApolloProvider } from "@apollo/client";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import App from "next/app";
import type { AppProps, AppContext } from "next/app";
import Head from "next/head";
import Router from "next/router";
import type { FC } from "react";
import { Fragment, useState } from "react";
import { baseTheme, DesignWrapper } from "design/setup";
import { generateTheme } from "design/setup/utils";
import { useInitializeClient } from "graphql/client";
import type { ReverssoPage } from "typing";
import { versionReload } from "utils";
import nextI18n, { languages } from "utils/i18n";
import { Shop, Platform } from "wrappers";
import type { ShopConfigType } from "wrappers/Shop/ssr-loader";
import { getShopConfig } from "wrappers/Shop/ssr-loader";
import "design/index.css";
import "antd/dist/antd.css";
import "../styles.css";

const { appWithTranslation, i18n } = nextI18n;

dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);

// "Hot reload" for version changes
Router.events.on("routeChangeStart", () => versionReload());

type MyAppType = AppProps & {
  ssrConfig?: ShopConfigType;
  Component: ReverssoPage<FC>;
};
const MyApp = ({
  Component,
  pageProps,
  ssrConfig: initialSsrConfig,
}: MyAppType) => {
  const { layout } = Component;
  const MainLayout = layout?.Component || Fragment;
  const layoutProps = layout?.props || undefined;

  const apolloClient = useInitializeClient(pageProps);

  const [ssrConfig] = useState(initialSsrConfig);

  if (i18n.language) {
    dayjs.locale(languages[i18n.language as keyof typeof languages].dayjs);
  }

  const theme = {
    ...baseTheme,
    colors: generateTheme({
      main: {
        black: "#8A8A8A",
        blue: "#0173E7",
        primary: ssrConfig?.data?.colors.primary_color || "#0173E7",
        yellow: "#D0AF00",
        orange: "#ED9809",
        red: "#DA1010",
        green: "#00AF70",
        violet: "#960BD6",
      },
      components: {
        button: {
          primary: {
            background: ssrConfig?.data?.colors.btn_primary_bg || "#0173E7",
            text: ssrConfig?.data?.colors.btn_primary_color || "#FFFFFF",
          },
        },
        header: {
          background:
            ssrConfig?.data?.colors.reversso_header_background_color ||
            "#FAFAFA",
          border:
            ssrConfig?.data?.colors.reversso_header_border_color || "#EBEBEB",
          text: ssrConfig?.data?.colors.reversso_header_color || "#242424",
        },
        layout: {
          background:
            ssrConfig?.data?.colors.layout_body_background || "#FAFAFA",
        },
      },
    }),
  };

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>

      <DesignWrapper theme={theme}>
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <Shop ssrConfig={ssrConfig!}>
          <Platform>
            <MainLayout {...layoutProps}>
              <Component {...pageProps} />
            </MainLayout>
          </Platform>
        </Shop>
      </DesignWrapper>
    </ApolloProvider>
  );
};

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  if (!process.browser) {
    const ssrConfig = await getShopConfig(appContext);
    return { ...appProps, ssrConfig };
  }
  return appProps;
};

export default appWithTranslation(MyApp);
