import type {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";
import Typography from "../../deprecated/Typography";
import type { ButtonProps } from "./index";

export const handleButtonSize = (
  size: ButtonProps["$size"],
  onlyIcon: boolean,
  isPlainType?: boolean
): FlattenSimpleInterpolation | null => {
  if (isPlainType) return null;
  switch (size) {
    case "small":
      return css`
        padding: 4px 12px;
        min-height: 28px;
        height: auto;
        ${onlyIcon &&
        css`
          padding: 4px;
          min-width: 28px;
          height: 28px;
          width: 28px;
        `}
      `;
    case "large":
      return css`
        padding: 12px 24px;
        min-height: 44px;
        height: auto;
        ${onlyIcon &&
        css`
          padding: 12px;
          height: 44px;
          min-width: 44px;
          width: 44px;
        `}
      `;
    case "medium":
    default:
      return css`
        padding: 8px 16px;
        min-height: 36px;
        height: 36px;
        height: auto;
        ${onlyIcon &&
        css`
          padding: 8px;
          height: 36px;
          min-width: 36px;
          width: 36px;
        `}
      `;
  }
};

const commonDisabledCss = css`
  cursor: not-allowed;
  ${Typography} {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

const disabledBasicCss = css`
  ${commonDisabledCss}
  background-color: ${({ theme }) => theme.colors.surface.default.disabled};
  border: 1px solid ${({ theme }) => theme.colors.border.default.disabled};
  ${Typography} {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;
const disabledPrimaryCss = css`
  ${commonDisabledCss}
  border: none;
  background-color: ${({ theme }) => theme.colors.action.primary.disabled};
`;

export const handleButtonType: Record<
  NonNullable<ButtonProps["type"]>,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  basic: css`
    background-color: ${({ theme }) => theme.colors.action.secondary.default};
    border: 1px solid ${({ theme }) => theme.colors.border.neutral.subdued};
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.action.secondary.hovered};
      outline: 0;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.action.secondary.pressed};
    }
    &:disabled {
      ${disabledBasicCss}
    }
  `,
  disabled: disabledPrimaryCss,
  "disabled-basic": disabledBasicCss,
  primary: css`
    border: none;
    background-color: ${({ theme }) =>
      theme.colors.components.button.primary.background.default};
    &:hover,
    &:focus {
      background-color: ${({ theme }) =>
        theme.colors.components.button.primary.background.hovered};
      outline: 0;
    }
    &:active {
      background-color: ${({ theme }) =>
        theme.colors.components.button.primary.background.pressed};
    }
    &:disabled {
      ${disabledPrimaryCss}
    }
    &:not([disabled]) ${Typography} {
      color: ${({ theme }) => theme.colors.components.button.primary.text};
    }
  `,
  critical: css`
    border: none;
    background-color: ${({ theme }) => theme.colors.action.critical.default};
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.action.critical.hovered};
      outline: 0;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.action.critical.pressed};
    }
    &:disabled {
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.colors.action.critical.disabled};
    }
    &:not([disabled]) ${Typography} {
      color: ${({ theme }) => theme.colors.text.white};
    }
  `,
  ghost: css`
    background-color: transparent;
    border: none;
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.action.secondary.hovered};
      outline: 0;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.action.secondary.pressed};
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
  `,
  "ghost-primary": css`
    background-color: transparent;
    border: none;
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.action.secondary.hovered};
      outline: 0;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.action.secondary.pressed};
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
    &:not([disabled]) ${Typography} {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  `,
  outline: css`
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.border.default.default};
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.action.secondary.hovered};
      outline: 0;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.action.secondary.pressed};
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.border.default.disabled};
    }
  `,
  "outline-critical": css`
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.border.critical.default};
    &:hover,
    &:focus {
      background-color: ${({ theme }) =>
        theme.colors.surface.critical.subduedHovered};
      outline: 0;
    }
    &:active {
      background-color: ${({ theme }) =>
        theme.colors.surface.critical.subduedPressed};
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.border.default.disabled};
    }
    &:not([disabled]) ${Typography} {
      color: ${({ theme }) => theme.colors.text.critical};
    }
  `,
  plain: css`
    background-color: transparent;
    border: none;
    padding: 2px;
    &:hover,
    &:focus {
      outline: 0;
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
    &:not([disabled]) ${Typography} {
      text-decoration: underline;
    }
  `,
  "plain-primary": css`
    background-color: transparent;
    border: none;
    padding: 2px;
    &:hover,
    &:focus {
      outline: 0;
      &:not([disabled]) ${Typography} {
        text-decoration: underline;
      }
    }
    &:active {
      &:not([disabled]) ${Typography} {
        text-decoration: underline;
      }
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
    &:not([disabled]) ${Typography} {
      color: ${({ theme }) => theme.colors.text.primary};
    }
  `,
  "plain-critical": css`
    background-color: transparent;
    border: none;
    padding: 2px;
    &:hover,
    &:focus {
      outline: 0;
      &:not([disabled]) ${Typography} {
        text-decoration: underline;
      }
    }
    &:active {
      &:not([disabled]) ${Typography} {
        text-decoration: underline;
      }
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
    &:not([disabled]) ${Typography} {
      color: ${({ theme }) => theme.colors.text.critical};
    }
  `,
  "plain-inverted": css`
    background-color: transparent;
    border: none;
    padding: 2px;
    &:hover,
    &:focus {
      outline: 0;
      &:not([disabled]) ${Typography} {
        text-decoration: underline;
      }
    }
    &:active {
      &:not([disabled]) ${Typography} {
        text-decoration: underline;
      }
    }
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
    &:not([disabled]) ${Typography} {
      color: ${({ theme }) => theme.colors.text.white};
    }
  `,
};
