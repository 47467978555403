import { createContext, useContext } from "react";
import type { DefaultTheme } from "styled-components";
import type { DesignDevice } from "./utils";

type DesignContextValues = {
  device: {
    type: DesignDevice;
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isMobileOrTablet: boolean;
    isMobileOrDesktop: boolean;
    isDesktopOrTablet: boolean;
  };
  theme: DefaultTheme;
};
export const DesignContext = createContext<DesignContextValues | null>(null);

export const useDesign = (): DesignContextValues => {
  const val = useContext(DesignContext);
  if (!val) throw new Error("Design not imported");
  return val;
};
