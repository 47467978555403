import type { FC } from "react";
import { useTheme } from "styled-components";

const Illustration: FC = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={246}
      height={208}
      fill="none"
    >
      <path
        fill="#EBEBEB"
        d="M122.5 202c53.681 0 97.201-43.52 97.201-97.393S175.99 7.214 122.5 7.214c-53.681 0-97.201 43.52-97.201 97.393S68.819 202 122.5 202Z"
      />
      <g filter="url(#a)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m109.42 48.013-6.667 23.514 17.259 11.962-1.213 29.221 21.784-39.98-10.118-8.085 10.376-16.632h68.213c3.447 0 6.068 2.758 6.068 6.067v112.803c0 3.31-2.758 6.068-6.068 6.068H37.644c-3.31.138-6.068-2.621-6.068-5.93V54.08c0-3.447 2.758-6.067 6.067-6.067h71.777Z"
          clipRule="evenodd"
        />
        <path
          fill={theme.colors.surface.primary.subduedPressed}
          d="M131.868 62.391h83.249v-8.627a5.752 5.752 0 0 0-5.752-5.752h-68.506l-8.991 14.38ZM31.603 62.391h74.026l3.834-14.378H37.355a5.752 5.752 0 0 0-5.752 5.751v8.627Z"
        />
        <path
          fill="#9FA8BA"
          d="M39.712 57.312a2.069 2.069 0 1 0 0-4.138 2.069 2.069 0 0 0 0 4.138ZM46.193 57.312a2.069 2.069 0 1 0 0-4.138 2.069 2.069 0 0 0 0 4.138ZM52.812 57.312a2.069 2.069 0 1 0 0-4.138 2.069 2.069 0 0 0 0 4.138Z"
        />
        <path
          fill={theme.colors.surface.primary.important}
          d="M98.024 145.305c-.806 0-1.45-.322-2.094-.805-1.128-1.127-1.128-3.06 0-4.188 7.409-7.248 17.234-11.274 27.704-11.274 10.469 0 20.294 4.026 27.703 11.274 1.128 1.128 1.128 3.061 0 4.188-1.127 1.128-3.06 1.128-4.187 0-6.282-6.282-14.658-9.664-23.516-9.664-8.859 0-17.235 3.382-23.516 9.664-.645.483-1.289.805-2.094.805ZM108.171 102.924l3.866-3.866c1.127-1.127 1.127-3.06 0-4.187-1.128-1.128-3.061-1.128-4.188 0l-3.866 3.865-3.865-3.865c-1.128-1.128-3.06-1.128-4.188 0-1.128 1.127-1.128 3.06 0 4.187l3.865 3.866-3.865 3.866c-1.128 1.127-1.128 3.06 0 4.187.644.645 1.288.806 2.094.806.805 0 1.45-.322 2.094-.806l3.865-3.865 3.866 3.865c.644.645 1.288.806 2.094.806.805 0 1.449-.322 2.094-.806 1.127-1.127 1.127-3.06 0-4.187l-3.866-3.866ZM147.058 102.924l3.866-3.866c1.127-1.127 1.127-3.06 0-4.187-1.128-1.128-3.06-1.128-4.188 0l-3.866 3.865-3.865-3.865c-1.128-1.128-3.06-1.128-4.188 0-1.127 1.127-1.127 3.06 0 4.187l3.866 3.866-3.866 3.866c-1.127 1.127-1.127 3.06 0 4.187.644.645 1.289.806 2.094.806.805 0 1.45-.322 2.094-.806l3.865-3.865 3.866 3.865c.644.645 1.289.806 2.094.806.805 0 1.45-.322 2.094-.806 1.127-1.127 1.127-3.06 0-4.187l-3.866-3.866Z"
        />
      </g>
      <path
        fill={theme.colors.surface.primary.importantSubdued}
        strokeLinecap="round"
        strokeWidth={3.834}
        d="m106.169 35.102-7.25-10.914M125.504 29.645 126.355 2M142.839 35.359l11.573-25.12"
      />
      <defs>
        <filter
          id="a"
          width={244.896}
          height={186.293}
          x={0.901}
          y={21.172}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={3.834} />
          <feGaussianBlur stdDeviation={15.338} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2191_2703"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={3.834} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend
            in2="effect1_dropShadow_2191_2703"
            result="effect2_dropShadow_2191_2703"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2191_2703"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Illustration;
