/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
export type TicketRequestResolutionNewProductsFragment = { __typename?: 'TicketRequest', id: string, allowUpselling: boolean, allowDownselling: boolean, isClosed: boolean, newProducts: Array<{ __typename?: 'TicketRequestNewProduct', id: string }>, totalProductsDifferenceAmount: { __typename?: 'Money', amount: number } };

export const TicketRequestResolutionNewProductsFragmentDoc = gql`
    fragment TicketRequestResolutionNewProducts on TicketRequest {
  id
  allowUpselling
  allowDownselling
  isClosed
  newProducts {
    id
  }
  totalProductsDifferenceAmount {
    amount
  }
}
    `;