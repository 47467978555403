import type { FC } from "react";
import type { TextProps } from "./Text";
import Text from "./Text";

type TextOptionsProps = Omit<TextProps, "$type">;

export const Button: FC<TextOptionsProps> = ({ children, ...props }) => (
  <Text $type="button" {...props}>
    {children}
  </Text>
);

export const Body: FC<TextOptionsProps> = ({ children, ...props }) => (
  <Text $type="body" {...props}>
    {children}
  </Text>
);

export const Caption: FC<TextOptionsProps> = ({ children, ...props }) => (
  <Text $type="caption" {...props}>
    {children}
  </Text>
);
