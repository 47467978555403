import type {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { css } from "styled-components";
import { DESKTOP_L_BREAKPOINT, DESKTOP_M_BREAKPOINT } from "../../constants";
import type { ContainerSectionProps } from "./styles";

export const handleSize: Record<
  ContainerSectionProps["$layoutSize"],
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  small: css`
    max-width: 610px;
    @media ${({ theme }) => theme.breakpoints.tablet.css} {
      max-width: unset;
    }
  `,
  medium: css`
    max-width: 988px;
    @media ${({ theme }) => theme.breakpoints.tablet.css} {
      max-width: unset;
    }
  `,
  large: css`
    @media (min-width: ${DESKTOP_M_BREAKPOINT}px) and (max-width: ${DESKTOP_L_BREAKPOINT}px) {
      max-width: 1224px;
    }
    @media (min-width: ${DESKTOP_L_BREAKPOINT}px) {
      max-width: 1422px;
    }
  `,
};
