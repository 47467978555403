import styled, { css } from "styled-components";
import { Card, Collapse, CollapseStyles, Row } from "design";

type Props = {
  $hasBackgroundImage: boolean;
};

export const ContainerRow = styled(Row)`
  margin: auto 0;
  @media ${({ theme }) => theme.breakpoints.tablet.css} {
    margin: 16px 0 auto;
  }
`;

export const FAQCollapse = styled(Collapse)`
  ${CollapseStyles.CollapseHeader} {
    padding: 8px 0;
  }
  ${CollapseStyles.CollapseIcon} {
    display: grid;
    top: 2px;
  }
`;

export const ContainerForm = styled.form`
  width: 100%;
`;

export const FAQContainer = styled.div`
  width: 358px;
  max-width: 358px;
  height: 100%;
`;

export const ContentContainerDiv = styled.div`
  max-width: 399px;
  ${Card} {
    margin-bottom: 20px;
    @media ${({ theme }) => theme.breakpoints.mobile.css} {
      margin-bottom: 8px;
    }
  }
`;

export const CardWelcomeMessageDiv = styled.div<Props>`
  ${({ theme, $hasBackgroundImage }) =>
    $hasBackgroundImage &&
    css`
      border-radius: 4px;
      color: ${theme.colors.text.white};
      max-width: 359px;
      width: fit-content;
      padding: 4px 8px;
      background-color: ${theme.colors.surface.default.inverted}b3;
    `};
  > * {
    /* using !important because the welcomeMessage comes with inline styles from the backend */
    padding: unset !important;
  }
`;

export const BackToStoreAnchor = styled.a<Props>`
  padding: 8px;
  ${({ theme, $hasBackgroundImage }) =>
    $hasBackgroundImage &&
    css`
      border-radius: 4px;
      width: fit-content;
      background-color: ${theme.colors.surface.default.inverted}b3;
    `}
`;

export const LogoContainerRow = styled(Row)`
  ${({ theme }) =>
    css`
      position: relative;
      top: 4px;
      margin-top: -17px;
      margin-left: -17px;
      width: calc(100% + 34px);
      height: 56px;
      border-radius: ${theme.borderRadius.card} ${theme.borderRadius.card} 0 0;
      background-color: ${theme.colors.components.header.background};
      border: 1px solid ${theme.colors.border.default.subdued};
      border-bottom: 0;
      justify-content: center;
      align-items: center;
      img {
        max-height: 32px;
        max-width: calc(100% - 32px);
      }
    `}
`;

export const CardContentRow = styled(Row)`
  margin-top: 8px;
`;

export const SupportEmailContainerRow = styled(Row)<Props>`
  margin: 0 auto;
  padding: 4px 8px;
  ${({ theme, $hasBackgroundImage }) =>
    $hasBackgroundImage &&
    css`
      border-radius: 4px;
      width: fit-content;
      background-color: ${theme.colors.surface.default.inverted}b3;
    `}
`;

export const SupportEmail = styled.a`
  color: inherit;
  font-size: inherit;
  :hover {
    color: inherit;
    text-decoration: underline;
  }
`;
