import BaseMenu from "./Menu";
import MenuItem from "./MenuItem";

export * as MenuStyles from "./Menu/styles";
export * as MenuItemStyles from "./MenuItem/styles";

export type { MenuProps } from "./Menu";
export type { MenuItemProps } from "./MenuItem";

type CompoundedComponent = typeof BaseMenu & {
  Item: typeof MenuItem;
};

const Menu = BaseMenu as CompoundedComponent;
Menu.Item = MenuItem;

export default Menu;
