import Portal from "rc-util/lib/Portal";
import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import BaseDrawer from "../../../atoms/Drawer/BaseDrawer";
import Row from "../../../atoms/Row";
import Typography from "../../../deprecated/Typography";
import Button from "../../Button";
import type { BaseProps } from "../types";
import * as S from "./styles";
import { useDelayedBoolean } from "hooks";
import nextI18n from "utils/i18n";

const { useTranslation } = nextI18n;
const { Title } = Typography;

export type MobileModalProps = BaseProps & {
  fullHeight?: boolean;
};

const MobileModalInner: FC<MobileModalProps> = ({
  title,
  subTitle,
  onBack,
  onClose,
  children,
  visible,
  fullHeight,
  destroyOnClose,
  ...props
}) => {
  const { t } = useTranslation("common");

  const [rendered, setRendered] = useState(false);
  const { display } = useDelayedBoolean(visible, S.animationTime);

  useEffect(() => {
    if (!rendered && visible) setRendered(true);
  }, [visible]);

  // destroyOnClose will unmount the component
  if (destroyOnClose && !display) return null;
  if (!rendered) return null;

  const closable: boolean = "closable" in props && !!props.closable;

  const footer: ReactNode[] = [];
  if (!props.withoutFooter) {
    const { onOk, onCancel, extraFooter } = props;
    if (extraFooter) footer.push(extraFooter);
    if (onOk) {
      footer.push(
        <Button key="mobile-modal-ok" type="primary" $block {...onOk}>
          {onOk?.children || t("confirm")}
        </Button>
      );
    }
    if (onCancel) {
      const { skipOnClose, ..._onCancel } =
        typeof onCancel === "boolean" ? { skipOnClose: false } : onCancel;
      footer.push(
        <Button
          key="mobile-modal-cancel"
          type="outline"
          $block
          {..._onCancel}
          onClick={(e) => {
            if (!skipOnClose) onClose?.();
            return _onCancel?.onClick?.(e);
          }}
        >
          {_onCancel?.children || t("cancel")}
        </Button>
      );
    }
  }

  return (
    <BaseDrawer
      {...props}
      $placement="bottom"
      fullHeight={fullHeight}
      onClose={onClose}
      visible={visible}
      header={
        <Row $column $block $gap={4}>
          <Row
            $noWrap
            $block
            $gap={12}
            $justify={!onBack ? "end" : "space-between"}
          >
            {onBack && (
              <S.BackIcon size={20} onClick={onBack} cursor="pointer" />
            )}
            {closable && (
              <S.CloseIcon size={20} onClick={onClose} cursor="pointer" />
            )}
          </Row>
          {title && <Title.Heading>{title}</Title.Heading>}
          {subTitle && (
            <Title.Subheading $color="subdued">{subTitle}</Title.Subheading>
          )}
        </Row>
      }
      footer={footer.length > 0 ? footer : null}
    >
      {children}
    </BaseDrawer>
  );
};

const MobileModal: FC<MobileModalProps> = (props) => (
  <Portal
    getContainer={() =>
      document.body.appendChild(document.createElement("div"))
    }
  >
    <MobileModalInner {...props} />
  </Portal>
);

export default styled(MobileModal)``;
