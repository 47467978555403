/* tslint:disable */
/* eslint-disable */
import * as Types from '../../../../../../graphql/generated/schema.platform';

import { gql } from '@apollo/client';
import { MoneyFragmentDoc } from '../../../../../../graphql/generated/fragments.platform.generated';
export type SiderReturnOrderProductTicketFragment = { __typename?: 'TicketReturnOrderProduct', returnProduct: { __typename?: 'TicketReturnProduct', id: string, reason: { __typename?: 'TicketReturnProductReason', reason: string } } | null, orderProduct: { __typename?: 'OrderProduct', id: string, imageUrl: string | null, name: string, variantValues: string, options: Array<{ __typename?: 'OrderProductOption', name: string, value: string }>, price: { __typename?: 'Money', formattedAmount: string } } };

export type SiderReturnOrderProductTicketRequestFragment = { __typename?: 'TicketRequestReturnOrderProduct', id: string, returnProduct: { __typename?: 'TicketRequestReturnProduct', id: string, reason: { __typename?: 'TicketRequestReturnProductReason', title: string } } | null, orderProduct: { __typename?: 'OrderProduct', id: string, imageUrl: string | null, name: string, variantValues: string, options: Array<{ __typename?: 'OrderProductOption', name: string, value: string }>, price: { __typename?: 'Money', formattedAmount: string } } };

export type SiderReturnProductsTicketFragment = { __typename?: 'Ticket', id: string, returnOrderProducts: Array<{ __typename?: 'TicketReturnOrderProduct', returnProduct: { __typename?: 'TicketReturnProduct', id: string, reason: { __typename?: 'TicketReturnProductReason', reason: string } } | null, orderProduct: { __typename?: 'OrderProduct', id: string, imageUrl: string | null, name: string, variantValues: string, options: Array<{ __typename?: 'OrderProductOption', name: string, value: string }>, price: { __typename?: 'Money', formattedAmount: string } } }>, returnProductsTotalAmount: { __typename?: 'Money', amount: number, formattedAmount: string } };

export type SiderReturnProductsTicketRequestFragment = { __typename?: 'TicketRequest', id: string, returnOrderProducts: Array<{ __typename?: 'TicketRequestReturnOrderProduct', id: string, returnProduct: { __typename?: 'TicketRequestReturnProduct', id: string, reason: { __typename?: 'TicketRequestReturnProductReason', title: string } } | null, orderProduct: { __typename?: 'OrderProduct', id: string, imageUrl: string | null, name: string, variantValues: string, options: Array<{ __typename?: 'OrderProductOption', name: string, value: string }>, price: { __typename?: 'Money', formattedAmount: string } } }>, returnProductsTotalAmount: { __typename?: 'Money', amount: number, formattedAmount: string } };

export const SiderReturnOrderProductTicketFragmentDoc = gql`
    fragment SiderReturnOrderProductTicket on TicketReturnOrderProduct {
  returnProduct {
    id
    reason {
      reason
    }
  }
  orderProduct {
    id
    imageUrl
    name
    variantValues
    options {
      name
      value
    }
    price {
      formattedAmount
    }
  }
}
    `;
export const SiderReturnProductsTicketFragmentDoc = gql`
    fragment SiderReturnProductsTicket on Ticket {
  id
  returnOrderProducts {
    ...SiderReturnOrderProductTicket
  }
  returnProductsTotalAmount {
    ...Money
  }
}
    ${SiderReturnOrderProductTicketFragmentDoc}
${MoneyFragmentDoc}`;
export const SiderReturnOrderProductTicketRequestFragmentDoc = gql`
    fragment SiderReturnOrderProductTicketRequest on TicketRequestReturnOrderProduct {
  id
  returnProduct {
    id
    reason {
      title
    }
  }
  orderProduct {
    id
    imageUrl
    name
    variantValues
    options {
      name
      value
    }
    price {
      formattedAmount
    }
  }
}
    `;
export const SiderReturnProductsTicketRequestFragmentDoc = gql`
    fragment SiderReturnProductsTicketRequest on TicketRequest {
  id
  returnOrderProducts {
    ...SiderReturnOrderProductTicketRequest
  }
  returnProductsTotalAmount {
    ...Money
  }
}
    ${SiderReturnOrderProductTicketRequestFragmentDoc}
${MoneyFragmentDoc}`;